import promptsSet from "../content/prompts";

// Defines constants relating to the path in the game map
// Used in GameMap.jsx and PathAndMarker.jsx

// To pass props for each prompt
export const PROMPTS = promptsSet;

// Position of the marker when on the indicated path block, according to
// PathAndMarker component's SVG element
// Currently hard-coded, could be calculated instead
export const BLOCK_POSITIONS = {
    'map-block-0': {x: 20, y: -48},
    'map-block-1': {x: 110, y: -48},
    'map-block-2': {x: 225, y: -48},
    'map-block-3': {x: 350, y: -48},
    'map-block-4': {x: 412, y: -160},
    'map-block-5': {x: 420, y: -240},
    'map-block-6': {x: 590, y: -260},
    'map-block-7': {x: 705, y: -260},
    'map-block-8': {x: 805, y: -260},
    'map-block-9': {x: 915, y: -260},
    'map-block-10': {x: 1030, y: -260},
    'map-block-11': {x: 1160, y: -125},
    'map-block-12': {x: 1260, y: -125},
    'map-block-13': {x: 1380, y: -125},
    'map-block-14': {x: 1510, y: -125},
    'map-block-15': {x: 1650, y: -125},
    'map-block--1': {x: 1830, y: -125},
}

// Indexes of the path blocks
export const START_BLOCK = 0;
export const NUM_PROMPTS = 15;
export const FINISH_BLOCK = -1;
