import React from "react";
import { Box, styled, useMediaQuery } from "@mui/material";
import {
    PageContainer,
    PageHeader,
    WorkshopTypeSelector,
} from "@features/learn";
import { useLearnContext } from "@hooks";

const PageContent = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isLessThan768px"
})(({ isLessThan768px }) => ({
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: isLessThan768px ? "column" : "row",
    flexWrap: "wrap",
    gap: "8px",
    padding: "0 16px",
}));

const SessionsPage = () => {
    const { getSessionPromotion, getSessions } = useLearnContext();
    const isLessThan768px = useMediaQuery("(max-width: 768px)");
    const header = {
        title: "1 : 1 Sessions",
        caption: "When it comes to your money, there's nothing like personal advice. 1:1 financial planning sessions are a total game-changer.",
    };
    // const cardStyles = { alignSelf: "stretch", width: "auto" };
    // const sessions = getSessions(cardStyles);
    const sessions = getSessionPromotion();

    return (
        <PageContainer sx={{ gap: "24px" }}>
            <PageHeader variant="h2" {...header}>
                <WorkshopTypeSelector selected="one-to-one" />
            </PageHeader>
            <PageContent isLessThan768px={isLessThan768px}>
                {sessions}
            </PageContent>
        </PageContainer>
    );
};

export default SessionsPage;
