const page1 = [
    {
        type: "title",
        text: "What steps can I take to ensure long-term financial security?",
    },
    {
        type: "body",
        text: "Diversify your investments across different asset classes—stocks, bonds, real estate, and maybe even some commodities. Ensure you have a well-funded emergency fund. Regularly review and adjust your investment portfolio to stay aligned with your long-term goals.",
    },
];

const page2 = [
    {
        type: "title",
        text: "Consider investing in rental properties for passive income and inflation protection.",
    },
    {
        type: "body",
        text: "Stay disciplined with your savings and investment strategy, avoiding impulsive decisions based on market fluctuations. Secure your future, darling—you deserve nothing but the best!",
    },
];

const content = [page1, page2];

const financialSecurity = {
    topic: "What steps can I take to ensure long-term financial security?",
    image: "/learn/financialTips/financial_security.png",
    color: {
        cardColor: "#E7D19E",
        contentColor: "#91742F",
    },
    imageStyles: {
        bottom: -28, objectFit: "contain", position: "absolute", right: -30,
    },
    content: content,
};

export default financialSecurity;
