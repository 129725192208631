import React from "react";
import { Box, styled } from "@mui/material";
import { BottomNavBar, NavigationSync, TopNavBar } from "../components";
import { useNavigation } from "@hooks";

const Frame = styled(Box, {
  shouldForwardProp: (prop) => prop !== "adjustHeight",
})(({ adjustHeight }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "100%",
  height: adjustHeight ? "100vh" : "auto",
  minHeight: "calc(100vh - 78px)",
}));

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "showBottom",
})(({ showBottom }) => ({
  flex: "1 1 auto",
  paddingBottom: showBottom ? 78 : 0,
  overflowY: "auto",
  // justifyContent: "stretch",
  // height: "120vh",
  // BOTTOM_NAV_BAR_HEIGHT = 78px
  // TOP_NAV_BAR_HEIGHT = 96pxFF
  // height: showBottom ? "calc(100%)" : "100vh",
  // height: "calc(100vh - 96px - 78px)",
  // marginTop: "96px",
  // overflow: "overlay",
}));

const Navigation = ({ children }) => {
  const { adjustHeight, showNavBar } = useNavigation();

  return (
    <Frame adjustHeight={adjustHeight}>
      {/* {showNavBar.top && <TopNavBar />} */}
      <Wrapper showBottom={showNavBar.bottom}>
        <NavigationSync />
        {children}
      </Wrapper>
      {showNavBar.bottom && <BottomNavBar />}
    </Frame>
  );
};

export default Navigation;
