const name = {
  id: "name",
  inputType: "textfield",
  dataType: "text",
  label: "Full Name",
  question: "What's your name?",
};

const birthYear = {
  id: "birthYear",
  inputType: "textfield",
  dataType: "number",
  label: "Enter Year",
  question: "What year were you born?",
};

const ethnicity = {
  id: "ethnicity",
  inputType: "chip",
  dataType: "array",
  question: "Would you consider yourself to be...",
  validation: "you can select one or more options that apply",
  options: [
    "English White",
    "White Others",
    "Black/African descendant",
    "Asian/Asian descendant",
    "Hispanic/Latinx",
    "Other",
    "Mix",
    "Prefer not to say",
  ],
  other: false,
};

const gender = {
  id: "gender",
  inputType: "chip",
  dataType: "single",
  question: "How would you identify yourself?",
  validation: false,
  options: ["She / Her", "They / Them", "He / Him"],
  other: false,
};

const priorities = {
  id: "priorities",
  inputType: "checkbox",
  dataType: "single",
  question: "Which of below best describes your current priorities?",
  options: [
    "I'm focused on increasing my wealth",
    "I'm focused on boosting my financial health",
    "Being financial literate is equally important as the actual financial action for me at the moment",
  ],
};

const attributes = {
  id: "attributes",
  inputType: "chip",
  dataType: "array",
  question: "Which of the following best describes you?",
  validation: "you can select one or more options that apply",
  options: [
    "Loves Spending",
    "Smart with Budgets",
    "Social and Enjoy Spending",
    "Loves Adventure and Saves",
    "Enjoys DIY and Saving",
    "Focused on Career and Saving",
  ],
  other: true,
};

const motivations = {
  id: "motivation",
  inputType: "chip",
  dataType: "single",
  question: "What's your main motivation to increase wealth?",
  validation: false,
  options: [
    "Kids",
    "Financial Independence",
    "Better Lifestyle",
    "Comfortable Retirement",
  ],
  other: true,
};

const financialGoals = {
  id: "financialGoals",
  inputType: "checkbox",
  dataType: "single",
  question: "What are your top 3 financial goals this year?",
  options: [
    "Clear debt",
    "Save for home downpayment",
    "Start investing",
    "Boost salary",
    "Major purchase plan",
    "Start Side income hustle",
    "Generate passive income",
    "Others",
  ],
};

const goals = {
  id: "goals",
  inputType: "chip",
  dataType: "array",
  question:
    "What are your goals in using our services and what do you hope to accomplish?",
  validation: false,
  options: [
    "Financial independence",
    "Early retirement",
    "Achieve 1 million net worth",
  ],
  other: true,
};

const page1 = [name, birthYear, ethnicity, gender];
const page2 = [priorities, attributes, motivations, financialGoals];
const page3 = [goals];

const questions = [page1, page2, page3];
const totalPages = 3;

export default questions;
export { totalPages };
