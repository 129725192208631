import React from "react";
import { Box, styled } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    width: "100%",
    height: "100%",
    padding: "0px 40px",
  },
}));

const Content = styled("img")(() => ({
  maxHeight: "100%",
  maxWidth: "100%",
  objectFit: "contain",
}));

const Image = ({ src }) => {
  return (
    <Container>
      <Content src={src} alt="" />
    </Container>
  );
};

export default Image;

// eslint-disable-next-line
const OldImage = (props) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      position={{ md: "absolute" }}
      bottom={{ md: 0 }}
    >
      <Box
        component={"img"}
        width={"100%"}
        maxWidth={{ xs: "300px", md: "30vw", lg: "25vw" }}
        {...props}
      />
    </Box>
  );
};
