import React from "react";
import { Box, IconButton, styled, SvgIcon, Typography } from "@mui/material";

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
}));

const IconWrapper = styled(IconButton)(({ theme }) => ({
  height: "22px",
  width: "22px",
  padding: 0,
}));

const Icon = () => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      inheritViewBox
      style={{ height: "100%", width: "100%" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 9 14"
        fill="none"
      >
        <path
          d="M7.5 1L1.5 7L7.5 13"
          stroke="#065D44"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

const Text = styled(Typography)(() => ({
  color: "#065D44", // UI-500
}));

const BackButton = () => {
  return (
    <Container>
      <IconWrapper aria-label="back">
        <Icon />
      </IconWrapper>
      <Text variant="bodyEmphasis">Back</Text>
    </Container>
  );
};

export default BackButton;
