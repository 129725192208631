import React, { useEffect, useState } from "react";
import Avatar from "./Avatar";
import ClosePopup from "./ClosePopup";
import {
  formatMoneyWithSpace,
  getCurrentPlayerAge,
  getNetWorth,
} from "../playerState";
import { ProjectionProvider } from "@features/projection/contexts/ProjectionContext";
import NetWorthProjection from "@features/projection/components/projectionscreen/networthchart/NetWorthProjectionChart";
import FinancialChart from "@features/projection/components/projectionscreen/networthchart/FinancialChart";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";

const MyNetWorthIcon = ({ size = "20" }) => {
  return (
    <div className="my-net-worth-icon popup-button-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        viewBox="0 0 17 18"
        fill="black"
      >
        <path d="M0 5.41659V8.79737H0.85V13.8664H0V16.4014H13.6L16.15 16.4022L17 16.4014V13.8664H16.15V8.79737H17V5.41659L8.5 0.34668L0 5.41659ZM3.4 13.8664V8.79737H5.1V13.8664H3.4ZM7.65 13.8664V8.79737H9.35V13.8664H7.65ZM13.6 13.8664H11.9V8.79737H13.6V13.8664ZM10.2 5.41659C10.1999 5.63857 10.1559 5.85837 10.0704 6.06344C9.98489 6.26851 9.8596 6.45482 9.70166 6.61175C9.54372 6.76868 9.35623 6.89315 9.14991 6.97805C8.94358 7.06295 8.72245 7.10661 8.49915 7.10656C8.27585 7.1065 8.05474 7.06272 7.84846 6.97772C7.64217 6.89272 7.45475 6.76816 7.29689 6.61115C7.13903 6.45415 7.01383 6.26777 6.92842 6.06266C6.84302 5.85755 6.79909 5.63773 6.79915 5.41574C6.79926 4.96742 6.97852 4.53751 7.29749 4.22058C7.61646 3.90365 8.04902 3.72566 8.5 3.72577C8.95098 3.72589 9.38345 3.90409 9.70226 4.22118C10.0211 4.53827 10.2001 4.96827 10.2 5.41659Z" />
      </svg>
    </div>
  );
};

const FinancesSummary = (props) => {
  const { totalIncome, totalSavings, totalInvestments, totalPension } = props;

  return (
    <div className="finances-summary">
      <div className="income-section">
        <p>Monthly Income</p>
        <p>{formatMoneyWithSpace(totalIncome)}</p>
      </div>
      <div className="savings-section">
        <p>Savings</p>
        <p>{formatMoneyWithSpace(totalSavings)}</p>
      </div>
      <div className="investments-section">
        <p>Investments</p>
        <p>{formatMoneyWithSpace(totalInvestments)}</p>
      </div>
      <div className="pension-section">
        <p>Pension</p>
        <p>{formatMoneyWithSpace(totalPension)}</p>
      </div>
    </div>
  );
};

const NetWorthChart = (props) => {
  const { state, dispatch } = useProjectionContext();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const annualSalary = props.income.salary * 12;
    const initialSavings = props.savings.savingsAccount;
    const initialInvestments = props.investments.total;
    const totalPension = props.pension.total;

    dispatch({
      type: "SET_SAVINGS",
      payload: {
        ...state.savings,
        initialAmount: initialSavings /* monthlyInvestment: 100 */,
      },
    });

    dispatch({
      type: "SET_INVESTMENT",
      payload: {
        ...state.investment,
        initialAmount: initialInvestments /* monthlyInvestment: 100 */,
      },
    });

    dispatch({
      type: "SET_PENSION",
      payload: {
        ...state.pension,
        annualSalary,
        totalPension /* monthlyInvestment: 100 */,
      },
    });

    dispatch({
      type: "SET_CURRENT_AGE",
      payload: getCurrentPlayerAge(),
    });

    // Hide chart if empty
    setVisible(
      annualSalary !== 0 || initialSavings !== 0 || initialInvestments !== 0
    );
  }, [
    dispatch,
    props.income.salary,
    props.savings.savingsAccount,
    props.investments.total,
    props.pension.total,
  ]);

  return (
    visible && (
      <div id="net-worth-chart">
        <FinancialChart />
      </div>
    )
  );
};

const Overview = (props) => {
  // const { totalNetWorth } = props;
  const { total, income, savings, investments, pension } = props;

  return (
    <div id="overview-section" className="popup-section">
      <div id="net-worth">
        <Avatar />
        <p>My Current Net Worth</p>
        <h1>{formatMoneyWithSpace(total)}</h1>
      </div>
      <FinancesSummary
        totalIncome={income.total}
        totalSavings={savings.total}
        totalInvestments={investments.total}
        totalPension={pension.total}
      />
      <ProjectionProvider>
        <NetWorthChart {...props} />
      </ProjectionProvider>
    </div>
  );
};

// Represents a financial product and it's value in a section
const SectionRow = ({ product, value, visible = false }) => {
  return (
    visible && (
      <div className="section-row">
        <p>{product}</p>
        <p>{formatMoneyWithSpace(value)}</p>
      </div>
    )
  );
};

const Income = ({ salary, mortgage, spending }) => {
  const [section, setSection] = useState(false);
  const [visible, setVisible] = useState({
    salary: false,
    mortgage: false,
    spending: false,
  });

  // Check if one of salary, mortgage, spending is zero
  // If any one is non-zero, show the section
  useEffect(() => {
    if (salary !== 0 || mortgage !== 0 || spending !== 0) {
      setSection(true);
      setVisible({
        salary: salary !== 0,
        mortgage: mortgage !== 0,
        spending: spending !== 0,
      });
    }
  }, [salary, mortgage, spending]);

  return (
    section && (
      <div className="popup-section income-section">
        <h3 className="section-header">Income</h3>
        <SectionRow
          product={"Monthly Salary"}
          value={salary}
          visible={visible.salary}
        />
        <SectionRow
          product={"Monthly Mortgage"}
          value={mortgage}
          visible={visible.mortgage}
        />
        {/* Special for Monthly Spending */}
        {visible.spending && (
          <div className="section-row">
            <div id="monthly-spending">
              <p>Monthly Spending</p>
              <p style={{ fontSize: "11px" }}>Essential and non-essential</p>
            </div>
            <p>{formatMoneyWithSpace(spending)}</p>
          </div>
        )}
      </div>
    )
  );
};

const Savings = ({ savingsAccount, emergencyFund, cash }) => {
  const [section, setSection] = useState(false);
  const [visible, setVisible] = useState({
    savingsAccount: false,
    emergencyFund: false,
    cash: false,
  });

  // Check if one of savingsAccount, emergencyFund, cash is zero
  // If any one is non-zero, show the section
  useEffect(() => {
    if (savingsAccount !== 0 || emergencyFund !== 0 || cash !== 0) {
      setSection(true);
      setVisible({
        savingsAccount: savingsAccount !== 0,
        emergencyFund: emergencyFund !== 0,
        cash: cash !== 0,
      });
    }
  }, [savingsAccount, emergencyFund, cash]);

  return (
    section && (
      <div className="popup-section savings-section">
        <h3 className="section-header">Savings</h3>
        <SectionRow
          product={"4% Savings Account"}
          value={savingsAccount}
          visible={visible.savingsAccount}
        />
        <SectionRow
          product={"4% Emergency Fund"}
          value={emergencyFund}
          visible={visible.emergencyFund}
        />
        <SectionRow
          product={"Available Cash"}
          value={cash}
          visible={visible.cash}
        />
      </div>
    )
  );
};

const Investments = ({ indexFund, blueChipStocks, isaAccount, juniorISA }) => {
  const [section, setSection] = useState(false);
  const [visible, setVisible] = useState({
    indexFund: false,
    blueChipStocks: false,
    isaAccount: false,
    juniorISA: false,
  });

  // Check if one of indexFund, blueChipStocks, juniorISA is zero
  // If any one is non-zero, show the section
  useEffect(() => {
    if (
      indexFund !== 0 ||
      blueChipStocks !== 0 ||
      isaAccount !== 0 ||
      juniorISA !== 0
    ) {
      setSection(true);
      setVisible({
        indexFund: indexFund !== 0,
        blueChipStocks: blueChipStocks !== 0,
        isaAccount: isaAccount !== 0,
        juniorISA: juniorISA !== 0,
      });
    }
  }, [indexFund, blueChipStocks, isaAccount, juniorISA]);

  return (
    section && (
      <div className="popup-section investments-section">
        <h3 className="section-header">Investments</h3>
        <SectionRow
          product={"8% Index Fund"}
          value={indexFund}
          visible={visible.indexFund}
        />
        <SectionRow
          product={"4.5% Blue Chip Stock"}
          value={blueChipStocks}
          visible={visible.blueChipStocks}
        />
        <SectionRow
          product={"8% Stocks and Shares ISA"}
          value={isaAccount}
          visible={visible.isaAccount}
        />
        <SectionRow
          product={"8% Junior ISA Stocks and Shares"}
          value={juniorISA}
          visible={visible.juniorISA}
        />
      </div>
    )
  );
};

const Pension = ({ annualGrowth }) => {
  const [section, setSection] = useState(false);
  const [visible, setVisible] = useState({
    annualGrowth: false,
  });

  // Check if annualGrowth is zero, if so, show the section
  useEffect(() => {
    if (annualGrowth !== 0) {
      setSection(true);
      setVisible({
        annualGrowth: annualGrowth !== 0,
      });
    }
  }, [annualGrowth]);

  return (
    section && (
      <div className="popup-section pension-section">
        <h3 className="section-header">Pension</h3>
        <SectionRow
          product={"4% Annual Growth"}
          value={annualGrowth}
          visible={visible.annualGrowth}
        />
      </div>
    )
  );
};

// Bank Statement Popup displayed when MyNetWorth is clicked
const BankStatement = ({ setBankStatement }) => {
  const NET_WORTH = getNetWorth();

  return (
    <div id="bank-statement" className="bank-statement popup">
      <div className="bank-statement-content popup-content">
        <ClosePopup setPopup={setBankStatement} />
        <Overview {...NET_WORTH} />
        <div className="popup-sections">
          <Income {...NET_WORTH.income} />
          <Savings {...NET_WORTH.savings} />
          <Investments {...NET_WORTH.investments} />
          <Pension {...NET_WORTH.pension} />
        </div>
      </div>
    </div>
  );
};

const MyNetWorth = () => {
  const [bankStatement, setBankStatement] = useState(null);

  const showNetWorth = () => {
    setBankStatement(<BankStatement setBankStatement={setBankStatement} />);
  };

  return (
    <div>
      <div className="popup-button" onClick={showNetWorth}>
        <MyNetWorthIcon />
        <p>My Net Worth</p>
      </div>
      {bankStatement}
    </div>
  );
};

export default MyNetWorth;
export { BankStatement };
