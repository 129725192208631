import React from "react";
import { Typography, Grid, Box, styled } from "@mui/material";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import { useAuthContext } from "@hooks";
import RetirementAgeButton from "./RetirementAgeButton";
import CurrentAgeButton from "./CurrentAgeButton";
import Frame from "./Frame";

const Description = () => {
  const { state } = useProjectionContext();
  const { currentAge } = state;

  const text = `You'll be projected for next 30 years, until the age of ${
    Number(currentAge) + 30
  }.`;

  return (
    <Typography fontSize={"13px"} lineHeight={"18px"}>
      {text}
    </Typography>
  );
};

const Title = () => {
  const { givenName } = useAuthContext();
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={"16px"}
      alignSelf={"stretch"}
    >
      <Typography fontSize={"15px"} lineHeight={"25px"}>
        👋 Hi{givenName ? " " + givenName : ""},
      </Typography>
      <Typography
        fontSize={"15px"}
        lineHeight={"25px"}
        sx={{ textShadow: "0px 4px 4px rgba(0,0,0,0.25)" }}
      >
        Welcome to your Future net worth projection!
      </Typography>
    </Box>
  );
};

const Age = () => {
  return (
    <Grid
      container
      direction={"row"}
      alignItems={"flex-start"}
      alignSelf={"stretch"}
      columnSpacing={"4px"}
    >
      <Grid item xs={6}>
        <CurrentAgeButton />
      </Grid>
      <Grid item xs={6}>
        <RetirementAgeButton />
      </Grid>
    </Grid>
  );
};

const InnerBody = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 24,
  alignSelf: "stretch",
});

const HelloCard = () => {
  return (
    <Frame>
      <InnerBody>
        <Title />
        <Age />
      </InnerBody>
      <Description />
    </Frame>
  );
};

export default HelloCard;
