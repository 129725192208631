const page = {
  title:
    "You just bought a new phone for £899 and were offered insurance for £10 per month. ",
  description:
    "Would you purchase it? Consider the situation carefully before making any decisions",
  choices: [
    {
      choice: "Yes",
      outcome: [
        {
          title: "Well done!",
          caption:
            "Keep up the great work! You invested £600 total this year, receiving approximately £23 as a dividend!",
        },
        {
          title: "Your phone got stolen while shopping downtown. ",
        },
        {
          title: "With insurance, you paid £100 to replace it.",
          caption:
            "It sucks that your phone got stolen, but your insurance covers it so all good!",
        },
      ],
    },
    {
      choice: "No",
      outcome: [
        {
          type: "consequence",
          title: "No insurance for your phone",
          caption:
            "It's okay, insurance is a personal choice. Nothing changes in your finances. ",
        },
        {
          title: "Your phone got stolen while shopping downtown. ",
        },
        {
          type: "consequence",
          title: "You lost £899 and had to buy a new phone.",
          caption:
            "It sucks that your phone got stolen, now you took another £899 to get the new phone, deducting £1899 from your account. ",
        },
      ],
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/phone-in-hand.png",
    style: {
      backgroundColor: "#F7BFD9",
    },
  },
};

const tipScreen = {
  content: [
    {
      type: "title",
      text: "Phone insurance used to be seen as unnecessary, but with crime rates rising, it's becoming more important. ",
    },
    {
      type: "paragraph",
      text: "Unexpected things happen, and having insurance, along with an emergency fund, helps protect you financially. Without them, people often end up in debt. Think about what you need: travel a lot? Get travel insurance. Regular health checkups? Consider private health insurance. Living in a risky area? Insure your valuables.",
    },
    {
      type: "paragraph",
      text: "Important insurances include Health, Auto, Home/Renter's, Life, and Travel Insurance.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/umbrella.jpeg",
    style: {
      backgroundColor: "#DBE9EA",
    },
  },
};

const tips = [
  tipScreen,
];

const prompt6 = {
  type: "choice",
  page: page,
  tips: tips,
  colour: {
    primary: "#FFDCDC",
    secondary: "#E04B77",
  },
};

export default prompt6;
