import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  financialProducts,
  productCategories,
} from "@content/financialProducts";
import financialTips from "@content/financialTips";
import workshops from "@content/workshops";
import {
  FinancialProductPage,
  FinancialProductsPage,
  FinancialTipPage,
  FinancialTipsPage,
  LearnPage,
  SessionsPage,
  WorkshopPage,
  WorkshopsPage,
} from "@pages/Learn";
import { getComponentPath } from "@lib/learn";

const LearnRoutes = () => {
  const workshopRoutes = Object.entries(workshops).map(
    ([id, workshop], index) => (
      <Route
        key={index}
        path={`workshops/${getComponentPath(workshop.topic)}`}
        element={<WorkshopPage id={id} />}
      />
    )
  );
  const sessionRoutes = [];
  const tipRoutes = Object.entries(financialTips).map(([id, tip], index) => (
    <Route
      key={index}
      path={`tips/${getComponentPath(tip.topic)}`}
      element={<FinancialTipPage id={id} />}
    />
  ));
  const productRoutes = Object.entries(financialProducts).map(
    ([id, product], index) => (
      <Route
        key={index}
        path={`products/${getComponentPath(
          productCategories[product.category].title,
          product.title
        )}`}
        element={<FinancialProductPage id={id} />}
      />
    )
  );

  return (
    <Routes>
      <Route path="" element={<LearnPage />} />
      <Route path="workshops" element={<WorkshopsPage />} />
      {workshopRoutes}
      <Route path="sessions" element={<SessionsPage />} />
      {sessionRoutes}
      <Route path="tips" element={<FinancialTipsPage />} />
      {tipRoutes}
      <Route path="products" element={<FinancialProductsPage />} />
      {productRoutes}
      <Route path="*" element={<Navigate to="" replace={true} />} />
    </Routes>
  );
};

export default LearnRoutes;
