import React from "react";
import { Box, styled, useMediaQuery } from "@mui/material";
import { Title, Subtitle } from "../TextStyles";
import GridContainer from "@features/signup/layouts/GridContainer";
import Button from "@ui-library/Button";
import Image from "./Image";

const Headings = () => {
  return (
    <Box>
      <Title>
        Feel great about <br />
        your financial future.
        <br />
      </Title>
      <Subtitle>
        Optimise current finances to achieve <br />
        long term financial goal! <br />
      </Subtitle>
    </Box>
  );
};

const CreateAccountButton = ({ setShowAuth }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Button
      type={"primary"}
      size={"large"}
      onClick={() => setShowAuth(true)}
      sx={{
        display: isMobile ? "flex" : "none",
        minWidth: "120px",
        maxWidth: "296px",
      }}
    >
      Sign In
    </Button>
  );
};

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "40px 0px 0px 40px",
  [theme.breakpoints.down("md")]: {
    padding: "40px 32px 32px 40px",
    gap: "48px",
  },
}));

const Description = ({ ...props }) => {
  return (
    <GridContainer>
      <InnerContainer>
        <Headings />
        <CreateAccountButton {...props} />
      </InnerContainer>
      <Image />
    </GridContainer>
  );
};

export default Description;
