const content = [];

const scalingPropertyInvestments = {
    category: "Properties",
    topic: "Scaling property investments",
    datetime: {
        date: "10 July",
        time: "05:00 PM - 05:30 PM",
    },
    tier: "free",
    image: "/learn/workshops/properties.png",
    color: "#2480DD",
    content: content,
};

export default scalingPropertyInvestments;
