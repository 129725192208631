const loadingMessages = [
  "Hold tight, queen! I'm crunching those numbers for you.",
  "One sec, love! Let me run the numbers real quick.",
  "Give me a moment, babe—I'm digging into those details!",
  "Let me think about this, hun. You know I got you!",
  "I'm on it, love! Just finding the best advice for you.",
  "Hang on, gorgeous! I'm working my financial magic!",
  "Don't worry, babe—I'm sorting through the numbers like a pro.",
  "Hold that thought, love. I'm cooking up some great advice!",
  "Hang in there, queen. This financial gold takes a minute!",
  "I'm on the case, babe! Just need a moment to get it right.",
  "Give me a sec, doll—I'm about to serve you some investment wisdom!",
  "Wait right there, gorgeous! I'm calculating your next money move.",
  "Hold up, hun—let me make sure I give you the best possible advice.",
  "You know it, babe—good things take a sec. I'm on it!",
  "Be right back, love! These numbers need my special touch.",
  "I'm thinking it through, doll. You're about to get some gold!",
  "Just a mo', queen! This advice is worth the wait.",
  "Stay with me, babe—I'm just fine-tuning your financial future!",
  "Hang tight, gorgeous! I'm locking down the details for you.",
  "Let me handle this, love—I'm finding the perfect money move for you.",
  "Patience, babe! Good money moves take a hot minute.",
  "Let me work my magic, queen. You're about to be impressed!",
  "Hold up, love. I'm about to dish out the best advice ever.",
  "One sec, doll—I'm working on some financial magic for you!",
  "Almost there, babe! Great advice is coming right up!",
  "Let me double-check this for you, hun. I won't keep you waiting long!",
  "Just a moment, love! I'm crafting the perfect response.",
  "One second, babe—I'm calculating your next boss move.",
  "Hang on, queen—I'm putting in the work to get you that advice!",
  "I'm thinking, love. Let me find the smartest option for you!",
  "Hold that thought, doll! I'm making sure everything checks out.",
  "I'm on it, babe! Just polishing up that financial strategy.",
  "Give me a moment, love—this advice is going to be worth it!",
  "Almost done, babe! I'm just double-checking those numbers for you.",
  "Hold tight, queen—I'm making sure every detail is perfect for you.",
  "I'm crunching the numbers, hun. Stay fabulous while I get this ready!",
  "One sec, love—I'm making sure you get the best of the best.",
  "Stay with me, babe! I'm fine-tuning your financial advice.",
  "Give me a moment, gorgeous—I'm locking in that next big money move!",
  "I'm on the case, queen—your financial future's about to sparkle!",
  "Let me check this out for you, love. I'll be right back!",
  "Hang tight, hun—I'm putting the final touches on your advice!",
  "Almost there, babe—I'm making sure everything adds up!",
  "Hold on, queen—I'm piecing together some financial gold for you!",
  "Just a minute, love—I'm making sure this advice is on point!",
  "Give me a sec, doll! I'm fine-tuning the perfect plan for you.",
  "Hold up, love—I'm crunching these numbers like a boss.",
  "Almost there, queen—your financial future is about to shine!",
  "Patience, gorgeous! I'm checking the best options for you.",
  "Stay tuned, babe—I'm making sure this advice is flawless!",
];

export default loadingMessages;
