import { experimental_extendTheme as extendTheme } from "@mui/material";
import typography from "./typography";
import colorSchemes from "./colorSchemes";
import components from "./components";

const theme = extendTheme({
  cssVarPrefix: "winvest",
  typography,
  colorSchemes,
  components,
});

export { weights as fontWeights } from "./typography";

export default theme;
