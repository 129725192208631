import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";

// Utility function for formatting numbers
const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
    number
  );
};

const categoryColors = {
  investment: {
    background: "#065d44",
    text: "#C6F54A",
  },
  savings: {
    background: "#ffb1e2",
    text: "#000",
  },
  pension: {
    background: "#ffef61",
    text: "#000",
  },
  realEstate: {
    background: "#c1effe",
    text: "#000",
  },
};

function predictRetirementYear(currentAge, retirementAge) {
  const currentYear = new Date().getFullYear();
  const yearsUntilRetirement = retirementAge - currentAge;
  return currentYear + yearsUntilRetirement;
}

const Title = ({ currentAge, retirementAge, totalProjectionAtRetirement }) => {
  const text = `In ${predictRetirementYear(
    currentAge,
    retirementAge
  )}, at the age of ${retirementAge}, you would have a total net worth of`;

  const year = `£ ${formatNumber(totalProjectionAtRetirement)}`;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={"12px"}
    >
      <Typography fontSize={"12px"} lineHeight={"20px"} color={"#4F4F4F"}>
        {text}
      </Typography>
      <Typography
        fontSize={"24px"}
        lineHeight={"26px"}
        letterSpacing={"-1px"}
        fontWeight={600}
      >
        {year}
      </Typography>
    </Box>
  );
};

const RetirementProjection = ({ id, value, backgroundColor, textColor }) => {
  return (
    <Grid item md={12} xs={6}>
      <Box
        display="flex"
        flexDirection={"column"}
        width="100%"
        padding="12px 8px 12px 12px"
        borderRadius={"8px"}
        backgroundColor={backgroundColor}
        gap={"12px"}
      >
        <Typography fontSize={"13px"} lineHeight={"14.5px"} color={textColor}>
          {`${id.charAt(0).toUpperCase() + id.slice(1)}`}
        </Typography>
        <Typography
          fontSize={"15px"}
          lineHeight={"25px"}
          fontWeight={600}
          color={textColor}
        >
          {`£ ${formatNumber(parseFloat(value))}`}
        </Typography>
      </Box>
    </Grid>
  );
};

const RetirementPlotBar = () => {
  const { state, retirementData } = useProjectionContext();
  const { investment, savings, pension, realEstate } = retirementData;
  const { currentAge, retirementAge } = state;

  const totalProjectionAtRetirement =
    investment + savings + pension + realEstate;

  const data = {
    investment: {
      id: "Investment",
      value: investment,
    },
    savings: {
      id: "savings",
      value: savings,
    },
    pension: {
      id: "pension",
      value: pension,
    },
    realEstate: {
      id: "Real Estate",
      value: realEstate,
    },
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      paddingTop={{ md: "88px" }}
      paddingBottom={"35px"}
      paddingX={"8px"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      alignSelf={"stretch"}
      gap={"16px"}
    >
      <Title
        currentAge={currentAge}
        retirementAge={retirementAge}
        totalProjectionAtRetirement={totalProjectionAtRetirement}
      />
      <Grid container rowSpacing={"4px"} columnSpacing={"4px"}>
        {Object.entries(data).map(([key, { id, value }]) => (
          <RetirementProjection
            key={key}
            id={id}
            value={value}
            backgroundColor={categoryColors[key].background}
            textColor={categoryColors[key].text}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default RetirementPlotBar;
