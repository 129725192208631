import { Typography } from "@mui/material";

const Heading = () => {
  return (
    <Typography fontSize={"13px"} color={"#110696"}>
      Unlock potential returns and strategies by inputting property and mortgage
      details.
      <br />
      An average house price appreciation of 4% is factored in.
    </Typography>
  );
};

export default Heading;
