import { REPAYMENT } from "@utils/financialCalculations";

const initialState = {
  investment: {
    initialAmount: 0,
    monthlyInvestment: 0,
    interestRate: 8.0,
  },
  savings: {
    initialAmount: 0,
    monthlyInvestment: 0,
    interestRate: 4.29,
  },
  pension: {
    annualSalary: 0,
    totalPension: 0,
    yourContribution: 4.0,
    totalContribution: 8.0,
    interestRate: 4.0,
  },
  realEstate: {
    currentPropertyValue: 0,
    remainingMortgage: 0,
    interestRate: 0,
    monthlyPayment: 0,
    monthlyOverpayment: 0,
    mortgageType: REPAYMENT,
  },
  currentAge: 25,
  retirementAge: 66,
};

const financialReducer = (state, action) => {
  switch (action.type) {
    case "SET_INVESTMENT":
      return { ...state, investment: action.payload };
    case "SET_SAVINGS":
      return { ...state, savings: action.payload };
    case "SET_PENSION":
      return { ...state, pension: action.payload };
    case "SET_REAL_ESTATE":
      return { ...state, realEstate: action.payload };
    case "SET_CURRENT_AGE":
      return { ...state, currentAge: action.payload };
    case "SET_RETIREMENT_AGE":
      return { ...state, retirementAge: action.payload };
    default:
      return state;
  }
};

export { financialReducer, initialState };
