const page1 = [
    {
        type: "title",
        text: "What are the best strategies for building wealth?",
    },
    {
        type: "body",
        text: "Invest in your education and skills, babe. Consider taking online courses in your field or a certification that can boost your career.",
    },
];

const page2 = [
    {
        type: "title",
        text: "Diversify and Grow with Expert-Backed Strategies",
    },
    {
        type: "body",
        text: "This is a general guideline based on common investment principles advocated by many financial experts, including Warren Buffett. Consistently invest in a diversified portfolio - allocate 70% to low-cost index funds, 20% to individual stocks in sectors you understand, and 10% to bonds. Reinvest dividends and capital gains to benefit from compound interest.",
    },
];

const page3 = [
    {
        type: "title",
        text: "Watch Your Wealth Soar Like a Hit Single!",
    },
    {
        type: "body",
        text: "Aim to maximise contributions to your retirement accounts, such as your work pension or SIPP (Self-invested personal pensions). Keep building, and watch your wealth grow like a hit single climbing the charts!",
    },
];

const content = [page1, page2, page3];

const buildingWealth = {
    topic: "Best strategies for building wealth?",
    image: "/learn/financialTips/building_wealth.png",
    color: {
        cardColor: "#FFAACE",
        textColor: "#261E40",
        contentColor: "#CF427D",
    },
    imageStyles: {
        bottom: -25, height: "80%", objectFit: "contain", position: "absolute",
    },
    content: content,
};

export default buildingWealth;
