import React, { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { PageContainer, WorkshopBooked } from "@features/learn";
import TextContent from "@components/textContent";
import { WorkshopCard } from "@components/workshops";
import workshops from "@content/workshops";
import Button from "@ui-library/Button";

const Header = styled(Box)(() => ({
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
}));

const Body = styled(Box)(() => ({
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: 24,
    padding: "32px 8px 80px 8px",
}));

const Frame = styled(Box)(() => ({
    alignItems: "center",
    alignSelf: "stretch",
    // background: "#FFFFFF",  // UI-50
    // borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    justifyContent: "flex-end",
    padding: "16px 24px 32px 24px",
    position: "sticky",
}));

const BookWorkshop = (props) => (
    <Frame>
        <Button
            type="primary"
            size="large"
            sx={{
                alignSelf: "stretch",
                backgroundColor: "#031711",  // UI-900
                color: "white",
                height: 48,
                padding: "0 32px",
            }}
            {...props}
        >
            Book a spot on Eventbrite
        </Button>
        <Typography
            variant="bodyEmphasis"
            color="#144235"  // UI-600
        >
            26 spots available
        </Typography>
    </Frame>
);

const WorkshopPage = (props) => {
    const { id } = props;
    const { content, ...workshop } = workshops[id];
    const [page, setPage] = useState(1);

    const pages = [
        <TextContent
            content={content}
            variants={{ title: "bodyLEmphasis" }}
            color="#031711"  // UI-900
            sx={{ gap: "40px" }}
        />,
        <WorkshopBooked />,
    ];

    const onClick = () => setPage(page + 1);

    return (
        <PageContainer sx={{ gap: 0, padding: "16px" }}>
            <Header>
                <WorkshopCard
                    {...workshop}
                    sx={{ minWidth: 0, width: "auto" }}
                />
            </Header>
            <Body>
                {pages[page - 1]}
            </Body>
            {page === 1 && <BookWorkshop onClick={onClick} />}
        </PageContainer>
    );
};

export default WorkshopPage;
