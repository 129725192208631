import React from 'react';
import PromptChoice from '../promptTypes/PromptChoice';
import { addAnnualChange, changeProperty } from '../../promptCalculations';

const page = {
    type: 'choice',
    title:
        "There is an index fund A available in your account.",
    caption: "It is tracking the biggest 500 companies in the US. In the past 20 years it gives average of 11%.",
    description: "Would you like to:",
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/crisis.jpeg",
    },
    choices: [
        {
            choice: "Invest £150 per month",
            outcome: 0,
        },
        {
            choice: "Invest £2000 at once",
            outcome: 1,
        },
    ],
};

const change1 = () => {
    addAnnualChange({
        change: "increase",
        property: "indexFund",
        by: "150",
        frequency: "monthly",
    });
}

const change2 = () => {
    changeProperty({
        change: "increase",
        property: "indexFund",
        by: "2000",
        frequency: 'once',
    });
}

const changes = [
  change1, change2
];
  
const tipScreen1 = {
    content: [
      {
        type: "title",
        text: "Investing regularly means putting money into investments on a regular basis, a bit at a time.",
      },
      {
        type: "paragraph",
        text: "This helps even out the ups and downs of prices because you're buying at different times. It's good if you want to play it safe and not get too emotional about your investments.",
      },
      {
        type: "paragraph",
        text: "On the other hand, investing a lump sum means putting all your money into investments at once. It's simple and can be good if you think it's the perfect time to invest.",
      },
    ],
  };
  
  const tipScreen2 = {
    content: [
      {
        type: "title",
        text: "Market's down? Quick Tip:",
      },
      {
        type: "subtitle",
        text: "Maintain Separate Finances with a Joint Account, formal prenup:",
      },
      {
        type: "list",
        listType: "ordered",
        listItems: [
          {
            text: "Stay Calm: Don't panic sell.",
          },
          {
            text: "Stick to Strategy: Think long-term, not short-term losses.",
          },
          {
            text: "Buy Low: Downturns can be a chance to buy good investments cheap.",
          },
          {
            text: "Check Funds: Ensure you have cash for emergencies.",
          },
          {
            text: "Diversify: Spread out your investments to reduce risk.",
          },
          {
            text: "Avoid Timing: Don't try to predict market moves.",
          },
          {
            text: "Learn and Adjust: Use this as a learning opportunity and tweak your plan if needed.",
          },
        ],
      },
    ],
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/panic-button.jpeg",
    },
  };
  
  const tips = [
    tipScreen1,
    tipScreen2,
  ];

const colour =  {
    primary: "#D9C5FF",
    secondary: "#5F32B6",
};

const Prompt10 = (props) => {
  const { setPrompt } = props;

  const outcomes = [
    {
        title: "Well done!",
        caption:
            `Keep up the great work! You invested £${150 * 12} total this year!`,
        change: 0,
    },
    {
        title: "Woohoo!",
        caption: "You are one of the 42% of the UK population that holds an investment!",
        change: 1,
    },
  ];

  const prompt10 = {
    type: "choice",
    page: page,
    outcomes: outcomes,
    changes: changes,
    tips: tips,
    colour: colour,
  };
  
  return (
    <PromptChoice {...prompt10} setPrompt={setPrompt} />
  );
}

export default Prompt10;
