import { Box, styled } from "@mui/material";
import { Caption, ConversationStarters } from "../conversationstarters";
import TextInput from "../textinput/TextInput";
import { useChat } from "@features/aichat/hooks";
import { FormProvider, useForm } from "react-hook-form";
import Options from "../options";
import {
  completeChat,
  getMessages,
  mostRecenthasAChoice,
} from "@services/chatService";
import { useAuthContext } from "@hooks";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "rgba(255,255,255,0.5)",
}));

const Form = () => {
  const methods = useForm();
  const { reset, handleSubmit } = methods;
  const {
    history, addMessage, loading, setLoadingResponse, setErrorOccurred,
  } = useChat();
  const { email } = useAuthContext();

  const onSubmit = async (data) => {
    setLoadingResponse(true);
    const { message, buttonClick } = data;
    if (message.trim()) {
      addMessage({ sender: "user", message });
      reset();
      try {
        const response = await completeChat(email, message, buttonClick);
        setErrorOccurred(false);
        addMessage({ sender: "assistant", message: response });
      } catch (error) {
        setErrorOccurred(true);
        console.error("Failed to complete chat:", error);
      }
      setLoadingResponse(false);
    }
  };

  const renderIntro = () => (
    <>
      <Caption />
      <ConversationStarters
        onSelect={(text) => onSubmit({ message: text, buttonClick: false })}
      />
    </>
  );

  const renderOptions = () => (
    <>
      <Options
        onSelect={(option) => onSubmit({ message: option, buttonClick: true })}
      />
    </>
  );

  const messages = getMessages(history);

  return (
    <FormProvider {...methods}>
      <Wrapper component={"form"} onSubmit={handleSubmit(onSubmit)}>
        {messages.length === 1 && !loading && renderIntro()}
        {mostRecenthasAChoice(messages) && !loading && renderOptions()}
        <TextInput control={methods.control} />
      </Wrapper>
    </FormProvider>
  );
};

export default Form;
