const title = {
    type: "title",
    text: "This is your fairy godmother for buying a home or retiring - you geta 25% bonus on what you put in, up to a certain limit.",
};

const description = {
    type: "body",
    text: "LISAs are designed to help first-time homebuyers and those saving for retirement with government bonuses. You can save up to £4,000 a year, and the government will add a 25% bonus.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Ideal for first-time homebuyers or those saving for retirement, providing a government bonus on your savings.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Nutmeg Lifetime ISA",
            subheading: "Average annual return of 6.5% over the past 5 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available online through the Nutmeg website and mobile app.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Moneybox Lifetime ISA",
            subheading: "Average annual return of 7% over the past 5 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available through the Moneybox app, downloadable on iOS and Android.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Skipton Building Society Lifetime ISA",
            subheading: "Average annual return of 6% over the past 5 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available through the Skipton Building Society website and branches.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you're under 40 and saving for your first home, a LISA gives you a 25% boost on your contributions.",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Example",
    body: [
        {
            type: "section",
            subheading: "Moneybox Lifetime ISA",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Interest Rate: Up to 1.50% AER + 25% government bonus on contributions",
                        },
                    ],
                },
            ],
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Can be accessed for first-time home purchase or after age 60; penalties apply for other withdrawals.",
        },
    ],
};

const lifetimeISA = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
    examples,
    accessibility,
];

export default lifetimeISA;
