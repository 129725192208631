import React from "react";
import { styled, Typography } from "@mui/material";

const Text = styled(Typography)(() => ({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#065D44", // UI-500
}));

const Title = () => {
  return <Text variant="bodyLEmphasis">Money Bestie</Text>;
};

export default Title;
