import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { HeaderCaption } from "../components";
import ScrollableCards from "./ScrollableCards";

const Container = styled(Box)(() => ({
    alignItems: "flex-start",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
}));

const SectionHeader = styled(Box)(() => ({
    alignItems: "flex-start",
    alignSelf: "stretch",
    display: "flex",
    gap: "4px",
    justifyContent: "space-between",
    padding: "0px 24px",
}));

const HeaderContent = styled(Box)(() => ({
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
}));

const PageSection = (props) => {
    const { variant = "h4", title, caption, onSeeAll, sx } = props;

    return (
        <Container sx={sx}>
            <SectionHeader>
                <HeaderContent>
                    <Typography variant={variant}>{title}</Typography>
                    <HeaderCaption>{caption}</HeaderCaption>
                </HeaderContent>
                {/* Move to its own file */}
                {onSeeAll &&
                    <Typography
                        variant="bodyEmphasis"
                        color="#065D44"  // UI-500
                        minWidth={48}
                        onClick={onSeeAll}
                    >See all</Typography>}
            </SectionHeader>
            <ScrollableCards>
                {props.children}
            </ScrollableCards>
        </Container>
    );
};

export default PageSection;
