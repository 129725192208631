import React from "react";
import { SvgIcon } from "@mui/material";

const BarChartIcon = ({ color = "white" }) => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
        >
            <path
                d="M21.6016 23.3496H3.60156C3.19156 23.3496 2.85156 23.0096 2.85156 22.5996C2.85156 22.1896 3.19156 21.8496 3.60156 21.8496H21.6016C22.0116 21.8496 22.3516 22.1896 22.3516 22.5996C22.3516 23.0096 22.0116 23.3496 21.6016 23.3496Z"
                fill={color}
            />
            <path
                d="M6.20154 20.3505H4.60156C3.64156 20.3505 2.85156 19.5605 2.85156 18.6005V9.98047C2.85156 9.02047 3.64156 8.23047 4.60156 8.23047H6.20154C7.16154 8.23047 7.95154 9.02047 7.95154 9.98047V18.6005C7.95154 19.5605 7.16154 20.3505 6.20154 20.3505ZM4.60156 9.72046C4.46156 9.72046 4.35156 9.83046 4.35156 9.97046V18.6005C4.35156 18.7405 4.46156 18.8505 4.60156 18.8505H6.20154C6.34154 18.8505 6.45154 18.7405 6.45154 18.6005V9.98047C6.45154 9.84047 6.34154 9.73047 6.20154 9.73047H4.60156V9.72046Z"
                fill={color}
            />
            <path
                d="M13.4008 20.3491H11.8008C10.8408 20.3491 10.0508 19.5591 10.0508 18.5991V6.78906C10.0508 5.82906 10.8408 5.03906 11.8008 5.03906H13.4008C14.3608 5.03906 15.1508 5.82906 15.1508 6.78906V18.5991C15.1508 19.5591 14.3608 20.3491 13.4008 20.3491ZM11.8008 6.53906C11.6608 6.53906 11.5508 6.64906 11.5508 6.78906V18.5991C11.5508 18.7391 11.6608 18.8491 11.8008 18.8491H13.4008C13.5408 18.8491 13.6508 18.7391 13.6508 18.5991V6.78906C13.6508 6.64906 13.5408 6.53906 13.4008 6.53906H11.8008Z"
                fill={color}
            />
            <path
                d="M20.6 20.3496H19C18.04 20.3496 17.25 19.5596 17.25 18.5996V3.59961C17.25 2.63961 18.04 1.84961 19 1.84961H20.6C21.56 1.84961 22.35 2.63961 22.35 3.59961V18.5996C22.35 19.5596 21.56 20.3496 20.6 20.3496ZM19 3.34961C18.86 3.34961 18.75 3.45961 18.75 3.59961V18.5996C18.75 18.7396 18.86 18.8496 19 18.8496H20.6C20.74 18.8496 20.85 18.7396 20.85 18.5996V3.59961C20.85 3.45961 20.74 3.34961 20.6 3.34961H19Z"
                fill={color}
            />
        </svg>
    </SvgIcon>
);

export default BarChartIcon;
