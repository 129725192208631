import React from "react";
import { useNavigate } from "react-router-dom";
import loadingMessages from "@content/loadingMessages";
import { Box, keyframes, styled } from "@mui/material";
import Button from "@ui-library/Button";
import { AIChatBubble } from "../chatbubble";

const AI_AVATAR = "assets/images/money_bestie_avatar.png";

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const Bubble = styled(AIChatBubble)(() => ({
  animation: `${fadeIn} 0.5s ease-in-out`,
  animationDelay: `${0.4}s`,
  opacity: 0,
  animationFillMode: "forwards",
}));

const SignIn = () => {
  const navigate = useNavigate();
  const onClick = () => navigate("/signup");

  return (
    <Button
      onClick={onClick}
      type={"secondary"}
      size={"small"}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: 20,
      }}
    >
      Sign In
    </Button>
  );
};

const Frame = styled(Box)(() => ({
  display: "grid",
  gap: 16,
}));

const ErrorOccurred = () => (
  <Bubble imgSrc={AI_AVATAR}>
    <Frame>
      An error occurred. Please sign in and try again. 
      If the issue continues, contact us for assistance.
      <SignIn />
    </Frame>
  </Bubble>
);

const LoadingResponse = () => {
  const max = loadingMessages.length;
  // Random integer between 0 (incl.) and loadingMessages.length (excl.)
  const index = Math.floor(Math.random() * max);

  return (
    <Bubble imgSrc={AI_AVATAR}>
      {index < max ?
      loadingMessages[index] :
      "Loading... This may take a moment."}
    </Bubble>
  );
};

export { ErrorOccurred, LoadingResponse };
