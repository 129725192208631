import { Box, styled } from "@mui/material";
import SignOut from "../components/authentication/SignOut";

const Frame = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#E6DFDB",
  minHeight: 60,
  width: "100%",
  borderRadius: 48,
  padding: "12px 12px 12px 24px",
}));

const Header = () => {
  return (
    <Frame>
      <Box component={"img"} src="assets/logos/logo-black.svg" />
      <SignOut />
    </Frame>
  );
};

export default Header;
