const title = {
    type: "title",
    text: "These are the superstars of the stock market, like the Hollywood A-listers of your investment portfolio.",
};

const description = {
    type: "body",
    text: "Blue-chip stocks are shares in large, reputable companies with a history of reliable performance and stable earnings. They typically offer solid dividend returns to their investors.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Ideal for conservative investors seeking steady growth and reliable dividends.",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "Coca-Cola (KO)",
                    subheading: "Average annual return of 7.5% over the past 10 years, including dividends.",
                },
                {
                    type: "section",
                    heading: "Procter & Gamble (PG)",
                    subheading: "Average annual return of 8.2% over the past 10 years, including dividends.",
                },
                {
                    type: "section",
                    heading: "Johnson & Johnson (JNJ)",
                    subheading: "Average annual return of 9.3% over the past 10 years, including dividends.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Looking for stability and consistent returns? Blue-chip stocks are your go-to, like a solid foundation in your investment wardrobe.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Easily bought and sold on the stock market.",
        },
    ],
};

const blueChipStocks = [
    title,
    description,
    benefits,
    examples,
    greatFor,
    accessibility,
];

export default blueChipStocks;
