import React from 'react';
import '../Prompts.css';

const SuccessBackground = () => {
    return (
        <>
            <svg
                id='success-background'
                xmlns="http://www.w3.org/2000/svg"
                width="600"
                height="470"
                viewBox="0 0 585 470"
                preserveAspectRatio="none"
                fill="#E04B77"
            >
                <path
                    d="M 213.761 37.732 C 251.996 -23.039 354.41 -7.518 364.278 63.598 L 379.731 55.106 C 427.084 29.083 486.083 56.518 496.7 109.497 L 500.528 128.598 C 500.528 128.598 555.802 118.197 574.395 170.607 C 592.33 221.165 582.415 258.959 572.851 281.006 C 557.958 315.336 522.278 325.598 522.278 325.598 C 522.278 325.598 518.898 388.416 483.641 409.56 C 454.692 426.921 403.015 429.799 375.278 408.598 C 375.278 408.598 328.278 479.598 264.278 469.598 C 200.278 459.598 184.278 391.598 184.278 391.598 L 182.764 392.587 C 134.453 443.734 42.899 392.1 60.056 335.148 C 64.41 320.695 15.527 345.084 1.714 276.544 C -3.494 250.702 3.392 211.388 19.318 192.865 C 42.667 165.71 55.832 170.363 69.382 167.535 C 61.952 158.776 36.294 130.63 71.009 83.712 C 101.389 42.653 166.059 51.77 189.639 75.946 L 213.761 37.732 Z"
                />
            </svg>
            <svg
                id='success-confetti'
                xmlns="http://www.w3.org/2000/svg"
                width="390"
                height="844"
                viewBox="0 0 390 844"
                preserveAspectRatio="none"
                fill="none"
            >
                <circle cx="303.5" cy="284.5" r="10.5" fill="#5F32B6"/>
                <circle cx="358.5" cy="270.5" r="7.5" fill="#FFDCDC"/>
                <circle cx="80" cy="170" r="12" fill="#F480A3"/>
                <circle cx="303" cy="176" r="6" fill="#F480A3"/>
                <circle cx="295" cy="88" r="6" fill="#FCF078"/>
                <circle cx="154" cy="254" r="6" fill="#FCF078"/>
                <circle cx="228" cy="680" r="12" fill="#FFDCDC"/>
                <circle cx="52" cy="481" r="8" fill="#E04B77"/>
                <circle cx="44" cy="121" r="6" fill="#B9EAFF"/>
                <circle cx="98" cy="71" r="6" fill="#E04B77"/>
                <circle cx="264" cy="150" r="8" fill="#2A935A"/>
                <circle cx="289" cy="654" r="8" fill="#F480A3"/>
            </svg>
        </>
    );
}

const Outcome = ({ type = 'success', title, caption, setPrompt }) => {
    const isSuccess = type === 'success' ? true : false;
    
    return (
        <div
            id='outcome-popup'
            className={isSuccess
                ? 'outcome outcome-success'
                : 'outcome outcome-consequence'}
        >
            <div className='outcome-content'>
                {isSuccess && <SuccessBackground />}
                <div className='outcome-text'>
                    <h1>{title}</h1>
                    <p>{caption}</p>
                </div>
            </div>
            <div className='outcome-button'>
                <button
                    onClick={() => setPrompt(null)}
                >Let's keep going</button>
            </div>
        </div>
    );
}

export default Outcome;
