import React, { useState } from "react";
import { Chip as MuiChip, styled } from "@mui/material";

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== "isSelected"
})(({ theme, isSelected, variant }) => ({
  ...(variant === "light" && {
    backgroundColor: isSelected ? `rgba(${0}, ${0}, ${0}, ${0.2})` : "initial",
    border: isSelected ? "none" : "1px solid #FFF",
    "& .MuiChip-label": {
      color: theme.vars.palette.common.white,
    },
    "&:hover": {
      backgroundColor: !isSelected
        ? "rgba(255, 255, 255, 0.1)"
        : `rgba(0, 0, 0, 0.2)`,
    },
  }),
  ...(variant === "dark" && {
    backgroundColor: isSelected
      ? `#168263` // UI-400
      : "initial",
    border: "1px solid #168263", // UI-400
    "& .MuiChip-label": {
      color: isSelected ? theme.vars.palette.common.white : "#168263",
    },
    "&:hover": {
      backgroundColor: !isSelected ? "inherit" : "#168263",
    },
  }),
}));

const Chip = ({ variant = "light", onClick, ...props }) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    setIsSelected((prev) => !prev);
    onClick && onClick();
  };

  return (
    <StyledChip
      isSelected={isSelected}
      variant={variant}
      onClick={handleClick}
      {...props}
    />
  );
};

export default Chip;
