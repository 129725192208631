import { SvgIcon } from "@mui/material";

const TrackerIcon = ({ color = "#065D44", selected = false }) => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={selected ? color : "none"}
        >
            <path
                d="M8.9707 22.5H14.9707C19.9707 22.5 21.9707 20.5 21.9707 15.5V9.5C21.9707 4.5 19.9707 2.5 14.9707 2.5H8.9707C3.9707 2.5 1.9707 4.5 1.9707 9.5V15.5C1.9707 20.5 3.9707 22.5 8.9707 22.5Z"
                stroke={selected ? "white" : color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.9707 13.2001L7.9707 13.1801C8.7207 13.1801 9.5607 13.7501 9.8407 14.4501L10.9807 17.3301C11.2407 17.9801 11.6507 17.9801 11.9107 17.3301L14.2007 11.5201C14.4207 10.9601 14.8307 10.9401 15.1107 11.4701L16.1507 13.4401C16.4607 14.0301 17.2607 14.5101 17.9207 14.5101H21.9807"
                stroke={selected ? "white" : color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

export default TrackerIcon;
