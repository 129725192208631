const title = {
    type: "title",
    text: "Mandatory coverage for your vehicle - keeps you financially safe on the road.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Covers repair costs, theft, and liability in case of accidents.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Direct Line Car Insurance",
            subheading: "Comprehensive cover including accidental damage, theft, and third-party liability.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £20 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available directly from the Direct Line website or via their customer service.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Admiral Car Insurance",
            subheading: "Comprehensive cover with additional options like breakdown cover and legal protection.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £18 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available on the Admiral website or through comparison sites like MoneySuperMarket.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Drive with confidence knowing your vehicle is covered with comprehensive car insurance.",
        },
    ],
};

const carInsurance = [
    title,
    benefits,
    providersExamples,
    greatFor,
];

export default carInsurance;
