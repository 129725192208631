import React from 'react';
import { changeProperty, setProperty } from '../../promptCalculations';
import PromptChoiceExtended from '../promptTypes/PromptChoiceExtended';
import { formatMoney, getNetWorth } from '../../playerState';
  
const tipScreen = {
    content: [
        {
            type: "title",
            text: "Pension sacrifice means putting more money into your retirement fund before taxes are taken out."
        },
        {
            type: "paragraph",
            text: "It's like giving your future self a raise because you save on taxes now, and your retirement fund grows fasterIt's like giving your future self a raise because you save on taxes now, and your retirement fund grows faster.",
        },
        {
            type: "paragraph",
            text: "You can also join a one-on-one call with our pension advisor or attend a pension workshop to learn more.",
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/people-2.jpeg",
    },
  };
  
const tips = [
    tipScreen,
];

const colour =  {
    primary: "#CEF2E7",
    secondary: "#00607E",
};

const Prompt9 = (props) => {
    const { setPrompt } = props;

    let newSalary = 0;

    const getTaxBracket = (salary) => {
        const annualSalary = salary * 12;
        if (annualSalary <= 12570) {
            return 0;
        } else if (annualSalary > 12570 && annualSalary <= 50270) {
            return 20;
        } else if (annualSalary > 50270 && annualSalary <= 125140) {
            return 40;
        } else {
            return 45;
        };
    };

    const pageText = () => {
        const NET_WORTH = getNetWorth();
        const salary = NET_WORTH.income.salary;
        const PERCENT_INCREASE = 1.8;
        // Calculate new salary
        newSalary = salary * PERCENT_INCREASE;
        const newAnnualSalary = formatMoney(newSalary * 12);
        // Generate text content for the prompt
        const title = `You are earning ${newAnnualSalary}`
        const contribution = NET_WORTH.pension.contribution * 100;
        const caption =
            `After a couple of pay rises and changing companies, you are currently earning ${newAnnualSalary} per annum, reaching the ${getTaxBracket(newSalary)}% tax bracket of your income. Right now, you are contributing ${contribution}% of your salary to your pension pot.`
        return { title, caption };
    }

    const page1 = {
        ...pageText(),
        description:
            "Many people don't contribute more to their pension because they prioritise instant gratification. However, I want to make the most of my money!",
        image: {
            src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-1.jpeg",
        },
    };

    const page2 = {
        title: pageText().title,
        description: "Would you like to:",
        image: {
            src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-1.jpeg",
        },
        choices: [
            {
                choice: "Keep the current pension contribution",
                outcome: 0,
            },
            {
                choice:
                    "Sacrifice 10% of your salary, increasing your pension contribution to 14%",
                outcome: 1,
            },
        ],
    };

    const change1 = () => {
        setProperty('salary', newSalary);
    }
    
    const change2 = () => {
        setProperty('salary', newSalary);
        changeProperty({
            change: "increase", property: "pension contribution", by: 10,
        });
    }
    
    const changes = [
        change1, change2,
    ];

    const outcomes = [
        {
            title: "Woohoo!",
            caption: "Your money is working hard for you",
            change: 0,
        },
        {
            title: "25k of your income will be taxed 40%",
            caption: "You will earn £3,110.60 per month",
            change: 1,
        },
    ];

    const prompt9 = {
        type: "choice-extended",
        page1: page1,
        page2: page2,
        outcomes: outcomes,
        changes: changes,
        tips: tips,
        colour: colour,
    };
    
    return (
        <PromptChoiceExtended {...prompt9} setPrompt={setPrompt} />
    );
}

export default Prompt9;
