import React, { useState } from 'react';
import { addAnnualChange, changeProperty } from '../../promptCalculations';
import { page2template, page3template } from '../../content/prompts/distribution/templates';
import { getNetWorth } from '../../playerState';
import PromptDistribution from '../promptTypes/PromptDistribution';

const page1 = {
  title: "You got a bonus of £5000 because you work really hard in your job.",
  description:
    "I have options to distribute £5000, bringing me closer to reaching my financial goals sooner! Hooray!",
  amount: 5000,
  frequency: 'once',
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/money-3.jpeg",
  },
};
  
const page2 = {
  ...page2template,
};

const page3 = {
  ...page3template,
};
  
const options = [
  {
    type: "InstantSavingsAccount",
    property: 'savingsAccount',
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-1.png",
    },
    caption: "Increase Your Savings",
    growth: "4%",
    rate: "Growth per year",
    risk: "low",
  },
  {
    type: "IndexFund",
    property: 'indexFund',
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/graph-3.png",
    },
    caption: "Increase Investment Account",
    growth: "8%",
    rate: "Growth per year",
    risk: "moderate",
  },
  {
    type: "EmergencyFund",
    property: 'emergencyFund',
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-1.png",
    },
    caption: "Prepare for the Unexpected",
    growth: "4%",
    rate: "Growth per year",
    risk: "low",
  },
];

const tipScreen = {
  content: [
    {
      type: "title", 
      text: "Revisit Your Financial Goals:"
    },
    {
      type: "paragraph",
      text: "Take a moment to revisit the most important financial goals right now (up to 3). Understand how you'd like to allocate this lump sum to achieve those goals.",
    },
    {
      type: "title", 
      text: "Allocate Wisely:"
    },
    {
      type: "paragraph",
      text: "Consider dividing the money into different pots based on your goals. For example, if you're planning to make a down payment on a house in 3 years, maximising the money would be key. In this case, you might want to contribute the majority of it to an investment account. On the other hand, if you're looking to make a down payment on a flat within 6 months to 1 year, liquidity becomes crucial. In this scenario, you'd lean towards putting the majority of the money into a savings account with high interest rates.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-2.jpeg",
  },
};

const tips = [
  tipScreen,
];

const colour =  {
    primary: "#FFE795",
    secondary: "#EF8E1D",
};

const Prompt4 = (props) => {
  const { setPrompt } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputs, setInputs] = useState([0, 0, 0]);

  const outcome = {
    title: "Well done!",
    caption:
      "Your money will now have the potential to grow annually. This is a significant victory in optimising your financial resources!",
    change: 0,
  };

  const prompt4 = {
    type: "distribution",
    page1: page1,
    page2: page2,
    page3: page3,
    options: options,
    outcome: outcome,
    tips: tips,
    colour: colour,
  };
  
  return (
    <PromptDistribution {...prompt4} setPrompt={setPrompt} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} inputs={inputs} setInputs={setInputs} />
  );
}

export default Prompt4;
