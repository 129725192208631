import blueChipStocks from "./blueChipStocks";
import bonds from "./bonds";
import carInsurance from "./carInsurance";
import cashISA from "./cashISA";
import criticalIllnessInsurance from "./criticalIllnessInsurance";
import diyInvestmentPlatform from "./diyInvestmentPlatform";
import easyAccessSavingsAccount from "./easyAccessSavingsAccount";
import fixedRateBonds from "./fixedRateBonds";
import generalInvestmentAccount from "./generalInvestmentAccount";
import growthStocks from "./growthStocks";
import healthInsurance from "./healthInsurance";
import homeInsurance from "./homeInsurance";
import humanFinancialAdvisor from "./humanFinancialAdvisor";
import incomeProtectionInsurance from "./incomeProtectionInsurance";
import indexFunds from "./indexFunds";
import juniorISA from "./juniorISA";
import lifeInsurance from "./lifeInsurance";
import lifetimeISA from "./lifetimeISA";
import mutualFunds from "./mutualFunds";
import personalPension from "./personalPension";
import regularSavingsAccount from "./regularSavingsAccount";
import reit from "./reit";
import roboAdvisor from "./roboAdvisor";
import sipp from "./sipp";
import stakeholderPension from "./stakeholderPension";
import statePension from "./statePension";
import stocksAndSharesISA from "./stocksAndSharesISA";
import travelInsurance from "./travelInsurance";
import workplacePension from "./workplacePension";

// Content for each financial product
const productContent = {
    blueChipStocks,
    bonds,
    carInsurance,
    cashISA,
    criticalIllnessInsurance,
    diyInvestmentPlatform,
    easyAccessSavingsAccount,
    fixedRateBonds,
    generalInvestmentAccount,
    growthStocks,
    healthInsurance,
    homeInsurance,
    humanFinancialAdvisor,
    incomeProtectionInsurance,
    indexFunds,
    juniorISA,
    lifeInsurance,
    lifetimeISA,
    mutualFunds,
    personalPension,
    regularSavingsAccount,
    reit,
    roboAdvisor,
    sipp,
    stakeholderPension,
    statePension,
    "stocksAndSharesAccount": stocksAndSharesISA,
    stocksAndSharesISA,
    travelInsurance,
    workplacePension,
};

export default productContent;
