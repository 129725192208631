import BarChartIcon from "./BarChartIcon";
import CoinStackIcon from "./CoinStackIcon";
import DiscountIcon from "./DiscountIcon";
import GrowthChartIcon from "./GrowthChartIcon";
import SavingsIcon from "./SavingsIcon";
import UmbrellaIcon from "./UmbrellaIcon";

const icons = {
    financialPlatforms: <BarChartIcon />,
    insuranceProducts: <UmbrellaIcon />,
    investmentAccounts: <GrowthChartIcon />,
    investmentProducts: <BarChartIcon />,
    ISAs: <DiscountIcon />,
    pensionProducts: <CoinStackIcon />,
    savingsAccounts: <SavingsIcon />,
};

export default icons;
