import React, { useCallback, useEffect } from "react";
import { jwtDecode } from "jwt-decode"; // Corrected import
import AuthButton from "./AuthButton";
import { useAuthContext } from "@hooks";

const GoogleAuth = () => {
  const {
    setIsSignedIn,
    setGivenName,
    setFamilyName,
    setUserEmail,
    setName,
    signInUser,
  } = useAuthContext();

  const handleCredentialResponse = useCallback(
    (response) => {
      //console.log('Encoded JWT ID token:', response.credential);
      try {
        const decoded = jwtDecode(response.credential);
        // console.log("User Details:", decoded);
        setName(decoded.name);
        setGivenName(decoded.given_name); // Assuming 'given_name' is part of the decoded object
        setFamilyName(decoded.family_name); // Assuming 'given_name' is part of the decoded object
        setUserEmail(decoded.email); // Assuming 'given_name' is part of the decoded object
        // sessionStorage.setItem("userEmail", decoded.email);
        // console.log(`This is the email ${sessionStorage.getItem("userEmail")}`);
        signInUser(decoded.name, decoded.email);
        setIsSignedIn(true);
      } catch (error) {
        console.error("Error decoding JWT", error);
      }
    },
    [setUserEmail, setFamilyName, setGivenName, setIsSignedIn, setName, signInUser]
  );

  useEffect(() => {
    const initGoogleSignIn = () => {
      if (window.google && window.google.accounts) {
        // console.log("Initialising Google Sign-In")
        window.google.accounts.id.initialize({
          client_id:
            "302908106727-t478ofsbja4sp9t2867sqc0m655g8m81.apps.googleusercontent.com",
          callback: handleCredentialResponse,
          use_fedcm_for_prompt: true,
          ux_mode: "redirect", // Optional: configure the ux_mode as needed
          redirect_uri: window.location.origin + "/projection", // Optional: specify your redirect_uri
        });
      } else {
        console.error("Google Sign-in script not loaded");
      }
    };

    if (window.google) {
      initGoogleSignIn();
    } else {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = () => {
        // console.log("Google Sign-in script loaded");
        initGoogleSignIn();
      };
      script.onerror = () => {
        console.error("Error loading Google Sign-in script")
      }
      document.body.appendChild(script);
    }
  }, [handleCredentialResponse]);

  // Custom button click handler
  const handleGoogleSignInClick = () => {
    // console.log("Google Sign-In button clicked");
    if (window.google && window.google.accounts) {
      window.google.accounts.id.prompt(); // This will display the One Tap dialog
    } else {
      console.error("Google Sign-In is not available");
    }
  };

  return (
    <AuthButton
      id={"google"}
      authIcon={"auth/google_icon.svg"}
      authText={"Continue with Google"}
      onClick={() => setTimeout(handleGoogleSignInClick, 100)}
    />
    // <div className="continue-with-google" onClick={() => setTimeout(handleGoogleSignInClick, 100)}>
    //     Continue with Google{" "}
    // </div>)
    //     <Button  style={buttonStyle} onClick={handleGoogleSignInClick}>
    //         <FcGoogle style={{ marginLeft: '15px', marginRight: '25px', height: '18px' }} />Continue with Google</Button>
  );
};

export default GoogleAuth;
