import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FinancialCard from "../FinancialCard";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import marketingTips from "./marketingTips";
import TextField from "@ui-library/TextField";

const Heading = () => {
  return (
    <Typography fontSize={"13px"} color={"#400c52"}>
      Share your exisiting savings status and potential future saving prospects.
    </Typography>
  );
};

const Inputs = () => {
  const { state, dispatch } = useProjectionContext();
  const { savings } = state;

  const handleChange = (event) => {
    const { name, value } = event.target;

    // First, remove leading zeros, but allow a single zero before a decimal point
    const sanitizedValue = value.replace(/^0+(?!\.|$)/, "");

    // Allow digits, a single dot, and nothing else.
    // This regex allows values like '2.', '2.0', '0.', etc.
    if (/^\d*\.?\d*$/.test(sanitizedValue)) {
      dispatch({
        type: "SET_SAVINGS",
        payload: { ...savings, [name]: sanitizedValue },
      });
    }
  };

  const textFields = [
    {
      id: "initialAmountSavings",
      name: "initialAmount",
      label: "Current Savings",
      startsymbol: "£",
      value: savings.initialAmount,
      tooltip: "Enter the current savings you have at the moment",
    },
    {
      id: "monthlySavings",
      name: "monthlyInvestment",
      label: "Monthly Savings",
      startsymbol: "£",
      value: savings.monthlyInvestment,
      tooltip: "Enter the savings you contribute to your savings every month",
    },
    {
      id: "interestRateSavings",
      name: "interestRate",
      label: "Savings Interest",
      startsymbol: "%",
      value: savings.interestRate,
      tooltip: `Enter the annual saving interest of your account. The default rate is set at 4.29%, based on the past 34 years the average interest rate in the U.K.`,
    },
  ];

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
      {textFields.map((data, i) => (
        <TextField
          key={i}
          tooltip={"true"}
          tooltipcontent={data.tooltip}
          id={data.id}
          name={data.name}
          label={data.label}
          startadornment="true"
          startsymbol={data.startsymbol}
          value={data.value}
          onChange={handleChange}
        />
      ))}{" "}
    </Box>
  );
};

const SavingsAccordion = () => {
  const theme = useTheme();
  const colorSet = theme.vars.palette.savings;

  const tipButtonColors = {
    circleColor: colorSet.secondary,
    pathColor: colorSet.tertiary,
    hoverCircleColor: colorSet.tertiary,
    hoverPathColor: colorSet.primary,
  };

  return (
    <FinancialCard
      title={"Savings"}
      backgroundColor={colorSet.primary}
      color={colorSet.tertiary}
      tipModalContent={marketingTips.saving}
      tipButtonColors={tipButtonColors}
    >
      <Heading />
      <Inputs />
    </FinancialCard>
  );
};

export default SavingsAccordion;
