import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
  const [currentPath, setCurrentPath] = useState("/");
  const [currentFeature, setCurrentFeature] = useState("");
  // Temporarily used projection instead of tracker
  const [isSelected, setIsSelected] = useState({
    home: false, projection: false, ai: false, learn: false, game: false,
  });
  const [showNavBar, setShowNavBar] = useState({ bottom: false, top: false });
  const [adjustHeight, setAdjustHeight] = useState(false);
  const navigateTo = useNavigate();

  const navigate = (path) => navigateTo(path);

  // Checks which feature is selected
  useEffect(() => {
    const newIsSelected = {...isSelected};
    Object.entries(newIsSelected).map(([feature, _]) => [feature, false]);
    newIsSelected[currentFeature] = true;
    setIsSelected(newIsSelected);
  }, [currentPath, currentFeature]);

  // Checks whether the navigation bars should be displayed
  useEffect(() => {
    const showBottom = (
      currentFeature !== "login" &&
      currentFeature !== "signup" &&
      currentFeature !== "privacy-policy" &&
      currentFeature !== "questionnaire"
    );
    // Todo: implement showTop
    const newShowNavBar = {...showNavBar};
    newShowNavBar.bottom = showBottom;
    setShowNavBar(newShowNavBar);
  }, [currentPath, currentFeature]);

  // Adjusts height if current page is AI chat
  useEffect(() => {
    setAdjustHeight(currentFeature === "ai");
  }, [currentPath, currentFeature]);

  const contextValue = {
    adjustHeight,
    currentFeature,
    currentPath,
    isSelected,
    navigate,
    setCurrentFeature,
    setCurrentPath,
    showNavBar,
  };

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
export { NavigationProvider };
