import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ChartNavigation from "./ChartNavigation";
import FinancialChart from "./FinancialChart";

const Heading = () => {
  return (
    <Box padding={"0px 24px 16px 8px"}>
      <Typography fontSize={"28px"} fontWeight={700} lineHeight={"150%"}>
        Future You Projection
      </Typography>
    </Box>
  );
};

const NetWorthProjection = () => {
  const [activeTab, setActiveTab] = useState(4);

  return (
    <Box
      display={"flex"}
      height={"100%"}
      flexDirection={"column"}
      alignItems={"flex-start"}
    >
      <Heading />
      <ChartNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
      <FinancialChart activeTab={activeTab} />
    </Box>
  );
};

export default NetWorthProjection;
