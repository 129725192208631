import React from 'react';
import PromptChoice from '../promptTypes/PromptChoice';
import { addAnnualChange, changeProperty } from '../../promptCalculations';

const AMOUNT = 1000;

const page = {
    title: `Your partner wants to borrow £${AMOUNT}.`,
    caption:
        "He claiming he can triple it with his successful investment in Apple stock options.",
    description:
        "Would you lend him the money? Think before making any decisions. ",
    choices: [
        {
            choice: "Loan him the money, but be ready to accept it as a loss",
            outcome: 0,
        },
        {
            choice: "Don't give him the money",
            outcome: 1,
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/money-2.jpeg",
    },
  };

// const changes = [
//     {
//         change: "decrease",
//         property: "cash",
//         by: "1000",
//     },
// ];

const change1 = () => {
    changeProperty({
        change: "decrease",
        property: "cash",
        by: AMOUNT,
    });
  }
  
const changes = [
    change1,
];

const tipPage1 = {
    content: [
        {
            type: "title",
            text: "We are good at daily finances, but what about long term finances...?",
        },
        {
            type: "paragraph",
            text: "One study found that women are more engaged in short-term financial planning (day-to-day expenses, household, paying bills), yet rely on their spouse for long-term financial matters such as retirement, investing, insurance, etc.",
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/money-4.jpeg",
    },
};
  
const tipPage2 = {
    content: [
        {
            type: "title",
            text: "Always manage your own money!",
        },
        {
            type: "paragraph",
            text: "As the new generation of women who can change the future of female finance, let's always ensure that you are the person in control of your financial independence. Manage your own money. Even if someone mentions an amazing investment opportunity, assess the situation thoroughly using multiple trustworthy sources. Check with the FSCS, financial experts, and Citizens Advice before making any decisions.",
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/money-4.jpeg",
    },
};
  
const tipPage3 = {
    content: [
        {
            type: "title",
            text: "If you are giving money away, make sure you:",
        },
        {
            type: "list",
            listType: "ordered",
            listItems: [
                {
                    text: "Study the situation thoroughly before making any emotional decision.",
                },
                {
                    text: "Consider whether you're comfortable with the possibility of losing that money entirely.",
                },
                {
                    text: "Evaluate how involving law enforcement to recover the money might impact your relationship with that person. (Remember, you'll need evidence to involve law enforcement).",
                },
            ],
        },
    ],
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/money-4.jpeg",
    },
};
  
const toolTip = {
    type: 'tool-tip',
    content: {
      page1: tipPage1,
      page2: tipPage2,
      page3: tipPage3,
    },
    colour: {
      background: "#5F32B6",
    }
};
  
const tips = [
    toolTip,
];

const colour = {
    primary: "#CEF2E7",
    secondary: "#00607E",
};

const Prompt5 = (props) => {
    const { setPrompt } = props;
  
    const outcomes = [
        {
            type: "consequence",
            title: "Whoops",
            caption:
                "He made 50% growth of that investment, but you had a disagreement with the profit, he kept the money and you got your original money back",
            change: 0,
        },
        {
            type: "consequence",
            title: "You didn't give him the money",
            caption:
                "He wasn't happy.",
        },
    ];
  
    const prompt5 = {
        type: "choice",
        page: page,
        outcomes: outcomes,
        changes: changes,
        tips: tips,
        colour: colour,
    };
    
    return (
        <PromptChoice {...prompt5} setPrompt={setPrompt} />
    );
}
  
export default Prompt5;
  