import React from "react";
import { Box, styled } from "@mui/material";
import {
    FinancialProductContentHeader,
    PageContainer,
} from "@features/learn";
import TextContent from "@components/textContent";
import { 
    financialProducts,
    productCategories,
    productContent,
} from "@content/financialProducts";

const Container = styled(Box)(() => ({
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
}));

const Header = styled(Box)(() => ({
    alignSelf: "stretch",
    backgroundColor: "white",
    borderRadius: "32px 32px 0 0",
    display: "flex",
    flexDirection: "column",
    padding: "8px 8px 16px 8px",
}));

const Body = styled(Box)(() => ({
    alignSelf: "stretch",
    borderRadius: "0 0 32px 32px",
    display: "flex",
    flexDirection: "column",
    gap: 48,
    padding: "24px 20px 32px 20px",
}));

const Content = styled(Box)(() => ({
    display: "grid",
    gap: 24,
}));

const FinancialProductPage = (props) => {
    const { id } = props;
    const { category, title, growth, risk } = financialProducts[id];
    const { image, color, icon } = productCategories[category];
    const content = productContent[id];

    return (
        // padding: "8px 16px 112px 16px", Changed when bottom navigation added
        <PageContainer sx={{ padding: "16px" }}>
            <Container>
                <Header>
                    <FinancialProductContentHeader
                        {...{title, growth, risk, image, color, icon}}
                    />
                </Header>
                <Body backgroundColor={color.backgroundColor}>
                    <Content>
                        {/* Share button placeholder */}
                        {/* <div style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "32px"
                        }} /> */}
                        <TextContent content={content} color="#031711" />
                    </Content>
                    {/* "Check available products" button */}
                </Body>
            </Container>
        </PageContainer>
    );
};

export default FinancialProductPage;
