import React from "react";
import theme from "@themes";
import FormContentBox, {
  SubmitButton,
} from "@features/questionnaire/layouts/FormContentBox";
import FormContent from "../FormContent";
import { Box } from "@mui/material";
import questions from "@content/questionnaire";

const Title = () => {
  return <>Tell us about Your Lifestyle</>;
};

const Subtitle = () => {
  return (
    <>
      so we can make a customised <br />
      financial projection for you
    </>
  );
};

const Form = () => {
  return (
    <FormContentBox text={"Your Primary Goal"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"8px"}
        overflow={"auto"}
        flexDirection={"column"}
      >
        <FormContent questionSet={questions[1]} />
        <SubmitButton text={"Continue"} />
      </Box>
    </FormContentBox>
  );
};

const content = {
  title: <Title />,
  subtitle: <Subtitle />,
  image: "assets/images/lifestyle-2.png",
  backgroundColor: theme.vars.palette.savings.tertiary,
  color: theme.vars.palette.properties.primary,
  form: <Form />,
};

export default content;
