import { useChat } from "@features/aichat/hooks";
import { Box, styled } from "@mui/material";
import { getMessages, getMostRecentMessage } from "@services/chatService";
import Chip from "@ui-library/Chip";
import { useEffect, useState } from "react";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
  padding: "16px 16px 8px",
}));

const Option = styled(Chip)(({ theme }) => ({
  minWidth: "120px",
  width: "100%",
}));

const Options = ({ onSelect }) => {
  const { history } = useChat();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const messages = getMessages(history);
    if (messages.length > 0) {
      const recentEntry = getMostRecentMessage(messages);
      if (recentEntry?.message?.responses) {
        setOptions(recentEntry.message.responses);
      }
    }
  }, [history]);

  return (
    <Container>
      {options.map(({ title, details }, index) => (
        <Option
          key={index}
          variant="dark"
          label={title}
          onClick={() => onSelect(title)}
        />
      ))}
    </Container>
  );
};

export default Options;
