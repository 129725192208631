import React from "react";
import { Grid, styled } from "@mui/material";
import { ProjectionProvider } from "../../contexts/ProjectionContext";
import Main from "./Main";
import Sidebar from "./Sidebar";

const Container = styled((props) => (
  <Grid container columnSpacing={{ md: "8px" }} {...props} />
))(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flex: "1 0 0",
  alignItems: "flex-start",
  alignSelf: "stretch",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const Content = () => {
  return (
    <ProjectionProvider>
      <Container>
        <Sidebar />
        <Main />
      </Container>
    </ProjectionProvider>
  );
};

export default Content;
