import React from 'react';

const Step1 = () => {
    return (
        <svg width="60" height="6" viewBox="0 0 60 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="6" rx="3" fill="black" fillOpacity="0.3"/>
            <rect x="40" width="6" height="6" rx="3" fill="black" fillOpacity="0.3"/>
            <rect x="54" width="6" height="6" rx="3" fill="black" fillOpacity="0.3"/>
        </svg>
    );
}

const Step2 = () => {
    return (
        <svg width="60" height="6" viewBox="0 0 60 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="6" height="6" rx="3" fill="black" fillOpacity="0.3"/>
            <rect x="14" width="32" height="6" rx="3" fill="black" fillOpacity="0.3"/>
            <rect x="54" width="6" height="6" rx="3" fill="black" fillOpacity="0.3"/>
        </svg>
    );
}

const Step3 = () => {
    return (
        <svg width="60" height="6" viewBox="0 0 60 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="6" height="6" rx="3" fill="black" fillOpacity="0.3"/>
            <rect x="14" width="6" height="6" rx="3" fill="black" fillOpacity="0.3"/>
            <rect x="28" width="32" height="6" rx="3" fill="black" fillOpacity="0.3"/>
        </svg>
    );
}

const StepIndicator = ({ stepNum = 1 }) => {
    const step = () => {
        switch (stepNum) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            default:
                return <></>;
        }
    }

    return step();
}

export default StepIndicator;
