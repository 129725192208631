import React, { useEffect, useState } from 'react';
import ClosePopup from '../components/ClosePopup';
import WealthQuestTitle from '../components/WealthQuestTitle';
import Avatar from '../components/Avatar';
import './Leaderboard.css';
import { getPlayerDatetime, getPlayerName } from '../playerState';

// Used to select top players of the last 7 days, or last 30 days
const ToggleButton = ({ isThirtyDays, setIsThirtyDays }) => {
    return (
        <div className="toggle-container">
            <div
                className={isThirtyDays ? "toggle-switch on" : "toggle-switch"}
                onClick={() => setIsThirtyDays(!isThirtyDays)}
            >
                <p>Last 7 days</p>
                <p>Last 30 days</p>
                <div className="toggle-switch-inner" />
            </div>
        </div>
    );
}

// Arrow icon displayed with starting and finishing net worth
const NetWorthArrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
        >
            <g clipPath="url(#clip0_2289_3221)">
                <path
                    d="M8.00016 1.37109C9.45056 1.37109 10.8684 1.80119 12.0743 2.60698C13.2803 3.41278 14.2202 4.55809 14.7753 5.89808C15.3303 7.23807 15.4755 8.71256 15.1926 10.1351C14.9096 11.5576 14.2112 12.8643 13.1856 13.8899C12.16 14.9155 10.8533 15.6139 9.43082 15.8969C8.00829 16.1798 6.5338 16.0346 5.19381 15.4795C3.85382 14.9245 2.70851 13.9846 1.90271 12.7786C1.09692 11.5726 0.666825 10.1548 0.666825 8.70443C0.666825 6.75951 1.43944 4.89424 2.81471 3.51898C4.18997 2.14371 6.05524 1.37109 8.00016 1.37109ZM4.00016 9.37109H10.3908L8.86216 10.8998C8.79849 10.9613 8.7477 11.0348 8.71276 11.1162C8.67782 11.1975 8.65943 11.285 8.65866 11.3735C8.65789 11.462 8.67476 11.5498 8.70828 11.6317C8.7418 11.7137 8.7913 11.7881 8.8539 11.8507C8.91649 11.9133 8.99093 11.9628 9.07286 11.9963C9.15479 12.0298 9.24257 12.0467 9.33109 12.0459C9.41961 12.0452 9.50709 12.0268 9.58843 11.9918C9.66976 11.9569 9.74333 11.9061 9.80483 11.8424L12.4715 9.17576C12.5965 9.05074 12.6667 8.8812 12.6667 8.70443C12.6667 8.52765 12.5965 8.35811 12.4715 8.23309L9.80483 5.56643C9.74333 5.50275 9.66976 5.45197 9.58843 5.41703C9.50709 5.38209 9.41961 5.3637 9.33109 5.36293C9.24257 5.36216 9.15479 5.37902 9.07286 5.41255C8.99093 5.44607 8.91649 5.49557 8.8539 5.55816C8.7913 5.62076 8.7418 5.69519 8.70828 5.77712C8.67476 5.85906 8.65789 5.94684 8.65866 6.03536C8.65943 6.12388 8.67782 6.21136 8.71276 6.2927C8.7477 6.37403 8.79849 6.4476 8.86216 6.50909L10.3908 8.03776H4.00016C3.82335 8.03776 3.65378 8.108 3.52875 8.23302C3.40373 8.35805 3.33349 8.52762 3.33349 8.70443C3.33349 8.88124 3.40373 9.05081 3.52875 9.17583C3.65378 9.30086 3.82335 9.37109 4.00016 9.37109Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_2289_3221">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="matrix(-1 0 0 1 16 0.70459)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

// Starting and finishing net worth of a player
const PlayerNetWorth = ({ initial, final }) => {
    return (
        <div className='player-net-worth'>
            <p>{initial}</p>
            <NetWorthArrow />
            <p>{final}</p>
        </div>
    );
}

// Arrow icon displayed with percentage increase
const IncreaseArrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M8.6668 4.56121V13.5379C8.6668 13.9061 8.36832 14.2046 8.00013 14.2046C7.63194 14.2046 7.33346 13.9061 7.33346 13.5379V4.56121L5.16177 6.7329C4.8884 7.00627 4.44519 7.00627 4.17182 6.7329C3.89846 6.45953 3.89846 6.01632 4.17182 5.74295L7.50516 2.40962C7.77852 2.13625 8.22174 2.13625 8.4951 2.40962L11.8284 5.74295C12.1018 6.01632 12.1018 6.45953 11.8284 6.7329C11.5551 7.00627 11.1119 7.00627 10.8385 6.7329L8.6668 4.56121Z" fill="#E04B77"
            />
        </svg>
    );
}

const PercentageIncrease = ({ value }) => {
    const decimalPlaces = 0;

    return (
        <div className='player-percentage-inc'>
            <IncreaseArrow />
            <p>{value.toFixed(decimalPlaces)}%</p>
        </div>
    );
}

// A player entry in the list of top players
const Player = (props) => {
    const {
        position, name, datetime, avatar, initial, final, percentageChange,
    } = props;

    const current = (
        name === getPlayerName() && datetime === getPlayerDatetime()
    ) ? true : false;
    
    return (
        <div className={current ? 'player current-player' : 'player'}>
            <div className='player-details'>
                <div className='player-rank-avatar'>
                    <p className='player-rank'>{position}</p>
                    <Avatar avatar={avatar} size='48px' />
                </div>
                <div className='player-name-net-worth'>
                    <p>{name}</p>
                    <PlayerNetWorth initial={initial} final={final} />
                </div>
            </div>
            <PercentageIncrease value={percentageChange} />
        </div>
    );
}

const Players = ({ players, isThirtyDays }) => {
    const sevenDays = players["7_days"]["top_10_last_7_days"];
    const thirtyDays = players["30_days"]["top_10_last_30_days"];

    const topTen = isThirtyDays ? thirtyDays : sevenDays;

    return (
        <div className='players'>
            {topTen.map((player, index) => (
                <React.Fragment key={index}>
                    <Player
                        position={index + 1}
                        name={player.name}
                        datetime={player.datetime}
                        avatar={player.player_icon}
                        initial={player.starting_net_worth}
                        final={player.finishing_net_worth}
                        percentageChange={player.percentage_change}
                    />
                </React.Fragment>
            ))}
        </div>
    );
}

const PlayerComparison = () => {
    return (
        <>
            <p className='leaderboard-footer'>
                How do I compare to Top 10?
            </p>
            {/* current player */}
        </>
    );
}

const Leaderboard = ({ setLeaderboard }) => {
    // Initial state of players must have the same format as fetched data
    const [players, setPlayers] = useState({
        "7_days": { "top_10_last_7_days": [] },
        "30_days": { "top_10_last_30_days": [] }
    });
    // Represents state of toggle button
    const [isThirtyDays, setIsThirtyDays] = useState(false);

    useEffect(() => {
        const fetchLeaderboard = async () => {
            const lambaUrl =
                "https://9si1yhwg1c.execute-api.eu-west-1.amazonaws.com/default/getLeaderboard";
            
            try {
                const response = await fetch(lambaUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                
                const data = await response.json();
                setPlayers(data);
            } catch (error) {
                // handle error
                console.log(error);
            }
        };

        fetchLeaderboard();
    }, []);

    const notInTopTen = false;

    return (
        <div className='leaderboard popup'>
            <div className='leaderboard-content popup-content'>
                <ClosePopup setPopup={setLeaderboard} type='dark' />
                <div className='leaderboard-header'>
                    <WealthQuestTitle size='137px' color='pink' />
                </div>
                <div className='leaderboard-top-ten'>
                    <h2>Top 10 Players This Month</h2>
                    <ToggleButton
                        isThirtyDays={isThirtyDays}
                        setIsThirtyDays={setIsThirtyDays}
                    />
                    <Players players={players} isThirtyDays={isThirtyDays} />
                </div>
                {/* if not in top 10 */}
                { notInTopTen && <PlayerComparison />}
            </div>
        </div>
    );
}

export default Leaderboard;
