const title = {
    type: "title",
    text: "Whether you're new or experienced, discover strategies to make informed decisions and maximise your financial potential.",
};

const keyTakeaways = {
    type: "section",
    heading: "Key Takeaways",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "body",
                    text: "Understand the fundamentals of investing and how it can work for you.",
                },
                {
                    type: "body",
                    text: "Learn practical tips to build a diversified investment portfolio.",
                },
                {
                    type: "body",
                    text: "Explore different investment vehicles and their potential returns.",
                },
                {
                    type: "body",
                    text: "Gain confidence in navigating the world of finance with expert guidance.",
                },
            ],
        },
    ],
};

const whoShouldAttend = {
    type: "section",
    heading: "Who Should Attend",
    body: [
        {
            type: "body",
            text: "This workshop is ideal for anyone interested in enhancing their financial literacy and exploring the opportunities that investing offers. Whether you're planning for retirement, saving for a major purchase, or simply aiming to grow your wealth, this session is tailored to meet your needs.",
        },
    ],
};

const instructor = {
    type: "section",
    heading: "Instructor",
    body: [],
};

const content = [
    title,
    keyTakeaways,
    whoShouldAttend,
    instructor,
];

const benefitsOfInvesting = {
    category: "Investing",
    topic: "All About the Benefits of Investing",
    datetime: {
        date: "10 July",
        time: "05:00 PM - 05:30 PM",
    },
    tier: "free",
    image: "/learn/workshops/investing.png",
    color: "#10773F",
    content: content,
};

export default benefitsOfInvesting;
