const content = [];

const allAboutPension = {
    category: "Pension",
    topic: "All About Pension",
    datetime: {
        date: "10 July",
        time: "05:00 PM - 05:30 PM",
    },
    tier: "free",
    image: "/learn/workshops/pension.png",
    color: "#EAB244",
    content: content,
};

export default allAboutPension;
