import { Box, Typography } from "@mui/material";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip from "@ui-library/Tooltip";
import TextField from "@ui-library/TextField";
import { INTEREST_ONLY, REPAYMENT } from "@utils/financialCalculations";
import MortgageTypeToggle from "./MortgageTypeToggle";

const Inputs = () => {
  const { state, dispatch } = useProjectionContext();
  const { realEstate } = state;

  const textFields = [
    {
      id: "currentPropertyValue",
      name: "currentPropertyValue",
      label: "The Current Property Value",
      startsymbol: "£",
      value: realEstate.currentPropertyValue,
      tooltip:
        "This value represents the market value of your property at the present time.",
    },
    {
      id: "remainingMortgage",
      name: "remainingMortgage",
      label: "Remaining Mortgage",
      startsymbol: "£",
      value: realEstate.remainingMortgage,
      tooltip: "Enter the remaining amount on your mortgage loan",
    },
    {
      id: "monthlyPayment",
      name: "monthlyPayment",
      label: "Monthly Payment",
      startsymbol: "£",
      value: realEstate.monthlyPayment,
      tooltip:
        "Additional monthly payments toward the mortgage that can decrease total interest paid and shorten the mortgage term.",
    },
    {
      id: "interestRateRealEstate",
      name: "interestRate",
      label: "Mortgage Interest",
      startsymbol: "%",
      value: realEstate.interestRate,
      tooltip: "Please enter the current mortgage interest rate",
    },
    {
      id: "monthlyOverpayment",
      name: "monthlyOverpayment",
      label: "Monthly Overpayment",
      startsymbol: "£",
      value: realEstate.monthlyOverpayment,
      tooltip:
        "Interest Only: Only pay the interest on the loan without reducing the principal amount.\n\nRepayment: Pay both the interest and the principal amount of the loan.",
    },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;

    // First, remove leading zeros, but allow a single zero before a decimal point
    const sanitizedValue = value.replace(/^0+(?!\.|$)/, "");

    // Allow digits, a single dot, and nothing else.
    // This regex allows values like '2.', '2.0', '0.', etc.
    if (/^\d*\.?\d*$/.test(sanitizedValue)) {
      // console.log(value);
      // console.log(sanitizedValue);
      dispatch({
        type: "SET_REAL_ESTATE",
        payload: { ...realEstate, [name]: sanitizedValue || 0 },
      });
    }
  };

  const handleMortgageTypeChange = (event, newMortgageType) => {
    if (newMortgageType !== null) {
      // Prevent deselecting all options
      dispatch({
        type: "SET_REAL_ESTATE",
        payload: {
          ...realEstate,
          mortgageType: newMortgageType,
        },
      });
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
      {textFields.map((data, i) => (
        <TextField
          key={i}
          tooltip={"true"}
          tooltipcontent={data.tooltip}
          id={data.id}
          name={data.name}
          label={data.label}
          startadornment="true"
          startsymbol={data.startsymbol}
          value={data.value}
          onChange={handleChange}
        />
      ))}{" "}
      <MortgageTypeToggle
        value={realEstate.mortgageType}
        onChange={handleMortgageTypeChange}
      />
    </Box>
  );
};

export default Inputs;
