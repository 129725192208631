// type property: required, indicates which type of prompt we're using,
//                one exception to the requirement could be the finish prompt,
//                takes values 'distribution', 'choice', 'choice-extended'

import {
  prompt2,
  prompt3,
  prompt5,
  prompt6,
  prompt10,
  prompt15,
} from "./choice";
import {
  prompt11,
  prompt12,
  prompt14,
  prompt7,
  prompt8,
  prompt9,
} from "./choiceExtended";
import { prompt1, prompt13, prompt4 } from "./distribution";
import promptFinish from "./promptFinish";

const promptsSet = {
  prompt1: prompt1,
  prompt2: prompt2,
  prompt3: prompt3,
  prompt4: prompt4,
  prompt5: prompt5,
  prompt6: prompt6,
  prompt7: prompt7,
  prompt8: prompt8,
  prompt9: prompt9,
  prompt10: prompt10,
  prompt11: prompt11,
  prompt12: prompt12,
  prompt13: prompt13,
  prompt14: prompt14,
  prompt15: prompt15,
  promptFinish: promptFinish,
};

// export const getPrompts = () => {
//   return prompts;
// };

export default promptsSet;
