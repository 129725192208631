import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Authentication, Description } from "@features/signup";
import { useAuthContext } from "@hooks";

const SignUpPage = () => {
  const { setIsSignedIn } = useAuthContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();
  const [showAuth, setShowAuth] = useState(false);

  useEffect(() => {
    setIsSignedIn(false);
  }, []);

  return (
    <Grid
      container
      height={"100vh"}
      // minHeight={"100vh"}
      backgroundColor={theme.vars.palette.investment.tertiary}
    >
      {!isMobile ? (
        <>
          <Description setShowAuth={setShowAuth} />
          <Authentication />
        </>
      ) : !showAuth ? (
        <Description setShowAuth={setShowAuth} />
      ) : (
        <Authentication />
      )}
    </Grid>
  );
};

export default SignUpPage;
