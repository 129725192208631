const title = {
    type: "title",
    text: "Covers trips, including emergencies and cancellations.",
};

const description = {
    type: "body",
    text: "It's your travel guardian angel.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Provides coverage for medical emergencies, trip cancellations, and lost luggage.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Allianz Travel Insurance",
            subheading: "Covers medical expenses, trip cancellations, and lost luggage.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £5 per trip.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available directly from the Allianz website or through travel agents."
                        }
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "AXA Travel Insurance",
            subheading: "Comprehensive cover including medical expenses, cancellations, and personal belongings.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £7 per trip.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available on the AXA website or through travel agencies and comparison sites."
                        }
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Travel worry-free knowing you're covered for unexpected events with comprehensive travel insurance.",
        },
    ],
};

const travelInsurance = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
];

export default travelInsurance;
