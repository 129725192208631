import { SvgIcon } from "@mui/material";

const LearnIcon = ({ color = "#065D44", selected = false }) => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill={selected ? color : "none"}
        >
            <path
                d="M22 17.2417V5.17165C22 3.97165 21.02 3.08165 19.83 3.18165H19.77C17.67 3.36165 14.48 4.43165 12.7 5.55165L12.53 5.66165C12.24 5.84165 11.76 5.84165 11.47 5.66165L11.22 5.51165C9.44 4.40165 6.26 3.34165 4.16 3.17165C2.97 3.07165 2 3.97165 2 5.16165V17.2417C2 18.2017 2.78 19.1017 3.74 19.2217L4.03 19.2617C6.2 19.5517 9.55 20.6517 11.47 21.7017L11.51 21.7217C11.78 21.8717 12.21 21.8717 12.47 21.7217C14.39 20.6617 17.75 19.5517 19.93 19.2617L20.26 19.2217C21.22 19.1017 22 18.2017 22 17.2417Z"
                stroke={selected ? "white" : color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 5.98828V20.9883"
                stroke={selected ? "white" : color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.75 8.98828H5.5"
                stroke={selected ? "white" : color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 11.9883H5.5"
                stroke={selected ? "white" : color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

export default LearnIcon;
