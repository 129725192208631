import React from "react";
import Typography from "@mui/material/Typography";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import { Box, useTheme } from "@mui/material";
import Button from "@ui-library/Button";

const TextInEllipse = ({ text, color }) => {
  return (
    <span style={{ verticalAlign: "middle" }}>
      <svg
        width="86"
        height="29"
        viewBox="0 0 86 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.1955 24.9228C39.2372 30.5523 65.9992 25.1279 71.533 23.7969C79.958 21.7705 85 17.083 85 13.5226C85 -2.66163 29.1839 0.574818 22.8649 1.13778C17.2573 1.63737 9.899 2.86939 5.4632 4.98C-0.563155 7.84743 -0.412242 16.74 5.46349 20.1C11.3392 23.46 20.9822 27.1746 33.0442 28.1592C45.1062 29.1439 53.9811 27.7376 63.1083 27.1746"
          stroke={color}
          strokeLinecap="round"
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fill={color}
        >
          {text}
        </text>
      </svg>
    </span>
  );
};

const Text = () => {
  const theme = useTheme();
  const { projectedAgeAtOneMillionNetWorth } = useProjectionContext();
  const line1 = `You're projected to reach a £1 million net worth in the next `;
  const line2 = `Interested in learning how to boost your net worth?`;
  return (
    <Typography
      fontSize={"15px"}
      lineHeight={"25px"}
      color={theme.vars.palette.investment.primary}
    >
      {line1}
      {
        <TextInEllipse
          text={`${projectedAgeAtOneMillionNetWorth} years.`}
          color={theme.vars.palette.investment.primary}
        />
      }
      <br />
      {line2}
    </Typography>
  );
};

export default Text;
