import React from "react";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import TextField from "@ui-library/TextField";

const RetirementAgeButton = () => {
  const { state, dispatch } = useProjectionContext();
  const { currentAge, retirementAge } = state;

  const handleChangeRetirementAge = (event) => {
    const { value } = event.target;
    // Allow digits, a single dot, and nothing else.
    // This regex allows values like '2.', '2.0', '0.', etc.
    if (/^\d*\d*$/.test(value)) {
      dispatch({ type: "SET_RETIREMENT_AGE", payload: value });
    }
  };

  const handleBlurRetirementAge = (event) => {
    const newValue = parseInt(event.target.value, 10);
    const currentAgeNumber = parseInt(currentAge, 10);
    if (!isNaN(newValue) && newValue >= currentAgeNumber && newValue <= 100) {
      dispatch({ type: "SET_RETIREMENT_AGE", payload: newValue.toString() });
    } else {
      dispatch({ type: "SET_RETIREMENT_AGE", payload: "66" }); // Clear the input if invalid
    }
  };

  const tooltipText = `The age at which individuals typically reach retirement based on standard guidelines or regulations.`;

  return (
    <TextField
      isdark="true"
      tooltip="true"
      tooltipcontent={tooltipText}
      id={"retirementAge"}
      label={"Retirement at"}
      value={retirementAge}
      onChange={handleChangeRetirementAge}
      onBlur={handleBlurRetirementAge}
    />
  );
};

export default RetirementAgeButton;
