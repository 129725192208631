import React from "react";
import { Grid, styled } from "@mui/material";

const GridContainer = styled(({ ...props }) => (
  <Grid item md={4} xs={12} {...props} />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // flex: "0 1 0",w
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    height: "100%",
    overflow: "hidden",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 24px 0px 24px",
    gap: 8,
    maxHeight: "50%",
    alignItems: "center",
    alignSelf: "stretch",
  },
}));

export default GridContainer;
