import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setAvatar, setInitialValues, setPlayerDetails } from '../playerState';
import WealthQuestTitle from '../components/WealthQuestTitle';
import Avatar from '../components/Avatar';
import StepIndicator from '../components/StepIndicator';
import './GameOnboarding.css';

const AVATAR_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8];

const FORM2_OPTIONS = [
    {id: 'name', label: 'Name'},
    {id: 'age', label: 'Age'}
];

const FORM3_OPTIONS = [
    {id: 'savings', label: 'Total Savings'},
    {id: 'salary', label: 'Year Salary Post Tax'},
    {id: 'spending', label: 'Monthly Spending'}
]

const Form1 = () => {
    return (
        <div className='form'>
            <h2>Who are you?</h2>
            <p>Choose your game avatar</p>
            <div className='avatars'>
                {AVATAR_OPTIONS.map((option, index) => {
                    return (
                        <React.Fragment key={index}>
                            <input
                                type='radio'
                                id={`option-${index}`}
                                name='avatar'
                                value={option}
                            />
                            <label htmlFor={`option-${index}`}>
                                <Avatar size='80' avatar={option} />
                            </label>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
}

const Form2 = ({ isValid }) => {
    return (
        <div className='form'>
            <Avatar />
            <h2>What's your<br/>name and age?</h2>
            <div className='text-inputs'>
                {FORM2_OPTIONS.map((line, index) => {
                    return (
                        <div key={index} className='text-input'>
                            <label htmlFor={line.id}>{line.label}</label><br />
                            <input
                                type='text'
                                id={line.id}
                                name={line.id}
                                autoComplete='off'
                                required={true}
                            /><br />
                        </div>
                    );
                })}
            </div>
            {!isValid &&
                <p
                    className='invalid-input'
                >Invalid input.</p>}
        </div> 
    );
}

const Form3 = ({ isValid }) => {
    return (
        <div className='form'>
            <Avatar />
            <h2>Let's set up your wallet</h2>
            <p>Enter the financial stats here</p>
            <div className='text-inputs'>
                {FORM3_OPTIONS.map((line, index) => {
                    return (
                        <div key={index} className='text-input'>
                            <label htmlFor={line.id}>{line.label}</label><br />
                            <input
                                type='text'
                                id={line.id}
                                name={line.id}
                                autoComplete='off'
                                required={true}
                            /><br />
                        </div>
                    );
                })}
            </div>
            {!isValid &&
                <p
                    className='invalid-input'
                >Invalid input. Ensure values are non-zero numbers.</p>}
        </div>
    );
}

const GameOnboarding = () => {
    const [form, setForm] = useState(1);
    const [isValid, setIsValid] = useState([true, true, true]);
    const navigate = useNavigate();

    const handleForm1 = (event) => {
        event.preventDefault();
        // Retrieve input
        const form1 = event.target;
        setAvatar(Number(form1.avatar.value));
        // Display next form
        setForm(form + 1);
    }

    const handleForm2 = (event) => {
        event.preventDefault();
        // Retrieve inputs
        const form2 = event.target;
        const name = form2.name.value;
        const age = Number(form2.age.value);
        // Validate inputs
        const regex = /^[a-zA-Z0-9\s]+$/;
        const localIsValid = regex.test(name) && Number.isInteger(age)
        setIsValid([isValid[0], localIsValid, isValid[2]]);
        // If inputs are valid, store values and display next form
        if (localIsValid) {
            setPlayerDetails(name, age);
            setForm(form + 1);
        }
    }

    const handleForm3 = (event) => {
        event.preventDefault();
        // Retrieve inputs
        const form3 = event.target;
        const savings = Number(form3.savings.value);
        const salary = Number(form3.salary.value);
        const spending = Number(form3.spending.value);
        // Validate inputs
        const isPositiveNumber = (value) => {
            const regex = /^\d*\.?\d+$/;
            return regex.test(value) && parseFloat(value) > 0;
        }
        const localIsValid = (
            isPositiveNumber(savings) &&
            isPositiveNumber(salary) &&
            isPositiveNumber(spending)
        );
        setIsValid([isValid[0], isValid[1], localIsValid]);
        // If inputs are valid, store values and navigate to game map
        if (localIsValid) {
            setInitialValues(savings, salary, spending);
            navigate('../wealth-quest', { replace: true });
        }
    }

    const forms = [
        <Form1 />, <Form2 isValid={isValid[1]} />, <Form3 isValid={isValid[2]} />,
    ];
    const formButtons = [
        <input type='submit' value={String.fromCharCode(8594)} />,
        <input type='submit' value={String.fromCharCode(8594)} />,
        <input type='submit' value='Submit' />,
    ];
    const formHandlers = [
        handleForm1, handleForm2, handleForm3,
    ];

    return (
        <div className='game-onboarding'>
            <div className='onboarding-title'>
                <WealthQuestTitle size='87px' color='pink' />
            </div>
            <form onSubmit={formHandlers[form - 1]}>
                {forms[form - 1]}
                <div className='step-button-footer'>
                    <StepIndicator stepNum={form} />
                    {formButtons[form - 1]}
                </div>
            </form>
        </div>
    );
}

export default GameOnboarding;
