const page = {
  title: "Your landlord is raising your rent £250 per month.",
  caption: "This will increase your essential spending.",
  description:
    "I am currently spending 50% of my salary in essentials, higher rent will result in 60% of my essential, 25% of my non-essential spending, and 15% of my future me fund.",
  choices: [
    {
      choice: "Negotiate with your landlord to keep the same price",
      outcome: 0,
    },
    {
      choice: "Offer your landlord £150",
      outcome: 1,
    },
    {
      choice: "Accept your landlords offer",
      outcome: 2,
    },
  ],
};

const outcomes = [
  {
    type: "consequence",
    title: "You tried, but it didn't work",
    caption:
      "He insisted on £250, you accepted. Now your essential spending is £250 more per month",
    change: 0,
  },
  {
    title: "It worked!",
    caption:
      "He agreed to £150 pm increase. Now your essential spending is £150 more per month",
    change: 1,
  },
  {
    type: "consequence",
    title: "You accepted £250 increase",
    caption: "Your essential spending is now £250 more",
    change: 0,
  },
];

const changes = [
  {
    change: "increase",
    property: "spending",
    by: "250",
  },
  {
    change: "increase",
    property: "spending",
    by: "150",
  },
];

const tipPage1 = {
  content: [
    {
      type: "title",
      text: "How do you negotiate your rent?",
    },
    {
      type: "paragraph",
      text: "Sign a longer lease with a 6-month break clause to convey your intention of a longer stay to the landlord. This provides flexibility to exit the contract if needed. During the fixed term, the landlord can't raise the rent unless agreed upon or specified in the tenancy agreement's rent review clause.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/houses.png",
  },
};

const tipPage2 = {
  content: [
    {
      type: "title",
      text: "Find a win-win situation for both parties",
    },
    {
      type: "paragraph",
      text: "Do the math for your landlord. Tell him, finding a new tenant and their move in date can a gap between 1 - 8 weeks, if you are renting £250 per month, he's losing £250 - £2000, let alone the tenancy clean and new fee to find a new tenant. Offer something thats in below his increase that year, but higher than the previous years rent.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/tickets-in-hand.png",
    style: {
      backgroundColor: "#FFB5B9",
    },
  },
};

const tipPage3 = {
  content: [
    {
      type: "title",
      text: "There are many schemes to protect you as a renter.",
    },
    {
      type: "paragraph",
      text: "Dive into the world of renter protection schemes - it's like unlocking secret powers! Explore resources that keep you safe and sound in your rental adventure. Remember, knowledge is your best sidekick in this superhero journey! If anything, go to WInvest community and ask for help, you aren't alone in this!",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/books-in-hand.jpeg",
  },
};

const toolTip = {
  type: 'tool-tip',
  content: {
    page1: tipPage1,
    page2: tipPage2,
    page3: tipPage3,
  },
  colour: {
    background: "#E04B77",
  }
};

const tips = [
  toolTip,
];

const prompt3 = {
  type: "choice",
  page: page,
  outcomes: outcomes,
  changes: changes,
  tips: tips,
  colour: {
    primary: "#CEF2E7",
    secondary: "#5F32B6",
  },
};

export default prompt3;
