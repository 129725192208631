import React from "react";
import { Box, Button as MuiButton, styled, Typography } from "@mui/material";
import Button from "@ui-library/Button";

const StyledButton = styled(MuiButton)(({ theme }) => ({
  border: "1px solid #DBDAD9",
  color: "#000",
  borderRadius: "4px",
  padding: "8px",
  height: "40px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "none", // Prevent uppercase transformation
}));

const AuthButton = (props) => {
  const { id, authIcon, authText, onClick } = props;
  return (
    <Button
      onClick={onClick}
      sx={{
        minWidth: "100%",
        backgroundColor: "#F3F3F3",
        color: "#000",
        justifyContent: "space-between",
        "&:hover": {
          backgroundColor: "#F3F3F3",
          color: "#000",
          boxShadow: "none",
        },
        "&:active": {
          backgroundColor: "#F3F3F3",
          color: "#000",
          boxShadow: "none",
        },
        "&.Mui-focusVisible": {
          backgroundColor: "#F3F3F3",
          color: "#000",
        },
      }}
    >
      {authText}
      <img src={authIcon} width="20px" height="20px" alt="auth icon" />
    </Button>
  );

  return (
    <StyledButton onClick={props.onClick}>
      <Box display="flex" gap="8px" alignItems="center" width="100%">
        <img src={props.authIcon} width="20px" height="20px" alt="auth icon" />
        <Typography
          width="100%"
          textAlign="center"
          alignSelf="center"
          flex="1 0 0"
          fontSize="14px"
          fontWeight={500}
          lineHeight="normal"
        >
          {props.authText}
        </Typography>
      </Box>
    </StyledButton>
  );
};

export default AuthButton;
