import React from 'react';
import ClosePopup from '../components/ClosePopup';
import PromptChoice from './promptTypes/PromptChoice';
import PromptChoiceExtended from './promptTypes/PromptChoiceExtended';
import PromptDistribution from './promptTypes/PromptDistribution';
import PromptCustom from './promptTypes/PromptCustom';
import './Prompts.css';
import { Prompt1, Prompt2, Prompt3, Prompt4, Prompt5, Prompt6, Prompt7, Prompt8, Prompt9, Prompt10, Prompt11, Prompt12, Prompt13, Prompt14 } from './prompts';

const Prompt = (props) => {
    const { index, type, colour, setPrompt } = props;
    // setPrompt(null) to close the prompt

    const PROMPTS = [
        <Prompt1 {...props} />, <Prompt2 {...props} />, <Prompt3 {...props} />,
        <Prompt4 {...props} />, <Prompt5 {...props} />, <Prompt6 {...props} />,
        <Prompt7 {...props} />, <Prompt8 {...props} />, <Prompt9 {...props} />,
        <Prompt10 {...props} />, <Prompt11 {...props} />, <Prompt12 {...props} />,
        <Prompt13 {...props} />, <Prompt14 {...props} />, <PromptChoice {...props} />,
    ];

    const prompt = PROMPTS[index - 1]

    return (
        <div className='prompt' style={{ backgroundColor: colour.primary }}>
            <ClosePopup setPopup={setPrompt} />
            <div className='prompt-content'>
                {/* {(() => {
                    switch (type) {
                        case 'choice':
                            return <PromptChoice {...props} />;
                        case 'choice-extended':
                            return <PromptChoiceExtended {...props} />;
                        case 'distribution':
                            return <PromptDistribution {...props} />;
                        case 'custom':
                            return <PromptCustom {...props} />;
                        default:
                            return <></>;
                    }
                })()} */}
                {prompt}
            </div>
        </div>
    );
}

export default Prompt;
