import React from "react";
import { InputLabel, InputBase, FormHelperText } from "@mui/material";
import StyledFormControl from "./StyledFormControl";
import StartAdornment from "./StartAdornment";
import EndAdornment from "./EndAdornment";

const TextField = (props) => {
  const {
    isdark,
    error,
    label,
    startadornment,
    startsymbol,
    tooltip,
    tooltipcontent,
    helperText,
    inputbasestyles,
  } = props;

  const styledFormControlProps = { isdark, error, inputbasestyles };
  const startAdornmentProps = { startadornment, isdark, startsymbol };
  const endAdornmentProps = { tooltip, tooltipcontent };

  return (
    <StyledFormControl {...styledFormControlProps}>
      <InputLabel>{label}</InputLabel>
      <InputBase
        startAdornment={<StartAdornment {...startAdornmentProps} />}
        endAdornment={<EndAdornment {...endAdornmentProps} />}
        {...props}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  );
};

export default TextField;
