import React from "react";
import { Typography } from "@mui/material";
import { useAuthContext } from "@hooks";
import Button from "@ui-library/Button";

const SubmitEmailButton = () => {
  const { name, email, signInUser } = useAuthContext();

  return (
    <Button
      type={"primary"}
      onClick={() => signInUser(name, email)}
      sx={{
        minWidth: "100%",
        backgroundColor: "#031711",
        color: "#FFF",
        "&:hover": {
          backgroundColor: "#031711",
          color: "#FFF",
          boxShadow: "none",
        },
        "&:active": {
          backgroundColor: "#031711",
          color: "#FFF",
          boxShadow: "none",
        },
        "&.Mui-focusVisible": {
          backgroundColor: "#031711",
          color: "#FFF",
        },
      }}
    >
      <Typography variant="bodyLEmphasis" color={"#FFF"}>
        Continue
      </Typography>
    </Button>
  );
};

export default SubmitEmailButton;
