import { keyframes, Box, styled, Typography } from "@mui/material";
import { AIChatBubble } from "../chatbubble";
import PromotionCard from "@components/promotioncards";
import promotionOptions from "@content/promotionOptions";
import { useChat } from "@features/aichat/hooks";
import {
  findFinancialProductByTitle,
  isMostRecentMessage,
} from "@services/chatService";
import { useLearnContext } from "@hooks";

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const Frame = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 60,
});

const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "95%",
  borderRadius: "20px",
  alignSelf: "flex-start",
  gap: "8px",
});

const AI_AVATAR = "assets/images/money_bestie_avatar.png";

const Bubble = styled(AIChatBubble)(({ index }) => ({
  animation: `${fadeIn} 0.5s ease-in-out`,
  animationDelay: `${index * 0.2}s`,
  opacity: 0,
  animationFillMode: "forwards",
}));

const renderBubble = (content, index) => (
  <Bubble key={index} index={index} imgSrc={AI_AVATAR}>
    {content}
  </Bubble>
);

const options = (responses) =>
  responses?.map(({ title, details }, index) => {
    let render = (
      <>
        <Typography
          variant="h5"
          textAlign={"center"}
          sx={{ marginBottom: "4px" }}
        >
          {title}
        </Typography>
        {details}
      </>
    );

    const productKey = findFinancialProductByTitle(title);
    if (productKey) {
      const { getFinancialProducts } = useLearnContext();

      render = (
        <>
          {getFinancialProducts()[productKey]}
          <Box sx={{ height: "10px" }} />
          {details}
        </>
      );
    }

    return renderBubble(render, index);
  });

const promotionItem = (key) => (
  <Box>
    {key === "workshop" && (
      <Typography
        variant="h3"
        paddingX={"20%"}
        marginBottom={"10px"}
        textAlign={"center"}
      >
        Would you rather talk to a human?
      </Typography>
    )}
    <PromotionCard {...promotionOptions[key]} />
  </Box>
);

const AssistantDisplay = ({ message, datetime, index }) => {
  const { history, promotionCounter, resetPromotionCounter } = useChat();
  const { intro, responses, conclusion, promotion } = message;

  const showPromotion =
    promotion &&
    isMostRecentMessage(datetime, history) &&
    promotionCounter >= 4;

  if (showPromotion) resetPromotionCounter();

  return (
    <Frame>
      <Wrapper index={index}>
        {renderBubble(intro, -1)}
        {options(responses)}
        {conclusion && renderBubble(conclusion, responses?.length + 1)}
      </Wrapper>
      {showPromotion && promotionItem(promotion)}
    </Frame>
  );
};

export default AssistantDisplay;
