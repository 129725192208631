import React from "react";
import { Box, SvgIcon } from "@mui/material";

const MarketingIconSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="var(--circle-color)" />
      <path
        d="M9.37237 12.661L7.80458 10.3017C7.62307 10.0286 7.69664 9.65955 7.96891 9.47745C8.24118 9.29535 8.60904 9.36916 8.79055 9.64231L10.3769 12.0294C11.8943 11.1809 13.6083 10.6733 15.4075 10.5815V7.59441C15.4075 7.26612 15.6728 7 16 7C16.3272 7 16.5925 7.26612 16.5925 7.59441V10.5815C18.3917 10.6733 20.1057 11.1809 21.6231 12.0294L23.2094 9.64231C23.391 9.36916 23.7588 9.29535 24.0311 9.47745C24.3034 9.65955 24.3769 10.0286 24.1954 10.3017L22.6276 12.661C23.9426 13.5853 25.0679 14.7864 25.9157 16.2048C26.0281 16.393 26.0281 16.628 25.9157 16.8162C23.842 20.2857 20.1077 22.4545 16 22.4545C11.8923 22.4545 8.15803 20.2857 6.08435 16.8162C5.97188 16.628 5.97188 16.393 6.08435 16.2048C6.93212 14.7864 8.05745 13.5853 9.37237 12.661ZM11.31 20.1432C10.5357 19.1387 10.0751 17.8786 10.0751 16.5105C10.0751 15.1424 10.5357 13.8823 11.31 12.8778C9.69073 13.7018 8.29857 14.948 7.29052 16.5105C8.29857 18.073 9.69073 19.3192 11.31 20.1432ZM20.69 12.8778C21.4643 13.8823 21.9249 15.1424 21.9249 16.5105C21.9249 17.8786 21.4643 19.1387 20.69 20.1432C22.3093 19.3192 23.7014 18.073 24.7095 16.5105C23.7014 14.948 22.3093 13.7018 20.69 12.8778ZM16 21.2657C18.6178 21.2657 20.74 19.1367 20.74 16.5105C20.74 13.8842 18.6178 11.7552 16 11.7552C13.3822 11.7552 11.26 13.8842 11.26 16.5105C11.26 19.1367 13.3822 21.2657 16 21.2657ZM16 18.8881C14.6911 18.8881 13.63 17.8236 13.63 16.5105C13.63 15.1974 14.6911 14.1329 16 14.1329C17.3089 14.1329 18.37 15.1974 18.37 16.5105C18.37 17.8236 17.3089 18.8881 16 18.8881ZM16 17.6993C16.6545 17.6993 17.185 17.1671 17.185 16.5105C17.185 15.8539 16.6545 15.3217 16 15.3217C15.3455 15.3217 14.815 15.8539 14.815 16.5105C14.815 17.1671 15.3455 17.6993 16 17.6993Z"
        fill="var(--path-color)"
      />
    </svg>
  );
};

const MarketingIcon = (props) => {
  return (
    <Box
      sx={{
        "--circle-color": props.colors.circleColor || "#0C3520",
        "--path-color": props.colors.pathColor || "#C6F54A",
        "&:hover": {
          "--circle-color": props.colors.hoverCircleColor || "#222",
          "--path-color": props.colors.hoverPathColor || "#FFF",
        },
      }}
      display={"flex"}
      alignSelf={"center"}
      onClick={props.onClick}
    >
      <SvgIcon
        component={MarketingIconSvg}
        viewBox="0 0 32 32"
        sx={{ width: 32, height: 32 }}
      />
    </Box>
  );
};

export default MarketingIcon;
