import React from 'react';
import { changeProperty, splitSharedInvestments } from '../../promptCalculations';
import PromptChoiceExtended from '../promptTypes/PromptChoiceExtended';

const page1 = {
    title: "You and your partner decide to get a divorce",
    description: "I am dealing with emotional changes in my life, but I will get myself together and make the best choice for me and my kid",
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/broken-heart.jpeg",
    },
};
  
const page2 = {
    title: "You and your partner decide to get a divorce",
    description: "What would you do? Choose one option",
    choices: [
        {
            choice: "Take it to court and try to claim 100% of my investment & property that my dad passed",
            outcome: 0,
        },
        {
            choice: "Take it to court and try to claim 70% of my investment & property that my dad passed",
            outcome: 0,
        },
        {
            choice: "Split evenly with my investment and our investment with my husband",
            outcome: 0,
        },
    ],
};

const change1 = () => {
    splitSharedInvestments();
}

const changes = [
    change1,
];
  
const tipScreen = {
    content: [
        { type: "title", text: "Navigating Divorce: How to Protect Yourself" },
        {
            type: "paragraph",
            text: "A good lawyer can help you navigate the legal process effectively and protect your interests. A prenuptial agreement (prenup) can also provide clarity and protection regarding asset division in the event of divorce."
        },
        {
            type: "paragraph",
            text: "Don't forget the importance of teamwork in managing finances during your marriage. It's like being financial superheroes together, ensuring stability and security for both of you."
        }
    ],
};
  
const tips = [
    tipScreen,
];

const colour =  {
    primary: "#FFDCDC",
    secondary: "#E04B77",
};

const Prompt14 = (props) => {
    const { setPrompt } = props;

    const outcomes = [
        {
            type: "consequence",
            title: "You tried!",
            caption: "Court decided to split everything in half",
            change: 0,
        },
    ];

    const prompt14 = {
        type: "choice-extended",
        page1: page1,
        page2: page2,
        outcomes: outcomes,
        changes: changes,
        tips: tips,
        colour: colour,
    };
    
    return (
        <PromptChoiceExtended {...prompt14} setPrompt={setPrompt} />
    );
}

export default Prompt14;
