const title = {
    type: "title",
    text: "Imagine locking your money in a safe for a few years. You can't touch it, but when you finally open it, there's more cash because of the higher interest.",
};

const description = {
    type: "body",
    text: "Fixed-rate bonds are ideal for long-term savings goals. You lock your money away for a set period, usually ranging from 1 to 5 years, earning a higher interest rate.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Ideal for long-term savings goals like a home deposit or a major life event where you won't need immediate access to your money.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "body",
            text: "NS&I, Yorkshire Building Society, Atom Bank.",
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Planning to buy a house in five years? Lock your savings in a fixed-rate bond to earn higher interest and make your dream home more affordable.",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Example",
    body: [
        {
            type: "section",
            subheading: "NS&I Guaranteed Growth Bonds",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Interest Rate: Up to 2.00% AER (Annual Equivalent Rate)",
                        },
                    ],
                },
            ],
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Locked for a fixed term, typically 1-5 years, with penalties for early withdrawal.",
        },
    ],
};

const fixedRateBonds = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
    examples,
    accessibility,
];

export default fixedRateBonds;
