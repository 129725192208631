const base = (size) => ({
  display: "inline-flex",
  flexShrink: 0,
  padding: "15px 24px",
  height: size === "small" ? "36px" : "44px",
  minWidth: "120px",
  gap: "8px",
  textAlign: "center",
  fontSize: "15px",
  lineHeight: "12.512px",
  textTransform: "none",
  textDecoration: "none",
});

const conditional = (theme, size) => ({
  primary: {
    default: {
      backgroundColor: theme.vars.palette.investment.primary,
      color: "#000",
      borderRadius: "44px",
      gap: "4px",
    },
    hover: {
      border: "2px solid #000",
      boxShadow: "0px 4px 0px 0px #757575",
      gap: "8px",
      fontWeight: 600,
    },
    pressed: {
      border: "2px solid #000",
      boxShadow: "0px 4px 0px 0px #757575",
      gap: "8px",
      fontWeight: 600,
      lineHeight: "normal",
    },
    inactive: {
      backgroundColor: "#EAE9E9",
      borderRadius: "36px",
      color: "#B7B4B1",
      padding: size === "small" ? "10px 24px" : "15px 32px",
      fontWeight: size === "small" ? 500 : 400,
    },
    inProgress: {
      backgroundColor: "#FFF",
      borderRadius: "36px",
      padding: "15px 32px",
    },
  },
  secondary: {
    default: {
      border: "1px solid #000",
      ...(size === "small" && {
        borderColor: theme.vars.palette.investment.primary,
        fontSize: "14px",
      }),
      borderRadius: "44px",
      color: "#010103",
      fontWeight: 400,
    },
    hover: {
      border: "2px solid #000",
      boxShadow: "0px 2px 0px 0px #000",
      fontWeight: 600,
      lineHeight: "normal",
    },
    pressed: {
      backgroundColor: "rgba(0, 0, 0, 0.70)",
      boxShadow: "0px 2px 0px 0px #000",
      color: "#FFF",
      fontWeight: 600,
      lineHeight: "normal",
    },
    inactive: {
      border: "1px solid #EAE9E9",
      backgroundColor: "#FFF",
      color: "#BDBDBD",
      padding: "11px 24px",
      fontWeight: size === "small" ? 500 : 400,
    },
  },
  tertiary: {
    default: {
      minWidth: "auto",
      borderRadius: "4px",
      backgroundColor: "#FFF",
      gap: "8px",
      fontSize: "14px",
    },
    hover: { fontWeight: 600 },
    focused: {
      fontWeight: 600,
      lineHeight: "normal",
    },
    inactive: {
      padding: "11px 24px",
      fontWeight: 600,
      lineHeight: "normal",
    },
  },
  icon: {
    default: {
      display: "flex",
      width: "36px",
      height: "36px",
      padding: "15px 24px",
      borderRadius: "44px",
      border: "1px solid #000",
    },
    hover: {
      border: "2px solid #000",
      boxShadow: "0px 2px 0px 0px #000",
    },
  },
});

export { base, conditional };
