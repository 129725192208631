import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { HeaderCaption } from "../components";

const Container = styled(Box)(() => ({
    alignItems: "flex-start",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "0 24px",
}));

const PageHeader = (props) => {
    const { variant = "h1", title, caption } = props;
    
    return (
        <Container>
            <Typography variant={variant}>{title}</Typography>
            {caption &&
                <HeaderCaption>{caption}</HeaderCaption>}
            {props.children}
        </Container>
    );
};

export default PageHeader;
