import React from 'react';

function PrivacyPolicy() {
    return (
        <div style={{ margin: '20px' }}>
            <h1>Privacy Policy for WInvest</h1>
            <p>Last updated: Feb 2024</p>
            <p>At WInvest, accessible from https://winvestcollective.com/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by WInvest and how we use it.</p>

            <h2>Consent</h2>
            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

            <h2>Information we collect</h2>
            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
            <p>If you agree to use our website, we may ask for your personal information, including items such as your name and email address.</p>

            <h2>How we use your information</h2>
            <p>We use the information we collect in various ways, including to:</p>
            <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
            </ul>

            <h2>Log Files</h2>
            <p>WInvest follows a standard procedure of using log files.</p>
        </div>
    )
}

export default PrivacyPolicy