const title = {
    type: "title",
    text: "Covers medical expenses - like a personal healthcare safety net.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Essential for covering unexpected medical costs and ensuring access to quality healthcare.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Bupa Health Insurance",
            subheading: "Covers hospital stays, outpatient care, and specialist consultations.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £30 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available directly from the Bupa website or through Bupa customer service.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Aviva Health Insurance",
            subheading: "Includes inpatient and outpatient treatment, mental health support, and dental cover.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £35 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available on the Aviva website or through Aviva's network of brokers.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you want peace of mind regarding medical expenses, health insurance is your go-to safety net.",
        },
    ],
};

const healthInsurance = [
    title,
    benefits,
    providersExamples,
    greatFor,
];

export default healthInsurance;
