import React, { useState } from 'react';
import PromptPage from '../components/PromptPage';

const Page1 = (props) => {
    const { page, tips, colour, setPrompt, onNext } = props;
    
    return (
        <PromptPage
            pageType={'description'}
            {...page}
            tips={tips}
            colour={colour.secondary}
            setPrompt={setPrompt}
            onNext={onNext}
        />
    );
}

const Page2 = (props) => {
    const { page, outcomes, changes, tips, colour, setPrompt } = props;

    return (
        <PromptPage
            pageType={'choice'}
            {...page}
            outcomes={outcomes}
            changes={changes}
            tips={tips}
            colour={colour.secondary}
            setPrompt={setPrompt}
        />
    );
}

const PromptChoiceExtended = (props) => {
    const { page1, page2, outcomes, changes, tips, colour, setPrompt } = props;
    const [page, setPage] = useState(1);

    const onNext = () => {
        setPage((prev) => prev + 1);
    }

    const pages = [
        <Page1
            page={page1}
            tips={tips}
            colour={colour}
            setPrompt={setPrompt}
            onNext={onNext}
        />,
        <Page2
            page={page2}
            outcomes={outcomes}
            changes={changes}
            tips={tips}
            colour={colour}
            setPrompt={setPrompt}
        />,
    ];

    return pages[page - 1];
}

export default PromptChoiceExtended;
