import { Box, styled } from "@mui/material";

const Frame = styled(Box)(({ theme, issticky }) => ({
  display: "flex",
  borderBottom: "1px solid #E0E0E0",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  position: issticky ? "sticky" : "relative",
  top: issticky ? 0 : "auto",
  zIndex: issticky ? 1000 : "auto",
  [theme.breakpoints.down("md")]: {
    backgroundColor: theme.vars.palette.common.white,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    flexDirection: "column",
    gap: 16,
    padding: 16,
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    maxHeight: 72,
    padding: "20px 24px 20px 30px",
  },
}));

export default Frame;
