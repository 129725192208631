const page1 = {
  title: "Your cousin asks you to borrow £300",
  caption:
    "Your cousin mentioned that he lost his job due to the market conditions and, since his family is unable to help him, he asked if you could lend him £300 so he can secure a place to stay.",
  description:
    "Dealing with money-related conversations with friends and families can be very difficult, but I will explore my options and be better at it. ",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/money-2.jpeg",
  },
};

const page2 = {
  title: "Your cousin asks you to borrow £300",
  description:
    "What would you do?  Consider the situation carefully before making any decisions",
  choices: [
    {
      choice: "Borrow him the money and leave it",
      outcome: {
        title: "You are a really kind person!",
        caption:
          "He won't be able to give you back for a while so we deducted that money from your saving.",
      },
    },
    {
      choice: "Borrow him the money and ask him to write a handwritten note",
      outcome: {
        title: "You did it!",
        caption:
          "He wasn't super happy with it but did it anyway. You kept the note and gave him the money. We deducted the money from your account.",
      },
    },
    {
      choice: "Don't borrow him the money but offer him your place to stay",
      outcome: {
        title: "You did it!",
        caption: "He understood and stayed at your flat for 2 weeks.",
      },
    },
  ],
  tip: 2,
};

const tipScreen = {
  content: [
    {
      type: "title",
      text: "Saying “no” to friends and families is hard. Handling money can be very emotional. ",
    },
    {
      type: "paragraph",
      text: "When friends/family asked for money, do the following: Assess the seriousness of the situation: Is it for investment purposes or other essential needs such as health-related issues?",
    },
    {
      type: "paragraph",
      text: "Consider whether you're comfortable with the possibility of losing that money entirely. Evaluate how involving law enforcement to recover the money might impact your relationship with that person. (Remember, you'll need evidence to involve law enforcement.)",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-2.jpeg",
  },
};

const tipPage1 = {
  content: [
    {
      type: "title",
      text: "Saying 'No' to friends and family",
    },
    {
      type: "paragraph",
      text: "As we are the new generation of women who can change the future of female finance, let's always make sure that you are the person who's in control of getting your financial independence.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/repeated-no.jpeg",
  },
};

const tipPage2 = {
  content: [
    {
      type: "title",
      text: "Saying 'No' to friends and family",
    },
    {
      type: "paragraph",
      text: "Make sure you:",
    },
    {
      type: "list",
      listType: "ordered",
      listItems: [
        {
          text: "Study the situation thoroughly before making any emotional decision.",
        },
        {
          text: "Consider whether you're comfortable with the possibility of losing that money entirely.",
        },
        {
          text: "Evaluate how involving law enforcement to recover the money might impact your relationship with that person. (Remember, you'll need evidence to involve law enforcement).",
        },
      ],
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/repeated-no.jpeg",
  },
}

const toolTip = {
  type: 'tool-tip',
  content: {
    page1: tipPage1,
    page2: tipPage2,
    page3: {content:[]}
  },
  colour: {
    background: "#00607E"
  }
};

const tips = [
  tipScreen,
  toolTip,
];

const prompt8 = {
  type: "choice-extended",
  page1: page1,
  page2: page2,
  tips: tips,
  colour: {
    primary: "#CEF2E7",
    secondary: "#00607E",
  },
};

export default prompt8;
