const savingsAccounts = {
    title: "Savings Accounts",
    image: "/learn/financialProducts/savings_account.png",
    color: {
        backgroundColor: "#FFE0E0",
        color: "#E04B77",
    },
    icon: "savingsAccounts",
    products: [
        "easyAccessSavingsAccount",
        "regularSavingsAccount",
        "fixedRateBonds",
    ],
};

const ISAs = {
    title: "Tax-Advantaged Accounts (ISAs)",
    image: "/learn/financialProducts/isa.png",
    color: {
        backgroundColor: "#D1F9FF",
        color: "#17AAD9",
        textColor: "#00607E",
    },
    icon: "ISAs",
    products: [
        "cashISA",
        "stocksAndSharesISA",
        "lifetimeISA",
        "juniorISA",
    ],
};

const pensionProducts = {
    title: "Pension Products",
    image: "/learn/financialProducts/pension.png",
    color: {
        backgroundColor: "#FCE7DB",
        color: "#A0512F",
    },
    icon: "pensionProducts",
    products: [
        "personalPension",
        "workplacePension",
        "sipp",
        "stakeholderPension",
        "statePension",
    ],
};

const investmentProducts = {
    title: "Investment Products",
    image: "/learn/financialProducts/investment.png",
    color: {
        backgroundColor: "#DAF2FF",
        color: "#2C3DE8",
    },
    icon: "investmentProducts",
    products: [
        "blueChipStocks",
        "growthStocks",
        "indexFunds",
        "reit",
        "bonds",
        "mutualFunds",
    ],
};

const financialPlatforms = {
    title: "Financial Platforms",
    image: "/learn/financialProducts/platform.png",
    color: {
        backgroundColor: "#EBD2FF",
        color: "#5F32B6",
    },
    icon: "financialPlatforms",
    products: [
        "diyInvestmentPlatform",
        "humanFinancialAdvisor",
        "roboAdvisor",
    ],
};

const insuranceProducts = {
    title: "Insurance Products",
    image: "/learn/financialProducts/insurance.png",
    color: {
        backgroundColor: "#EAF3E4",
        color: "#2A935A",
    },
    icon: "insuranceProducts",
    products: [
        "healthInsurance",
        "lifeInsurance",
        "carInsurance",
        "homeInsurance",
        "travelInsurance",
        "incomeProtectionInsurance",
        "criticalIllnessInsurance",
    ],
};

const investmentAccounts = {
    title: "Investment Accounts",
    image: "/learn/financialProducts/investment_account.png",
    color: {
        backgroundColor: "#FFD8F9",
        color: "#D23BDF",
        textColor: "#AE43E0",
    },
    icon: "investmentAccounts",
    products: [
        "generalInvestmentAccount",
        "stocksAndSharesAccount",
    ],
};

// Contains all financial product categories
const productCategories = {
    savingsAccounts,
    ISAs,
    pensionProducts,
    investmentProducts,
    financialPlatforms,
    insuranceProducts,
    investmentAccounts,
};

export default productCategories;
