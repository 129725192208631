import React from "react";
import { Box, styled } from "@mui/material";

const ImageFrame = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  height: "126px",
  justifyContent: "center",
  overflow: "hidden",
  width: "auto",
}));

const Content = styled("img")(() => ({
  maxHeight: "100%",
}));

const Image = ({ src }) => {
  return (
    <ImageFrame>
      <Content src={src} alt="" />
    </ImageFrame>
  );
};

export default Image;
