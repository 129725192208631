const title = {
    type: "title",
    text: "Picture this as your favourite boutique where you handpick your investments.",
};

const description = {
    type: "body",
    text: "Want to choose S&P500 while picking other stocks and shares? DIY investment platform is your go-to.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Ideal for savvy investors who want to make their own decisions. These platforms offer a wide range of investment options with low fees, empowering you to build your own portfolio.",
        },
    ],
};

const growth = {
    type: "section",
    heading: "Growth",
    body: [
        {
            type: "body",
            text: "Varies widely based on your investment choices, typically 4% to 12% per year.",
        },
    ],
};

const risk = {
    type: "section",
    heading: "Risk",
    body: [
        {
            type: "body",
            text: "High, as the performance depends on your investment decisions.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "Vanguard",
                    subheading: "Average annual growth of 8% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "AJ Bell Youinvest",
                    subheading: "Average annual growth of 7.5% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Interactive Investor",
                    subheading: "Average annual growth of 7% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you're a DIY diva who loves having control and making your own investment choices, these platforms are your stage.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Available online via the providers' websites or mobile apps. Easy to set up, with a range of investment options and tools to help you shine bright.",
        },
    ],
};

const diyInvestmentPlatform = [
    title,
    description,
    benefits,
    growth,
    risk,
    providersExamples,
    greatFor,
    accessibility,
];

export default diyInvestmentPlatform;
