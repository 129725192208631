import financialProducts from "../financialProducts/financialProducts";

const page1 = {
    content: [
        {
            type: 'title',
            text: 'Why Invest?'
        },
        {
            type: 'list',
            listType: 'ordered',
            listItems: [
                {
                    text: 'Cash depreciates over time, good assets can combat inflation.'
                },
                {
                    text: 'The best time to start investing was yesterday, the second best time is today.'
                },
            ],
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/money-plant.jpeg",
    },
};

const page2 = {
    content: [
        {
            type: 'title',
            text: 'What\'s Index fund EFT?'
        },
        {
            type: 'paragraph',
            text: 'An index fund is an investment that tracks a market index, typically made up of stocks or bonds.'
        },
        {
            type: 'paragraph',
            text: 'The most popular index fund S&P500, is made up of the top 500 biggest companies listed in the US, for example Apple, Microsoft Corp, Amazon etc.'
        },
        {
            type: 'paragraph',
            text: 'There\'s also FTSE 100, is the top 100 listed companies in London Stock Exchange, for example BT, Aviva, Barclays.'
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/companies.png",
    },
};

const page3 = {
    content: [
        {
            type: 'title',
            text: 'Think of an ETF as a makeup palatte'
        },
        {
            type: 'paragraph',
            text: 'Think of an ETF as a makeup palette that offers a combination of blush shades in one convenient package. Just like how you can buy a single palette with multiple blush options, an ETF is like a bundled investment that combines different stocks into one investment product.'
        },
        {
            type: 'paragraph',
            text: 'With an ETF, you can trade it on the stock market just like buying and selling individual stocks.'
        },
        {
            type: 'paragraph',
            text: 'It\'s like having the flexibility to choose and switch between different blush shades while enjoying the convenience of having them all in one palette.'
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/makeup.jpeg",
    },
};

// page4

const indexFund = {
    ...financialProducts['IndexFund'],
    content: {
        page1: page1,
        page2: page2,
        page3: page3
    },
};

export default indexFund;
