import { Card, CardContent, styled, Typography } from "@mui/material";
import Header from "./Header";
import { useEffect, useRef, useState } from "react";

const Container = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "transparent",
  width: "100%",
  minHeight: "100%",
  borderRadius: 16,
  gap: "24px",
  boxShadow: "none",
}));

const ContentWrapper = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: "1 0 0",
  padding: "0px 32px",
  gap: "12px",
  flexShrink: 0,
  alignSelf: "stretch",
}));

const Title = () => {
  return (
    <Typography variant="h4" color={"#031711"} align="center">
      I'm your Money Bestie.
      <br />
      Ask me anything.
    </Typography>
  );
};

const BodyText = ({ children }) => {
  const defaultText = (
    <>
      I am trained with the knowledge of Warren Buffet, combined with insights
      from hundreds of other financial education coaches. <br />
      Our conversations are private.
    </>
  );

  return (
    <Typography variant="body" color={"#031711"} align="center">
      {children ? children : defaultText}
    </Typography>
  );
};

const IntroCard = ({ message }) => {
  const [paddingTop, setPaddingTop] = useState("80px");
  const containerRef = useRef(null);

  useEffect(() => {
    const updatePadding = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        const minPadding = Math.min(80, 0.0947 * window.innerHeight);
        const dynamicPadding = Math.min(minPadding, 0.1 * containerHeight);
        setPaddingTop(`${dynamicPadding}px`);
      }
    };

    window.addEventListener("resize", updatePadding);
    updatePadding();

    return () => {
      window.removeEventListener("resize", updatePadding);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <Header
        avatar={"assets/images/money_bestie_avatar.png"}
        paddingTop={paddingTop}
      />
      <ContentWrapper>
        <Title />
        <BodyText>{message}</BodyText>
      </ContentWrapper>
    </Container>
  );
};

export default IntroCard;
