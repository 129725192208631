import React, { useState } from "react";
import { Tooltip as MuiTooltip, styled, tooltipClasses } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { getQuestionMarkIconStyles, getTooltipContentStyles } from "./styles";

const StyledTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: getTooltipContentStyles(),
}));

const Tooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <StyledTooltip
      title={props.tooltipcontent}
      placement={"right"}
      onClick={handleClick}
      {...props}
    >
      <QuestionMarkIcon
        edge="end"
        sx={getQuestionMarkIconStyles()}
        onClick={handleClick}
      />
    </StyledTooltip>
  );
};

export default Tooltip;
