import React from "react";
import { Box, styled } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-start",
  // alignSelf: "stretch",
  flex: "1 0 0",
  // flexGrow: 1,
  width: "100%",
  overflow: "hidden",
  maxHeight: "100%",
  [theme.breakpoints.down("md")]: {
    // height: "396px",
    height: "auto",
  },
}));

const Content = styled("img")(() => ({
  // flex: "1 0 0",
  // alignSelf: "stretch",
  maxWidth: "100%",
  maxHeight: "100%",
  width: "auto",
  height: "auto",
  // alignSelf: "flex-end",
  objectFit: "contain",
  // marginLeft: 0,
  // marginBottom: 0,
}));

const Image = () => {
  return (
    <Container>
      <Content
        src="assets/images/woman-2-10.png"
        alt="Woman in control of her finances"
      />
    </Container>
  );
};

export default Image;
