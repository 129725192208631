const title = {
    type: "title",
    text: "Think of this as your money getting a makeover with stocks and shares - you can grow your wealth without the taxman taking a cut.",
};

const description = {
    type: "body",
    text: "These ISAs allow you to invest in the stock market with tax-free returns on your investments.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "They are perfect for long-term growth, especially for retirement savings or building a substantial investment portfolio.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Hargreaves Lansdown Stocks and Shares ISA",
            subheading: "Average annual return of 7.5% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available online through the Hargreaves Lansdown website and mobile app.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Vanguard Stocks and Shares ISA",
            subheading: "Average annual return of 8% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available directly through the Vanguard website and mobile app.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "AJ Bell Stocks and Shares ISA",
            subheading: "Average annual return of 7% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available online through the AJ Bell website and mobile app.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Want to grow your wealth significantly for retirement? A Stocks and Shares ISA lets your investments flourish tax-free.",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Example",
    body: [
        {
            type: "section",
            subheading: "Vanguard Stocks and Shares ISA",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Interest Rate: Varies based on investment performance, typically 5-10% per year",
                        },
                    ],
                },
            ],
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Can be accessed anytime, but best for long-term holding due to market fluctuations.",
        },
    ],
};

const stocksAndSharesISA = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
    examples,
    accessibility,
];

export default stocksAndSharesISA;
