import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import TextField from "@ui-library/TextField";
import ProgressButton from "../components/steps/ProgressButton";
import Navbar from "./Navbar";

const OthersField = ({ id, onAddOption }) => {
  const [value, setValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && value.trim()) {
      onAddOption(value.trim());
      setValue("");
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      alignSelf={"stretch"}
      gap={"16px"}
      width={"300px"}
      paddingX={"8px"}
      paddingY={"16px"}
    >
      <Typography fontSize={"14px"} fontWeight={500} lineHeight={"21px"}>
        Others
      </Typography>
      <TextField
        id={id}
        label={"Please specify"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </Box>
  );
};

const SubmitButton = (props) => {
  return (
    <Box
      display={"flex"}
      width={"100%"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"flex-end"}
      paddingX={"7.77vw"}
      paddingTop={"4.16vh"}
      paddingBottom={"10vh"}
      marginBottom={"4.79vh"}
    >
      <ProgressButton {...props} />
    </Box>
  );
};

const FormContentBox = (props) => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const handleScroll = () => {
      // console.log("Handle scroll runs");
      if (!navbarRef.current || !contentRef.current) return;

      const navbarRect = navbarRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();

      if (navbarRect.top <= 0) {
        setIsSticky(true);
      } else if (contentRect.top > navbarRect.height) {
        setIsSticky(false);
      }
    };

    if (isMobile) {
      window.addEventListener("scroll", handleScroll);
    } else {
      setIsSticky(false);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile]);

  return (
    <Box display={"flex"} flexDirection={"column"} height={"100vh"}>
      <Navbar
        ref={navbarRef}
        text={props.text}
        issticky={isSticky.toString()}
      />
      <Box
        ref={contentRef}
        flex={{ xs: "1 0 auto", md: "1 1 auto" }}
        overflow={{ xs: isMobile && isSticky ? "auto" : "hidden", md: "auto" }}
        paddingX={{ xs: "24px", md: "auto" }}
        width={"100%"}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default FormContentBox;
export { OthersField, SubmitButton };
