const title = {
    type: "title",
    text: "Pool your money with others and let a professional manage it - like joining a VIP club where the experts do the hard work.",
};

const description = {
    type: "body",
    text: "Mutual funds and unit trusts offer professional management and diversification. There's no minimum investment, making them accessible for all investors.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Great for hands-off investors wanting diversification managed by professionals. (Usually management fees are higher than ETFs).",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "Fidelity Contrafund",
                    subheading: "Average annual return of 11% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "BlackRock Global Allocation Fund",
                    subheading: "Average annual return of 8% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Schroders Global Equity Fund",
                    subheading: "Average annual return of 9% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Want professional management of your investments? Mutual funds and unit trusts let you benefit from expert strategies.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Easily bought and sold through fund platforms.",
        },
    ],
};

const mutualFunds = [
    title,
    description,
    benefits,
    examples,
    greatFor,
    accessibility,
];

export default mutualFunds;
