const title = {
    type: "title",
    text: "This is your ultimate remix album - a type of personal pension that offers a wider range of investment options and greater control over how your money is invested.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Perfect for savvy investors who want more control and a broader range of investment choices.",
        },
    ],
};

const growth = {
    type: "section",
    heading: "Growth",
    body: [
        {
            type: "body",
            text: "Typically 6% to 10% per year. Data from various SIPP providers indicates that well-managed SIPPs can achieve returns in this range.",
        },
    ],
};

const risk = {
    type: "section",
    heading: "Risk",
    body: [
        {
            type: "body",
            text: "Moderate to High (depending on investment choices)",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "Hargreaves Lansdown SIPP",
                    subheading: "Average annual growth of 7.5% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "AJ Bell Youinvest SIPP",
                    subheading: "Average annual growth of 8% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Fidelity SIPP",
                    subheading: "Average annual growth of 7% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you're knowledgeable about investments and want more control over your pension fund, a SIPP offers flexibility and a wider range of investment options.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Available directly from the providers' websites or through financial advisors.",
        },
    ],
};

const sipp = [
    title,
    benefits,
    growth,
    risk,
    providersExamples,
    greatFor,
    accessibility,
];

export default sipp;
