const title = {
    type: "title",
    text: "This is like setting up a trust fund for your kids, with tax-free savings.",
};

const description = {
    type: "body",
    text: "Junior ISAs are a tax-efficient way to save for your child's future. You can save up to £9,000 per year, with interest or investment growth free from tax.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Perfect for building a nest egg for your child's future, whether for education or starting their adult life.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Nationwide Junior ISA",
            subheading: "Average annual return of 3% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available through the Nationwide website and branches.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Hargreaves Lansdown Junior ISA",
            subheading: "Average annual return of 7% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available online through the Hargreaves Lansdown website and mobile app.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Fidelity Junior ISA",
            subheading: "Average annual return of 6.5% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available through the Fidelity website and mobile app.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Want to give your child a financial head start? A Junior ISA grows their savings tax-free until they're 18.",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Example",
    body: [
        {
            type: "section",
            subheading: "Fidelity Junior ISA",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Interest Rate: Varies based on investment choices, typically 5-10% per year",
                        },
                    ],
                },
            ],
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Funds are locked until the child turns 18.",
        },
    ],
};

const juniorISA = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
    examples,
    accessibility,
];

export default juniorISA;
