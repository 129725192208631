import React from "react";
import { Box, styled } from "@mui/material";
import { PageContainer, PageHeader } from "@features/learn";
import { useLearnContext } from "@hooks";

const PageContent = styled(Box)(() => ({
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: "8px",
    // padding: "0 8px 72px 8px", // Changed when bottom navigation added
    padding: "0 8px 8px 8px",
}));

const FinancialTipsPage = () => {
    const { getFinancialTips } = useLearnContext();
    const header = {
        title: "Bestie's Top Tips",
        caption: "Your Go-To Guide for Smart, Savy Financial Moves.",
    };
    const financialTips = getFinancialTips();

    return (
        <PageContainer sx={{ gap: "32px" }}>
            <PageHeader variant="h2" {...header} />
            <PageContent>
                {financialTips}
            </PageContent>
        </PageContainer>
    );
};

export default FinancialTipsPage;
