import React, { useState } from "react";
import AudioButton from "./AudioButton";
import Field from "./Field";
import Container from "./Container";
import SendButton from "./SendButton";
import {
  Box,
  InputAdornment,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import Button from "@ui-library/Button";

const OverlayBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  backgroundColor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 8,
});

const CustomModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="coming-soon-modal-title"
      aria-describedby="coming-soon-modal-description"
    >
      <OverlayBox>
        <Typography id="coming-soon-modal-title" variant="h6" component="h2">
          Feature Coming Soon
        </Typography>
        <Typography
          id="coming-soon-modal-description"
          textAlign={"center"}
          sx={{ mt: 2 }}
        >
          This feature is currently in development. Stay tuned!
        </Typography>
        <Button onClick={onClose} sx={{ mt: 3 }}>
          Close
        </Button>
      </OverlayBox>
    </Modal>
  );
};

const TextInput = ({ control, onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAudioButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const render = ({ field }) => (
    <Field
      {...field}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          field.onChange(""); // Clear input after submission
        }
      }}
      endAdornment={
        <InputAdornment
          position="end"
          sx={{ minHeight: "auto", height: "auto" }}
        >
          <SendButton type="submit" />
        </InputAdornment>
      }
    />
  );

  return (
    <Container>
      <Controller
        name="message"
        control={control}
        defaultValue={""}
        render={render}
      />

      <AudioButton onClick={handleAudioButtonClick} />
      <CustomModal open={isModalOpen} onClose={handleCloseModal} />
    </Container>
  );
};

export default TextInput;
