import { useContext } from "react";
import AuthContext from "@context/AuthContext";
import LearnContext from "@context/LearnContext";
import QuestionnaireContext from "@context/QuestionnaireContext";
import NavigationContext from "@context/NavigationContext";

const useAuthContext = () => useContext(AuthContext);
const useLearnContext = () => useContext(LearnContext);
const useQuestionnaireContext = () => useContext(QuestionnaireContext);
const useNavigation = () => useContext(NavigationContext);

export {
    useAuthContext,
    useLearnContext,
    useNavigation,
    useQuestionnaireContext,
};
