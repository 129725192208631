const page1 = [
    {
        type: "title",
        text: "What are the best investment options for beginners?",
    },
    {
        type: "body",
        text: "Start with low-cost index funds or ETFs for broad market exposure and lower risk. Vanguard Total Stock Market ETF (VTI) and Vanguard S&P 500 ETF (VOO) are excellent choices.",
    },
];

const page2 = [
    {
        type: "title",
        text: "Consider Robo-advisors",
    },
    {
        type: "body",
        text: "Consider robo-advisors like Nutmeg or Moneyfarm, for automated, diversified investing based on your risk tolerance and goals.",
    },
];

const page3 = [
    {
        type: "title",
        text: "Invest in Established Stocks as You Gain Experience",
    },
    {
        type: "body",
        text: "As you gain experience, start researching and investing in individual stocks of well-established companies. You're off to a great start, superstar - keep shining!",
    },
];

const content = [page1, page2, page3];

const investmentOptions = {
    topic: "What are the best investment options for beginners?",
    image: "/learn/financialTips/investment_options.jpeg",
    color: {
        cardColor: "#C5D3D4",
        contentColor: "#00607E",
    },
    content: content,
};

export default investmentOptions;
