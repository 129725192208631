import React from "react";
import Section from "../Section";
import { Question } from "@features/questionnaire/components/textStyles";
import UISelection, { SelectBar } from "src/components/ui/UISelection";

const CheckboxQuestion = ({ id, question, options, ...props }) => {
  const fields = options.map((option, i) => {
    return <SelectBar key={i} label={option} id={option} group={id} />;
  });

  return (
    <Section gap={"32px"}>
      <Question>{question}</Question>
      <UISelection width={{ md: "30.7vw" }}>{fields}</UISelection>
    </Section>
  );
};

export { CheckboxQuestion };
