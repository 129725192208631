import React, { useState } from 'react';
import ClosePopup from '../../components/ClosePopup';

// Colours used for risk level indicators
const riskColours = {
    'low': {background: '#D4F0CD', text: '#065D44'},
    'low to moderate': {background: '#FFE0E0', text: '#E1349C'},
    'moderate': {background: '#FFF2DF', text: '#FF8E26'},
};

// Content for risk levels popup
const riskLevelsContent = [
    {
        title: 'Low Risk',
        icon: 'low',
        content: [
            {
                keyword: 'Stable',
                text: 'These investments don\'t change much in value.'
            },
            {
                keyword: 'Predictable Returns',
                text: 'You can expect steady, but usually smaller, earnings.'
            },
            {
                keyword: 'Safe Principal',
                text: 'You are likely to keep the money you originally invested.'
            }
        ],
        colour: '#D4F0CD',
    },
    {
        title: 'Moderate Risk',
        icon: 'moderate',
        content: [
            {
                keyword: 'Moderate Changes',
                text: 'These investments can go up and down, but not wildly.'
            },
            {
                keyword: 'Better Returns',
                text: 'You might earn more than with low-risk investments, but there\'s some risk.'
            },
            {
                keyword: 'Diversified',
                text: 'They often include a mix of different types of investments to spread risk.'
            }
        ],
        colour: '#FFF7C6',
    },
    {
        title: 'High Risk',
        icon: 'high',
        content: [
            {
                keyword: 'High Volatility',
                text: 'These investments can go up and down a lot.'
            },
            {
                keyword: 'High Returns',
                text: 'You might earn a lot, but you could also lose a lot.'
            },
            {
                keyword: 'Speculative',
                text: 'Based on potential future growth rather than current stability.'
            }
        ],
        colour: '#FFBFC9',
    },
];

// Icon shown next to each section header
const RiskLevelIcon = ({ type }) => {
    return (
        <img
            className='risk-levels-icon'
            src={(() => {
                switch (type) {
                    case 'low':
                        return "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/icons/risk-low.svg";
                    case 'moderate':
                        return "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/icons/risk-moderate.svg";
                    case 'high':
                        return "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/icons/risk-high.svg";
                    default:
                        return <></>;
                }
            })()}
        />
    );
}

// Component for a section in the risk levels popup
const RiskLevelsSection = (props) => {
    const { title, icon, content, colour } = props;
    return (
        <div
            className='risk-levels-section popup-section'
            style={{ backgroundColor: colour }}
        >
            <div className='section-header risk-levels-section-header'>
                <RiskLevelIcon type={icon} />
                <h3>{title}</h3>
            </div>
            <div className='risk-levels-section-content'>
                {content.map((line, index) => (
                    <p key={index}>
                        <span className='text-highlight-dark'>{line.keyword}: </span>
                        {line.text}
                    </p>
                ))}
            </div>
        </div>
    );
}

// Risk levels popup displayed when the question mark icon of a risk level
// indicator is clicked
const RiskLevels = ({ setRiskLevels }) => {
    return (
        <div id='risk-levels' className='risk-levels popup'>
            <div className='risk-levels-content popup-content'>
                <ClosePopup setPopup={setRiskLevels} />
                <h2 className='risk-levels-content-title'>Risk Levels</h2>
                <div className='popup-sections'>
                    {riskLevelsContent.map((section, index) => (
                        <React.Fragment key={index}>
                            <RiskLevelsSection {...section} />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}

const QuestionMarkIcon = ({ colour }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
            <circle cx='8' cy='8' r='8' fill={colour}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M7.182 10.7274C7.182 11.179 7.54854 11.5456 8.00018 11.5456C8.45181 11.5456 8.81836 11.179 8.81836 10.7274C8.81836 10.2757 8.45181 9.9092 8.00018 9.9092C7.54854 9.9092 7.182 10.2757 7.182 10.7274ZM8.54563 8.54556C8.54563 8.27938 8.76709 8.06066 9.12818 7.72738L9.12912 7.72651C9.59859 7.29248 10.182 6.75312 10.182 5.81829C10.182 4.61502 9.20345 3.63647 8.00018 3.63647C6.7969 3.63647 5.81836 4.61502 5.81836 5.81829H6.90927C6.90927 5.21666 7.39854 4.72738 8.00018 4.72738C8.60181 4.72738 9.09109 5.21666 9.09109 5.81829C9.09109 6.25411 8.82381 6.52302 8.388 6.92557L8.38619 6.92723C7.97086 7.31149 7.45472 7.78902 7.45472 8.54556H8.54563Z' fill='white' />
        </svg>
    );
}

// Coloured indicator displayed for PromptDistribution prompts
const RiskLevelIndicator = ({ risk, setRiskLevels }) => {
    const colour = riskColours[risk];

    const showRiskLevels = () => {
        setRiskLevels(<RiskLevels setRiskLevels={setRiskLevels} />);
    }
    
    return (
        <div
            className='risk-level-indicator'
            onClick={showRiskLevels}
            style={{ backgroundColor: colour.background, color: colour.text }}
        >
            <p>{risk.charAt(0).toUpperCase() + risk.slice(1)} Risk</p>
            <QuestionMarkIcon colour={colour.text} />
        </div>
    );
}

export default RiskLevelIndicator;
