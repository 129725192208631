import { useMemo } from "react";
import {
  defaultYears,
  getInvestmentSeries,
  getPensionSeries,
  getRealEstateSeries,
  getSavingsSeries,
} from "@content/financialData";
import useProjectionContext from "./useProjectionContext";

const useSeriesData = (activeTab) => {
  const { state } = useProjectionContext();

  // Memoize the series data to avoid unnecessary recalculations
  const seriesData = useMemo(() => {
    const investmentSeries = getInvestmentSeries(
      defaultYears,
      state.investment,
      state.currentAge,
      state.retirementAge
    );
    const pensionSeries = getPensionSeries(
      defaultYears,
      state.pension,
      state.currentAge,
      state.retirementAge
    );
    const savingsSeries = getSavingsSeries(
      defaultYears,
      state.savings,
      state.currentAge,
      state.retirementAge
    );
    const realEstateSeries = getRealEstateSeries(
      defaultYears,
      state.realEstate,
      state.currentAge,
      state.retirementAge
    );

    const defaultSeriesData = [
      realEstateSeries,
      savingsSeries,
      pensionSeries,
      investmentSeries,
    ];

    switch (activeTab) {
      case 0:
        return [investmentSeries];
      case 1:
        return [savingsSeries];
      case 2:
        return [pensionSeries];
      case 3:
        return [realEstateSeries];
      default:
        return defaultSeriesData;
    }
  }, [activeTab, state]);

  return seriesData;
};

export default useSeriesData;
