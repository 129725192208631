import React from "react";
import { Box, styled, SvgIcon, Typography, useTheme } from "@mui/material";

const Frame = styled(Box)(({ backgroundColor }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2px 2px 2px 8px",
  minWidth: 100,
  gap: 8,
  flexShrink: 0,
  borderRadius: 28,
  backgroundColor: backgroundColor,
}));

const IconWrapper = styled(SvgIcon)(() => ({
  width: 16,
  height: 16,
}));

const Icon = ({ color }) => {
  return (
    <IconWrapper viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.18004 10.7276C7.18004 11.1793 7.54659 11.5458 7.99822 11.5458C8.44986 11.5458 8.81641 11.1793 8.81641 10.7276C8.81641 10.276 8.44986 9.90945 7.99822 9.90945C7.54659 9.90945 7.18004 10.276 7.18004 10.7276ZM8.54368 8.54581C8.54368 8.27963 8.76513 8.0609 9.12622 7.72763L9.12716 7.72676C9.59664 7.29272 10.18 6.75337 10.18 5.81854C10.18 4.61526 9.2015 3.63672 7.99822 3.63672C6.79495 3.63672 5.81641 4.61526 5.81641 5.81854H6.90731C6.90731 5.2169 7.39659 4.72763 7.99822 4.72763C8.59986 4.72763 9.08913 5.2169 9.08913 5.81854C9.08913 6.25435 8.82186 6.52326 8.38604 6.92581L8.38424 6.92748C7.96891 7.31174 7.45277 7.78927 7.45277 8.54581H8.54368Z"
        fill="white"
      />
    </IconWrapper>
  );
};

const RiskLevel = ({ risk }) => {
  const theme = useTheme();

  const levels = {
    low: {
      text: "Low Risk",
      background: "#D2E9CC",
      color: "#031711", // UI-900
      icon: theme.vars.palette.investment.secondary,
    },
    moderate: {
      text: "Moderate Risk",
      background: "#FFE6C1",
      color: "#031711", // UI-900
      icon: "#FF8E26",
    },
    high: {
      text: "High Risk",
      background: "#FEBEC6",
      color: "#031711", // UI-900
      icon: "#C61919",
    },
    lowToModerate: {
      text: "Low to Moderate Risk",
      background: "#FFE0E0",
      color: "#031711", // UI-900
      icon: "#E6649B",
    },
    lowToHigh: {
      text: "Low to High Risk",
      background: "#FFE0E0",
      color: "#031711", // UI-900
      icon: "#E6649B",
    },
    moderateToHigh: {
      text: "Moderate to High Risk",
      background: "#FFE0E0",
      color: "#031711", // UI-900
      icon: "#E6649B",
    },
    varies: {
      text: "Varies",
      background: "#FFE6C1",
      color: "#031711", // UI-900
      icon: "#FF8E26",
    },
  };

  const { text, background, icon } = levels[risk];

  return (
    <Frame backgroundColor={background}>
      <Typography variant="caption1">{text}</Typography>
      <Icon color={icon} />
    </Frame>
  );
};

export default RiskLevel;
