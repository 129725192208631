const title = {
    type: "title",
    text: "Invest in property without buying a house - like having a stake in a glamorous hotel chain.",
};

const description = {
    type: "body",
    text: "REITs offer exposure to the real estate market with regular income through dividends. There's no minimum investment, making it accessible for all investors.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Offers exposure to the real estate market with regular income through dividends.",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "British Land (BLND.L)",
                    subheading: "Average annual return of 6% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Land Securities (LAND.L)",
                    subheading: "Average annual return of 5.8% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "SEGRO (SGRO.L)",
                    subheading: "Average annual return of 11% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Interested in real estate but don't want to manage property? REITs let you invest in real estate and enjoy the income without the hassle.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Easily bought and sold on the stock market.",
        },
    ],
};

const reit = [
    title,
    description,
    benefits,
    examples,
    greatFor,
    accessibility,
];

export default reit;
