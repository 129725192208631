import React, { useState } from "react";
import {
    ToggleButton,
    ToggleButtonGroup,
    styled,
    toggleButtonGroupClasses
} from "@mui/material";
import { fontWeights } from "@themes";
import { useLearnContext } from "@hooks";

const Container = styled(ToggleButtonGroup)(({ theme }) => ({
    gap: 8,
    // Applies styles to each ToggleButton in the ToggleButtonGroup
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        ...theme.typography.caption1,
        backgroundColor: "#FFFFFF",  // UI-50
        border: "none",
        borderRadius: "40px",
        color: "#065D44",  // UI-500
        fontWeight: fontWeights.MEDIUM,
        padding: "8px 12px",
        "&:hover": {
            backgroundColor: "#DDD",
        },
        "&.Mui-selected": {
            backgroundColor: "#B7D9CF",  // UI-300
            "&:hover": {
                backgroundColor: "#B7D9CF",  // UI-300
            },
        },
    },
}));

const WorkshopTypeSelector = (props) => {
    const { navigate } = useLearnContext();
    // Takes the value of the button selected, "group" / "one-to-one"
    const { selected = "group" } = props;
    const [type, setType] = useState(selected);
    
    const handleType = (event, newType) => {
        setType(newType);
        // Navigate to corresponding page
        switch (newType) {
            case "group":
                navigate("/learn/workshops");
                break;
            case "one-to-one":
            default:
                navigate("/learn/sessions");
                break;
        }
    };

    return (
        <Container
            size="small"
            value={type}
            exclusive
            onChange={handleType}
            aria-label="workshop type"
        >
            <ToggleButton value="group" aria-label="group workshops">
                Group Workshops
            </ToggleButton>
            <ToggleButton value="one-to-one" aria-label="1:1 sessions">
                1:1 Sessions
            </ToggleButton>
        </Container>
    );
};

export default WorkshopTypeSelector;
