import React from "react";
import { Typography } from "@mui/material";

const Subtitle = (props) => {
  return (
    <Typography
      fontSize={{
        md: "clamp(1.1rem, 0.8766rem + 0.4149vw, 1.25rem)",
      }}
      lineHeight={{
        md: "clamp(1.95rem, 1.5031rem + 0.8299vw, 2.25rem)",
      }}
      display={{ xs: "none", md: "block" }}
      {...props}
    >
      {props.children}
    </Typography>
  );
};

export default Subtitle;
