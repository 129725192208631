import { Box } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { QuestionnaireProvider } from "@context/QuestionnaireContext";
import { AuthProvider } from "@context/AuthContext";
import { NavigationProvider } from "@context/NavigationContext";
import { LearnProvider } from "@context/LearnContext";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProjectionPage from "./pages/Projection";
import LoginPage from "./pages/Login";
import SignUpPage from "./pages/Onboarding/SignUp";
import QuestionnairePage from "./pages/Onboarding/Questionnaire";
import AIChatPage from "@pages/AIChat";
import LearnRoutes from "./routes/Learn";
import Game from "./game/Game";
import Navigation from "@features/navigation";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/signup" replace={true} />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/projection" element={<ProjectionPage />} />
      <Route path="/game/*" element={<Game />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/questionnaire" element={<QuestionnairePage />} />
      <Route path="/learn/*" element={<LearnRoutes />} />
      <Route path="/ai" element={<AIChatPage />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
};

const AppContext = ({ children }) => {
  return (
    <NavigationProvider>
      <QuestionnaireProvider>
        <AuthProvider>
          <LearnProvider>{children}</LearnProvider>
        </AuthProvider>
      </QuestionnaireProvider>
    </NavigationProvider>
  );
};

function App() {
  return (
    <Router>
      <AppContext>
        <Navigation>
          <AppRoutes />
        </Navigation>
      </AppContext>
    </Router>
  );
}

export default App;

