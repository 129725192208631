// Defines name, associated icon, and colour for each financial product.
// Format: { title, icon, colour: { type, title, background } }

const blueChipStocks = {
    type: 'BlueChipStocks',
    title: 'Blue Chip Stocks',
    colour: {
        title: 'rgba(9, 166, 255, 0.15)',
        icon: '#3E4CD2',
        background: '#3E4CD2'
    },
};

const emergencyFund = {
    type: 'EmergencyFund',
    title: 'Emergency Fund',
    colour: {
        title: 'rgba(151, 197, 120, 0.20)',
        icon: '#2A935A',
        background: '#2A935A'
    },
};

const indexFund = {
    type: 'IndexFund',
    title: 'Index Fund',
    colour: {
        title: 'rgba(95, 50, 182, 0.15)',
        icon: '#5F32B6',
        background: '#5F32B6'
    },
};

const instantSavingsAccount = {
    type: 'InstantSavingsAccount',
    title: 'Instant Access Savings Account',
    colour: {
        title: 'rgba(255, 220, 220, 0.60)',
        icon: '#F480A3',
        background: '#E04B77'
    },
};

const isaAccount = {
    type: 'IsaAccount',
    title: 'ISA Account',
    colour: {
        title: 'rgba(185, 234, 255, 0.39)',
        icon: '#00607E',
        background: '#00607E'
    },
};

const juniorIsaAccount = {
    type: 'JuniorIsaAccount',
    title: 'Junior ISA Account',
    colour: {
        title: 'rgba(253, 218, 0, 0.15)',
        icon: '#FFAA2A',
        background: '#C7821A'
    },
};

// Keys are equal to the type attribute of each product
const financialProducts = {
    'BlueChipStocks': blueChipStocks,
    'EmergencyFund': emergencyFund,
    'IndexFund': indexFund,
    'InstantSavingsAccount': instantSavingsAccount,
    'IsaAccount': isaAccount,
    'JuniorIsaAccount': juniorIsaAccount,
}

export default financialProducts;
