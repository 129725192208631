import React from "react";
import { Box, Typography, Paper } from "@mui/material";

const Field = ({ section, index }) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
      <Box
        display={"flex"}
        alignSelf={"flex-start"}
        backgroundColor={section.color}
        marginTop={"3px"}
        width={"12px"}
        height={"12px"}
        borderRadius={"6px"}
      />
      <Typography
        fontSize={"13px"}
        lineHeight={"18px"}
        // backgroundColor={"#00F"}
      >
        {section.label}
        <br />
        <span style={{ fontWeight: 600 }}>
          £ {section.data[index].toFixed(2)}
        </span>
      </Typography>
    </Box>
  );
};

const FinancialSnapshot = ({ axisData, series }) => {
  if (!series || !axisData) {
    return;
  }

  const age = axisData.x.value;
  const index = axisData.x.index;
  const sum = series
    .map((section) => {
      return section.data[index];
    })
    .reduce((a, b) => a + b)
    .toFixed(2);

  const fields = series.map((section, i) => {
    return <Field key={i} index={index} section={section} />;
  });

  return (
    <Paper
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "#FFF",
        width: "202px",
        padding: "16px",
        borderRadius: "8px",
        gap: "24px",
        boxShadow: "4px 4px 16px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Typography fontSize={"13px"} lineHeight={"20px"}>
        At the age of <span style={{ fontWeight: 600 }}>{age}</span> you would
        have a net worth of <br />
        <span style={{ fontWeight: 600 }}>£ {sum}</span>
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"16px"}
        alignSelf={"stretch"}
        alignItems={"flex-start"}
      >
        {fields}
      </Box>
    </Paper>
  );
};

export default FinancialSnapshot;
