const title = {
    type: "title",
    text: "Imagine this as your greatest hits collection - a flexible and low-cost pension plan with capped charges, designed to make saving for retirement straightforward and affordable.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Perfect for individuals looking for low fees and flexibility in contributions. There's no minimum deposit, so you can start saving right away.",
        },
    ],
};

const growth = {
    type: "section",
    heading: "Growth",
    body: [
        {
            type: "body",
            text: "Typically 4% to 7% per year. Stakeholder pensions are designed to grow conservatively, aligning with benchmarks like the FTSE 100.",
        },
    ],
};

const risk = {
    type: "section",
    heading: "Risk",
    body: [
        {
            type: "body",
            text: "Moderate to High (depending on investment choices)",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "Aviva Stakeholder Pension",
                    subheading: "Average annual growth of 5% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Standard Life Stakeholder Pension",
                    subheading: "Average annual growth of 5.5% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Scottish Widows Stakeholder Pension",
                    subheading: "Average annual growth of 6% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you want a low-cost, flexible pension plan with no high fees, a stakeholder pension is a great choice.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Available directly from the providers' websites or through financial advisors.",
        },
    ],
};

const stakeholderPension = [
    title,
    benefits,
    growth,
    risk,
    providersExamples,
    greatFor,
    accessibility,
];

export default stakeholderPension;
