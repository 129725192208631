import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const setUserEmail = (email) => {
    sessionStorage.setItem("userEmail", email);
    setEmail(email);
  };

  const registerUser = (status) => {
    if (status === 200) {
      // navigate("/projection");
      navigate("/ai");
    } else if (status === 404) {
      navigate("/questionnaire");
    }
  };

  const signInUser = (name, email) => {
    const lambdaUrl =
      "https://ojxoecgquj.execute-api.eu-west-1.amazonaws.com/default/signIn";

    fetch(lambdaUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email }),
    })
      .then((response) => {
        return response.status;
      })
      .then((status) => {
        registerUser(status);
        setIsSignedIn(true);
      })
      .catch((error) => {
        ("Error fetching profile data");
        console.error("Error:", error);
      });
  };

  const contextValue = {
    isSignedIn,
    setIsSignedIn,
    name,
    setName,
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    email,
    setUserEmail,
    signInUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
