import React from "react";
import { InputAdornment } from "@mui/material";
import { endAdornment } from "./styles";
import Tooltip from "@ui-library/Tooltip";

const EndAdornment = (props) => {
  return (
    <InputAdornment position="end" sx={endAdornment(props.tooltip)}>
      <Tooltip {...props} />
    </InputAdornment>
  );
};

export default EndAdornment;
