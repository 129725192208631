import React from "react";
import { Box, styled } from "@mui/material";
import { PageContainer, PageHeader, PageSection } from "@features/learn";
import { useLearnContext } from "@hooks";

const workshopsSection = {
    title: "Upcoming Workshops",
    caption: "Learn about finance from Winvest experts in various financial areas.",
};

const financialTipsSection = {
    title: "Bestie's Top Tips",
    caption: "Your Go-To Guide for Smart, Savy Financial Moves",
};

const personalConsultingSection = {
    title: "Personal Consulting",
    caption: "Book one to one call with Winvest Expert",
};

const financialProductsSection = {
    title: "Financial Products",
    caption: "Explore the Variety of Best Financial Products available in UK ",
};

const PageContent = styled(Box)(() => ({
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: "80px",
    // paddingBottom: "80px", // Removed when bottom navigation added
}));

const LearnPage = () => {
    const {
        getRandomFinancialProducts,
        getFinancialTips,
        getSessionPromotion,
        getWorkshops,
        navigate,
    } = useLearnContext();
    const workshops = getWorkshops();
    const financialTips = getFinancialTips();
    const personalConsulting = getSessionPromotion();
    const financialProducts = getRandomFinancialProducts();

    const sections = [
        {
            path: "sessions",
            props: personalConsultingSection,
            content: personalConsulting,
        },
        {
            path: "tips",
            props: financialTipsSection,
            content: financialTips,
        },
        {
            path: "products",
            props: financialProductsSection,
            content: financialProducts,
        },
        {
            path: "workshops",
            props: workshopsSection,
            content: workshops,
        },
    ];

    return (
        <PageContainer sx={{ paddingTop: "16px" }}>
            <PageHeader
                variant="h1"
                title="WInvest Library"
            />
            <PageContent>
                {sections.map((section, index) => (
                    <PageSection
                        key={index}
                        {...section.props}
                        onSeeAll={() => navigate(`learn/${section.path}`)}
                    >
                        {section.content}
                    </PageSection>
                ))}
            </PageContent>
        </PageContainer>
    );
};

export default LearnPage;
