import React from "react";
import { Typography } from "@mui/material";

const HeaderCaption = (props) => {
    const color = "#3D4A47";  // UI-800

    return (
        <Typography variant="body" color={color}>
            {props.children}
        </Typography>
    );
};

export default HeaderCaption;
