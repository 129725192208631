import React from "react";
import { Box, styled } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: "0px 40px 0px 32px",
    minHeight: "72px",
  },
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
  },
}));

const Content = () => {
  return <img src="assets/logos/logo-white.svg" alt="" width={"194px"} />;
};

const Logo = () => {
  return (
    <Container>
      <Content />
    </Container>
  );
};

export default Logo;

//eslint-disable-next-line
const OldLogo = () => {
  return (
    <Box
      alignItems={"flex-start"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      marginTop={{ xs: "5.84vh", md: "0" }}
      paddingLeft={{ md: "2.22vw" }}
      paddingRight={{ md: "2.77vw" }}
      paddingY={{ md: "2.5vh" }}
      height={{ md: "7.5vh" }}
      width={"100%"}
    >
      <Box
        component={"img"}
        src="assets/logos/logo-white.svg"
        maxWidth={"100%"}
        alignSelf={{ xs: "center", md: "auto" }}
      />
    </Box>
  );
};
