import { financialProducts } from "@content/financialProducts";
import axios from "axios";

// eslint-disable-next-line
const API_BASE_URL = "";

const cleanUserMessage = (text) => {
  return text.replace(/^#+|#+$/g, "");
};

const cleanAssistantMessage = (text) => {
  const jsonString = text.replace(/```json\n|\n```/g, "");
  return JSON.parse(jsonString);
};

const getChatHistory = async (email) => {
  const URL =
    "https://nlzeiwi972.execute-api.eu-west-1.amazonaws.com/default/getChatHistory";
  // eslint-disable-next-line
  const { data, ...response } = await axios.post(URL, { email });

  const cleanMessage = (sender, message) => {
    if (sender === "user") {
      return cleanUserMessage(message);
    } else if (sender === "assistant") {
      return cleanAssistantMessage(message);
    }
    return message;
  };

  return data.map(({ sender, message, ...item }) => ({
    sender,
    message: cleanMessage(sender, message),
    ...item,
  }));
};

const completeChat = async (email, prompt, secondaryPrompt = false) => {
  const URL =
    "https://8m4e71cuu6.execute-api.eu-west-1.amazonaws.com/default/completeChat";

  const secondaryPromptText = `Please guide me on how to invest in ${prompt} in a step-by-step fashion. First provide some platforms for me to choose from. Make sure to ask me to confirm my platform before moving forward. Then Ask what amount I want to invest. Make sure to ask me my investment amount before moving forward. Finally summarize the conversation so that I can take this as a notes.`;

  // eslint-disable-next-line
  const { data, ...response } = await axios.post(URL, {
    email,
    prompt,
    ...(secondaryPrompt && { secondary_prompt: secondaryPromptText }),
  });
  return cleanAssistantMessage(data);
};

// key: product returned by service, value: key stored in financialProducts.js
const productTitleMap = {};

for (let key in financialProducts) {
  const { title } = financialProducts[key];
  productTitleMap[title] = key;
}

const findFinancialProductByTitle = (title) => productTitleMap[title] || null;

const getMessages = (messages) => {
  // find the first assistant message in the history given
  let assistantIndex = -1;
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].sender === "assistant") {
      assistantIndex = i;
      break;
    }
  }

  // if no assistant messages found, return an empty array
  if (assistantIndex === -1) return [];

  // if no messages after the assistant, it is a new conversation, return just the first assistant message
  const userHasInteracted = messages.slice(assistantIndex + 1).length > 0;
  if (!userHasInteracted) return [messages[assistantIndex]];

  // else the conversation has history, return the first assistant message and all following messages
  return messages.slice(assistantIndex);
};

const isMostRecentMessage = (datetime, messages) =>
  messages[messages.length - 1].datetime === datetime;

const getMostRecentMessage = (messages) => messages[messages.length - 1] || {};

const mostRecenthasAChoice = (messages) => {
  const recentEntry = getMostRecentMessage(messages);

  return (
    Array.isArray(recentEntry?.message?.responses) &&
    recentEntry.message.responses.length > 0 &&
    recentEntry.message.responses.every(
      ({ title }) => title && findFinancialProductByTitle(title)
    )
  );
};

export {
  getChatHistory,
  completeChat,
  findFinancialProductByTitle,
  getMessages,
  isMostRecentMessage,
  getMostRecentMessage,
  mostRecenthasAChoice,
};
