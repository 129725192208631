const title = {
    type: "title",
    text: "These are the rising stars, the trendsetters of the stock market.",
};

const description = {
    type: "body",
    text: "Growth stocks are shares in companies expected to grow at an above-average rate compared to other companies. They might not pay dividends now, but they reinvest profits to fuel their future expansion.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Perfect for aggressive investors aiming for high capital appreciation.",
        },
    ],
};

const stockExamples = {
    type: "section",
    heading: "Stock Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "Nvidia (NVDA)",
                    subheading: "Average annual return of 35% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Tesla (TSLA)",
                    subheading: "Average annual return of 55% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Amazon (AMZN)",
                    subheading: "Average annual return of 29% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Ready to take some risks for high rewards? Growth stocks are your ticket to potentially massive gains, like catching the next big trend.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Easily bought and sold on the stock market.",
        },
    ],
};

const growthStocks = [
    title,
    description,
    benefits,
    stockExamples,
    greatFor,
    accessibility,
];

export default growthStocks;
