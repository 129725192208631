import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import FinancialCard from "../FinancialCard";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import marketingTips from "./marketingTips";
import TextField from "@ui-library/TextField";

const Heading = () => {
  return (
    <Typography fontSize={"13px"} color={"#cbf56a"}>
      Provide us with your current investment information and potential plans
    </Typography>
  );
};

const Inputs = () => {
  const { state, dispatch } = useProjectionContext();
  const { investment } = state;

  const handleChange = (event) => {
    const { name, value } = event.target;

    // First, remove leading zeros, but allow a single zero before a decimal point
    const sanitizedValue = value.replace(/^0+(?!\.|$)/, "");

    // Allow digits, a single dot, and nothing else.
    // This regex allows values like '2.', '2.0', '0.', etc.
    if (/^\d*\.?\d*$/.test(sanitizedValue)) {
      dispatch({
        type: "SET_INVESTMENT",
        payload: { ...investment, [name]: sanitizedValue },
      });
    }
  };

  const textFields = [
    {
      id: "initialAmountInvestment",
      name: "initialAmount",
      label: "Initial Investment",
      startsymbol: "£",
      value: investment.initialAmount,
      tooltip: `If you are a new investor, please enter the initial deposit amount here. If you are already investing, specify your current existing investment amount, including stocks, bonds, funds, or any other financial products you have.`,
    },
    {
      id: "monthlyInvestment",
      name: "monthlyInvestment",
      label: "Monthly Contribution",
      startsymbol: "£",
      value: investment.monthlyInvestment,
      tooltip:
        "Enter the amount of your monthly savings that you wish to allocate to your investment.",
    },
    {
      id: "interestRateInvestment",
      name: "interestRate",
      label: "Annual Compound Interest",
      startsymbol: "%",
      value: investment.interestRate,
      tooltip: `Enter the annual compound rate of your overall investment. The default rate is set at 8%, based on the average market return of the MSCI World Index since 1987. You can provide alternative options to adjust this rate as needed.`,
    },
  ];

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
      {textFields.map((data, i) => (
        <TextField
          key={i}
          tooltip={"true"}
          tooltipcontent={data.tooltip}
          isdark="true"
          id={data.id}
          name={data.name}
          label={data.label}
          startadornment="true"
          startsymbol={data.startsymbol}
          value={data.value}
          onChange={handleChange}
        />
      ))}{" "}
    </Box>
  );
};

const InvestmentAccordion = () => {
  const theme = useTheme();
  const colorSet = theme.vars.palette.investment;

  const tipButtonColors = {
    circleColor: colorSet.tertiary,
    pathColor: colorSet.primary,
    hoverCircleColor: colorSet.primary,
    hoverPathColor: colorSet.tertiary,
  };

  return (
    <FinancialCard
      title={"Investment"}
      backgroundColor={colorSet.secondary}
      color={colorSet.primary}
      tipModalContent={marketingTips.investment}
      tipButtonColors={tipButtonColors}
    >
      <Heading />
      <Inputs />
    </FinancialCard>
  );
};

export default InvestmentAccordion;
