import React from "react";
import { Box, styled } from "@mui/material";

const Container = styled(Box)(() => ({
    alignItems: "flex-start",
    backgroundColor: "#F3F3F3",  // UI-100
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    // minHeight: "100vh", // Changed when bottom navigation added
    minHeight: "100%",
    overflow: "hidden",
    padding: "24px 0 40px 0",
}));

const PageContainer = (props) => {
    return (
        <Container sx={props.sx}>
            {props.children}
        </Container>
    );
};

export default PageContainer;
