import React from "react";
import { Header, Frame, Content } from "@features/projection";

const ProjectionPage = () => {
  return (
    <Frame>
      <Header />
      <Content />
    </Frame>
  );
};

export default ProjectionPage;
