import React from "react";
import { Box, Divider } from "@mui/material";
import { totalPages } from "../../../../content/questionnaire";

const ProgressBar = ({ page }) => {
  const widthProportion = (100 - (totalPages - 1)) / totalPages;

  const bars = Array(totalPages)
    .fill(0)
    .map((_, i) => {
      const color = i < page ? "#E1349C" : "";
      const leftBorder = i === 0 ? "4px" : "";
      const rightBorder = i === totalPages - 1 ? "4px" : "";

      return (
        <Divider
          key={i}
          sx={{
            borderBottomWidth: "8px",
            backgroundColor: color,
            borderBottomLeftRadius: leftBorder,
            borderTopLeftRadius: leftBorder,
            borderBottomRightRadius: rightBorder,
            borderTopRightRadius: rightBorder,
          }}
          width={widthProportion + "%"}
        />
      );
    });

  return (
    <Box
      display={"flex"}
      alignItems={"flex-start"}
      width={{ xs: "100%", md: "194px" }}
      gap={"1px"}
    >
      {bars}
    </Box>
  );
};

export default ProgressBar;
