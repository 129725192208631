import React from "react";
import { Box, styled } from "@mui/material";
import Button from "@ui-library/Button";
import Text from "./Text";

const handleExploreMoreClick = () => {
  // window.open("https://winvest.uk/financial-course", "_blank");
  window.open(
    "https://superpeer.com/winvest/-/winvest-collective-one-on-one-coaching",
    "_blank",
  );
};

const Frame = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  alignSelf: "stretch",
  flexShrink: 0,
  justifyContent: "space-between",
  borderRadius: 12,
  backgroundColor: "#2B2A28",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
  },
}));

const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: 24,
  gap: 16,
  zIndex: 1,
});

const ImageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignSelf: "flex-end",
    marginTop: -100,
  },
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    right: 0,
    bottom: 0,
    top: 0,
  },
  zIndex: 0,
}));

const Image = styled((props) => <Box component={"img"} {...props} />)(
  ({ theme }) => ({
    maxWidth: 214,
    height: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      marginTop: 16,
      borderRadius: "0 0 12px 12px",
    },
    [theme.breakpoints.up("md")]: {
      borderRadius: 12,
    },
  })
);

const Banner = () => {
  return (
    <Frame>
      <Content>
        <Text />
        <Button
          type={"primary"}
          size={"small"}
          onClick={handleExploreMoreClick}
          sx={{
            width: "120px",
          }}
        >
          Take Me
        </Button>
      </Content>
      <ImageWrapper>
        <Image src="assets/images/MoneyInHand.png" />
      </ImageWrapper>
    </Frame>
  );
};

export default Banner;
