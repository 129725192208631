import { Box } from "@mui/material";
import { useChat } from "@features/aichat/hooks";
import AssistantDisplay from "./AssistantDisplay";
import UserDisplay from "./UserDisplay";
import { ErrorOccurred, LoadingResponse } from "./PlaceholderMessage";

const ChatDisplay = ({ history }) => {
  const { loadingResponse, errorOccurred } = useChat();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {history.map(({ sender, message, datetime }, index) =>
        sender === "user" ? (
          <UserDisplay key={index} message={message} />
        ) : (
          <AssistantDisplay
            key={index}
            message={message}
            datetime={datetime}
            index={index}
          />
        )
      )}
      {errorOccurred && <ErrorOccurred />}
      {loadingResponse && <LoadingResponse />}
    </Box>
  );
};

export default ChatDisplay;
