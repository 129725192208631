import React, { Children } from "react";
import { Box, Divider, FormControl, FormGroup } from "@mui/material";

const SelectorGroup = (props) => {
  const children = Children.toArray(props.children);

  const selectors = children.map((child, i) => {
    return (
      <React.Fragment key={i}>
        {child}
        {i < children.length - 1 && (
          <Divider
            sx={{
              width: props.width,
              borderBottomWidth: "1px",
              backgroundColor: "#BDBDBD",
            }}
          />
        )}
      </React.Fragment>
    );
  });

  return (
    <Box borderRadius={"8px"} border={"1px solid #BDBDBD"} {...props}>
      <FormControl component={"fieldset"}>
        <FormGroup>{selectors}</FormGroup>
      </FormControl>
    </Box>
  );
};

export default SelectorGroup;
