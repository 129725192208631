import React from "react";
import { Box } from "@mui/material";

const Section = (props) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      borderTop={"1px solid #E0E0E0"}
      paddingX={{ md: "5.55vw" }}
      paddingY={{ xs: "7.58vh", md: "6.66vh" }}
      alignItems={"center"}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default Section;
