import React from "react";
import { useQuestionnaireContext } from "@hooks";
import Section from "../Section";
import { Question } from "@features/questionnaire/components/textStyles";
import TextField from "@ui-library/TextField";

const InputQuestion = ({ id, label, type, question, ...props }) => {
  const { formData, updateFormData } = useQuestionnaireContext();

  return (
    <Section
      gap={"16px"}
      borderTop={""}
      paddingX={{ md: "5.55vw" }}
      paddingTop={{ md: "8.33vh" }}
      paddingBottom={{ md: "7.5vh" }}
    >
      <Question>{question}</Question>
      <TextField
        id={id}
        type={type}
        label={label}
        value={formData[id]}
        onChange={(e) => updateFormData(id, e.target.value)}
        {...props}
      />
    </Section>
  );
};

export { InputQuestion };
