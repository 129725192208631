import TextContent from "./TextContent";

export default TextContent;
export {
    ContentBody,
    ContentList,
    ContentHeading,
    ContentSection,
    ContentTitle,
    default as displayContent,
} from "./Content";
