import React from "react";
import { SvgIcon } from "@mui/material";

const CoinStackIcon = ({ color = "white" }) => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12 22.7504C8 22.7504 4.75 19.8804 4.75 16.3504V12.6504C4.75 12.2404 5.09 11.9004 5.5 11.9004C5.91 11.9004 6.25 12.2404 6.25 12.6504C6.25 15.2704 8.72 17.2504 12 17.2504C15.28 17.2504 17.75 15.2704 17.75 12.6504C17.75 12.2404 18.09 11.9004 18.5 11.9004C18.91 11.9004 19.25 12.2404 19.25 12.6504V16.3504C19.25 19.8804 16 22.7504 12 22.7504ZM6.25 16.4604C6.32 19.1104 8.87 21.2504 12 21.2504C15.13 21.2504 17.68 19.1104 17.75 16.4604C16.45 17.8704 14.39 18.7504 12 18.7504C9.61 18.7504 7.56 17.8704 6.25 16.4604Z"
                fill={color}
            />
            <path
                d="M12 13.75C9.24 13.75 6.75999 12.51 5.54999 10.51C5.02999 9.66 4.75 8.67 4.75 7.65C4.75 5.93 5.52 4.31 6.91 3.09C8.27 1.9 10.08 1.25 12 1.25C13.92 1.25 15.72 1.9 17.09 3.08C18.48 4.31 19.25 5.93 19.25 7.65C19.25 8.67 18.97 9.65 18.45 10.51C17.24 12.51 14.76 13.75 12 13.75ZM12 2.75C10.44 2.75 8.98001 3.27 7.89001 4.23C6.83001 5.15 6.25 6.37 6.25 7.65C6.25 8.4 6.44999 9.1 6.82999 9.73C7.77999 11.29 9.76 12.25 12 12.25C14.24 12.25 16.22 11.28 17.17 9.73C17.56 9.1 17.75 8.4 17.75 7.65C17.75 6.37 17.17 5.15 16.1 4.21C15.01 3.27 13.56 2.75 12 2.75Z"
                fill={color}
            />
            <path
                d="M12 18.75C7.87 18.75 4.75 16.13 4.75 12.65V7.65C4.75 4.12 8 1.25 12 1.25C13.92 1.25 15.72 1.9 17.09 3.08C18.48 4.31 19.25 5.93 19.25 7.65V12.65C19.25 16.13 16.13 18.75 12 18.75ZM12 2.75C8.83 2.75 6.25 4.95 6.25 7.65V12.65C6.25 15.27 8.72 17.25 12 17.25C15.28 17.25 17.75 15.27 17.75 12.65V7.65C17.75 6.37 17.17 5.15 16.1 4.21C15.01 3.27 13.56 2.75 12 2.75Z"
                fill={color}
            />
        </svg>
    </SvgIcon>
);

export default CoinStackIcon;
