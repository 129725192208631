import React from 'react';
import { getAvatarURL } from '../playerState';

const Avatar = ({ x = 0, y = 0, size = 100, avatar }) => {
    // If avatar is given, use that, else use the stored avatar,
    // i.e. no parameter needed for getAvatarURL()
    const src = avatar ? getAvatarURL(avatar) : getAvatarURL();

    return (
        <svg width={size} viewBox='0 0 100 100'>
            <image href={src} height='100' width='100' />
        </svg>
    );
}

export default Avatar;
