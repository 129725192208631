const base = () => ({
  display: "inline-flex",
  minHeight: "32px",
  padding: "8px 12px",
  borderRadius: "40px",
  textTransform: "none",
  border: "1px solid #E0E0E0",
  fontSize: "12px",
  lineHeight: "14.5px",
  fontWeight: 400,
  color: "#4F4F4F",
  minWidth: "auto",
});

const hover = () => ({
  border: "1px solid #333",
  color: "#000",
});

const selected = () => ({
  border: "0px",
  backgroundColor: "#F2F1EF",
  color: "#333",
});

const baseMobile = () => ({
  width: "64px",
  padding: "8px 0px",
  fontSize: "10px",
  gap: "4px",
  borderRadius: "4px",
});

export { base, hover, selected, baseMobile };
