const title = {
    type: "title",
    text: "Think of this as your personal assistant, always on call.",
};

const description = {
    type: "body",
    text: "Robo-advisors are like having a team of stylists who manage your investments with cutting-edge algorithms. Perfect for those who want a hands-off, effortless approach to investing.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Ideal for beginners or busy bees who want professional investment management without the hassle. Low fees and minimum deposits mean you can start with a small investment and watch it grow.",
        },
    ],
};

const growth = {
    type: "section",
    heading: "Growth",
    body: [
        {
            type: "body",
            text: "Typically 5% to 8% per year. These platforms optimize your portfolio for steady growth.",
        },
    ],
};

const risk = {
    type: "section",
    heading: "Risk",
    body: [
        {
            type: "body",
            text: "Moderate, as portfolios are typically well-diversified.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "Nutmeg",
                    subheading: "Average annual growth of 7% over the past 5 years.",
                },
                {
                    type: "section",
                    heading: "Wealthify",
                    subheading: "Average annual growth of 6.5% over the past 5 years.",
                },
                {
                    type: "section",
                    heading: "Moneyfarm",
                    subheading: "Average annual growth of 6% over the past 5 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you want to dip your toes into the investment world without getting overwhelmed, a robo-advisor is your perfect sidekick.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Available online via the providers' websites or mobile apps. Easy sign-up process and low minimum investments.",
        },
    ],
};

const roboAdvisor = [
    title,
    description,
    benefits,
    growth,
    risk,
    providersExamples,
    greatFor,
    accessibility,
];

export default roboAdvisor;
