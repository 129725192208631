import { default as step0 } from "./components/steps/step0";
import { default as step1 } from "./components/steps/step1";
import { default as step2 } from "./components/steps/step2";
import { default as step3 } from "./components/steps/step3";

const steps = [step0, step1, step2, step3];
export { steps };

export { totalPages } from "../../content/questionnaire";

export { default as ThankYouScreen } from "./components/steps/ThankYouScreen";
export { default as Description } from "./components/description";
export { default as FormContainer } from "./layouts/FormContainer";
