import React from "react";
import { Box, Typography } from "@mui/material";
import { useQuestionnaireContext } from "@hooks";
import Frame from "./Frame";
import ProgressBar from "./ProgressBar";

const PageText = ({ page }) => {
  return (
    <Typography
      display={{ xs: "none", md: "inline" }}
      fontSize={"13px"}
      lineHeight={"12.512px"}
      fontWeight={600}
      color={"#000"}
    >
      Step {page + " "}
      <Typography
        component={"span"}
        fontSize={"13px"}
        lineHeight={"12.512px"}
        color={"#828282"}
      >
        of 3
      </Typography>
    </Typography>
  );
};

const PageInfo = (props) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={"8px"}
      width={{ xs: "100%", md: "auto" }}
    >
      <PageText {...props} />
      <ProgressBar {...props} />
    </Box>
  );
};

const Navbar = (props) => {
  const { page } = useQuestionnaireContext();

  return (
    <Frame {...props}>
      <Typography fontSize={"1rem"} fontWeight={600} lineHeight={"1.625rem"}>
        {props.text}
      </Typography>
      <PageInfo page={page} />
    </Frame>
  );
};

export default Navbar;
