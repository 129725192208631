import React from "react";
import { Divider, Grid, Typography } from "@mui/material";

const CustomDivider = () => (
  <Grid
    item
    xs
    sx={{
      display: "flex",
      alignSelf: "stretch",
      alignItems: "center",
    }}
  >
    <Divider
      sx={{
        width: "100%",
        height: "1px",
        backgroundColor: "#5C736C",
      }}
    />
  </Grid>
);

const Separator = () => {
  return (
    <Grid container direction={"row"} sx={{ gap: "8px", alignSelf: "stretch" }}>
      <CustomDivider />
      <Typography variant="bodyL" color={"#B7D9CF"}>
        or
      </Typography>
      <CustomDivider />
    </Grid>
  );
};

export default Separator;
