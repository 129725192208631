const title = {
    type: "title",
    text: "Provides for your loved ones if something happens to you.",
};

const description = {
    type: "body",
    text: "It's your financial legacy plan.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Ensures your family is financially protected in case of your untimely death.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Legal & General Life Insurance",
            subheading: "Offers lump sum payment upon death or terminal illness diagnosis.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £6 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available directly from Legal & General's website or through their network of financial advisors.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Aviva Life Insurance",
            subheading: "Provides a lump sum payout or regular income to your family.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £10 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available on the Aviva website or through Aviva brokers.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Want to ensure your family's financial security? Life insurance gives them the financial support they need.",
        },
    ],
};

const lifeInsurance = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
];

export default lifeInsurance;
