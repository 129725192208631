const page = {
  title:
    "You're planning your own estate.",
  caption: "You want to minimise the inheritance tax burden on your beneficiaries. In case anything happened to me, I want to make sure that the money is going to the people I love and care about. ",
  description: "Would you like to:\nChoose one option.",
  choices: [
    {
      choice: "Legalise Trusts for Children's Future",
      // outcome: {
      //   title: "Congratulations!",
      //   caption: "Congratulations, you are now in the process of securing trust for your kids, ensuring their future!"
      // },
      outcome: 0,
    },
    {
      choice: "Lifetime Gifts to Reduce Tax Liability",
      // outcome: {
      //   title: "Congratulations!",
      //   caption: "Congratulations, you have now initiated lifetime gifts to your beneficiaries, reducing your tax liability and providing immediate benefits to your loved ones!"
      // },
      outcome: 1,
    },
    {
      choice: "Charitable Giving for Tax Efficiency",
      // outcome: {
      //   title: "Congratulations!",
      //   caption: "Congratulations, you have now incorporated charitable giving into your estate plan, reducing your taxable estate while supporting causes you care about!"
      // },
      outcome: 2,
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/money-5.jpeg",
  }
};

const outcomes = [
  {
    title: "Congratulations!",
    caption: "Congratulations, you are now in the process of securing trust for your kids, ensuring their future!"
    // change: ,
  },
  {
    title: "Congratulations!",
    caption: "Congratulations, you have now initiated lifetime gifts to your beneficiaries, reducing your tax liability and providing immediate benefits to your loved ones!"
    // change: ,
  },
  {
    title: "Congratulations!",
    caption: "Congratulations, you have now incorporated charitable giving into your estate plan, reducing your taxable estate while supporting causes you care about!"
    // change: ,
  },
];

const changes = [
  // {
  //   change: "increase",
  //   property: "blueChipStocks",
  //   by: "50",
  //   frequency: "month",
  // },
  // {
  //   change: "increase",
  //   property: "blueChipStocks",
  //   by: "200",
  //   frequency: "once",
  // },
];

const tipScreen = {
  content: [
    {
      type: "title",
      text: "Planning Your Afterlife Asset... now! ",
    },
    {
      type: "paragraph",
      text: "Legalise Trusts for Children's Future\nDon't forget the importance of teamwork in managing finances during your marriage. It's like being financial superheroes together, ensuring stability and security for both of you.",
    },
  ],
};

const tips = [
  tipScreen,
];

const prompt15 = {
  type: "choice",
  page: page,
  outcomes: outcomes,
  changes: changes,
  tips: tips,
  colour: {
    primary: "#CEF2E7",
    secondary: "#00607E",
  },
};

export default prompt15;
