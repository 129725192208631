const title = {
    type: "title",
    text: "Loaning your money to the government or companies, and they pay you interest. It's like lending your friend cash and getting it back with a little extra.",
};

const description = {
    type: "body",
    text: "Bonds provide steady, low-risk income. There's no minimum investment, making them accessible for all investors.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Suitable for conservative investors seeking steady, low-risk income.",
        },
    ],
};

const bondsExamples = {
    type: "section",
    heading: "Bonds Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "UK Government Gilts",
                    subheading: "Average annual return of 1.5-2.5% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Corporate Bonds from Tesco",
                    subheading: "Average annual return of 3-4% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Corporate Bonds from BT",
                    subheading: "Average annual return of 3-4% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Looking for a safer investment? Bonds provide steady interest payments, perfect for balancing a riskier portfolio.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Easily bought and sold on the bond market.",
        },
    ],
};

const bonds = [
    title,
    description,
    benefits,
    bondsExamples,
    greatFor,
    accessibility,
];

export default bonds;
