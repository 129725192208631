import buildingWealth from "./buildingWealth";
import financialIndependence from "./financialIndependence";
import financialSecurity from "./financialSecurity";
import investmentOptions from "./investmentOptions";
import negotiateSalary from "./negotiateSalary";
import saveEachMonth from "./saveEachMonth";

const financialTips = {
    "financialSecurity": financialSecurity,
    "negotiateSalary": negotiateSalary,
    "investmentOptions": investmentOptions,
    "buildingWealth": buildingWealth,
    "financialIndependence": financialIndependence,
    "saveEachMonth": saveEachMonth,
};

export default financialTips;
