const page1 = [
    {
        type: "title",
        text: "How can I achieve financial independence?",
    },
    {
        type: "body",
        text: "Darling, start by tracking your spending and create a budget that allows you to save at least 20% of your income each month.",
    },
];

const page2 = [
    {
        type: "title",
        text: "Open a Savings Account",
    },
    {
        type: "body",
        text: "Open a high-yield savings account for your emergency fund with at least 3-6 months of living expenses. Invest in low-cost index funds, like the Vanguard S&P 500 ETF (VOO), to build wealth over time.",
    },
];

const page3 = [
    {
        type: "title",
        text: "Reduce Expenses",
    },
    {
        type: "body",
        text: "Focus on cutting unnecessary expenses and increasing your income through side hustles or career advancement. You've got this, superstar - financial freedom is within your reach!",
    },
];

const content = [page1, page2, page3];

const financialIndependence = {
    topic: "How can I achieve financial independence?",
    image: "/learn/financialTips/financial_independence.jpeg",
    color: {
        cardColor: "#F5C1F6",
        textColor: "#400C52",
        contentColor: "#5F32B6",
    },
    content: content,
};

export default financialIndependence;
