import { Box, styled, Typography } from "@mui/material";

const Frame = styled(Box)(({ backgroundColor }) => ({
  display: "inline-flex",
  padding: "16px 24px 16px 16px",
  borderRadius: "8px",
  maxWidth: "85%",
  overflow: "hidden",
  backgroundColor: backgroundColor,
  flex: "1 0 auto",
}));

const StarterCard = ({ text, backgroundColor, onClick }) => {
  return (
    <Frame backgroundColor={backgroundColor} onClick={onClick}>
      <Typography variant="body">{text}</Typography>
    </Frame>
  );
};

export default StarterCard;
