import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigation } from "@hooks";

const NavigationSync = () => {
  const location = useLocation();
  const { setCurrentFeature, setCurrentPath } = useNavigation();

  useEffect(() => {
    setCurrentPath(location.pathname);
    setCurrentFeature(location.pathname.split("/")[1]);
  }, [location.pathname, setCurrentPath]);

  return <></>;
};

export default NavigationSync;
