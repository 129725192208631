import { styled, InputBase } from "@mui/material";

const Field = styled(InputBase)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  justifyContent: "space-between",
  flex: 1,
  padding: "8px 8px 8px 20px",
  borderRadius: 30,
  backgroundColor: "#FFF",
  "& .MuiInputBase-input": {
    ...theme.typography.body,
    padding: 0,
    caretColor: theme.vars.palette.common.black,
  },
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
}));

export default Field;
