const page1 = {
  title: "You are earning £75,000",
  caption:
    "After a couple of pay rises and changing companies, you are currently earning £75,000 per annum, reaching the 40% tax bracket of your income. Right now, you are contributing 5% of your salary to your pension pot. ",
  description:
    "Many people don't contribute more to their pension because they prioritise instant gratification. However, I want to make the most of my money!",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-1.jpeg",
  },
};

const page2 = {
  title: "You are currently earning £75,000",
  description: "Would you like to:",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-1.jpeg",
  },
  choices: [
    {
      choice: "Keep the current pension contribution",
      outcome: {
        title: "Woohoo!",
        caption: "Your money is working hard for you",
      },
    },
    {
      choice:
        "Sacrifice 10% of your salary, increasing your pension contribution to 15%",
      outcome: {
        title: "25k of your income will be taxed 40%",
        caption: "You will earn £3,110.60 per month",
      },
    },
  ],
};

const tipScreen = {
  content: [
    {
      type: "title",
      text: "Pension sacrifice means putting more money into your retirement fund before taxes are taken out."
    },
    {
      type: "paragraph",
      text: "It's like giving your future self a raise because you save on taxes now, and your retirement fund grows fasterIt's like giving your future self a raise because you save on taxes now, and your retirement fund grows faster.",
    },
    {
      type: "paragraph",
      text: "You can also join a one-on-one call with our pension advisor or attend a pension workshop to learn more.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/people-2.jpeg",
  },
};

const tips = [
  tipScreen,
];

const prompt9 = {
  type: "choice-extended",
  page1: page1,
  page2: page2,
  tips: tips,
  colour: {
    primary: "#CEF2E7",
    secondary: "#00607E",
  },
};

export default prompt9;
