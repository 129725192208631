import React from "react";
import TextField from "@ui-library/TextField";

const AuthInputField = ({ id, label, value, setValue }) => {
  return (
    <TextField
      id={id}
      label={label}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      inputbasestyles={{
        backgroundColor: "#FFF",
        "&.Mui-focused, &:hover": {
          backgroundColor: "#FFF",
        },
      }}
    />
  );
};

export default AuthInputField;
