const page1 = {
  title: "You are having a kid.",
  caption: "The full time childcare is £263 per week. After 52 weeks of maternity leave",
  description: "Balancing work and family is one of the toughest job in the world. I trust myself that I can make the best situation for me and my family.",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-4.jpeg",
  },
};

const page2 = {
  title: "You are having a kid.",
  caption: "",
  description: "What would you choose? There's no right or wrong decision here.",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-4.jpeg",
  },
  choices: [
    {
      choice: "Go back to work, paying £263 per week for childcare",
      outcome: {
        title: "Woohoo!",
        caption: "You now are paying half of the childcare from your account, your husband paying half. You have £526 as extra going out of your account",
      },
    },
    {
      choice: "Work part time, paying £131 per week for childcare",
      outcome: {
        title: "Woohoo!",
        caption: "Work part time, paying £131 per week for childcare",
      },
    },
    {
      choice: "Stop working, don't pay any childcare",
      outcome: {
        title: "Woohoo!",
        caption: "Stop working, don\'t pay any childcare",
      },
    },
  ],
};

const tipScreen = {
  content: [
    { type: "title", text: "Balancing Work and Family" },
    { type: "subtitle", text: "Go back to work, paying £263 per week for childcare:" },
    {
      type: "paragraph",
      text: "Pros: Financial stability, career continuity, social interaction."
    },
    {
      type: "paragraph",
      text: "Cons: High childcare costs, limited time with child, work-life balance challenges."
    },
    { type: "subtitle", text: "Work part-time, paying £131 per week for childcare:" },
    {
      type: "paragraph",
      text: "Pros: Reduced childcare costs, more time with child, improved work-life balance."
    },
    {
      type: "paragraph",
      text: "Cons: Lower income(result in lower pension pot in retirement), career advancement challenges, potential for isolation."
    },
    { type: "subtitle", text: "Stop working, don\'t pay any childcare:" },
    {
      type: "paragraph",
      text: "Pros: Full-time parenting, cost savings, flexibility."
    },
    {
      type: "paragraph",
      text: "Cons: Financial dependence, career interruption(result in much lower pension pot in retirement), social and professional isolation."
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-3.jpeg",
  },
};

const tips = [
  tipScreen,
];

const prompt12 = {
  type: "choice-extended",
  page1: page1,
  page2: page2,
  tips: tips,
  colour: {
    primary: "#FFDCDC",
    secondary: "#E04B77",
  },
};

export default prompt12;
