import React from "react";
import { Typography } from "@mui/material";

const Question = (props) => {
  return (
    <Typography
      fontSize={{ md: "20px" }}
      fontWeight={700}
      lineHeight={{ md: "28px" }}
      {...props}
    >
      {props.children}
    </Typography>
  );
};

export default Question;
