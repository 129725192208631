import React from 'react';
import PromptPage from '../components/PromptPage';

const PromptChoice = (props) => {
    const { page, outcomes, changes, tips, colour, setPrompt } = props;

    return (
        <PromptPage
            pageType={'choice'}
            {...page}
            outcomes={outcomes}
            changes={changes}
            tips={tips}
            colour={colour.secondary}
            setPrompt={setPrompt}
        />
    );
}

export default PromptChoice;
