import { Box, styled, Typography } from "@mui/material";

const Frame = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "stretch",
  padding: "8px 16px 0px 16px",
}));

const Caption = () => {
  return (
    <Frame>
      <Typography
        variant="bodyEmphasis"
        color={"#3D4A47"}
        alignSelf={"stretch"}
      >
        Pick any topic or just start typing
      </Typography>
    </Frame>
  );
};

export default Caption;
