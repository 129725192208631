import { page2template, page3template } from "./templates";

const page1 = {
  title: "Your kid is going to nursery school",
  caption: "which will free up your expenses of £500/month from your end.",
  description:
    "I have options to distribute these money, making sooner to achieve my financial goals! Hooray!",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/children-1.jpeg",
  },
};

const page2 = {
  ...page2template,
};

const page3 = {
  ...page3template,
};

const options = [
  {
    type: "JuniorIsaAccount",
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-1.png",
    },
    caption: "Contribute to a Junior ISA for your child",
    alt: "Contribute to a Junior stocks and shares ISA for your child",
    growth: "8%",
    rate: "Growth per year",
    risk: "low to moderate",
  },
  {
    type: "IsaAccount",
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/graph-3.png",
    },
    caption: "Increase Investment Account",
    alt: "Contribute to a stocks and shares ISA for yourself",
    growth: "8%",
    rate: "Growth per year",
    risk: "low to moderate",
  },
  {
    type: "EmergencyFund",
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-1.png",
    },
    caption: "Prepare for the Unexpected",
    growth: "4%",
    rate: "Growth per year",
    risk: "low",
  },
];

const outcome = {
  title: "Well done!",
  caption: "",
};

const tipScreen = {
  content: [
    {
      type: "title",
      text: "What's my money goal now?",
    },
    {
      type: "definition",
      keyword: "Junior ISA:",
      text: "A tax-free savings/investment account for your child. Ideal for future college funds, gap year travel, etc.",
    },
    {
      type: "definition",
      keyword: "ISA:",
      text: "A tax-free savings/investment account for yourself. Perfect for increasing net worth for retirement, family, or kids.",
    },
    {
      type: "definition",
      keyword: "An emergency fund",
      text: "is your life jacket; Your financial safety net for unexpected events such as illness, keeping you afloat and avoid going into debt hole.",
    },
  ],
};

const tips = [
  tipScreen,
];

const prompt13 = {
  type: "distribution",
  page1: page1,
  page2: page2,
  page3: page3,
  options: options,
  outcome: outcome,
  tips: tips,
  colour: {
    primary: "#FFDCDC",
    secondary: "#E04B77",
  },
};

export default prompt13;
