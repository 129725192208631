import React from "react";
import { styled, IconButton, SvgIcon } from "@mui/material";

const Icon = () => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      inheritViewBox
      style={{ height: "100%", width: "auto" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.83408 0.190703C0.971239 -0.192743 0.0842762 0.677599 0.451322 1.54754L2.71767 6.91905C2.73952 6.97083 2.73952 7.02924 2.71767 7.08102L0.451322 12.4525C0.084276 13.3225 0.971239 14.1928 1.83408 13.8094L15.0147 7.95194C15.8396 7.58537 15.8396 6.4147 15.0147 6.04813L1.83408 0.190703ZM6.03434 6.37492C5.68916 6.37492 5.40934 6.65474 5.40934 6.99992C5.40934 7.34509 5.68916 7.62492 6.03434 7.62492L8.53434 7.62492C8.87952 7.62492 9.15934 7.3451 9.15934 6.99992C9.15934 6.65474 8.87952 6.37492 8.53434 6.37492L6.03434 6.37492Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

const SendButton = styled((props) => (
  <IconButton {...props}>
    <Icon />
  </IconButton>
))(() => ({
  backgroundColor: "#FFF",
  height: "100%",
  padding: 8,
  borderRadius: 60,
}));

export default SendButton;
