// investment contributing timing types
const BEGINNING_OF_PERIOD = 1;
const END_OF_PERIOD = 0;

// real estate mortgage types
const REPAYMENT = 1;
const INTEREST_ONLY = 0;

const calculateFutureValue = (
  futureValue,
  contributionAmount,
  contributionTiming,
  contributionPeriods,
  interestRate
) => {
  const timingFactor =
    contributionTiming === BEGINNING_OF_PERIOD ? 1 + interestRate : 1;

  // Future value of initial investment after contribution years
  futureValue *= Math.pow(1 + interestRate, contributionPeriods);

  // Calculate the compound factor for the payments
  const compoundFactor =
    (Math.pow(1 + interestRate, contributionPeriods) - 1) / interestRate;

  // Adjust payments according to timing
  const adjustedContribution = contributionAmount * timingFactor;

  // Calculate the future value of the contributions
  futureValue += adjustedContribution * compoundFactor;
  return futureValue;
};

const calculateFutureValueUntilRetirement = (
  startingAge, // The age at which the individual starts contributing
  targetRetirementAge, // The age at which the individual plans to retire
  yearsUntilRetirement, // Number of years until retirement

  initialInvestment, // Present value or initial amount invested
  annualContribution, // Amount contributed each year
  annualInterestRate, // Interest rate applied to the investment

  contributionTiming = END_OF_PERIOD // Timing of the contribution, default is end of the period
) => {
  // Validate the input parameters to ensure they are within expected bounds
  if (
    yearsUntilRetirement < 0 ||
    startingAge < 0 ||
    targetRetirementAge < startingAge
  ) {
    console.error("Invalid input parameters");
    return 0;
  }

  let futureValue = initialInvestment; // start with the current value

  // calculate the numer of periods with contributions made
  const contributionYears = Math.max(
    0,
    Math.min(targetRetirementAge - startingAge, yearsUntilRetirement)
  );

  // First phase: Calculate the future value with active contributions
  if (annualInterestRate !== 0) {
    futureValue = calculateFutureValue(
      futureValue,
      annualContribution,
      contributionTiming,
      contributionYears,
      annualInterestRate
    );
  } else {
    // Simple accumulation if the interest rate is 0
    futureValue += annualContribution * contributionYears;
  }

  // Second phase: Calculate future value after contributions have stopped
  const remainingPeriods = yearsUntilRetirement - contributionYears;
  if (remainingPeriods > 0) {
    futureValue *= Math.pow(1 + annualInterestRate, remainingPeriods);
  }

  // Return the future value, ensuring a positive number
  const numericFutureValue = Number(futureValue);
  return Math.abs(numericFutureValue);
};

const calculateRealEstateFutureValue = (
  startingAge, // The age at which the individual starts contributing
  targetRetirementAge, // The age at which the individual plans to retire
  yearsUntilRetirement, // Number of months until retirement

  currentPropertyValue,
  annualAppreciationRate,
  monthlyPayment,
  monthlyOverpayment,

  remainingMortgage,
  annualInterestRate, // Interest rate applied to the investment
  mortgageType = REPAYMENT
) => {
  const MONTHS = 12;
  const monthlyInterestRate = Number(annualInterestRate) / MONTHS;
  const yearsUntil = Math.max(
    0,
    Math.min(targetRetirementAge - startingAge, yearsUntilRetirement)
  );

  let futureMortgageValue = Number(remainingMortgage);
  let futurePropertyValue = Number(currentPropertyValue);

  for (let year = 1; year <= yearsUntil; year++) {
    // Apply property value appreciation
    futurePropertyValue *= 1 + annualAppreciationRate;

    if (futureMortgageValue <= 0) continue;

    for (let month = 1; month <= MONTHS; month++) {
      const monthInterest = futureMortgageValue * monthlyInterestRate;

      if (mortgageType === INTEREST_ONLY) {
        // Add any unpaid interest to the mortgage balance
        futureMortgageValue += Math.max(
          monthInterest - Number(monthlyPayment),
          0
        );
        // Apply overpayment to reduce mortgage principal
        futureMortgageValue -= Number(monthlyOverpayment);
      } else if (mortgageType === REPAYMENT) {
        // Calculate principal reduction
        futureMortgageValue -= Number(monthlyPayment) - monthInterest;
        // Further reduce principal with overpayment
        futureMortgageValue -= Number(monthlyOverpayment);
      }
      if (futureMortgageValue < 0) {
        futureMortgageValue = 0;
        break;
      }
    }

    if (yearsUntil === 5) {
      // console.log(`value at the end of year ${year}`);
      // console.log({
      //   futurePropertyValue,
      //   futureMortgageValue,
      //   userNetWorth: futurePropertyValue - futureMortgageValue,
      // });
    }
  }

  return Math.max(futurePropertyValue - futureMortgageValue, 0);
};

export {
  calculateFutureValueUntilRetirement,
  calculateRealEstateFutureValue,
  BEGINNING_OF_PERIOD,
  END_OF_PERIOD,
  REPAYMENT,
  INTEREST_ONLY,
};
