import financialProducts from "../financialProducts/financialProducts";

const page1 = {
    content: [
        {
            type: 'title',
            text: 'An ISA is a savings or investment account that you don\'t pay tax on.'
        },
        {
            type: 'paragraph',
            text: 'Every year, you\'re given an ISA allowance, which is currently set at £20k. The tax year goes on for 12 months, starting from April 6 and ending on April 5 of the next year.'
        },
        {
            type: 'paragraph',
            text: 'You pay no tax on the money you earn in interest, dividends or capital gains within your ISAs.'
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/isa-icons.svg",
        style: {
            objectFit: "contain",
        },
    },
};

const page2 = {
    content: [
        {
            type: 'title',
            text: 'What\'s ISA'
        },
        {
            type: 'definition',
            keyword: 'LISA (Lifetime ISA):',
            text: 'For first-time homebuyers or retirement savings, offering a 25% government bonus on contributions.'
        },
        {
            type: 'definition',
            keyword: 'Stocks and Shares ISA:',
            text: 'Allows tax-free investing in stocks, bonds, and funds, ideal for growing wealth through the stock market.'
        },
        {
            type: 'definition',
            keyword: 'Cash ISA:',
            text: 'Tax-free savings account for low-risk saving, providing tax advantages on earned interest.'
        },
        {
            type: 'definition',
            keyword: 'Innovative Finance ISA (IFISA):',
            text: 'Enables tax-free investing in peer-to-peer loans, offering diversification beyond traditional savings and stocks.'
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/isa-icons.svg",
        style: {
            objectFit: "contain",
        },
    },
};

const page3 = {
    content: [
        {
            type: 'title',
            text: 'How much should I put in an ISA account?'
        },
        {
            type: 'paragraph',
            text: 'Let\'s say you contribute your entire £500 monthly budget to your Stocks and Shares ISA account since you are 35. Assuming an approximate annual growth of 8%, by the time you retire at 60, that will grow to almost £500k, tax-free!'
        },
        {
            type: 'paragraph',
            text: 'If you contribute for 10 years and then stop, by the time you retire, it will grow to almost £306k, tax-free!'
        },
        {
            type: 'paragraph',
            text: 'Let money and time do their magic.'
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/graph-2.png",
        style: {
            objectFit: "contain",
        },
    },
};

const isaAccount = {
    ...financialProducts['IsaAccount'],
    content: {
        page1: page1,
        page2: page2,
        page3: page3
    },
};

export default isaAccount;
