import React from "react";
import {
  styled,
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Box,
} from "@mui/material";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "#FFF", // should be UI-50, which is #FBFBFB
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  minHeight: `min(${96}px, ${11}vh)`,
  padding: "32px 16px 16px 16px",
  alignItems: "flex-end",
  alignSelf: "stretch",
  justifyContent: "space-between",
}));

const Container = ({ children }) => {
  return (
    <AppBar>
      <Toolbar>{children}</Toolbar>
    </AppBar>
  );
};

const OldContainer = styled(Box)(() => ({
  display: "flex",
  backgroundColor: "#FFF", // should be UI-50, which is #FBFBFB
  minHeight: `min(${96}px, ${11}vh)`,
  padding: "32px 16px 16px 16px",
  alignItems: "flex-end",
  alignSelf: "stretch",
  justifyContent: "space-between",
  position: "sticky",
}));

export default Container;
