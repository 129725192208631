import React from "react";
import { Typography } from "@mui/material";

const Title = (props) => {
  return (
    <Typography
      fontSize={{
        xs: "clamp(1.625rem, 0.6992rem + 3.112vi, 2.25rem)",
        md: "clamp(2.25rem, 0.6992rem + 3.112vi, 3.5rem)",
      }}
      lineHeight={{
        xs: "clamp(1.95rem, 0.5674rem + 4.6473vw, 3.375rem)",
        md: "clamp(3.375rem, 0.5674rem + 4.6473vw, 4.75rem)",
      }}
      fontWeight={700}
      {...props}
    >
      {props.children}
    </Typography>
  );
};

export default Title;
