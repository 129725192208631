import { useAuthContext } from "@hooks";
import {
  findFinancialProductByTitle,
  getChatHistory,
} from "@services/chatService";
import { createContext, useEffect, useReducer, useState } from "react";

const ChatContext = createContext();

const ADD_MESSAGE = "ADD_MESSAGE";
const SET_HISTORY = "SET_HISTORY";
const INCREMENT_PROMOTION_COUNTER = "INCREMENT_PROMOTION_COUNTER";
const RESET_PROMOTION_COUNTER = "RESET_PROMOTION_COUNTER";

const initialState = {
  history: [],
  promotionCounter: 0,
};

const chatReducer = (state, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        ...state,
        history: [
          ...state.history,
          { ...action.payload, datetime: Date.now() },
        ],
      };
    case SET_HISTORY:
      return { ...state, history: action.payload };
    case INCREMENT_PROMOTION_COUNTER:
      return { ...state, promotionCounter: state.promotionCounter + 1 };
    case RESET_PROMOTION_COUNTER:
      return { ...state, promotionCounter: 0 };
    default:
      return state;
  }
};

const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const { email, isSignedIn } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const addMessage = (message) => {
    dispatch({ type: ADD_MESSAGE, payload: message });

    if (
      message.responses?.some((response) =>
        findFinancialProductByTitle(response.title)
      )
    ) {
      dispatch({ type: INCREMENT_PROMOTION_COUNTER });
    }
  };

  const setHistory = (messages) =>
    dispatch({ type: SET_HISTORY, payload: messages });

  const resetPromotionCounter = () =>
    dispatch({ type: RESET_PROMOTION_COUNTER });

  useEffect(() => {
    const fetchChatHistory = async () => {
      if (isSignedIn && email) {
        setLoading(true);
        try {
          const history = await getChatHistory(email);
          setHistory(history);
        } catch (error) {
          console.error("Failed to fetch chat history:", error);
        } finally {
          setLoading(false); // stop loading once history has been fetched
        }
      }
    };

    fetchChatHistory();
  }, [isSignedIn, email]);

  const value = {
    ...state,
    addMessage,
    setHistory,
    loading,
    loadingResponse,
    setLoadingResponse,
    errorOccurred,
    setErrorOccurred,
    resetPromotionCounter,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export default ChatContext;
export { ChatProvider };
