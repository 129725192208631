import React from "react";
import { Box, Typography } from "@mui/material";

const Logo = () => {
  return (
    <Box
      component={"img"}
      src="assets/logos/logo-black.svg"
      marginTop={"5.625vh"}
      width={"194px"}
    />
  );
};

const Image = () => {
  return (
    <Box
      component={"img"}
      src="assets/images/AdobeStock_654813754_Thank-You.png"
      width={"26.80vw"}
    />
  );
};

const Text = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      // alignItems={"flex-end"}
      gap={"32px"}
    >
      <Typography
        textAlign={"center"}
        fontSize={"28px"}
        lineHeight={"42px"}
        fontWeight={700}
      >
        Your data is confidential <br />
        and secure with us.
      </Typography>
      <Typography textAlign={"center"} fontSize={"15px"} lineHeight={"25px"}>
        We're now creating a customised financial <br />
        dashboard tailored to you.
      </Typography>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"12px"}
        paddingX={"48px"}
        paddingY={"15px"}
        minWidth={"120px"}
        alignSelf={"stretch"}
      >
        <Box component={"img"} src="assets/images/loading-dots.gif" />
        <Typography
          textAlign={"center"}
          fontSize={"15px"}
          lineHeight={"12.512px"}
        >
          Creating Projection
        </Typography>
      </Box>
    </Box>
  );
};

const ThankYouScreen = () => {
  return (
    <Box
      backgroundColor={"#FFF"}
      height={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      flexShrink={0}
    >
      <Logo />
      <Box
        display={"inline-flex"}
        position={"absolute"}
        top={"14.68vh"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"32px"}
      >
        <Image />
        <Text />
      </Box>
    </Box>
  );
};

export default ThankYouScreen;
