import React from "react";
import { Box, Typography, styled } from "@mui/material";
import Button from "@ui-library/Button";
import { fontWeights } from "@themes";

const Container = styled(Box)(() => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: 24,
    // padding: "72px 24px 0px 24px",
    paddingTop: 40,
}));

const Frame = styled(Box)(() => ({
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: 12,
    textAlign: "center",
}));

const Title = ({ children }) => (
    <Typography variant="h4">{children}</Typography>
);

const Caption = ({ children }) => (
    // #3D4A47 --UI-800
    <Typography variant="bodyL" color="#3D4A47" padding="0 40px">
        {children}
    </Typography>
);

const TickIcon = ({ width = 40, height = 40, color = "#168263" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 40 40"
        fill="none"
    >
        <path
            d="M19.9994 1.66699C16.3734 1.66699 12.8288 2.74222 9.8139 4.75672C6.799 6.77121 4.44917 9.63448 3.06157 12.9845C1.67396 16.3344 1.3109 20.0207 2.01829 23.577C2.72569 27.1333 4.47177 30.4 7.03573 32.964C9.5997 35.5279 12.8664 37.274 16.4227 37.9814C19.979 38.6888 23.6652 38.3257 27.0152 36.9381C30.3652 35.5505 33.2285 33.2007 35.243 30.1858C37.2575 27.1709 38.3327 23.6263 38.3327 20.0003C38.3327 15.138 36.4011 10.4749 32.963 7.0367C29.5248 3.59853 24.8617 1.66699 19.9994 1.66699ZM29.511 16.1787L17.8444 27.8453C17.5318 28.1578 17.108 28.3333 16.666 28.3333C16.2241 28.3333 15.8002 28.1578 15.4877 27.8453L10.4877 22.8453C10.1841 22.531 10.0161 22.11 10.0199 21.673C10.0237 21.236 10.199 20.818 10.508 20.509C10.817 20.1999 11.235 20.0247 11.672 20.0209C12.109 20.0171 12.53 20.1851 12.8444 20.4887L16.666 24.3103L27.1544 13.822C27.4687 13.5184 27.8897 13.3504 28.3267 13.3542C28.7637 13.358 29.1817 13.5333 29.4907 13.8423C29.7997 14.1513 29.975 14.5693 29.9788 15.0063C29.9826 15.4433 29.8146 15.8643 29.511 16.1787Z"
            fill={color}
        />
    </svg>
);

const ConfirmationMessage = ({ title, caption }) => (
    <Frame>
        <Title>{title}</Title>
        <Caption>{caption}</Caption>
    </Frame>
);

const AddToCalendar = (props) => (
    <Button
        type="secondary"
        size="small"
        sx={{
            border: "1px solid #144235",
            color: "#144235",
            fontWeight:
            fontWeights.MEDIUM,
            height: 36,
        }}
        {...props}
    >
        Add to calendar
    </Button>
);

const WorkshopBooked = () => (
    <Container>
        <TickIcon />
        <ConfirmationMessage
            title="Booking confirmed"
            caption="We will email you all the details and the zoom link shortly"
        />
        <AddToCalendar onClick={() => {}} />
    </Container>
);

export default WorkshopBooked;
