import blueChipStocks from './blueChipStocks';
import emergencyFund from './emergencyFund';
import indexFund from './indexFund';
import instantSavingsAccount from './instantSavingsAccount';
import isaAccount from './isaAccount';
import juniorIsaAccount from './juniorIsaAccount';

// Insight Format: { title, icon, content, colour }
// Content Format: { page1, page2, page3 }
// Page content types: 'title', 'paragraph', 'definition', 'list'
// Colour Format: { title, icon, background }

const insightsSet = [
    instantSavingsAccount,
    indexFund,
    blueChipStocks,
    emergencyFund,
    juniorIsaAccount,
    isaAccount
];

export default insightsSet;
