import React from "react";
import { Box, styled } from "@mui/material";
import { Subtitle, Title } from "../textStyles";

const Text = styled(({ title, subtitle, color, ...props }) => (
  <Box {...props}>
    <Title color={color}>{title}</Title>
    <Subtitle color={color}>{subtitle}</Subtitle>
  </Box>
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    padding: "40px",
    gap: "32px",
    alignItems: "flex-start",
  },
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
  },
}));
 
export default Text;
