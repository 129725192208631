import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import GameWelcome from './GameOnboarding/GameWelcome';
import GameOnboarding from './GameOnboarding/GameOnboarding';
import GameMap from './GameMap/GameMap';
import PromptFinish from './Prompts/promptTypes/PromptFinish';
import './styles.css';

const Game = () => {
    return (
        <Routes>
            <Route path='' element={<GameWelcome />} />
            <Route path='onboarding' element={<GameOnboarding />} />
            <Route path='wealth-quest' element={<GameMap />} />
            <Route path='over' element={<PromptFinish />} />
            <Route path="*" element={<Navigate to="/game" replace={true} />} />
        </Routes>
    )
}

export default Game;
