// SignOutButton.js
import React from "react";
import { useAuthContext } from "@hooks";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Button from "@ui-library/Button";

const SignOut = () => {
  const { setIsSignedIn, setGivenName } = useAuthContext();
  const navigate = useNavigate(); // Initialize useNavigate

  const signOutGoogle = () => {
    if (window.google) {
      const auth2 = window.google.accounts.id;
      auth2.disableAutoSelect();
      setIsSignedIn(false);
      setGivenName("");
      //console.log("Google user signed out.");
    }
  };

  const signOutLinkedIn = () => {
    // Invalidate the LinkedIn access token (if you have stored it)
    // Note: This does not log the user out of LinkedIn, but invalidates the token used in your app
    // Implement the token invalidation logic here
    //console.log("LinkedIn user signed out.");
  };

  const signOut = () => {
    signOutGoogle();
    signOutLinkedIn();
    // Any additional sign out logic
    navigate("/"); // Redirect to the login page
  };

  return (
    <Button
      onClick={signOut}
      type={"secondary"}
      size={"small"}
      sx={{
        // backgroundColor: "#FF0",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Sign Out
    </Button>
  );
};

export default SignOut;
