import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FinancialProductCard } from '@components/financialproducts';
import { FinancialTipCard } from '@components/financialtips';
import PromotionCard from '@components/promotioncards/PromotionCard';
import { SessionCard, WorkshopCard } from "@components/workshops";
import {
    financialProducts,
    productCategories,
} from "@content/financialProducts";
import financialTips from "@content/financialTips";
import promotionOptions from '@content/promotionOptions';
import workshops from "@content/workshops";
import { getComponentPath } from '@lib/learn';

const LearnContext = createContext();

const LearnProvider = ({ children }) => {
    const navigate = useNavigate();

    // Returns a map of string (name) to FinancialProductCard
    const getFinancialProducts = () => (Object.fromEntries(Object
        .entries(financialProducts)
        .map(([id, product]) => {
            const category = productCategories[product.category];
            const path = `/learn/products/${getComponentPath(
                category.title,
                product.title,
            )}`;
            return [
                id,
                <FinancialProductCard
                    key={id}
                    {...product}
                    image={category.image}
                    color={category.color}
                    icon={category.icon}
                    onClick={() => navigate(path)}
                />,
            ];
        })
    ));

    // Returns an array of FinancialProductCard components
    const getRandomFinancialProducts = (numProducts = 8) => {
        const allFinancialProducts = getFinancialProducts();

        // Selects n random elements of the given array
        const selectedProducts = ((array, n) => {
            const arrayCopy = array.slice();
            // Shuffles the given array
            for (let i = arrayCopy.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
            };
            // Selects the first n elements (or all if n > array.length)
            return arrayCopy.slice(0, n > array.length ? array.length : n);
        })(Object.values(allFinancialProducts), numProducts);

        return selectedProducts;
    };

    // Returns an array of FinancialTipCard components
    const getFinancialTips = (sx = {}) => (Object
        .entries(financialTips)
        .map(([id, tip]) => {
            const path = `/learn/tips/${getComponentPath(tip.topic)}`;
            return (
                <FinancialTipCard
                    key={id}
                    {...tip}
                    sx={sx}
                    onClick={() => navigate(path)}
                />
            );
        })
    );

    const getSessions = (sx = {}) => (Object
        // Temporarily uses workshops
        .entries(workshops)
        .map(([id, session]) => (
            <SessionCard
                key={id}
                {...session}
                sx={sx}
            />
        ))
    );

    const getSessionPromotion = (sx = {}) => (
        <PromotionCard {...promotionOptions.workshop} left={false}>
            Use promo code WINVEST for 50% off your first call
        </PromotionCard>
    );

    // Returns an array of WorkshopCard components
    const getWorkshops = (sx = {}) => (Object
        .entries(workshops)
        .map(([id, workshop]) => {
            const path = `/learn/workshops/${getComponentPath(workshop.topic)}`;
            return (
                <WorkshopCard
                    key={id}
                    {...workshop}
                    sx={sx}
                    onClick={() => navigate(path)}
                />
            );
        })
    );
    
    const contextValue = {
        getComponentPath,
        getFinancialProducts,
        getRandomFinancialProducts,
        getFinancialTips,
        getSessionPromotion,
        getSessions,
        getWorkshops,
        navigate,
    };

    return (
        <LearnContext.Provider value={contextValue}>
            {children}
        </LearnContext.Provider>
    );
};

export default LearnContext;
export { LearnProvider };
