import React from "react";
import { Box, styled } from "@mui/material";
import { PageContainer, PageHeader, PageSection } from "@features/learn";
import { productCategories } from "@content/financialProducts";
import { useLearnContext } from "@hooks";

const PageContent = styled(Box)(() => ({
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: "64px",
    // paddingBottom: "80px", // Removed when bottom navigation added
}));

const FinancialProductsPage = () => {
    const { getFinancialProducts } = useLearnContext();
    const header = {
        title: "Financial Products",
        caption: "Explore the Variety of Financial Tools available to Empower Your Growth.",
    };
    const financialProducts = getFinancialProducts();
    const pageSections = Object.values(productCategories);

    return (
        <PageContainer>
            <PageHeader variant="h2" {...header} />
            <PageContent>
                {pageSections.map((section, sectionIndex) => (
                    <PageSection
                        key={sectionIndex}
                        variant="h5"
                        title={section.title}
                        sx={{ gap: "8px" }}
                    >
                        {section.products.map((product) => (
                            financialProducts[product]
                        ))}
                    </PageSection>
                ))}
            </PageContent>
        </PageContainer>
    );
};

export default FinancialProductsPage;
