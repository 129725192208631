import React, { useState } from 'react';
import { ConfirmButton, SelectButton, SkipButton } from './ChoiceButton';
import RiskLevelIndicator from './RiskLevels';
import ProductIcon from '../../components/ProductIcon';
import financialProducts from '../../content/financialProducts/financialProducts';
import Outcome from './Outcome';
import { createChanges } from 'src/game/promptCalculations';
import { Dictionary } from 'src/game/components/FinancialGuide';

const ProductTitle = ({ type, setDictionary }) => {
    const { title, colour } = financialProducts[type];

    return (
        <div
            className='product-option-title'
            style={{ backgroundColor: colour ? colour.title : null }}
            onClick={() => setDictionary(<Dictionary setDictionary={setDictionary} />)}
        >
            <ProductIcon icon={type} colour={colour ? colour.icon : null} />
            <p style={{ color: colour ? colour.icon : null }}>{title}</p>
        </div>
    );
}

const OptionImage = ({ src, alt = '', style = {} }) => {
    return (
        src && 
            <div className='product-option-image'>
                <img src={src} alt={alt} style={style} />
            </div>       
    );
}

// Caption and growth rate for a given option
const OptionDetails = ({ caption, growth, rate }) => {
    return (
        <>
            <h3>{caption}</h3>
            <p><span className='text-highlight-dark'>{growth}</span> {rate}</p>
        </>
    );
}

// Used in ProductSelection to represent an option to select
const OptionCard = (props) => {
    const { option, selected, onSelect, setRiskLevels, setDictionary, onNext } = props;
    const { type, image, caption, growth, rate, risk } = option;

    return (
        <div className='product-option'>
            <ProductTitle type={type} setDictionary={setDictionary} />
            <OptionImage {...image} />
            <div className='product-option-text'>
                <OptionDetails caption={caption} growth={growth} rate={rate} />
                <RiskLevelIndicator risk={risk} setRiskLevels={setRiskLevels} />
            </div>
            <SelectButton selected={selected} onSelect={onSelect} onNext={onNext} />
        </div>
    );
}

// Used in ProductDistribution to allow a ratio input for a selected option
const RatioCard = (props) => {
    const { index, type, alt, growth, rate, risk, setRiskLevels, onChange } = props;
    const { title } = financialProducts[type];

    return (
        <div className='product-ratio'>
            <div className='product-ratio-text'>
                <OptionDetails
                    caption={alt ? alt : title}
                    growth={growth}
                    rate={rate}
                />
                <RiskLevelIndicator
                    risk={risk}
                    setRiskLevels={setRiskLevels}
                />
            </div>
            {/* text input */}
            <div className='product-ratio-input text-input'>
                <label htmlFor={`ratio-${index}`}>Ratio %</label><br />
                <input
                    type='text'
                    id={`ratio-${index}`}
                    name={`ratio-${index}`}
                    onChange={onChange(index)}
                /><br />
            </div>
        </div>
    );
}

// Main component for second page of PromptDistribution
const ProductSelection = (props) => {
    const { options, setSelectedOptions, setPrompt, onNext, setRiskLevels, setDictionary } = props;
    const [button, setButton] = useState(1);
    // Indicates which options have been selected
    const [clickedStates, setClickedStates] = useState([false, false, false]);

    // Updates clicked state for the selected option
    const handleSelect = (index) => {
        const newClickedStates = [...clickedStates];
        newClickedStates[index] = !newClickedStates[index];
        setClickedStates(newClickedStates);
        // If at least one is selected, display 'Confirm Selection' button
        setButton(newClickedStates.includes(true) ? 2 : 1);
    };

    // Stores the indices of the selected options in selectedOptions state
    const onConfirm = () => {
        const selectedOptions = clickedStates.reduce((acc, selected, index) => {
            if (selected) {
                acc.push(index);
            }
            return acc;
        }, []);
        setSelectedOptions(selectedOptions);
        onNext();
    };

    const buttons = [
        <SkipButton setPrompt={setPrompt} style={{ color: 'black' }} />,
        <ConfirmButton onConfirm={onConfirm} />
    ];

    return (
        <>
            <div className='product-options-wrapper'>
                <div className='product-options'>
                    {options.map((option, index) => (
                        <React.Fragment key={index}>
                            <OptionCard
                                option={option}
                                selected={clickedStates[index]}
                                onSelect={() => handleSelect(index)}
                                setRiskLevels={setRiskLevels}
                                setDictionary={setDictionary}
                                onNext={onNext}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {buttons[button - 1]}
        </>
    );
}

// Main component for third page of PromptDistribution
const ProductDistribution = (props) => {
    const { amount, frequency, options, outcome, selectedOptions, setPrompt, setRiskLevels, setOutcome, inputs, setInputs } = props;
    // const [inputs, setInputs] = useState([0, 0, 0]);
    const [isValid, setIsValid] = useState(true);

    const handleInputChange = (index) => (event) => {
        // index -> index of selectedOption
        // selectedOption[index] -> index of option
        const optionIndex = selectedOptions[index];
        const newInputs = [...inputs];
        newInputs[optionIndex] = event.target.value;
        setInputs(newInputs);
    };

    const onConfirm = () => {
        let confirm = false;
        // handle inputs
        // convert to numbers, if all inputs are valid, confirm = true
        const numInputs = inputs.map((input, index) => Number(input));
        if (numInputs.every(value => !isNaN(value) && value >= 0)) {
            let sum = numInputs.reduce((acc, value) => acc + value, 0);
            confirm = sum <= 100;
            setIsValid(confirm);
        } else {
            setIsValid(false);
        }

        if (confirm) {
            console.log(options);
            console.log(inputs);
            createChanges(amount, frequency, options, selectedOptions, inputs);
            setOutcome(<Outcome {...outcome} setPrompt={setPrompt} />);
        }
    };
    
    return (
        <>
            <div className='product-ratios'>
                {selectedOptions.map((optionIndex, index) => (
                    <React.Fragment key={index}>
                        <RatioCard
                            index={index}
                            {...options[optionIndex]}
                            setRiskLevels={setRiskLevels}
                            onChange={handleInputChange}
                        />
                    </React.Fragment>
                ))}
            </div>
            <ConfirmButton onConfirm={onConfirm} />
            {!isValid && 
                <p
                    className='invalid-input'
                >Invalid input. Ensure inputs are numbers, and sum to less than or equal to 100.</p>}
        </>
    );
}

export { ProductDistribution, ProductSelection };
