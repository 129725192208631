import React from "react";
import { Box, styled, useMediaQuery } from "@mui/material";
import {
    PageContainer,
    PageHeader,
    WorkshopTypeSelector,
} from "@features/learn";
import { useLearnContext } from "@hooks";

const PageContent = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isLessThan768px"
})(({ isLessThan768px }) => ({
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: isLessThan768px ? "column" : "row",
    flexWrap: "wrap",
    gap: "16px",
    padding: "0 16px",
}));

const WorkshopsPage = () => {
    const { getWorkshops } = useLearnContext();
    const isLessThan768px = useMediaQuery("(max-width: 768px)");
    const header = {
        title: "Upcoming Workshops",
        caption: "Choose a topic and time for WInvests' Group workshops.",
    };
    const cardStyles = { alignSelf: "stretch", width: "auto" };
    const workshops = getWorkshops(cardStyles);

    return (
        <PageContainer sx={{ gap: "24px" }}>
            <PageHeader variant="h2" {...header}>
                <WorkshopTypeSelector selected="group" />
            </PageHeader>
            <PageContent isLessThan768px={isLessThan768px}>
                {workshops}
            </PageContent>
        </PageContainer>
    );
};

export default WorkshopsPage;
