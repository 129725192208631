import React from "react";
import { SvgIcon } from "@mui/material";

const GrowthChartIcon = ({ color = "white" }) => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M7.50141 15.2495C7.31141 15.2495 7.12141 15.1795 6.97141 15.0295C6.68141 14.7395 6.68141 14.2595 6.97141 13.9695L10.1714 10.7695C10.3314 10.6095 10.5414 10.5295 10.7714 10.5495C10.9914 10.5695 11.1914 10.6895 11.3214 10.8795L12.4114 12.5195L15.9614 8.96945C16.2514 8.67945 16.7314 8.67945 17.0214 8.96945C17.3114 9.25945 17.3114 9.73945 17.0214 10.0295L12.8214 14.2295C12.6614 14.3895 12.4514 14.4695 12.2214 14.4495C12.0014 14.4295 11.8014 14.3095 11.6714 14.1195L10.5814 12.4795L8.03141 15.0295C7.88141 15.1795 7.69141 15.2495 7.50141 15.2495Z"
                fill={color}
            />
            <path
                d="M16.5 12.25C16.09 12.25 15.75 11.91 15.75 11.5V10.25H14.5C14.09 10.25 13.75 9.91 13.75 9.5C13.75 9.09 14.09 8.75 14.5 8.75H16.5C16.91 8.75 17.25 9.09 17.25 9.5V11.5C17.25 11.91 16.91 12.25 16.5 12.25Z"
                fill={color}
            />
            <path
                d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
                fill={color}
            />
        </svg>
    </SvgIcon>
);

export default GrowthChartIcon;
