const page = {
    title: "You retire from your planned retirement age!",
    description: "Would you like to:",
    choices: [
        {
            choice: "Play Again",
        },
        {
            choice: "Check Your Net Worth",
        },
        {
            choice: "See How You Compare to Others",
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-5.jpeg",
    },
};

const promptFinish = {
    type: "finish",
    page: page,
    colour: {
        primary: "#FFDCDC",
        secondary: "#E04B77",
    },
};

export default promptFinish;
