const title = {
    type: "title",
    text: "GIAs are like your favourite store - you can pick and choose your investments without any limits.",
};

const description = {
    type: "body",
    text: "Unlike ISAs, GIAs aren't tax-free, but they're perfect for when you've maxed out your £20,000 ISA allowance and still want to keep investing.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Ideal for savvy investors who want flexibility and control over a wide range of investments. You can invest in stocks, bonds, funds, and more. No limits on how much you can invest, making it perfect for diversifying your portfolio.",
        },
    ],
};

const growth = {
    type: "section",
    heading: "Growth",
    body: [
        {
            type: "body",
            text: "Varies widely based on your investment choices, typically 4% to 12% per year.",
        },
    ],
};

const risk = {
    type: "section",
    heading: "Risk",
    body: [
        {
            type: "body",
            text: "High, as the performance depends on your investment decisions.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Interactive Investor GIA",
            subheading: "Average annual return of 7% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available online through the Interactive Investor website and mobile app.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Hargreaves Lansdown GIA",
            subheading: "Average annual return of 7.5% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available online through the Hargreaves Lansdown website and mobile app.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "AJ Bell Youinvest GIA",
            subheading: "Average annual return of 7% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available online through the AJ Bell website and mobile app.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you've maxed out your ISA allowance and still want to invest, a GIA is your next best friend. It's your platform to keep rocking the investment world without limits.",
        },
    ],
};

const example = {
    type: "section",
    heading: "Example",
    body: [
        {
            type: "section",
            subheading: "Hargreaves Lansdown General Investment Account",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Interest Rate: Varies based on investment performance, typically 4-12% per year.",
                        },
                    ],
                },
            ],
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Easily accessible online through provider websites and mobile apps. No tax-free perks, but perfect for continued investment growth beyond ISAs.",
        },
    ],
};

const generalInvestmentAccount = [
    title,
    description,
    benefits,
    growth,
    risk,
    providersExamples,
    greatFor,
    example,
    accessibility,
];

export default generalInvestmentAccount;
