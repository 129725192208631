import React, { useState } from 'react';
import MyNetWorth from '../../components/MyNetWorth';
import NeedAdvice from './NeedAdvice';
import { ContinueSkipCard, MultipleChoiceCard } from './ChoiceCard';
import { ProductDistribution, ProductSelection } from './ProductDistribution';
import Outcome from './Outcome';
import { addChanges } from '../../promptCalculations';

const PromptImage = ({ src, alt, style }) => {
    return (
        <>
            {src && 
                <div className='prompt-image'>
                    <img src={src} alt={alt ? alt : ''} style={style ? style : {}} />
                </div>
            }
        </>
    );
}

const PromptHeader = (props) => {
    const { title, caption } = props;

    return (
        <div className='prompt-header'>
            <h2>{title}</h2>
            {caption ? <p>{caption}</p> : <></>}
        </div>
    );
}

const PromptFooter = (props) => {
    const { tip = 1, tips = [] } = props;

    // Selects which tip to display on this particular page
    // tip has default value of 1, so if there is one tip per (entire) prompt,
    // then tips[tip - 1], i.e. tips[0], should exist
    // Otherwise, undefined since NeedAdvice should not be displayed
    const tipScreen = tips[tip - 1] ? tips[tip - 1] : undefined;

    return (
        <div className='prompt-footer-wrapper'>
            <div className='prompt-footer'>
                <MyNetWorth />
                {tipScreen && <NeedAdvice {...tipScreen} />}
            </div>
        </div>
    );
}

const MultipleChoice = (props) => {
    const { choices, outcomes, changes, setOutcome, setPrompt, onNext } = props;

    // Handles the behaviour when a choice is made.
    // Parameter `choiceIndex` is the index of the choice in `choices`
    const showOutcome = (choiceIndex) => {
        const outcome = outcomes[choices[choiceIndex].outcome];
        const { type = 'success' } = outcome;
        // If outcome.type is 'next-page', apply onNext when a choice is made.
        // Otherwise, apply setOutcome as default.
        if (type === 'next-page') {
            onNext();
        } else {
            addChanges(changes, outcome.change);
            setOutcome(<Outcome {...outcome} setPrompt={setPrompt} />);
        }
    }

    return (
        <MultipleChoiceCard {...props} onChoice={showOutcome} />
    );
}

// props passed to PromptPage should only be relevant to the content of the
// particular page being rendered, i.e. pass title, caption, etc.
// pageType takes values 'description', 'choice', 'product-selection', and
// 'product-distribution'.
const PromptPage = (props) => {
    // props also includes: setPrompt, onNext, etc.
    const { pageType, title, caption, image, includeFooter = true, tip, tips } = props;
    
    // Displays risk levels for ProductSelect and ProductDistribution
    // Displays the financial library for ProductSelect
    // Displays outcomes for prompts (MultipleChoice, ProductDistribution)
    const [popup, setPopup] = useState(null);

    // Represents the part of a page that differs between page types
    const pageContent = (() => {
        switch (pageType) {
            case 'description':
                return (
                    <ContinueSkipCard {...props} />
                );
            case 'choice':
                return (
                    <MultipleChoice {...props} setOutcome={setPopup} />
                );
            case 'product-selection':
                return (
                    <ProductSelection
                        {...props}
                        setRiskLevels={setPopup}
                        setDictionary={setPopup}
                    />
                );
            case 'product-distribution':
                return (
                    <ProductDistribution
                        {...props}
                        setRiskLevels={setPopup}
                        setOutcome={setPopup}
                    />
                );
            case 'finish':
                return (
                    <MultipleChoiceCard
                        {...props}
                    />
                )
            default:
                return <></>;
        }
    })();

    return (
        <>
            <PromptImage {...image} />
            <div className='prompt-page-content'>
                <PromptHeader title={title} caption={caption} />
                {pageContent}
                {includeFooter && <PromptFooter tip={tip} tips={tips} />}
            </div>
            {popup}
        </>
    );
}

export default PromptPage;
