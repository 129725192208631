import React from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useQuestionnaireContext } from "@hooks";

const SelectBar = (props) => {
  const { formData, toggleCheckbox } = useQuestionnaireContext();
  const { group, id } = props;

  return (
    <Box padding={"16px"}>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData[group].includes(id)}
            onChange={() => toggleCheckbox(group, id)}
            name={`${id}`}
            sx={{ paddingTop: 0, paddingBottom: 0 }}
          />
        }
        {...props}
      />
    </Box>
  );
};

export default SelectBar;
