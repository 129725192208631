const page1 = {
  title: "Your partner proposed to you! You're getting married!",
  caption:
    "At the moment, you both have assets in your accounts, and you've previously managed finances separately, occasionally splitting expenses.",
  description:
    "I love my partner and hope the marriage will last a lifetime. However, I want to be smart at my finances and take control of my financial Independence.",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-1.jpeg",
  },
};

const page2 = {
  title: "Your partner proposed to you! You're getting married!",
  description:
    "At the moment, you both have assets in your accounts, and you've previously managed finances separately, occasionally splitting expenses. What would You do? ",
  choices: [
    {
      choice: "Maintain Separate Finances with a Joint Account, formal prenup",
      outcome: {
        title: "Woohoo!",
        caption:
          "You now have separate your assets and liabilities from his, prior to marriage. Welcome to building new life together!",
      },
    },
    {
      choice: "Maintain Separate Finances with a Joint Account, formal prenup",
      outcome: {
        title: "Woohoo!",
        caption:
          "Your assets and liabilities remain separate from your partner's, as no prenuptial agreement was established. You're ready to embark on your journey together with individual financial autonomy.",
      },
    },
    {
      choice: "Merge Finances Completely",
      outcome: {
        title: "Woohoo!",
        caption:
          "You have combined your assets and liabilities with your partner's, embracing a unified approach to finances as you embark on your journey together. Welcome to a life of shared financial goals and responsibilities!",
      },
    },
  ],
};

const tipScreen = {
  content: [
    {
      type: "title",
      text: "Money & Marriage",
    },
    {
      type: "subtitle",
      text: "Maintain Separate Finances with a Joint Account, formal prenup:",
    },
    {
      type: "paragraph",
      text: "Pros: Retain financial autonomy while sharing expenses for bills, groceries, and vacations. Balances independence and collaboration.",
    },
    {
      type: "paragraph",
      text: "Cons: Requires clear communication and trust. Potential for disagreements over joint expenses.",
    },
    {
      type: "subtitle",
      text: "Maintain Separate Finances with a Joint Account, no prenup:",
    },
    {
      type: "paragraph",
      text: "Pros: Allows financial autonomy and shared expenses without formalities. Offers flexibility and simplicity. ",
    },
    {
      type: "paragraph",
      text: "Cons: Uncertainty in asset division and financial responsibilities in divorce. Potential for significant conflicts without legal clarity.",
    },
    {
      type: "subtitle",
      text: "Merge Finances Completely:",
    },
    {
      type: "paragraph",
      text: "Pros: Simplifies money management, consolidates assets, and promotes shared financial goals. Encourages transparency and unity.",
    },
    {
      type: "paragraph",
      text: "Cons: Requires open communication and compromise. Risk of disagreements over spending habits and financial priorities.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/money-1.jpeg",
  },
};

const tips = [
  tipScreen,
];

const prompt11 = {
  type: "choice-extended",
  page1: page1,
  page2: page2,
  tips: tips,
  colour: {
    primary: "#FFDCDC",
    secondary: "#5F32B6",
  },
};

export default prompt11;
