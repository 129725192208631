import { Box, styled, Typography, useTheme } from "@mui/material";

const Frame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
  alignSelf: "stretch",
  gap: "12px",
}));

const Title = ({ title }) => {
  const theme = useTheme();
  return (
    <Typography variant="h5" color={theme.vars.palette.investment.primary}>
      {title}
    </Typography>
  );
};

const Body = ({ body }) => {
  return (
    <Typography variant="body" color={"#B7D9CF"}>
      {body}
    </Typography>
  );
};

const Text = (props) => {
  const { title, body, ...rest } = props;

  return (
    <Frame {...rest}>
      <Title title={title} />
      <Body body={body} />
    </Frame>
  );
};

export default Text;
export { Body };
