import Chip from "@ui-library/Chip";

const workshop = {
  tag: "1 : 1 SESSION",
  title: "Let's talk through your options",
  body: "Want one-on-one support to take financial action? Take a 30 min call with Sophia",
  button: <Chip variant="light" label={"Schedule a call"} onClick={() => {
    window.open(
      "https://superpeer.com/winvest/-/winvest-collective-one-on-one-coaching",
      "_blank",
    );
  }} />,
  left: (
    <img
      width={"100%"}
      height={"100%"}
      src={"assets/images/temporary_avatar.png"}
    />
  ),
};

const game = {
  title: "Let's play a game",
  body: "Want to play a game that gives you the impact of your financial decisions?",
  button: <Chip variant="light" label={"Let's play"} onClick={() => {}} />,
  right: (
    <img
      width={"100%"}
      height={"100%"}
      src={"assets/images/temporary_avatar.png"}
    />
  ),
};

const financeTracker = {
  title: "Check out your progress",
  body: "You can have a look to see if you're on track to meet your financial targets!",
  button: (
    <Chip variant="light" label={"Track your goals"} onClick={() => {}} />
  ),
};

const projection = {
  title: "Look to the future...",
  body: "Want to predict your financial life path? Try out our projection tool!",
  button: <Chip variant="light" label={"Have a look"} onClick={() => {}} />,
};

const promotionOptions = {
  workshop,
  game,
  financeTracker,
  projection,
};

export default promotionOptions;
