import React from "react";
import {
    Card,
    CardHeader,
    CardMedia,
    styled,
} from "@mui/material";

// Styles for the top-level component
const Container = styled(Card)(({ color }) => ({
    backgroundColor: color,
    border: "1px solid rgba(0, 0, 0, 0.10);",
    borderRadius: 12,
    boxShadow: "none",
    height: 198,
    minWidth: 175.5,
    position: "relative",
    width: 175.5,
}));

// Styles for the header, indicating the workshop "category"
const Header = styled(CardHeader)(({
    theme, color = theme.vars.palette.common.black,
}) => ({
    position: "relative",
    width: "inherit",
    zIndex: 1,
    ".MuiCardHeader-title": {
        ...theme.typography.bodyEmphasis,
        color: color,
    },
}));

const Image = styled(CardMedia)(() => ({
    height: "73.533%",
}));

const FinancialTipCard = (props) => {
    const {
        topic, image, color, imageStyles, sx, onClick = () => {},
    } = props;

    return (
        <Container
            image={image}
            color={color.cardColor}
            sx={sx}
            onClick={onClick}
        >
            <Header title={topic} color={color.textColor} />
            <Image
                component="img"
                image={image}
                sx={imageStyles}
            />
        </Container>
    );
};

export default FinancialTipCard;
