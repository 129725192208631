import React from "react";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import TextField from "@ui-library/TextField";

const CurrentAgeButton = () => {
  const { state, dispatch } = useProjectionContext();
  const { currentAge } = state;

  const handleChangeCurrentAge = (event) => {
    const { value } = event.target;

    // Allow digits, a single dot, and nothing else.
    // This regex allows values like '2.', '2.0', '0.', etc.
    if (/^\d*\d*$/.test(value)) {
      dispatch({ type: "SET_CURRENT_AGE", payload: value });
    }
  };

  const handleBlurCurrentAge = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      dispatch({ type: "SET_CURRENT_AGE", payload: newValue.toString() });
    } else {
      // Optionally reset to a default or last valid value or provide user feedback
      dispatch({ type: "SET_CURRENT_AGE", payload: "25" });
    }
  };

  return (
    <TextField
      isdark="true"
      id={"currentAge"}
      label={"Your Age"}
      onChange={handleChangeCurrentAge}
      onBlur={handleBlurCurrentAge}
      value={currentAge}
    />
  );
};

export default CurrentAgeButton;
