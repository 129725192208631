import React from "react";
import { Grid, styled } from "@mui/material";

const Frame = styled(Grid)(({ theme }) => ({
  backgroundColor: "#FFF",
  [theme.breakpoints.down("md")]: {
    minHeight: "30vh",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  [theme.breakpoints.up("md")]: {
    height: "100vh",
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
  },
}));

const Form = ({ children }) => {
  return (
    <Frame item xs={12} md={8}>
      {children}
    </Frame>
  );
};

export default Form;
