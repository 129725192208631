const page1 = {
  title: "You and your partner decide to get a divorce",
  description: "I am dealing with emotional changes in my life, but I will get myself together and make the best choice for me and my kid",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/broken-heart.jpeg",
  },
};

const page2 = {
  title: "You and your partner decide to get a divorce",
  description: "What would you do? Choose one option",
  choices: [
    {
      choice: "Take it to court and try to claim 100% of my investment & property that my dad passed",
      outcome: {
        type: "consequence",
        title: "You tried!",
        caption: "Court decided to split everything in half",
      },
    },
    {
      choice: "Take it to court and try to claim 70% of my investment & property that my dad passed",
      outcome: {
        type: "consequence",
        title: "You tried!",
        caption: "Court decided to split everything in half",
      },
    },
    {
      choice: "Split evenly with my investment and our investment with my husband",
      outcome: {
        type: "consequence",
        title: "You tried!",
        caption: "Court decided to split everything in half",
      },
    },
  ],
};

const tipScreen = {
  content: [
    { type: "title", text: "Navigating Divorce: How to Protect Yourself" },
    {
      type: "paragraph",
      text: "A good lawyer can help you navigate the legal process effectively and protect your interests. A prenuptial agreement (prenup) can also provide clarity and protection regarding asset division in the event of divorce."
    },
    {
      type: "paragraph",
      text: "Don't forget the importance of teamwork in managing finances during your marriage. It's like being financial superheroes together, ensuring stability and security for both of you."
    }
  ],
};

const tips = [
  tipScreen,
];

const prompt14 = {
  type: "choice-extended",
  page1: page1,
  page2: page2,
  tips: tips,
  colour: {
    primary: "#FFDCDC",
    secondary: "#E04B77",
  },
};

export default prompt14;
