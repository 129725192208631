import {
  calculateInvestmentData,
  calculatePensionData,
  calculateRealEstateData,
  calculateSavingsData,
} from "@features/projection/contexts/calculations";
import { YEARS } from "@features/projection/contexts/constants";

const defaultYears = YEARS;

const getInvestmentSeries = (years, investment, currentAge, retirementAge) => ({
  data: calculateInvestmentData(investment, currentAge, retirementAge),
  label: "Investment",
  stack: "total",
  color: "#065d44",
  // cornerRadius: 10,
  // borderRadius: 12,
  type: "bar",
});

const getSavingsSeries = (years, savings, currentAge, retirementAge) => ({
  data: calculateSavingsData(savings, currentAge, retirementAge),
  label: "Savings",
  stack: "total",
  color: "#ffb1e2",
  type: "bar",
});

const getPensionSeries = (years, pension, currentAge, retirementAge) => ({
  data: calculatePensionData(pension, currentAge, retirementAge),
  label: "Pension",
  stack: "total",
  color: "#ffef61",
  type: "bar",
});

const getRealEstateSeries = (years, realEstate, currentAge, retirementAge) => {
  const RE_APPRECIATION_RATE = 4 * 0.01;

  return {
    data: calculateRealEstateData(
      realEstate,
      currentAge,
      retirementAge,
      RE_APPRECIATION_RATE
    ),
    label: "Real-Estate",
    stack: "total",
    color: "#c1effe",
    type: "bar",
  };
};

export {
  defaultYears,
  getInvestmentSeries,
  getPensionSeries,
  getRealEstateSeries,
  getSavingsSeries,
};
