import { Box, styled, Typography, useTheme } from "@mui/material";
import React from "react";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-end",
  gap: 8,
}));

const Container = styled(Box)(() => ({
  display: "flex",
  padding: "20px",
  alignItems: "center",
  justifyContent: "flex-end",
  borderRadius: 8,
}));

const Text = ({ ...props }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="body"
      color={theme.vars.palette.common.black}
      {...props}
    />
  );
};

const AvatarFrame = styled(Box)(({ theme, imgSrc }) => ({
  display: "flex",
  width: 48,
  height: 48,
  alignItems: "flex-start",
  flexShrink: 0,
  borderRadius: 184,
}));

const AIChatBubble = ({ children, imgSrc, ...props }) => {
  const theme = useTheme();

  return (
    <Wrapper {...props}>
      <AvatarFrame>
        <img src={imgSrc} alt="" width={"100%"} />
      </AvatarFrame>
      <Container>
        <Text>{children}</Text>
      </Container>
    </Wrapper>
  );
};

const UserChatBubble = ({ children, ...props }) => {
  return (
    <Wrapper sx={{ justifyContent: "flex-end" }} {...props}>
      <Container sx={{ backgroundColor: "#FFF" }}>
        <Text>{children}</Text>
      </Container>
    </Wrapper>
  );
};

export { AIChatBubble, UserChatBubble };
