const title = {
    type: "title",
    text: "Picture this as your solo album - a private pension plan you set up yourself.",
};

const description = {
    type: "body",
    text: "Personal pensions are perfect for individuals who want control over their retirement savings. There's no minimum deposit, so you can start saving right away.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Ideal for self-employed individuals or those without a workplace pension. You control the contributions and investment choices.",
        },
    ],
};

const growth = {
    type: "section",
    heading: "Growth",
    body: [
        {
            type: "body",
            text: "Typically 4% to 8% per year. Historically, balanced pension funds have averaged around 4-8% annual growth.",
        },
    ],
};

const risk = {
    type: "section",
    heading: "Risk",
    body: [
        {
            type: "body",
            text: "Moderate to High (depending on investment choices)",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "Standard Life Active Retirement Fund",
                    subheading: "Average annual growth of 6.5% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Aviva Pension Managed Fund",
                    subheading: "Average annual growth of 7% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Legal & General Multi-Asset Fund",
                    subheading: "Average annual growth of 6.8% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you're self-employed or want to supplement your workplace pension, a personal pension offers flexibility and control over your retirement savings.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Available directly from the providers' websites or through financial advisors.",
        },
    ],
};

const personalPension = [
    title,
    description,
    benefits,
    growth,
    risk,
    providersExamples,
    greatFor,
    accessibility,
];

export default personalPension;
