import React, { useState } from 'react';
import ClosePopup from '../components/ClosePopup';
import './Insights.css'
import ProductIcon from '../components/ProductIcon';
import StepIndicator from '../components/StepIndicator';

const InsightTitle = ({ title, icon, colour }) => {
    return (
        <div className='insight-title' style={{ backgroundColor: colour.title }}>
            <ProductIcon icon={icon} colour={colour.icon} />
            <p>{title}</p>
        </div>
    );
}

const InsightHeader = ({ type, title, colour, image }) => {
    return (
        <div className={type === 'tool-tip' ? 'tool-tip-header' : 'insight-header'}>
            {type === 'tool-tip'
                ? <></>
                : <InsightTitle title={title} icon={type} colour={colour} />}
            {image && 
                <div className={type === 'tool-tip' ? 'tool-tip-image' : 'insight-image'}>
                    <img src={image.src} alt={image.alt ? image.alt : ''} style={image.style ? image.style : {}} />
                </div>
            }
        </div>
    );
}

const Page = (props) => {
    const { type, title, colour, page, content, image, onNext } = props;

    return (
        <>
            <InsightHeader type={type} title={title} colour={colour} image={image} />
            <div className='insight-text'>
                {content.map((line, index) => {
                    switch (line.type) {
                        case 'title':
                            return <h2 key={index}>{line.text}</h2>;
                        case 'subtitle':
                            return <h3 key={index}>{line.text}</h3>;
                        case 'paragraph':
                            return <p key={index}>{line.text}</p>;
                        case 'definition':
                            return <p key={index}><span className='text-highlight-light'>{line.keyword}</span> {line.text}</p>;
                        case 'list':
                            switch (line.listType) {
                                case 'unordered':
                                    return (
                                        <ul key={index}>
                                            {line.listItems.map((item, i) => {
                                                return <li key={i}>{item.text}</li>
                                            })}
                                        </ul>
                                    );
                                default:
                                    return (
                                        <ol key={index}>
                                            {line.listItems.map((item, i) => {
                                                return <li key={i}>{item.text}</li>
                                            })}
                                        </ol>
                                    );
                            }
                        case 'image':
                            return (
                                <img key={index} src={line.src} alt={line.alt ? line.alt : ''} style={line.style ? line.style : {}} />
                            );
                        default:
                            return <></>;
                    }
                })}
            </div>
            <div className='step-button-footer'>
                <StepIndicator stepNum={page} />
                <button onClick={onNext}>{String.fromCharCode(8594)}</button>
            </div>
        </>
    );
}

const Insight = (props) => {
    const { type, title, colour, content, setInsight } = props;
    const { page1, page2, page3 } = content;
    const [page, setPage] = useState(1);

    const onNext = () => {
        setPage((prev) => prev + 1);
    }

    const pages = [
        <Page type={type} title={title} colour={colour} page={page} {...page1} onNext={onNext} />,
        <Page type={type} title={title} colour={colour} page={page} {...page2} onNext={onNext} />,
        <Page type={type} title={title} colour={colour} page={page} {...page3} onNext={() => setInsight(null)} />,
    ];

    return (
        <div className='insight popup'>
            <div
                className='insight-content popup-content'
                style={{ backgroundColor: colour.background }}
            >
                <ClosePopup setPopup={setInsight} />
                {/* <InsightHeader type={type} title={title} colour={colour} /> */}
                {pages[page - 1]}
            </div>
        </div>
    );
}

export default Insight;
