import React from "react";
import { SvgIcon } from "@mui/material";

const SavingsIcon = ({ color = "white" }) => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M6.875 11.875C7.15114 11.875 7.375 11.6511 7.375 11.375C7.375 11.0989 7.15114 10.875 6.875 10.875C6.59886 10.875 6.375 11.0989 6.375 11.375C6.375 11.6511 6.59886 11.875 6.875 11.875Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.9485 12.9479C22.8015 13.3176 22.5136 13.6137 22.1483 13.7711C21.7829 13.9285 21.37 13.9344 21.0003 13.7874"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6897 6.97018C10.762 6.96653 9.83678 7.07161 8.93103 7.28349C8.58621 6.5 8.58621 6.4236 8.00322 5C8.00322 5 6.18966 5.52585 5.5 6L5.82759 8.51487H5.87586C4.4656 9.2903 3.35221 10.5567 2.72414 12.0997H1.68966C1.50675 12.0997 1.33133 12.1765 1.202 12.3131C1.07266 12.4498 1 12.6351 1 12.8284V15.0142C1 15.2075 1.07266 15.3928 1.202 15.5295C1.33133 15.6661 1.50675 15.7429 1.68966 15.7429H2.72414C3.35044 17.256 4.44471 18.4995 5.82759 19.2694V22.2714C5.82759 22.4646 5.90025 22.6499 6.02958 22.7866C6.15892 22.9232 6.33433 23 6.51724 23H8.58621C8.76911 23 8.94453 22.9232 9.07387 22.7866C9.2032 22.6499 9.27586 22.4646 9.27586 22.2714V20.5737C10.8704 20.8982 12.5089 20.8982 14.1034 20.5737V22.2714C14.1034 22.4646 14.1761 22.6499 14.3054 22.7866C14.4348 22.9232 14.6102 23 14.7931 23H16.8621C17.045 23 17.2204 22.9232 17.3497 22.7866C17.4791 22.6499 17.5517 22.4646 17.5517 22.2714V19.2694C19.6207 18.0016 21 16.0635 21 13.8921C21 10.0669 16.8345 6.97018 11.6897 6.97018Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.1104 11.6628C14.6618 10.4915 13.2004 9.99829 11.8205 10.215"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 5C16.1046 5 17 4.10457 17 3C17 1.89543 16.1046 1 15 1C13.8954 1 13 1.89543 13 3C13 4.10457 13.8954 5 15 5Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

export default SavingsIcon;
