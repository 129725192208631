import React from "react";
import { Grid, styled } from "@mui/material";
import {
  Description,
  FormContainer,
  totalPages,
  ThankYouScreen,
  steps,
} from "@features/questionnaire";
import { useQuestionnaireContext } from "@hooks";

const Frame = styled(Grid)(({ backgroundColor }) => ({
  display: "flex",
  width: "100%",
  // flex: "1 0 auto",
  // minHeight: "100%",
  backgroundColor,
}));

const FormStep = ({ pageDetails }) => {
  const { form, backgroundColor } = pageDetails;
  return (
    <Frame container backgroundColor={backgroundColor}>
      <Description pageDetails={pageDetails} />
      <FormContainer>{form}</FormContainer>
    </Frame>
  );
};

const QuestionnairePage = () => {
  const { page } = useQuestionnaireContext();
  const pageDetails = steps[page];

  return page <= totalPages ? (
    <FormStep pageDetails={pageDetails} />
  ) : (
    <ThankYouScreen />
  );
};

export default QuestionnairePage;
