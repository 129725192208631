import { createContext, useMemo, useReducer } from "react";
// import { calculateRealEstateFutureValue } from "../../../lib/fv";
import { financialReducer, initialState } from "./reducer";
import {
  calculateInvestmentData,
  calculatePensionData,
  calculateRealEstateData,
  calculateSavingsData,
} from "./calculations";
import { RE_APPRECIATION_RATE } from "./constants";
import {
  calculateFutureValueUntilRetirement,
  calculateRealEstateFutureValue,
  END_OF_PERIOD,
} from "@utils/financialCalculations";

const ProjectionContext = createContext();

const calculateFutureValue = (
  investment,
  savings,
  pension,
  realEstate,
  currentAge,
  retirementAge,
  appreciationRate = 0
) => {
  const yearsUntilRetirement = retirementAge - currentAge;

  const investmentFutureValue = calculateFutureValueUntilRetirement(
    currentAge,
    retirementAge,
    yearsUntilRetirement,
    -investment.initialAmount,
    -investment.monthlyInvestment * 12,
    investment.interestRate / 100,
    END_OF_PERIOD
  );

  const savingsFutureValue = calculateFutureValueUntilRetirement(
    currentAge,
    retirementAge,
    yearsUntilRetirement,
    -savings.initialAmount,
    -savings.monthlyInvestment * 12,
    savings.interestRate / 100,
    END_OF_PERIOD
  );

  const pensionFutureValue = calculateFutureValueUntilRetirement(
    currentAge,
    retirementAge,
    yearsUntilRetirement,
    -pension.totalPension,
    -(pension.totalContribution * 0.01 * pension.annualSalary),
    pension.interestRate / 100,
    END_OF_PERIOD
  );

  const realEstateFutureValue = calculateRealEstateFutureValue(
    currentAge,
    retirementAge,
    yearsUntilRetirement,

    realEstate.currentPropertyValue,
    appreciationRate,
    realEstate.monthlyPayment,
    realEstate.monthlyOverpayment,

    realEstate.remainingMortgage,
    realEstate.interestRate * 0.01,
    realEstate.mortgageType
  );
  // const realEstateFutureValue = calculateRealEstateFutureValue(
  //   realEstate.currentPropertyValue,
  //   realEstate.remainingMortgage,
  //   realEstate.monthlyPayment,
  //   appreciationRate,
  //   realEstate.mortgageType,
  //   yearsUntilRetirement * 12,
  //   realEstate.monthlyOverpayment,
  //   realEstate.interestRate * 0.01,
  //   currentAge,
  //   retirementAge
  // );

  const futureValues = {
    investment: investmentFutureValue,
    savings: savingsFutureValue,
    pension: pensionFutureValue,
    realEstate: realEstateFutureValue,
  };

  return futureValues;
};

const ProjectionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(financialReducer, initialState);

  const investmentData = useMemo(
    () =>
      calculateInvestmentData(
        state.investment,
        state.currentAge,
        state.retirementAge
      ),
    [state.investment, state.currentAge, state.retirementAge]
  );
  const savingsData = useMemo(
    () =>
      calculateSavingsData(
        state.savings,
        state.currentAge,
        state.retirementAge
      ),
    [state.savings, state.currentAge, state.retirementAge]
  );
  const pensionData = useMemo(
    () =>
      calculatePensionData(
        state.pension,
        state.currentAge,
        state.retirementAge
      ),
    [state.pension, state.currentAge, state.retirementAge]
  );
  const realEstateData = useMemo(
    () =>
      calculateRealEstateData(
        state.realEstate,
        state.currentAge,
        state.retirementAge,
        RE_APPRECIATION_RATE
      ),
    [
      state.realEstate,
      state.currentAge,
      state.retirementAge,
      RE_APPRECIATION_RATE,
    ]
  );

  const retirementData = useMemo(() => {
    const {
      investment,
      savings,
      pension,
      realEstate,
      currentAge,
      retirementAge,
    } = state;

    return calculateFutureValue(
      investment,
      savings,
      pension,
      realEstate,
      currentAge,
      retirementAge,
      RE_APPRECIATION_RATE
    );
  }, [
    state.investment,
    state.savings,
    state.pension,
    state.realEstate,
    state.currentAge,
    state.retirementAge,
    RE_APPRECIATION_RATE,
  ]);

  const totalInvestment = useMemo(
    () => investmentData[investmentData.length - 1] || 0,
    [investmentData]
  );
  const totalSavings = useMemo(
    () => savingsData[savingsData.length - 1] || 0,
    [savingsData]
  );
  const totalPension = useMemo(
    () => pensionData[pensionData.length - 1] || 0,
    [pensionData]
  );
  const totalRealEstate = useMemo(
    () => realEstateData[realEstateData.length - 1] || 0,
    [realEstateData]
  );

  const projectedAgeAtOneMillionNetWorth = useMemo(() => {
    const { investment, savings, pension, realEstate, currentAge } = state;
    let year = 0;
    let totalProjection = 0;
    const maxYears = 999;

    while (year < maxYears && totalProjection < 1_000_000) {
      year++;
      const yearToProject = Number(currentAge) + year;
      const futureValues = calculateFutureValue(
        investment,
        savings,
        pension,
        realEstate,
        currentAge,
        yearToProject,
        RE_APPRECIATION_RATE
      );

      totalProjection =
        futureValues.investment +
        futureValues.savings +
        futureValues.pension +
        futureValues.realEstate;

      if (totalProjection >= 1_000_000) return year;
    }
    return maxYears;
  }, [state, RE_APPRECIATION_RATE]);

  const contextValue = {
    state,
    dispatch,
    investmentData,
    savingsData,
    totalInvestment,
    totalSavings,
    totalPension,
    totalRealEstate,
    retirementData,
    projectedAgeAtOneMillionNetWorth,
  };

  return (
    <ProjectionContext.Provider value={contextValue}>
      {children}
    </ProjectionContext.Provider>
  );
};

export default ProjectionContext;
export { ProjectionProvider };
