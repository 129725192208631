import { Card, styled } from "@mui/material";

const Frame = styled((props) => <Card elevation={0} {...props} />)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#000",
  color: "#FFF",
  borderRadius: 16,
  width: "100%",
  padding: "24px 16px 32px 16px",
  gap: 16,
});

export default Frame;
