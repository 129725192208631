import financialProducts from "../financialProducts/financialProducts";

const page1 = {
    content: [
        {
            type: 'title',
            text: 'The importance of an emergency fund 1/2'
        },
        {
            type: 'paragraph',
            text: 'Most people live paycheck to paycheck. This means that an unexpected expense can spell serious trouble.'
        }
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/falling-man.jpeg",
    },
};

const page2 = {
    content: [
        {
            type: 'title',
            text: 'The importance of an emergency fund 2/2'
        },
        {
            type: 'paragraph',
            text: 'Imagine your phone got stolen; flights got delayed; got injured in foreign country; having emergency funds allows you to swiftly navigate such situations, bounce back, and stay on track with your financial goals.'
        },
        {
            type: 'paragraph',
            text: 'Make sure that this money is saved in an instant access high-interest savings account so that whenever you need it you can access it.'
        }
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/falling-man.jpeg",
    },
};

const page3 = {
    content: [
        {
            type: 'title',
            text: 'How much should I put in my emergency fund? '
        },
        {
            type: 'paragraph',
            text: 'Start off by saving up £1000 and then slowly work up to having 3-6 months of necessary living expenses. It is important to separate this from your savings so that you only dip into this fund in case of an emergency.'
        }
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/pie-chart-3.png",
    },
};

const emergencyFund = {
    ...financialProducts['EmergencyFund'],
    content: {
        page1: page1,
        page2: page2,
        page3: page3
    },
};

export default emergencyFund;
