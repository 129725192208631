import React from "react";
import { Box, styled } from "@mui/material";
import FinancialSnapshot from "./FinancialSnapshot";
import {
  BarPlot,
  ChartsClipPath,
  ChartsGrid,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
} from "@mui/x-charts";
import GraphContainer from "./GraphContainer";
import useSeriesData from "@features/projection/hooks/useSeriesData";

const Frame = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  minWidth: 300,
  padding: 8,
  position: "relative",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    height: 400,
  },
  [theme.breakpoints.up("md")]: {
    flex: "1 1 auto",
    maxHeight: 500,
  },
}));

const FinancialChart = ({ activeTab }) => {
  const seriesData = useSeriesData(activeTab);

  return (
    <Frame>
      <GraphContainer data={seriesData}>
        <ChartsGrid horizontal />
        <ChartsYAxis axisId={"money"} position="left" />
        <ChartsYAxis position="right" tickLabelStyle={{ display: "none" }} />
        <ChartsXAxis axisId={"ages"} />
        <g clipPath={`url(#clipPath)`}>
          <BarPlot borderRadius={8} />
        </g>
        <ChartsClipPath id={"clipPath"} />
        <ChartsTooltip
          trigger={"axis"}
          slots={{ axisContent: FinancialSnapshot }}
        />
      </GraphContainer>
    </Frame>
  );
};

export default FinancialChart;
