import React from "react";
import { Button, Chip as MuiChip, styled, Typography } from "@mui/material";
import { conditional } from "./styles";

const LabelText = ({ type, text, ischecked }) => {
  const color = ischecked ? "#FFF" : "#333";
  const weight = type === "single" ? 500 : 400;
  const height = type === "single" ? "21px" : "17px";

  return (
    <Typography
      flex={"1 0 0"}
      fontWeight={weight}
      fontSize={"14px"}
      lineHeight={height}
      color={color}
    >
      {text}
    </Typography>
  );
};

const StyledChip = styled(({ type, text, onChange, isChecked, ...props }) => (
  <MuiChip
    variant="outlined"
    label={<LabelText text={text} type={type} ischecked={isChecked} />}
    onClick={onChange}
    {...props}
  />
))(({ theme, type = "default", isChecked }) => ({
  ...conditional(type, isChecked),
}));

// eslint-disable-next-line
const OldChip = ({ text, isChecked, onChange }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      sx={{
        backgroundColor: isChecked ? "#C7B99C" : "#F2F1EF",
        color: "#333333",
        paddingX: "24px",
        paddingY: "8px",
        height: "44px",
        borderRadius: "40px",
        textTransform: "none",
        alignItems: "center",
        "&:hover": {
          backgroundColor: "#C7B99C",
        },
        "&:active": {
          backgroundColor: "#C7B99C",
        },
      }}
      onClick={onChange}
    >
      <Typography
        flex={"1 0 0"}
        fontWeight={500}
        fontSize={"14px"}
        lineHeight={"21px"}
        color={"#333333"}
      >
        {text}
      </Typography>
    </Button>
  );
};

const Chip = (props) => {
  return <StyledChip {...props} />;
};

export default Chip;
