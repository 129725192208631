const base = () => ({
  width: "100%",
  margin: 0,
});

const inputBaseRoot = (isdark, error, custom) => ({
  paddingTop: "30px", // 8 padding + 20 line height + 12 gap
  paddingLeft: "8px",
  paddingRight: "8px",
  paddingBottom: "12px",
  borderRadius: "8px",
  backgroundColor: isdark
    ? error
      ? "rgba(255, 255, 255, 0.10)"
      : "rgba(255, 255, 255, 0.15)"
    : "rgba(255, 255, 255, 0.65)",
  border: error
    ? "1px solid #EB5757"
    : isdark
    ? "1px solid rgba(255, 255, 255, 0.20)"
    : "1px solid rgba(0, 0, 0, 0.10)",
  "&.Mui-focused, &:hover": {
    border: error
      ? "1px solid #EB5757"
      : isdark
      ? "1px solid #FFFAFA"
      : "1px solid #000",
    backgroundColor: isdark
      ? "rgba(255, 255, 255, 0.10)"
      : "rgba(255, 255, 255, 0.65)",
  },
  ...custom,
});

const inputBaseInput = (isdark) => ({
  color: isdark ? "#FFF" : "#000",
  fontSize: "20px",
  lineHeight: "36px",
  paddingLeft: "4px",
  paddingTop: 0,
  paddingBottom: 0,
});

const inputLabelRoot = (isdark, error) => ({
  top: "8px",
  left: "13px", // 12 padding + 1 border
  color: isdark ? "#FFF" : "#000",
  fontSize: "13px",
  lineHeight: "20px",
  pointerEvents: "none",
  "&.Mui-focused": {
    color: isdark ? "#FFF" : "#000",
  },
  "&:hover": {
    color: isdark ? "#FFF" : "#000", // Maintain the original color on hover
  },
  ".Mui-error": {
    color: isdark ? "#FFF" : "#000",
  },
});

const inputLabelShrink = () => ({
  transform: "none",
});

const startAdornment = (display, isdark) => ({
  color: isdark ? "#FFF" : "#000",
  alignItems: "center",
  display: display ? "flex" : "none",
});

const endAdornment = (display) => ({
  position: "absolute",
  top: "19px", // 11 height + 8 padding
  // top: "50%", transform: "translateY(-50%)",
  right: "8px",
  display: display ? "flex" : "none",
});

const formHelperText = () => ({
  zIndex: 2, // Higher z-index than InputBase
  marginTop: "-8px", // 6px padding + 2px borders
  marginLeft: "0px",
  marginRight: "0px",
  width: "100%",
  border: "1px solid #EB5757",
  backgroundColor: "#EB5757",
  borderRadius: "0px 0px 4px 4px",
  padding: "6px 4px 6px 12px",
  alignItems: "center",
  alignSelf: "stretch",
  fontSize: "13px",
  lineHeight: "20px",
  color: "#FFF",
});

export {
  base,
  inputBaseRoot,
  inputBaseInput,
  inputLabelShrink,
  inputLabelRoot,
  startAdornment,
  endAdornment,
  formHelperText,
};
