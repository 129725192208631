import weights from "./weights";

const typography = {};

typography.fontFamily = ["Inter", "Gopher", "Arial", "sans-serif"].join(",");
typography.fontSize = 14;

typography.h1 = {
  fontWeight: weights.SEMI_BOLD,
  fontSize: 34,
  lineHeight: 42 / 34,
  letterSpacing: "-0.02em",
};

typography.h2 = {
  fontWeight: weights.SEMI_BOLD,
  fontSize: 28,
  lineHeight: 34 / 28,
  letterSpacing: "-0.015em",
};

typography.h3 = {
  fontWeight: weights.BOLD,
  fontSize: 22,
  lineHeight: 28 / 22,
  letterSpacing: "-0.01em",
};

typography.h4 = {
  fontWeight: weights.SEMI_BOLD,
  fontSize: 20,
  lineHeight: 26 / 20,
  letterSpacing: "-0.005em",
};

typography.h5 = {
  fontWeight: weights.SEMI_BOLD,
  fontSize: 18,
  lineHeight: 24 / 18,
  letterSpacing: "0em",
};

typography.bodyXL = {
  fontWeight: weights.REGULAR,
  fontSize: 17,
  lineHeight: 22 / 17,
  letterSpacing: "0em",
};

typography.callout = {
  fontWeight: weights.REGULAR,
  fontSize: 16,
  lineHeight: 22 / 16,
  letterSpacing: "0em",
};

typography.bodyL = {
  fontWeight: weights.REGULAR,
  fontSize: 15,
  lineHeight: 22 / 15,
  letterSpacing: "0em",
};

typography.bodyLEmphasis = {
  fontWeight: weights.MEDIUM,
  fontSize: 15,
  lineHeight: 22 / 15,
  letterSpacing: "0em",
};

typography.body = {
  fontWeight: weights.REGULAR,
  fontSize: 14,
  lineHeight: 22 / 14,
  letterSpacing: "0em",
};

typography.bodyEmphasis = {
  fontWeight: weights.MEDIUM,
  fontSize: 14,
  lineHeight: 22 / 14,
  letterSpacing: "0em",
};

typography.bodyS = {
  fontWeight: weights.REGULAR,
  fontSize: 13,
  lineHeight: 18 / 13,
  letterSpacing: "0em",
};

typography.caption1 = {
  fontWeight: weights.REGULAR,
  fontSize: 12,
  lineHeight: 16 / 12,
  letterSpacing: "0em",
};

typography.caption2 = {
  fontWeight: weights.REGULAR,
  fontSize: 11,
  lineHeight: 13 / 11,
  letterSpacing: "0em",
};

typography.h6 = undefined;

typography.subtitle1 = undefined;

typography.subtitle2 = undefined;

typography.body1 = undefined;

typography.body2 = undefined;

typography.button = undefined;

typography.caption = undefined;

typography.overline = undefined;

typography.inherit = undefined;

export { weights };

export default typography;
