import colors from "./colors";

const colorSchemes = {
  light: {
    palette: {},
  },
  dark: {
    palette: {},
  },
};

colorSchemes.light.palette = {
  investment: colors.investment,
  savings: colors.savings,
  pension: colors.pension,
  properties: colors.properties,
};

colorSchemes.dark.palette = {
  investment: colors.investment,
  savings: colors.savings,
  pension: colors.pension,
  properties: colors.properties,
};

export default colorSchemes;
