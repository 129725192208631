import React from "react";
import { SvgIcon } from "@mui/material";

const DiscountIcon = ({ color = "white" }) => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12 22.7494C11.37 22.7494 10.78 22.5094 10.34 22.0594L8.82001 20.5394C8.70001 20.4194 8.38 20.2894 8.22 20.2894H6.06C4.76 20.2894 3.70999 19.2393 3.70999 17.9393V15.7794C3.70999 15.6194 3.57999 15.2994 3.45999 15.1794L1.94 13.6594C1.5 13.2194 1.25 12.6294 1.25 11.9994C1.25 11.3694 1.49 10.7793 1.94 10.3393L3.45999 8.8193C3.57999 8.6993 3.70999 8.37933 3.70999 8.21933V6.05941C3.70999 4.75941 4.76 3.70932 6.06 3.70932H8.22C8.38 3.70932 8.70001 3.57932 8.82001 3.45932L10.34 1.9393C11.22 1.0593 12.78 1.0593 13.66 1.9393L15.18 3.45932C15.3 3.57932 15.62 3.70932 15.78 3.70932H17.94C19.24 3.70932 20.29 4.75941 20.29 6.05941V8.21933C20.29 8.37933 20.42 8.6993 20.54 8.8193L22.06 10.3393C22.5 10.7793 22.75 11.3694 22.75 11.9994C22.75 12.6294 22.51 13.2194 22.06 13.6594L20.54 15.1794C20.42 15.2994 20.29 15.6194 20.29 15.7794V17.9393C20.29 19.2393 19.24 20.2894 17.94 20.2894H15.78C15.62 20.2894 15.3 20.4194 15.18 20.5394L13.66 22.0594C13.22 22.5094 12.63 22.7494 12 22.7494ZM4.51999 14.1194C4.91999 14.5194 5.20999 15.2194 5.20999 15.7794V17.9393C5.20999 18.4093 5.59 18.7894 6.06 18.7894H8.22C8.78 18.7894 9.48001 19.0793 9.88 19.4793L11.4 20.9994C11.72 21.3194 12.28 21.3194 12.6 20.9994L14.12 19.4793C14.52 19.0793 15.22 18.7894 15.78 18.7894H17.94C18.41 18.7894 18.79 18.4093 18.79 17.9393V15.7794C18.79 15.2194 19.08 14.5194 19.48 14.1194L21 12.5993C21.16 12.4393 21.25 12.2294 21.25 11.9994C21.25 11.7694 21.16 11.5594 21 11.3994L19.48 9.87936C19.08 9.47936 18.79 8.77933 18.79 8.21933V6.05941C18.79 5.58941 18.41 5.20932 17.94 5.20932H15.78C15.22 5.20932 14.52 4.91938 14.12 4.51938L12.6 2.99936C12.28 2.67936 11.72 2.67936 11.4 2.99936L9.88 4.51938C9.48001 4.91938 8.78 5.20932 8.22 5.20932H6.06C5.59 5.20932 5.20999 5.58941 5.20999 6.05941V8.21933C5.20999 8.77933 4.91999 9.47936 4.51999 9.87936L3 11.3994C2.84 11.5594 2.75 11.7694 2.75 11.9994C2.75 12.2294 2.84 12.4393 3 12.5993L4.51999 14.1194Z"
                fill={color}
            />
            <path
                d="M15.0022 16C14.4422 16 13.9922 15.55 13.9922 15C13.9922 14.45 14.4422 14 14.9922 14C15.5422 14 15.9922 14.45 15.9922 15C15.9922 15.55 15.5522 16 15.0022 16Z"
                fill={color}
            />
            <path
                d="M9.01001 10C8.45001 10 8 9.55 8 9C8 8.45 8.45 8 9 8C9.55 8 10 8.45 10 9C10 9.55 9.56001 10 9.01001 10Z"
                fill={color}
            />
            <path
                d="M9.00141 15.7495C8.81141 15.7495 8.62141 15.6795 8.47141 15.5295C8.18141 15.2395 8.18141 14.7595 8.47141 14.4695L14.4714 8.46945C14.7614 8.17945 15.2414 8.17945 15.5314 8.46945C15.8214 8.75945 15.8214 9.23951 15.5314 9.52951L9.5314 15.5295C9.3814 15.6795 9.1914 15.7495 9.00141 15.7495Z"
                fill={color}
            />
        </svg>
    </SvgIcon>
);

export default DiscountIcon;
