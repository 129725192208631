import React from "react";
import { styled, Box, FormControl } from "@mui/material";

const BoxContainer = styled(FormControl)(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "flex-start",
  minHeight: `min(${99}px, ${11}vh)`,
  width: "100%",
  padding: "12px 12px 0px 12px",
  flexShrink: 0,
}));

const Container = ({ children }) => {
  return (
    <BoxContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
      >
        {children}
      </Box>
    </BoxContainer>
  );
};

export default Container;
