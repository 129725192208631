// Prompt Calculations

import { getCurrentPlayerAge, getNetWorth, setCurrentPlayerAge } from "./playerState";

// Interval between two prompts is 2 years
export const PROMPT_INTERVAL = 2;

// Prompt Changes

// Placeholder for change function to be applied after a prompt
let applyPromptChanges = () => {};

export const addChanges = (changes, change = 'none') => {
    if (change !== 'none' && changes[change]) {
        applyPromptChanges = changes[change];
    }
}

// Applies change function for a prompt
// Change functions modify net worth based on choices made
export const applyChanges = () => {
    applyPromptChanges();
    // Reset promptChanges to (() => {}) for the next prompt
    applyPromptChanges = () => {};
}

// Annual Changes

const allAnnualChanges = [];

export const rollbackAnnualChanges = () => {
    allAnnualChanges.pop();
}

// Placeholder for new changes to be added
// { change, property, by, frequency }
let annualChanges = [];

export const addAnnualChange = (change) => {
    annualChanges.push(change);
}

const byValue = (change, by, frequency) => {
    // Converts by to a number, if necessary
    const value = Number(by);
    const times = frequency === 'monthly' ? 12 : 1;
    return change === 'increase' ? (value * times) : -(value * times);
}

const byPercent = (value, change, by) => {
    const percent = Number(by) / 100;
    return change === 'increase' ? (value * percent) : -(value * percent);
}

export const changeProperty = (
    { change, property, by, frequency = 'once' },
) => {
    const NET_WORTH = getNetWorth();
    const { income, savings, investments, pension } = NET_WORTH;

    switch (property) {
        case 'salary':
            // assume salary is increased by a percentage
            income.salary = (
                income.salary + byPercent(income.salary, change, by)
            );
            break;
        case 'mortgage':
            income.mortgage += byValue(change, by);
        case 'spending':
            // More negative spending => more spending
            income.spending = income.spending - byValue(change, by);
            break;
        case 'savingsAccount':
        case 'emergencyFund':
        case 'cash':
            savings[property] = (
                savings[property] + byValue(change, by, frequency)
            );
            break;
        case 'indexFund':
        case 'blueChipStocks':
        case 'isaAccount':
        case 'juniorISA':
            investments[property] = (
                investments[property] + byValue(change, by, frequency)
            );
            break;
        case 'pension contribution':
            pension.contribution = (
                ((pension.contribution * 100) + byValue(change, by)) / 100
            );
            break;
        default:
            break;
    }
}

export const setProperty = (property, value) => {
    const NET_WORTH = getNetWorth();
    const { income, savings, investments, pension } = NET_WORTH;

    switch (property) {
        case 'salary':
            income[property] = value;
            break;
        case 'mortgage':
        case 'spending':
            income[property] = -value;
            break;
        default:
            break;
    }
}

export const createChanges = (
    amount, frequency, options, selectedOptions, inputs,
) => {
    // selectedOptions -> array of indexes
    // inputs -> [X, Y, Z]

    const changes = selectedOptions.map((optionIndex) => {
        const PERCENT = Number(inputs[optionIndex]) / 100;
        return {
            change: 'increase',
            property: options[optionIndex].property,
            by: amount * PERCENT,
            frequency: frequency,
        }
    });
    console.log(changes);
    
    // Assumes 'once' is the default behaviour
    changes.map((change) => {
        switch (change.frequency) {
            case 'monthly':
                addAnnualChange(change);
                break;
            case 'once':
            default:
                changeProperty(change);
                break;
        }
    });

    console.log(annualChanges);
}

// Marriage Stuff

let isMarried = false;

export const getIsMarried = () => {
    return isMarried;
}

export const setIsMarried = (value) => {
    isMarried = value;
}

let marriageStartSnapshot = {};

export const setMarriageStartSnapshot = () => {
    marriageStartSnapshot = getInvestmentsSnapshot();
}

const getInvestmentsSnapshot = () => {
    const NET_WORTH = getNetWorth();
    const { savings, investments } = NET_WORTH;
    return {
        savingsAccount: savings.savingsAccount,
        emergencyFund: savings.emergencyFund,
        indexFund: investments.indexFund,
        blueChipStocks: investments.blueChipStocks,
        isaAccount: investments.isaAccount,
        juniorISA: investments.juniorISA,
    };
}

export const splitSharedInvestments = () => {
    // marriageDifference = marriageEnd - marriageStart
    // NET_WORTH - marriageDifference / 2
    const NET_WORTH = getNetWorth();
    const { savings, investments } = NET_WORTH;
    const before = marriageStartSnapshot;
    const after = getInvestmentsSnapshot();
    let difference = {};
    for (let key in before) {
        if (before.hasOwnProperty(key) && after.hasOwnProperty(key)) {
            difference[key] = after[key] - before[key];
        }
    }
    // Split shared investments
    const split = (shared, diff) => {
        return shared - (diff / 2);
    }
    savings.savingsAccount = split(
        savings.savingsAccount, difference.savingsAccount
    );
    savings.emergencyFund = split(
        savings.emergencyFund, difference.emergencyFund
    );
    investments.indexFund = split(
        investments.indexFund, difference.indexFund
    );
    investments.blueChipStocks = split(
        investments.blueChipStocks, difference.blueChipStocks
    );
    investments.isaAccount = split(
        investments.isaAccount, difference.isaAccount
    );
    investments.juniorISA = split(
        investments.juniorISA, difference.juniorISA
    );
}

// Apply interests during prompt intervals

export const applyInterval = ( interval = PROMPT_INTERVAL ) => {
    // Add annualChanges for the prompt to allAnnualChanges
    allAnnualChanges.push(annualChanges);
    // Reset annualChanges to [] for the next prompt
    annualChanges = [];
    console.log(allAnnualChanges)

    const NET_WORTH = getNetWorth();
    const { income, savings, investments, pension } = NET_WORTH;

    // Once all changes have been applied, remaining income is added to cash
    let remainingIncome = (income.salary * 12) * 2;

    const applied = {
        savingsAccount: false,
        emergencyFund: false,
        indexFund: false,
        blueChipStocks: false,
        isaAccount: false,
        juniorISA: false,
    };

    // Changes that are applied for each year of the interval
    for (let i = 0; i < interval; i++) {
        // Iterate over allAnnualChanges and apply
        for (let j = 0; j < allAnnualChanges.length; j++) {
            allAnnualChanges[j].map((change, index) => {
                const property = change.property;
                switch (property) {
                    case 'savingsAccount':
                    case 'emergencyFund':
                        // Savings account and emergency fund both have 4% interest
                        savings[property] = applyInterestMonthly(
                            savings[property], 0.04, change
                        );
                        applied[property] = true;
                        break;
                    case 'indexFund':
                    case 'isaAccount':
                    case 'juniorISA':
                        // Index fund, ISA, and junior ISA all have 8% interest
                        investments[property] = applyInterestMonthly(
                            investments[property], 0.08, change
                        );
                        applied[property] = true;
                        break;
                    case 'blueChipStocks':
                        investments.blueChipStocks = applyInterestMonthly(
                            investments.blueChipStocks, 0.045, change
                        );
                        applied.blueChipStocks = true;
                        break;
                    default:
                        break;
                }
                // Remove money put into assets from remainingIncome
                remainingIncome -= (
                    change.by * (change.frequency === "monthly" ? 12 : 1)
                );
            })
        };
        // Pension growth
        increasePension(NET_WORTH);
        remainingIncome -= ((income.salary * 12) * pension.contribution);
        // Increase monthly spending by 10% each year
        increaseSpending(NET_WORTH);
        remainingIncome -= (Math.abs(income.spending) * 12);
        // Apply interest on accounts
        if (!applied.savingsAccount) {
            savings.savingsAccount = applyInterest(
                savings.savingsAccount, 0.04
            );
        }
        if (!applied.emergencyFund) {
            savings.emergencyFund = applyInterest(
                savings.emergencyFund, 0.04
            );
        }
        if (!applied.indexFund) {
            investments.indexFund = applyInterest(
                investments.indexFund, 0.08
            );
        }
        if (!applied.blueChipStocks) {
            investments.blueChipStocks = applyInterest(
                investments.blueChipStocks, 0.045
            );
        }
        if (!applied.isaAccount) {
            investments.isaAccount = applyInterest(
                investments.isaAccount, 0.08
            );
        }
        if (!applied.juniorISA) {
            investments.juniorISA = applyInterest(
                investments.juniorISA, 0.08
            );
        }
    }

    // Move remainingIncome into available cash
    savings.cash += remainingIncome;

    // Changes applied per interval
    // 10% salary increase
    const salary = NET_WORTH.income.salary;
    NET_WORTH.income.salary = (salary + (salary * 0.1));

    // Increase current age of player
    setCurrentPlayerAge(getCurrentPlayerAge() + interval);
}

const applyInterestMonthly = (existing, rate, { by, frequency }) => {
    const NUM_MONTHS = 12;
    const MONTHLY_RATE = rate / NUM_MONTHS;

    const fvExisting = existing * ((1 + MONTHLY_RATE) ** NUM_MONTHS);
    const fvContributions = (() => {
        switch (frequency) {
            case 'monthly':
                return (by * (
                    ( ((1 + MONTHLY_RATE) ** NUM_MONTHS) - 1 ) / MONTHLY_RATE 
                ) * (1 + MONTHLY_RATE));
            default:
                return 0;
        }
    })();
    const futureValue = fvExisting + fvContributions;
    const dividend = futureValue * 0.03;
    return futureValue + dividend;
}

const applyInterest = (existing, rate) => {
    const NUM_MONTHS = 12;
    const MONTHLY_RATE = rate / NUM_MONTHS;

    const futureValue = existing * ((1 + MONTHLY_RATE) ** NUM_MONTHS);
    const dividend = futureValue * 0.03;
    return futureValue + dividend;
}

const increasePension = (NET_WORTH) => {
    const existing = NET_WORTH.pension.total;
    const annualSalary = NET_WORTH.income.salary * 12;
    NET_WORTH.pension.annualGrowth = (
        (existing
            + (annualSalary * NET_WORTH.pension.contribution) + (annualSalary * 0.04)
        ) * (1 + 0.04)
    );
}

const increaseSpending = (NET_WORTH) => {
    const existing = NET_WORTH.income.spending;
    NET_WORTH.income.spending = existing + (existing * 0.1);
}
