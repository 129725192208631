import React, { useState } from 'react';
import ClosePopup from '../../components/ClosePopup';
import { ContinueButton } from './ChoiceButton';
import Insight from '../../Insights/Insight';

const TipScreenImage = ({ src, alt, style }) => {
    return (
        <>
            {src && 
                <div className='tip-screen-image'>
                    <img src={src} alt={alt ? alt : ''} style={style ? style : {}} />
                </div>
            }
        </>
    );
}

const TipScreenText = ({ content }) => {
    return (
        <div className='tip-screen-text'>
            {content.map((line, index) => {
                switch (line.type) {
                    case 'title':
                        return <h2 key={index}>{line.text}</h2>;
                    case 'subtitle':
                        return <h3 key={index}>{line.text}</h3>;
                    case 'paragraph':
                        return <p key={index}>{line.text}</p>;
                    case 'definition':
                        return <p key={index}><span className='text-highlight-light'>{line.keyword}</span> {line.text}</p>;
                    case 'list':
                        return (
                            <ol key={index}>
                                {line.listItems.map((item, i) => {
                                    return <li key={i}>{item.text}</li>
                                })}
                            </ol>
                        );
                    default:
                        return <></>;
                }
            })}
        </div>
    );
}

// Tip screen popup displayed when NeedAdvice is clicked
const TipScreen = (props) => {
    const { content, image, setTipScreen } = props;
    
    return (
        <div id='tip-screen' className='tip-screen popup'>
            <div className='tip-screen-content popup-content'>
                <TipScreenImage {...image} />
                <ClosePopup setPopup={setTipScreen} type='dark' />
                <div className='tip-screen-text-wrapper'>
                    <TipScreenText content={content} />
                </div>
                <div className='tip-screen-button'>
                    <ContinueButton onNext={() => setTipScreen(null)} />
                </div>
            </div>
        </div>
    );
}

// Tool-tip popup displayed when NeedAdvice is clicked
const ToolTip = (props) => {
    // pass the props to Insight
    return (
        <Insight {...props} setInsight={props.setTipScreen} />
    );
}

const NeedAdvice = (props) => {
    const [tipScreen, setTipScreen] = useState(null);

    const showTipScreen = () => {
        const tip = (() => {
            switch (props.type) {
                case 'tool-tip':
                    return <ToolTip {...props} setTipScreen={setTipScreen} />;
                default:
                    return <TipScreen {...props} setTipScreen={setTipScreen} />;
            };
        })();
        setTipScreen(tip);
    }

    return (
        <div>
            <div className='popup-button need-advice' onClick={showTipScreen}>
                <p>Need Advice ?</p>
            </div>
            {tipScreen}
        </div>
    );
}

export default NeedAdvice;
