import { page2template, page3template } from "./templates";

const page1 = {
  title: "You are currently earning £32,000 annually!",
  caption:
    "After WInvest's budgeting class you have optimized your spendings to 50/30/20",
  description:
    "I have 20% of my monthly salary to dedicate to the future me fund. That's £530/month.",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/pie-chart-1.jpeg",
  },
};

const page2 = {
  ...page2template,
};

const page3 = {
  ...page3template,
};

const options = [
  {
    type: "InstantSavingsAccount",
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-1.png",
    },
    caption: "Start Saving Regularly",
    growth: "4%",
    rate: "Growth per year",
    risk: "low",
  },
  {
    type: "IndexFund",
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/graph-3.png",
    },
    caption: "Increase Investment",
    alt: 'General Investment Account',
    growth: "8%",
    rate: "Growth per year",
    risk: "moderate",
  },
  {
    type: "EmergencyFund",
    image: {
      src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-1.png",
    },
    caption: "Prepare for the Unexpected",
    growth: "4%",
    rate: "Growth per year",
    risk: "low",
  },
];

const outcome = {
  title: "Woohoo!",
  caption:
    "You are among the top 16% of the UK population who have investment accounts.",
};

const tipScreen = {
  content: [
    {
      type: "title",
      text: "Imagine you are a fisherman, out at sea, hunting for big fish..."
    },
    {
      type: "paragraph",
      text: "Saving is like using small mesh fishnets: you'll catch smaller fish and some shrimp.",
    },
    {
      type: "paragraph",
      text: "Investing is like casting a much larger net, where you might even catch a shark.",
    },
    {
      type: "paragraph",
      text: "An emergency fund is your life jacket; when the wind blows your boat off course, it keeps you afloat and allows you to start again. Depending on your risk tolerance and goals, you'll allocate your funds differently.",
    },
    {
      type: "paragraph",
      text: "For example, if you're saving for a holiday or a down payment that requires cash liquidity, you might distribute 50% to savings, 30% to investments, and 20% to your emergency fund.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/sharks.jpeg"
  },
};

const tips = [
  tipScreen,
];

const prompt1 = {
  type: "distribution",
  page1: page1,
  page2: page2,
  page3: page3,
  options: options,
  outcome: outcome,
  tips: tips,
  colour: {
    primary: "#FFDCDC",
    secondary: "#E04B77",
  },
};

export default prompt1;
