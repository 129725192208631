import React from "react";
import { Typography } from "@mui/material";

const Validation = (props) => {
  return (
    <Typography
      fontSize={{ md: "15px" }}
      lineHeight={{ md: "25px" }}
      color={"#4F4F4F"}
      {...props}
    >
      {props.children}
    </Typography>
  );
};

export default Validation;
