import React from "react";
import { Box } from "@mui/material";

const ChipGroup = (props) => {
  return (
    <Box
      display={"flex"}
      alignItems={"flex-start"}
      alignContent={"flex-start"}
      justifyContent={"center"}
      flexWrap={"wrap"}
      width={"100%"}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default ChipGroup;
