import React from "react";
import { Avatar as MuiAvatar, CardHeader, styled } from "@mui/material";

const Avatar = styled(MuiAvatar)(() => ({
  width: 96,
  height: 96,
}));

const Wrapper = styled(CardHeader, {
  shouldForwardProp: (prop) => prop !== "paddingTop",
})(({ theme, paddingTop }) => ({
  padding: 0,
  // paddingTop: "min(80px, 9.47vh)",
  paddingTop,
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  ".MuiCardHeader-content": {
    flex: "0 0 auto",
  },
  ".MuiCardHeader-avatar": { margin: 0 },
}));

const Header = ({ avatar, paddingTop }) => {
  return (
    <Wrapper avatar={<Avatar src={avatar} alt="" />} paddingTop={paddingTop} />
  );
};

export default Header;
