import React from "react";
import { Box, styled, Typography } from "@mui/material";
import RiskLevel from "./RiskLevel";

const Frame = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  alignSelf: "stretch",
  minHeight: 76,
  gap: 12,
}));

const GrowthPerYear = ({ growth }) => {
  const { low, high, other } = growth;
  let text;

  if (low && high) {
    text = `Growth: ~ ${low}% to ${high}% per year`;
  } else if (other) {
    text = `Growth: ${other}`;
  } else {
    text = `Growth: ${growth}% per year`;
  }

  return (
    <Typography variant="bodyEmphasis" textAlign="center">
      {text}
    </Typography>
  );
};

const Details = ({ growth, risk, sx = {} }) => {
  return (
    <Frame sx={sx}>
      <GrowthPerYear growth={growth} />
      <RiskLevel risk={risk} />
    </Frame>
  );
};

export default Details;
