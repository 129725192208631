import React from "react";
import { Box, styled } from "@mui/material";
import Logo from "./Logo";
import Text from "./Text";
import Image from "./Image";
import GridContainer from "../../layouts/description/GridContainer";

const Description = ({ pageDetails }) => {
  const { title, subtitle, color, image } = pageDetails;
  return (
    <GridContainer>
      <Logo />
      <Text title={title} subtitle={subtitle} color={color} />
      <Image src={image} />
    </GridContainer>
  );
};

export default Description;

// eslint-disable-next-line
const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  // alignSelf: "stretch",
  [theme.breakpoints.up("md")]: {
    padding: "40px 0px 0px 40px",
    flex: "1 0 0",
  },
  [theme.breakpoints.down("md")]: {
    padding: "16px 0px 24px 0px",
  },
}));
