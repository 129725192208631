import React from "react";
import { Box, styled, Typography } from "@mui/material";
import ProgressButton from "../ProgressButton";

const Frame = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "stretch",
  justifyContent: "center",
  height: "100%",
  gap: 24,
});

const Image = styled((props) => <Box component={"img"} {...props} />)(
  ({ theme }) => ({
    width: 170,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  })
);

const InnerFrame = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    gap: 24,
    width: "90%",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-start",
    gap: 32,
    width: "70%",
  },
}));

const Text = () => {
  return (
    <Typography
      textAlign={"center"}
      fontWeight={700}
      fontSize={{ xs: "15px", md: "24px" }}
      lineHeight={{ xs: "25px", md: "40px" }}
      flexWrap={"wrap"}
    >
      Let's customise your personalised finance dashboard
    </Typography>
  );
};

const FormContent = () => {
  return (
    <Frame>
      <Image src="assets/images/AdobeStock_532045436.png" />
      <InnerFrame>
        <Text />
        <ProgressButton text={"Let's get started"} />
      </InnerFrame>
    </Frame>
  );
};

export default FormContent;
