import React, { forwardRef } from 'react';
import { getAvatarURL, getMarkerPosition } from '../../playerState';
import { BLOCK_POSITIONS } from '../mapBlocks';

const Marker = forwardRef((_, ref) => {
    const avatar = getAvatarURL();
    const position = BLOCK_POSITIONS[`map-block-${getMarkerPosition()}`];

    return (
        <svg id='progress-marker' x={position.x} y={position.y} width='85' viewBox="0 0 80 101" ref={ref}>
            <g filter="url(#filter0_d_633_5559)">
                <path d="M39.6118 85L20.7525 52.3347L58.4711 52.3347L39.6118 85Z" fill="white"/>
                <circle cx="39.6116" cy="31.6116" r="31.6116" fill="white"/>
                <circle cx="39.6116" cy="31.6116" r="28.09915" fill="url(#avatarPattern)" />
            </g>
            <defs>
                <filter id="filter0_d_633_5559" x="0" y="0" width="79.2227" height="101" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="8"/>
                    <feGaussianBlur stdDeviation="4"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_633_5559"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_633_5559" result="shape"/>
                </filter>
                <pattern id="avatarPattern" patternUnits="userSpaceOnUse" height="100" width="100" >
                    <image href={avatar} x="11.51245" y="3.51245" height="56.1983" width="56.1983" />
                </pattern>
            </defs>
        </svg>
    );
});

export default Marker;
