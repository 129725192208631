import financialProducts from "../financialProducts/financialProducts";

const page1 = {
    content: [
        {
            type: 'title',
            text: 'Why are You with your current Bank?'
        },
        {
            type: 'paragraph',
            text: 'Most of us choose our banks quite randomly. Revolut, HSBC...what\'s the difference? Whilst traditional banks is the most popular, challanger banks want to \'challange\' the old banks, and they are usually more customer-focused, and better services & products. e.g. Monzo, Revolut, etc.'
        }
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-3.png",
    },
};

const page2 = {
    content: [
        {
            type: 'title',
            text: 'Some thoughts when looking for a bank?'
        },
        {
            type: 'paragraph',
            text: 'Ensure that the bank you choose is FSCS (Financial Services Compensation Scheme) protected. This scheme protects your deposits up to £85,000 per person per authorized institution, in case the bank fails.'
        },
        {
            type: 'image',
            src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/fscs.png",
            alt: "Financial Services Compensation Scheme",
            style: {
                height: "94px",
                width: "auto",
            },
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-3.png",
    },
};

const page3 = {
    content: [
        {
            type: 'title',
            text: 'Accessibility & Interest Rate'
        },
        {
            type: 'paragraph',
            text: 'Check if they offer instant access with higher interest rates than the market.'
        }
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-3.png",
    },
};

const instantSavingsAccount = {
    ...financialProducts['InstantSavingsAccount'],
    content: {
        page1: page1,
        page2: page2,
        page3: page3
    },
};

export default instantSavingsAccount;
