const colors = {
  investment: {
    primary: "#C6F54A",
    secondary: "#065D44",
    tertiary: "#0C3520",
    other: "#FFF",
  },
  savings: {
    primary: "#FFB1E2",
    secondary: "#E1349C",
    tertiary: "#400C52",
    other: "#FFF",
  },
  pension: {
    primary: "#FFEF61",
    secondary: "#FFAA2A",
    tertiary: "#4C2413",
    other: "#FFF",
  },
  properties: {
    primary: "#C1EFFE",
    secondary: "#4399FF",
    tertiary: "#110696",
    other: "#FFF",
  },
};

export default colors;
