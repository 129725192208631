const rootStyles = {
  minWidth: 120,
  maxWidth: 310,
  minHeight: 44,
  padding: "15px 24px",
  justifyContent: "center",
  alignItens: "center",
  gap: 8,
  flexShrink: 0,
  borderRadius: 40,
  backgroundColor: "transparent",
};

const Chip = {
  styleOverrides: {
    root: rootStyles,
    label: ({ theme }) => ({
      ...theme.typography.bodyLEmphasis,
    }),
  },
};

export default Chip;
