import React from "react";
import { useAuthContext } from "@hooks";
import theme from "@themes";
import FormContent from "./FormContent";

const Title = () => {
  const { name } = useAuthContext();
  return <>Hi, {name}</>;
};

const Subtitle = () => {
  return <>Welcome to WInvest Collective</>;
};

const content = {
  title: <Title />,
  subtitle: <Subtitle />,
  image: "assets/images/hello-1.png",
  backgroundColor: theme.vars.palette.investment.tertiary,
  color: theme.vars.palette.savings.primary,
  form: <FormContent />,
};

export default content;
