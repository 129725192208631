const page = {
  title:
    "There is a blue chip stock with dividend of 4.5% with potential capital gain.",
  description: "Would you:",
  choices: [
    {
      choice: "Invest £50 per month",
      outcome: 0,
    },
    {
      choice: "Invest £200 at once",
      outcome: 1,
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/pie-chart-2.png",
    style: {
      backgroundColor: "#77B6D1",
    },
  },
};

const outcomes = [
  {
    title: "Well done!",
    caption:
      "Keep up the great work! You invested £600 total this year, receiving approximately £23 as a dividend!",
    change: 0,
  },
  {
    title: "Woohoo!",
      caption:
        "You are one of the 42% of the UK population that holds an investment!",
    change: 1,
  },
];

const changes = [
  {
    change: "increase",
    property: "blueChipStocks",
    by: "50",
    frequency: "month",
  },
  {
    change: "increase",
    property: "blueChipStocks",
    by: "200",
    frequency: "once",
  },
];

const tipScreen = {
  content: [
    {
      type: "title",
      text: "Investing regularly means putting money into investments on a regular basis, a bit at a time.",
    },
    {
      type: "paragraph",
      text: "This helps even out the ups and downs of prices because you're buying at different times. It's good if you want to play it safe and not get too emotional about your investments.",
    },
    {
      type: "paragraph",
      text: "On the other hand, investing a lump sum means putting all your money into investments at once. It's simple and can be good if you think it's the perfect time to invest.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-2.jpeg",
  },
};

const tips = [
  tipScreen,
];

const prompt2 = {
  type: "choice",
  page: page,
  outcomes: outcomes,
  changes: changes,
  tips: tips,
  colour: {
    primary: "#B9EAFF",
    secondary: "#5F32B6",
  },
};

export default prompt2;
