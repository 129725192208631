import React from "react";
import { styled } from "@mui/material";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import { defaultYears } from "@content/financialData";
import {
  axisClasses,
  chartsGridClasses,
  ResponsiveChartContainer,
} from "@mui/x-charts";

const Frame = styled(ResponsiveChartContainer)(({}) => ({
  margin: 0,
  display: "flex",
  [`.${axisClasses.root}`]: {
    [`.${axisClasses.line}`]: {
      stroke: "#E0E0E0",
      strokeWidth: "1px",
    },
    [`.${axisClasses.tickLabel}`]: {
      fill: "#828282",
    },
  },
  [`.${chartsGridClasses.horizontalLine}`]: {
    stroke: "#E0E0E0",
    strokeWidth: "1px",
  },
}));

const GraphContainer = (props) => {
  const { data, ...rest } = props;
  const { state } = useProjectionContext();

  // Dynamically generate projection ages based on the current age
  const generateProjectionAges = () => {
    return defaultYears.map((year) => year + parseInt(state.currentAge));
  };

  const formatYAxisValue = (value) => {
    const symbol = `£`;
    const numericValue = Number(value);
    if (numericValue >= 1_000_000_000) {
      return `${symbol} ${numericValue / 1_000_000_000} B`;
    } else if (numericValue >= 1_000_000) {
      return `${symbol} ${numericValue / 1_000_000} M`;
    } else if (numericValue >= 1_000) {
      return `${symbol} ${numericValue / 1_000} K`;
    } else {
      return `${symbol} ${numericValue}`;
    }
  };

  return (
    <Frame
      {...rest}
      xAxis={[
        {
          id: "ages",
          scaleType: "band",
          data: generateProjectionAges(),
          disableLine: true,
          disableTicks: true,
          categoryGapRatio: 0.5,
          tickLabelStyle: {
            fontSize: "13px",
            fontWeight: 500,
            lineHeight: "14.5px",
          },
        },
      ]}
      yAxis={[
        {
          id: "money",
          valueFormatter: formatYAxisValue,
          disableTicks: true,
          tickLabelStyle: {
            fontSize: "13px",
            lineHeight: "15.983px",
            letterSpacing: "-0.571px",
          },
        },
      ]}
      series={data.map((series) => ({
        ...series,
        valueFormatter: (v) => (v === null ? "" : "£ " + v),
      }))}
      slotProps={{ legend: { hidden: true } }}
    />
  );
};

export default GraphContainer;
