import { Box, styled } from "@mui/material";
import { UserChatBubble } from "../chatbubble";

const Wrapper = styled(Box)(({ theme, sender }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "75%",
  borderRadius: "20px",
  alignSelf: "flex-end",
}));

const UserDisplay = ({ message }) => {
  return (
    <Wrapper>
      <UserChatBubble>{message}</UserChatBubble>
    </Wrapper>
  );
};

export default UserDisplay;
