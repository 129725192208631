import React from 'react';
import PromptChoice from '../promptTypes/PromptChoice';
import { addAnnualChange, changeProperty } from '../../promptCalculations';

const COST = 899;
const BILL = 10;

const page = {
    title:
        `You just bought a new phone for £${COST} and were offered insurance for £${BILL} per month.`,
    description:
        "Would you purchase it? Consider the situation carefully before making any decisions",
    choices: [
        {
            choice: "Yes",
            outcome: 0,
        },
        {
            choice: "No",
            outcome: 1,
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/phone-in-hand.png",
        style: {
            backgroundColor: "#F7BFD9",
        },
    },
};

const change1 = () => {
    changeProperty({ change: "decrease", property: "cash", by: COST, });
    changeProperty({ change: "increase", property: "spending", by: 10 });
}

const change2 = () => {
    changeProperty({ change: "decrease", property: "cash", by: COST, });
}

const changes = [
  change1, change2
];
  
const tipScreen = {
    content: [
        {
            type: "title",
            text: "Phone insurance used to be seen as unnecessary, but with crime rates rising, it's becoming more important. ",
        },
        {
            type: "paragraph",
            text: "Unexpected things happen, and having insurance, along with an emergency fund, helps protect you financially. Without them, people often end up in debt. Think about what you need: travel a lot? Get travel insurance. Regular health checkups? Consider private health insurance. Living in a risky area? Insure your valuables.",
        },
        {
            type: "paragraph",
            text: "Important insurances include Health, Auto, Home/Renter's, Life, and Travel Insurance.",
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/umbrella.jpeg",
        style: {
            backgroundColor: "#DBE9EA",
        },
    },
};
  
const tips = [
    tipScreen,
];

const colour =  {
    primary: "#FFDCDC",
    secondary: "#E04B77",
};

const Prompt6 = (props) => {
    const { setPrompt } = props;

    const outcomes = [
        {
            title: "You got a phone insurance!",
            caption:
                `Now your phone is protected by theft & water damage! £${BILL}/month is deducted from your savings account`,
            change: 0,
        },
        {
            type: "consequence",
            title: "No insurance for your phone",
            caption: "It's okay, insurance is a personal choice. Nothing changes in your finances.",
            change: 1,
        },
    ];

    const prompt6 = {
        type: "choice",
        page: page,
        outcomes: outcomes,
        changes: changes,
        tips: tips,
        colour: colour,
    };
  
    return (
        <PromptChoice {...prompt6} setPrompt={setPrompt} />
    );
}

export default Prompt6;
