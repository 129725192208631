import allAboutPension from "./allAboutPension";
import benefitsOfInvesting from "./benefitsOfInvesting";
import savingsForBeginners from "./savingsForBeginners";
import scalingPropertyInvestments from "./scalingPropertyInvestments";

const workshops = {
    "benefitsOfInvesting": benefitsOfInvesting,
    "scalingPropertyInvestments": scalingPropertyInvestments,
    "allAboutPension": allAboutPension,
    "savingsForBeginners": savingsForBeginners,
};

export default workshops;
