import React from "react";
import { Grid, styled } from "@mui/material";

const GridContainer = styled(({ ...props }) => (
  <Grid item xs={12} md={6} {...props} />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
  justifyContent: "space-between",
  height: "100%",
  overflow: "hidden",
}));

export default GridContainer;
