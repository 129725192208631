const title = {
    type: "title",
    text: "Think of this as a duet with your employer - a pension scheme provided by your employer, which includes contributions from both you and your employer.",
};

const description = {
    type: "body",
    text: "Workplace pensions are straightforward and beneficial, especially with employer contributions. There's no minimum deposit, so you can start saving right away.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Great for employees, offering a straightforward way to save for retirement with employer contributions boosting your savings.",
        },
    ],
};

const growth = {
    type: "section",
    heading: "Growth",
    body: [
        {
            type: "body",
            text: "Typically 5% to 8% per year. According to the Pensions and Lifetime Savings Association (PLSA), average workplace pension funds have returned about 6-8% annually over the past decade.",
        },
    ],
};

const risk = {
    type: "section",
    heading: "Risk",
    body: [
        {
            type: "body",
            text: "Moderate to High (depending on investment choices)",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "Nest Retirement Date Funds",
                    subheading: "Average annual growth of 6% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "The People's Pension Growth Fund",
                    subheading: "Average annual growth of 7% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "NOW Pensions Diversified Growth Fund",
                    subheading: "Average annual growth of 6.5% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you're employed, a workplace pension is an effortless way to build your retirement fund, especially with employer contributions.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Enrolled through your employer, managed via the respective provider's portal or website.",
        },
    ],
};

const workplacePension = [
    title,
    description,
    benefits,
    growth,
    risk,
    providersExamples,
    greatFor,
    accessibility,
];

export default workplacePension;
