const title = {
    type: "title",
    text: "This is like your favourite gym membership - you commit regularly, and over time, you get some nice rewards (interest).",
};

const description = {
    type: "body",
    text: "This account is perfect for keeping your savings accessible while earning a bit of interest. There's no minimum deposit, so you can start saving right away.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Great for disciplined savers who want to build a savings habit with monthly contributions.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "body",
            text: "HSBC Regular Saver, NatWest Regular Saver, Lloyds Bank Monthly Saver.",
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you're saving for a big purchase like a holiday or a new gadget, this account helps you get there faster with higher interest rates.",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Example",
    body: [
        {
            type: "section",
            subheading: "HSBC Regular Saver",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Interest Rate: Up to 3.00% AER (Annual Equivalent Rate)",
                        },
                    ],
                },
            ],
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Requires monthly deposits and may have restrictions on withdrawals.",
        },
    ],
};

const regularSavingsAccount = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
    examples,
    accessibility,
];

export default regularSavingsAccount;
