import React from 'react';

// setPopup is the set function returned by useState for an element
const ClosePopup = ({ setPopup, type = 'light' }) => {
    return (
        <svg className='close-popup' onClick={() => setPopup(null)}
            width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <circle opacity={type === 'dark' ? '0.3' : '0.06'} cx="20" cy="20" r="20" fill="black"/>
            <path
                d="M14.3332 13C13.9922 13 13.6505 13.1303 13.3905 13.3906C12.8698 13.9113 12.8698 14.7554 13.3905 15.276L18.1145 20L13.3905 24.724C12.8698 25.2446 12.8698 26.0887 13.3905 26.6094C13.6505 26.8694 13.9922 27 14.3332 27C14.6742 27 15.0159 26.8697 15.2759 26.6094L19.9999 21.8854L24.7238 26.6094C25.2438 27.13 26.0889 27.13 26.6093 26.6094C27.1303 26.0884 27.1303 25.245 26.6093 24.724L21.8853 20L26.6093 15.276C27.1299 14.7554 27.1299 13.9113 26.6093 13.3906C26.0893 12.8703 25.2438 12.87 24.7238 13.3906L19.9999 18.1146L15.2759 13.3906C15.0159 13.1306 14.6742 13 14.3332 13Z"
                fill={type === 'dark' ? 'white' : 'black'}
                fillOpacity={type === 'dark' ? '1' : '0.3'}
            />
        </svg>
    );
}

export default ClosePopup;
