const title = {
    type: "title",
    text: "It's like a VIP club for your savings.",
};

const description = {
    type: "body",
    text: "You get interest without paying any tax on it. Cash ISAs offer tax-free interest on your savings, making them an attractive option for both short-term and long-term goals. There's no minimum deposit, so you can start saving right away.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Perfect for anyone who wants tax-free interest on their savings. Ideal for both short-term and long-term savings.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Virgin Money Cash ISA",
            subheading: "Average annual return of 1.5% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available directly through the Virgin Money website, branches, and mobile app.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Aldermore Cash ISA",
            subheading: "Average annual return of 1.7% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available online via the Aldermore website.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Coventry Building Society Cash ISA",
            subheading: "Average annual return of 1.8% over the past 10 years.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Accessibility: Available through the Coventry Building Society website, branches, and customer service.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you're saving for a rainy day or a specific goal like a wedding, a Cash ISA keeps your interest tax-free.",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Example",
    body: [
        {
            type: "section",
            subheading: "Coventry Building Society Cash ISA",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Interest Rate: Up to 2% AER",
                        },
                    ],
                },
            ],
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Typically allows easy access, but may vary by provider.",
        },
    ],
};

const cashISA = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
    examples,
    accessibility,
];

export default cashISA;
