import React from "react";
import { styled, Typography } from "@mui/material";
import { fontWeights } from "@themes";
import { fluidTypography } from "@utils/themes";

const Title = styled(({ ...props }) => <Typography {...props} />)(
  ({ theme }) => ({
    fontSize: fluidTypography("fontSize", 476, 1440, 36, 56, "rem"),
    lineHeight: fluidTypography("lineHeight", 476, 1440, 1.5, 76 / 56, ""),
    fontWeight: fontWeights.BOLD,
    letterSpacing: "0em",
    color: theme.vars.palette.savings.primary,
  })
);

const Subtitle = styled(({ ...props }) => <Typography {...props} />)(
  ({ theme }) => ({
    fontSize: fluidTypography("fontSize", 476, 1440, 16, 20, "rem"),
    lineHeight: fluidTypography("lineHeight", 476, 1440, 28 / 16, 36 / 20, ""),
    fontWeight: fontWeights.REGULAR,
    letterSpacing: "0em",
    color: theme.vars.palette.savings.primary,
  })
);

export { Title, Subtitle };
