import { Box, styled } from "@mui/material";
import StarterCard from "./StarterCard";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "12px 64px 0px 16px",
  alignItems: "flex-start",
  gap: "8px",
  alignSelf: "stretch",
  overflowX: "auto",
  width: "100%",
}));

const colors = ["#FFE5ED", "#ECDDFF", "#E0EDE0", "#FCF4D6", "#E0F5F9"];

const options = [
  "How can I start investing with a small amount of money?",
  "How can I negotiate a higher salary or a raise?",
  "How can I create an effective budget for my lifestyle?",
  "How can I balance work, family, and financial planning?",
  "What are the best financial education resources for women?",
];

const ConversationStarters = ({ onSelect }) => {
  return (
    <Container>
      {options.map((text, index) => (
        <StarterCard
          key={index}
          text={text}
          backgroundColor={colors[index % 5]}
          onClick={() => onSelect(text)}
        />
      ))}
    </Container>
  );
};

export default ConversationStarters;
