import React from "react";
import { Stack, styled } from "@mui/material";

const HorizontalScroll = styled(Stack)(() => ({
    height: "fit-content",
    overflowX: "auto",
    padding: "0px 16px",
    width: "100%",
}));

const ScrollableCards = ({ children }) => {
    return (
        <HorizontalScroll
            direction="row"
            spacing="8px"
        >
            {children}
        </HorizontalScroll>
    );
};

export default ScrollableCards;
