import React from "react";
import { Box, Grid, styled } from "@mui/material";
import {
  HelloCard,
  InvestmentAccordion,
  PensionAccordion,
  SavingsAccordion,
  RealEstateAccordion,
} from "@features/projection/components/sidebar";

const Frame = styled((props) => <Grid item xs={12} md={4} xl={3} {...props} />)({
  display: "flex",
  flex: "1 0 0",
  flexDirection: "column",
  alignSelf: "stretch",
  alignItems: "flex-end",
  gap: 8,
});

const Sidebar = () => {
  return (
    <Frame>
      <HelloCard />
      <InvestmentAccordion />
      <SavingsAccordion />
      <PensionAccordion />
      <RealEstateAccordion />
      {/* only there so that the last accordion fully renders its bottom border */}
      <Box height={0} />
    </Frame>
  );
};

export default Sidebar;
