import React from "react";
import { Box } from "@mui/material";
import { InputQuestion } from "@features/questionnaire/layouts/questions/InputQuestion";
import { ChipQuestion } from "@features/questionnaire/layouts/questions/ChipQuestion";
import { CheckboxQuestion } from "@features/questionnaire/layouts/questions/CheckboxQuestion";

const getTextField = (section, index) => {
  const { id, label, dataType, question } = section;
  return (
    <InputQuestion
      key={index}
      id={id}
      label={label}
      type={dataType}
      question={question}
    />
  );
};

const getChip = (section, index) => {
  const { id, dataType, question, validation, options, other } = section;
  return (
    <ChipQuestion
      key={index}
      selectType={dataType}
      id={id}
      question={question}
      validation={validation}
      options={options}
      other={other}
    />
  );
};

const getCheckbox = (section, index) => {
  const { id, question, options } = section;
  return (
    <CheckboxQuestion
      key={index}
      id={id}
      question={question}
      options={options}
    />
  );
};

const FormContent = ({ questionSet }) => {
  const sections = questionSet.map((section, index) => {
    let component;
    const { inputType } = section;
    switch (inputType) {
      case "textfield":
        component = getTextField(section, index);
        break;
      case "chip":
        component = getChip(section, index);
        break;
      case "checkbox":
        component = getCheckbox(section, index);
        break;
      default:
        break;
    }
    return component;
  });

  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-end"}>
      {sections}
    </Box>
  );
};

export default FormContent;
