const title = {
    type: "title",
    text: "This is your personal guru - a professional financial advisor who offers bespoke investment advice and management based on your unique goals and style.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Great for individuals with complex financial situations or those who prefer a personal touch. Financial advisors provide bespoke advice, helping you navigate the financial landscape with ease.",
        },
    ],
};

const growth = {
    type: "section",
    heading: "Growth",
    body: [
        {
            type: "body",
            text: "Typically 5% to 10% per year, depending on the advisor's strategy and your investment profile.",
        },
    ],
};

const risk = {
    type: "section",
    heading: "Risk",
    body: [
        {
            type: "body",
            text: "Varies depending on the advisor's strategy and your risk tolerance.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "St. James's Place Wealth Management",
                    subheading: "Average annual growth of 8% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Hargreaves Lansdown Financial Advice",
                    subheading: "Average annual growth of 7.5% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Brewin Dolphin",
                    subheading: "Average annual growth of 7% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you crave a personalized touch and expert guidance, a human financial advisor is your go-to.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Available through financial advisory firms, by appointment. Often requires a higher initial investment and ongoing fees.",
        },
    ],
};

const humanFinancialAdvisor = [
    title,
    benefits,
    growth,
    risk,
    providersExamples,
    greatFor,
    accessibility,
];

export default humanFinancialAdvisor;
