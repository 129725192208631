import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMarkerPosition, getNetWorthStates, initGame, rollbackNetWorth, saveNetWorth, setMarkerPosition } from '../playerState';
import { BLOCK_POSITIONS, FINISH_BLOCK, NUM_PROMPTS, START_BLOCK } from './mapBlocks';
import WealthQuestTitle from '../components/WealthQuestTitle';
import MyNetWorth from '../components/MyNetWorth';
import FinancialGuide from '../components/FinancialGuide';
import background from './components/background.svg';
import Path from './components/Path';
import Marker from './components/Marker';
import arrowButton from './components/arrowButton.svg';
import Prompt from '../Prompts/Prompt';
import './GameMap.css';
import { applyChanges, applyInterval } from '../promptCalculations';

// Custom hook used to execute initGame() only once before GameMap renders
const usePreRenderOnce = (callback) => {
    const hasRun = useRef(false);
  
    if (!hasRun.current) {
        callback();
        hasRun.current = true;
    }
}

const MapHeader = () => {
    return (
        <header className='popup-container'>
            <WealthQuestTitle size='80px' color='green' />
            <div id='net-worth-financial-guide'>
                <MyNetWorth />
                <FinancialGuide />
            </div>
        </header>
    );
}

const PathAndMarker = forwardRef(({ showPrompt }, markerRef) => {
    return (
        <svg
            id='map-path'
            viewBox="0 0 1950 719"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <Path showPrompt={showPrompt} />
            <Marker ref={markerRef} />
        </svg>
    );
});

const Map = forwardRef(({ showPrompt }, markerRef) => {
    return (
        <div className='map'>
            <img id='map-background' src={background} alt='' />
            <PathAndMarker showPrompt={showPrompt} ref={markerRef} />
        </div>
    );
});

const MapFooter = ({ visible, moveBack, moveForward }) => {
    const [ back, forward ] = visible;
    return (
        <footer>
            {/* back && */<img
                id='map-back'
                src={arrowButton}
                alt='Back'
                onClick={moveBack}
                style={{ transform: 'scaleX(-1)', visibility: back ? '' : 'hidden' }}
            />}
            {/* forward && */<img
                id='map-forward'
                src={arrowButton}
                alt='Forward'
                onClick={moveForward}
                style={{ visibility: forward ? '' : 'hidden' }}
            />}
        </footer>
    );
}

const GameMap = () => {
    const markerRef = useRef(null);
    const [prompt, setPrompt] = useState(null);
    // Indicates whether the arrow buttons are visible or not
    const [visible, setVisible] = useState([false, true]);
    const navigate = useNavigate();

    // Initialise the game once before the GameMap renders
    usePreRenderOnce(() => {
        initGame();
    });

    const scrollBehaviour = () => {
        const marker = markerRef.current;
        if (marker) {
            marker.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
    }

    const moveMarker = (nextBlock) => {
        const nextPosition = BLOCK_POSITIONS[`map-block-${nextBlock}`];

        const marker = markerRef.current;
        marker.setAttribute('x', nextPosition.x);
        marker.setAttribute('y', nextPosition.y);
        scrollBehaviour();

        setMarkerPosition(nextBlock);
    }

    const moveBack = () => {
        const block = getMarkerPosition();
        const nextBlock = block === FINISH_BLOCK
            ? NUM_PROMPTS
            : block === START_BLOCK
                ? START_BLOCK
                : block - 1;
        moveMarker(nextBlock);

        if (nextBlock !== FINISH_BLOCK) {
            setVisible([nextBlock !== START_BLOCK, true]);
        }

        rollbackNetWorth();
        console.log(getNetWorthStates());
    }

    const moveForward = () => {
        const block = getMarkerPosition();
        const nextBlock = block === NUM_PROMPTS
            ? FINISH_BLOCK
            : block === FINISH_BLOCK
                ? FINISH_BLOCK
                : block + 1;
        moveMarker(nextBlock);

        if (nextBlock !== START_BLOCK) {
            setVisible([true, nextBlock !== FINISH_BLOCK]);
        }

        // Nothing is applied when player moves from start to first prompt
        if (block !== START_BLOCK) {
            // Applies changes to net worth based on choices made
            applyChanges();
            // Applies the n-year interval between prompts
            applyInterval();
        }
        saveNetWorth();
        console.log(getNetWorthStates());
    }

    // onClick behaviour for path blocks
    const showPrompt = (blockClicked, props) => {
        const markerPosition = getMarkerPosition();

        // If on the "finish" prompt, navigate to "./game/over"
        // Otherwise, display the relevant prompt
        if (markerPosition === blockClicked) {
            if (markerPosition === FINISH_BLOCK) {
                navigate('../over', { replace: true });
            } else {
                setPrompt(
                    <Prompt
                        index={markerPosition}
                        {...props}
                        setPrompt={setPrompt}
                    />
                );
            }
        }
    }

    return (
        <div className='game-map'>
            <MapHeader />
            <Map showPrompt={showPrompt} ref={markerRef} />
            <MapFooter visible={visible} moveBack={moveBack} moveForward={moveForward} />
            {prompt}
        </div>
    );
}

export default GameMap;
