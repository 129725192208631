import { styled, FormControl } from "@mui/material";
import {
  base,
  formHelperText,
  inputBaseInput,
  inputBaseRoot,
  inputLabelShrink,
  inputLabelRoot,
} from "./styles";

const StyledFormControl = styled(FormControl)(
  ({ isdark, error, inputbasestyles }) => ({
    ...base(),
    "& .MuiInputBase-root": inputBaseRoot(isdark, error, inputbasestyles),
    "& .MuiInputBase-input": inputBaseInput(isdark),
    "& .MuiInputLabel-root": inputLabelRoot(isdark, error),
    "& .MuiInputLabel-shrink": inputLabelShrink(),
    "& .MuiFormHelperText-root": formHelperText(),
  })
);

export default StyledFormControl;
