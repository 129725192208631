import React from "react";
import { Box, styled } from "@mui/material";

const Rect = ({ width = 32, height = 6, color = "white" }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 32 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="32" height="6" rx="3" fill={color} />
    </svg>
);

const Dot = ({ size = 6, color = "white" }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="6" height="6" rx="3" fill={color} />
    </svg>
);

const Container = styled(Box)(() => ({
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.20)",
    borderRadius: 32,
    display: "flex",
    flexDirection: "row",
    gap: 8,
    justifyContent: "center",
    padding: "0px 24px",
}));

const StepIcon = (props) => {
    const { step, of } = props;

    const icons = (() => {
        const array = [];
        for (let i = 1; i <= of; i++) {
            array.push(i === step ? <Rect key={i} /> : <Dot key={i} />);
        }
        return array;
    })();
    
    return (
        <Container>
            {icons}
        </Container>
    );
};

export default StepIcon;
