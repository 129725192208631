import React from "react";
import { InputAdornment, Typography } from "@mui/material";
import { startAdornment } from "./styles";

const StartAdornment = (props) => {
  return (
    <InputAdornment
      position="start"
      sx={startAdornment(props.startadornment, props.isdark)}
    >
      <Typography fontSize={"20px"} lineHeight={"36px"}>
        {props.startsymbol}
      </Typography>
    </InputAdornment>
  );
};

export default StartAdornment;
