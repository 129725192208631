import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FinancialCard from "../FinancialCard";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import marketingTips from "./marketingTips";
import TextField from "@ui-library/TextField";

const Heading = () => {
  return (
    <Typography fontSize={"13px"} color={"#442909"}>
      Get a clear insight into your retirement plans by entering key details.
    </Typography>
  );
};

const Inputs = () => {
  const { state, dispatch } = useProjectionContext();
  const { pension } = state;

  const handleChange = (event) => {
    const { name, value } = event.target;

    // First, remove leading zeros, but allow a single zero before a decimal point
    const sanitizedValue = value.replace(/^0+(?!\.|$)/, "");

    // Allow digits, a single dot, and nothing else.
    // This regex allows values like '2.', '2.0', '0.', etc.
    if (/^\d*\.?\d*$/.test(sanitizedValue)) {
      dispatch({
        type: "SET_PENSION",
        payload: { ...pension, [name]: sanitizedValue },
      });
    }
  };

  const textFields = [
    {
      id: "annualSalary",
      name: "annualSalary",
      label: "Annual Salary",
      startsymbol: "£",
      value: pension.annualSalary,
      tooltip: "Enter your gross income.",
    },
    {
      id: "totalPension",
      name: "totalPension",
      label: "Your Total Pension",
      startsymbol: "£",
      value: pension.totalPension,
      tooltip:
        "Enter the sum of your total previous and current pension funds.",
    },
    {
      id: "yourContribution",
      name: "yourContribution",
      label: "Your Contribution *",
      startsymbol: "%",
      value: pension.yourContribution,
      tooltip:
        "Enter your contribution from your salary, a ratio of your overall pension. If unsure, check with your employer; the default is 4%, meeting the minimum requirement.",
    },
    {
      id: "totalContribution",
      name: "totalContribution",
      label: "Total Contribution",
      startsymbol: "%",
      value: pension.totalContribution,
      tooltip:
        "This is the sum of your contribution and your employer’s contribution. If the exact amount is unknown, the default is set at 8%, meeting the minimum requirement.",
    },
    {
      id: "interestRatePension",
      name: "interestRate",
      label: "Annual Compound Interest",
      startsymbol: "%",
      value: pension.interestRate,
      tooltip:
        "Enter the annual interest rate of your pension fund. We default to a 4% average annual return rate based on historical pension performance from 2001 to 2023.",
    },
  ];

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
      {textFields.map((data, i) => (
        <TextField
          key={i}
          tooltip={"true"}
          tooltipcontent={data.tooltip}
          id={data.id}
          name={data.name}
          label={data.label}
          startadornment="true"
          startsymbol={data.startsymbol}
          value={data.value}
          onChange={handleChange}
        />
      ))}{" "}
    </Box>
  );
};

const PensionAccordion = () => {
  const theme = useTheme();
  const colorSet = theme.vars.palette.pension;

  const tipButtonColors = {
    circleColor: colorSet.secondary,
    pathColor: colorSet.tertiary,
    hoverCircleColor: colorSet.tertiary,
    hoverPathColor: colorSet.primary,
  };

  return (
    <FinancialCard
      title={"Pension"}
      backgroundColor={colorSet.primary}
      color={colorSet.tertiary}
      tipModalContent={marketingTips.pension}
      tipButtonColors={tipButtonColors}
    >
      <Heading />
      <Inputs />
    </FinancialCard>
  );
};

export default PensionAccordion;
