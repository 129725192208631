import React, { useState } from 'react';

const ChoiceButton = (props) => {
    const { choice, onChoice } = props;

    return (
        <button
            className='button-choice'
            onClick={onChoice}
        >{choice}</button>
    );
}

// Used in ProductSelection and ProductDistribution
const ConfirmButton = ({ onConfirm }) => {
    return (
        <button
            className='button-confirm'
            onClick={onConfirm ? onConfirm : () => {}}
        >Confirm Selection</button>
    );
}

const ContinueButton = ({ onNext }) => {
    return (
        <button
            className='button-continue'
            onClick={onNext ? onNext : () => {}}
        >Let's see what you can do</button>
    );
}

// Used in ProductSelection
const SelectButton = ({ selected, onSelect }) => {
    return (
        <button
            className={selected ? 'button-select-clicked' : 'button-select'}
            onClick={onSelect}
        >{selected ? `${String.fromCharCode(0x2713)} Selected` : 'Select'}</button>
    );
}

const SkipButton = ({ setPrompt, style }) => {
    return (
        <button
            className='button-skip'
            onClick={() => setPrompt(null)}
            style={style}
        >Skip for now</button>
    );
}

export default ChoiceButton;
export {
    ConfirmButton, ContinueButton, SelectButton, SkipButton
};
