const page1 = {
  title: "You just moved to a new job, with a 30% pay rise",
  caption:
    "You did your research and think you can argue for another 10% at that level. They are really keen to have you.",
  description:
    "Each negotiation is an opportunity to advocate for myself. So, I will approach it with confidence and have a growth mindset to practice this.",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/people-1.jpeg",
  },
};

const page2 = {
  title: "You just moved to a new job, with a 30% pay rise",
  caption:
    "You did your research and think you can argue for another 10% at that level. They are really keen to have you.",
  description:
    "What would you do? Consider the situation carefully before making any decisions",
  choices: [
    {
      choice: "Argue for the 15%",
      outcome: {
        title: "You got 10% higher salary!",
        caption:
          "Well done on doing your research and staying calm in your negotiation. Your employer has offered you 10% and you agree to it!",
      },
    },
    {
      choice: "Argue for the 10%",
      outcome: {
        title: "You got 5% higher salary!",
        caption:
          "Well done on doing your research and staying calm in your negotiation. Your employer has offered you 5% and you agree to it!",
      },
    },
    {
      choice: "Leave it",
      outcome: {
        title: "Congratulations on your new job!",
        caption:
          "You are happy with what they proposed, so no negotiation, and you accepted the offer.",
      },
    },
  ],
};

const tipScreen = {
  content: [
    { type: "subtitle", text: "Know When to Negotiate:" },
    {
      type: "paragraph",
      text: "There are typically two good opportunities to negotiate your salary: when you've been in the same role for at least 6 months or when you're taking on a new role.",
    },
    { type: "subtitle", text: "Know When Not to Negotiate:" },
    {
      type: "paragraph",
      text: "Salary negotiation may not be possible in roles with clear salary bands, graduate schemes, and many charity/government positions.",
    },
    { type: "subtitle", text: "Determine Your Worth:" },
    {
      type: "paragraph",
      text: "Understand your market value and decide on three important numbers: The highest salary you're aiming for. The salary you would be satisfied with. Your absolute minimum, or \"walk away\" number.",
    },
    { type: "subtitle", text: "Timing is Key:" },
    {
      type: "paragraph",
      text: "In a new job, it's often better to wait until you receive an offer before discussing salary. Allow your skills and abilities to impress your employer first. In an existing job, provide examples of your successes and their positive impact on the company, quantifying your contributions. Remember that salary negotiation can be a delicate process, so approach it with abundant mindset and preparation. ",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-2.jpeg",
  },
};

const tips = [
  tipScreen,
];

const prompt7 = {
  type: "choice-extended",
  page1: page1,
  page2: page2,
  tips: tips,
  colour: {
    primary: "#FFE795",
    secondary: "#E04B77",
  },
};

export default prompt7;
