const content = [];

const savingsForBeginners = {
    category: "Savings",
    topic: "Savings for Beginners",
    datetime: {
        date: "10 July",
        time: "05:00 PM - 05:30 PM",
    },
    tier: "free",
    image: "/learn/workshops/savings.png",
    color: "#FF95B5",
    content: content,
};

export default savingsForBeginners;
