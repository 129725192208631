import React from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Typography,
    styled,
    useMediaQuery,
} from "@mui/material";
import { fontWeights } from "@themes";

// Styles for the top-level component
const Container = styled(Card, {
    shouldForwardProp: (prop) => prop !== "isLessThan375px",
})(({ theme, isLessThan375px }) => ({
    backgroundColor: theme.vars.palette.common.white,
    border: "1px solid rgba(0, 0, 0, 0.10);",
    borderRadius: 12,
    boxShadow: "var(--winvest-shadows-0)",  // "none"
    flex: 1,
    minWidth: isLessThan375px ? 300 : 355,
    position: "relative",
    width: isLessThan375px ? 300 : 355,
}));

// Styles for the header, indicating the workshop "category"
const Header = styled(CardHeader)(({ theme }) => ({
    position: "absolute",
    width: "inherit",
    ".MuiCardHeader-title": {
        ...theme.typography.callout,
        color: theme.vars.palette.common.white,
    },
}));

// Sty;es for the image displayed on the card
const Image = styled(CardMedia)(({ color }) => ({
    backgroundColor: color,
    height: "160px",
    objectPosition: "center",
    paddingLeft: "20px",
}));

// Styles for the text content, indicating topic, date and time
const ContentWrapper = styled(CardContent)(() => ({
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    gap: 4,
    padding: "12px 16px",
    ":last-child": { paddingBottom: 12 },
}));

// Styles for the tier (e.g. "FREE")
const Tier = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "isLessThan375px",
})(({ theme, isLessThan375px }) => ({
    ...theme.typography.bodyS,
    alignItems: "center",
    alignSelf: "stretch",
    background: "#F3F3F3", // UI-100
    borderRadius: 32,
    color: "#168263", // UI-400
    display: "flex",
    fontWeight: fontWeights.MEDIUM,
    height: 25,
    padding: "8px 12px",
    // Styles when screen width is 300px or less
    position: isLessThan375px ? "absolute" : "",
    right: isLessThan375px ? "16px" : "",
    top: isLessThan375px ? "120px" : "",
}));

// Workshop topic and tier (e.g. "FREE")
const Details = (props) => {
    const { topic, tier, isLessThan375px } = props;

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            minHeight={25}
            justifyContent="space-between"
        >
            <Typography variant="bodyLEmphasis">{topic}</Typography>
            <Tier isLessThan375px={isLessThan375px}>
                {tier && tier.toUpperCase()}
            </Tier>
        </Box>
    );
};

// Date and time of the workshop
const DateTime = (props) => {
    const { date, time } = props;
    const color = "#5C736C";  // UI-700

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            gap="8px"
            minHeight={25}
        >
            <Typography variant="bodyEmphasis" color={color}>
                {date}
            </Typography>
            <Typography variant="body" color={color}>
                {time}
            </Typography>
        </Box>
    );
};

const WorkshopCard = (props) => {
    const {
        category, topic, datetime, tier, image, color, sx, onClick = () => {},
     } = props;
    // Media query that matches when screen width is 300px or less
    const isLessThan375px = useMediaQuery("(max-width: 375px)");

    return (
        <Container isLessThan375px={isLessThan375px} sx={sx} onClick={onClick}>
            <Header title={category} />
            <Image component="img" image={image} color={color} />
            <ContentWrapper>
                <Details
                    topic={topic}
                    tier={tier}
                    isLessThan375px={isLessThan375px}
                />
                <DateTime {...datetime} />
            </ContentWrapper>
        </Container>
    );
};

export default WorkshopCard;
