const title = {
    type: "title",
    text: "Replaces part of your income if you can't work.",
};

const description = {
    type: "body",
    text: "Income protection insurance provides financial support if you're unable to work due to illness or injury.",
}

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Provides financial support if you're unable to work due to illness or injury.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "LV= Income Protection",
            subheading: "Covers up to 60% of your income if you're unable to work.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £10 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available directly from the LV= website or through financial advisors.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Aviva Income Protection",
            subheading: "Provides a monthly income if you're unable to work due to illness or injury.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £15 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available on the Aviva website or through Aviva brokers.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Ensure you have a steady income even when you're unable to work with income protection insurance.",
        },
    ],
};

const incomeProtectionInsurance = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
];

export default incomeProtectionInsurance;
