const page1 = {
  type: 'choice',
  title:
    "There is an index fund A available in your account.",
  caption: "It is tracking the biggest 500 companies in the US. In the past 20 years it gives average of 11%.",
  description: "Would you like to:",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/crisis.jpeg",
  },
  choices: [
    {
      choice: "Invest £150 per month",
      outcome: {
        type: "next-page",
      },
    },
    {
      choice: "Invest £2000 at once",
      outcome: {
        type: "next-page",
      },
    },
  ],
};

const page2 = {
  type: 'choice',
  title: "Recession happened",
  caption: "Stock market went down 8%, as a result, your index fund has gone down 9% as well.",
  description: "Would you like to:",
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/crisis.jpeg",
  },
  choices: [
    {
      choice: "Keep it",
      outcome: {
        title: "Woohoo, you kept it!",
        caption: "Investors sell at lows due to the intense pain of financial loss. By staying calm and not selling, your index fund rebounded and grew by 12% the following year.",
      },
    },
    {
      choice: "Sell it and take the loss",
      outcome: {
        title: "You sold your fund!",
        caption: "Now you sold your index fund, we put that into the saving accounts for you!",
      },
    },
  ],
  tip: 2,
};

const tipScreen1 = {
  content: [
    {
      type: "title",
      text: "Investing regularly means putting money into investments on a regular basis, a bit at a time.",
    },
    {
      type: "paragraph",
      text: "This helps even out the ups and downs of prices because you're buying at different times. It's good if you want to play it safe and not get too emotional about your investments.",
    },
    {
      type: "paragraph",
      text: "On the other hand, investing a lump sum means putting all your money into investments at once. It's simple and can be good if you think it's the perfect time to invest.",
    },
  ],
};

const tipScreen2 = {
  content: [
    {
      type: "title",
      text: "Market's down? Quick Tip:",
    },
    {
      type: "subtitle",
      text: "Maintain Separate Finances with a Joint Account, formal prenup:",
    },
    {
      type: "list",
      listType: "ordered",
      listItems: [
        {
          text: "Stay Calm: Don't panic sell.",
        },
        {
          text: "Stick to Strategy: Think long-term, not short-term losses.",
        },
        {
          text: "Buy Low: Downturns can be a chance to buy good investments cheap.",
        },
        {
          text: "Check Funds: Ensure you have cash for emergencies.",
        },
        {
          text: "Diversify: Spread out your investments to reduce risk.",
        },
        {
          text: "Avoid Timing: Don't try to predict market moves.",
        },
        {
          text: "Learn and Adjust: Use this as a learning opportunity and tweak your plan if needed.",
        },
      ],
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/panic-button.jpeg",
  },
};

const tips = [
  tipScreen1,
  tipScreen2,
];

const prompt10 = {
  type: "custom",
  pages: [
    page1,
    page2,
  ],
  tips: tips,
  colour: {
    primary: "#D9C5FF",
    secondary: "#5F32B6",
  },
};

export default prompt10;
