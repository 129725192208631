import React from "react";
import { Box, Button as MuiButton, styled } from "@mui/material";
import { base, conditional } from "./styles";

const StyledButton = styled(MuiButton)(
  ({ theme, type = "primary", size = "large", custom }) => ({
    ...base(size),
    ...conditional(theme, size)[type]?.default,
    "&:hover": {
      ...conditional(theme)[type]?.default,
      ...conditional(theme)[type]?.hover,
    },
    "&:active": {
      ...conditional(theme)[type]?.default,
      ...conditional(theme)[type]?.pressed,
    },
    "&.Mui-disabled": {
      ...conditional(theme)[type]?.default,
      ...conditional(theme)[type]?.inactive,
    },
    "&.Mui-focusVisible": {
      ...conditional(theme)[type]?.default,
      ...(type === "tertiary" ? conditional(theme)[type]?.focused : {}),
    },
    "&[data-in-progress=true]": {
      ...conditional(theme)[type]?.default,
      ...conditional(theme)[type]?.inProgress,
    },
  })
);

const InProgressIcon = () => {
  return (
    <Box
      component={"img"}
      src="assets/images/loading-dots.gif"
      display={"flex"}
      width={"24px"}
      height={"24px"}
    />
  );
};

const Button = ({ type, children, size, custom, ...props }) => {
  return (
    <StyledButton type={type} size={size} custom={custom} {...props}>
      {props["data-in-progress"] && <InProgressIcon />}
      {type === "icon" ? (
        <Box width={"24px"} height={"24px"}>
          {children}
        </Box>
      ) : (
        children
      )}
      {/* {children} */}
    </StyledButton>
  );
};

export default Button;
