const getTooltipContentStyles = () => ({
  display: "flex",
  width: "240px",
  padding: "23px 16px 24px 24px",
  alignItems: "flex-start",
  borderRadius: "16px",
  backgroundColor: "#222",
  color: "#FFF",
  boxShadow: "4px 4px 16px 0px rgba(0, 0, 0, 0.25)",
  fontSize: "13px",
  fontWeight: 300,
  lineHeight: "22px",
});

const getQuestionMarkIconStyles = () => ({
  cursor: "pointer",
  color: "#FFF",
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  borderRadius: "11px",
  padding: "3px",
  height: "22px",
  width: "22px",
  transition: "background-color 0.3s",
  ".Mui-focused &": {
    backgroundColor: "black",
  },
  "&:hover": {
    backgroundColor: "black",
  },
});

export { getTooltipContentStyles, getQuestionMarkIconStyles };
