import React from "react";
import { styled, IconButton, SvgIcon } from "@mui/material";

const Icon = () => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      inheritViewBox
      style={{ height: "100%", width: "auto" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_2761_71055)">
          <path
            d="M15.75 6C15.75 3.92893 14.0711 2.25 12 2.25C9.92893 2.25 8.25 3.92893 8.25 6V12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12V6Z"
            stroke="black"
            strokeOpacity="0.7"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 18.75V21.75"
            stroke="black"
            strokeOpacity="0.7"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.7121 12.75C18.5175 14.3936 17.7267 15.9087 16.4895 17.0081C15.2523 18.1075 13.6547 18.7147 11.9996 18.7147C10.3445 18.7147 8.74695 18.1075 7.50975 17.0081C6.27254 15.9087 5.48169 14.3936 5.28711 12.75"
            stroke="black"
            strokeOpacity="0.7"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2761_71055">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

const AudioButton = styled((props) => (
  <IconButton {...props}>
    <Icon />
  </IconButton>
))(() => ({
  backgroundColor: "#FFF",
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
  height: 36,
  padding: 8,
}));

export default AudioButton;
