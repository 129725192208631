const title = {
    type: "title",
    text: "Covers your home and belongings - your castle's shield.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Protects against damages, theft, and natural disasters.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Aviva Home Insurance",
            subheading: "Covers buildings and contents with options for accidental damage and personal possessions cover.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £12 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available directly from the Aviva website or through Aviva brokers.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Churchill Home Insurance",
            subheading: "Comprehensive cover including buildings, contents, and optional home emergency cover.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £15 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available on the Churchill website or through comparison sites like Comparethemarket.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Protect your home and everything in it with robust home insurance.",
        },
    ],
};

const homeInsurance = [
    title,
    benefits,
    providersExamples,
    greatFor,
];

export default homeInsurance;
