const fluidTypography = (
  property,
  minWidth,
  maxWidth,
  minFontSize,
  maxFontSize,
  unit = "rem",
  htmlFontSize = 16
) => {
  const minViewportWidth = minWidth / htmlFontSize;
  const maxViewportWidth = maxWidth / htmlFontSize;

  let minValue, maxValue;

  switch (unit) {
    case "rem":
      minValue = minFontSize / htmlFontSize;
      maxValue = maxFontSize / htmlFontSize;
      break;
    default:
      minValue = minFontSize;
      maxValue = maxFontSize;
      break;
  }

  const slope = (maxValue - minValue) / (maxViewportWidth - minViewportWidth);

  const yAxisIntersection = -minViewportWidth * slope + minValue;

  const preferred = `${yAxisIntersection}${unit} + ${slope * 100}${
    property !== "lineHeight" ? "vw" : ""
  }`;

  return `clamp(${minValue}${unit}, ${preferred}, ${maxValue}${unit})`;
};

export { fluidTypography };
