import React from "react";
import { Box, Typography, styled } from "@mui/material";

const Title = ({ variant = "h5", children }) => (
    <Typography variant={variant}>
        {children}
    </Typography>
);

const Heading = ({ variant = "bodyEmphasis", children }) => (
    <Typography variant={variant}>
        {children}
    </Typography>
);

const Body = ({ variant = "body", children }) => (
    <Typography variant={variant}>
        {children}
    </Typography>
);

const List = styled("ul")(() => ({
    display: "grid",
    gap: "inherit",
    margin: 0,
    paddingLeft: 20,
}));

const ListItem = "li";

const BulletedList = ({ variants, items }) => (
    <List>
        {items.map((item, index) => (
            item.type !== "list"
            ? <ListItem key={index}>
                {displayContent([item], variants)}
            </ListItem>
            : displayContent([item], variants)
        ))}
    </List>
);

const Frame = styled(Box, {
    shouldForwardProp: (prop) => (prop !== "includeGap"),
})(({ includeGap }) => ({
    display: "flex",
    flexDirection: "column",
    gap: includeGap && 8,
}));

const Section = ({ variants, heading, subheading, body = [] }) => (
    // If there is a heading or subheading, include them
    <Frame includeGap={!subheading}>
        {(heading || subheading) &&
            // If heading exists, include colon and space
            <Body variant={variants["body"]}>
                {<Heading variant={variants["heading"]}>
                    {heading}
                </Heading>}
                {heading && ": "}
                {subheading}
            </Body>
        }
        {displayContent(body, variants)}
    </Frame>
);

const displayContent = (content, variants = {}) => (
    content.map(({type, ...attr}, index) => {
        const props = { key: index, variant: variants[type], variants };
        switch (type) {
            case "title":
                return <Title {...props}>{attr.text}</Title>;
            case "section":
                return <Section {...props} {...attr} />;
            case "list":
                return <BulletedList {...props} items={attr.items} />;
            case "body":
            default:
                return <Body {...props}>{attr.text}</Body>;
        };
    })
);

export default displayContent;
export {
    Body as ContentBody,
    BulletedList as ContentList,
    Heading as ContentHeading,
    Section as ContentSection,
    Title as ContentTitle,
};
