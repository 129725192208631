import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

const Image = ({ src }) => {
  return (
    <Box
      component={"img"}
      src={src}
      alignSelf={"stretch"}
      sx={{ objectFit: "contain" }}
    />
  );
};

const Title = ({ title }) => {
  return (
    <Typography
      fontSize={"20px"}
      fontWeight={700}
      lineHeight={"28px"}
      letterSpacing={"0px"}
    >
      {title}
    </Typography>
  );
};

const Paragraph = ({ children }) => {
  return (
    <Typography
      alignSelf={"stretch"}
      color={"#4F4F4F"}
      fontSize={"13px"}
      lineHeight={"24px"}
    >
      {children}
    </Typography>
  );
};

const BodyText = ({ description }) => {
  return (
    <>
      {description.map((paragraph, i) => {
        return <Paragraph key={i}>{paragraph}</Paragraph>;
      })}
    </>
  );
};

const handleExploreMoreClick = () => {
  window.open("https://winvest.uk/financial-course", "_blank");
};

const CloseButtons = (props) => {
  return (
    <Stack spacing={"32px"} direction={"row"}>
      <Button onClick={props.onClose} sx={{ color: "#000" }}>
        Got it
      </Button>
      <Button
        sx={{
          color: "#000",
          border: "1px solid #000",
          borderRadius: "25px",
        }}
        onClick={handleExploreMoreClick}
      >
        Explore more
      </Button>
    </Stack>
  );
};

const FinancialTip = (props) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      alignSelf={"stretch"}
      paddingBottom={"24px"}
      backgroundColor={"#FFF"}
      borderRadius={"16px"}
      gap={"24px"}
      width={"340px"}
    >
      <Image src={props.content.image} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"32px"}
        paddingX={"24px"}
        alignItems={"flex-start"}
        alignSelf={"stretch"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"24px"}
          alignItems={"flex-start"}
          alignSelf={"stretch"}
        >
          <Title title={props.content.title} />
          <BodyText description={props.content.description} />
        </Box>
        <Box display={"flex"} paddingX={"24px"} justifyContent={"center"}>
          <CloseButtons {...props} />
        </Box>
      </Box>
    </Box>
  );
};

const FinancialTipModal = (props) => {
  return (
    <Box
      position={"fixed"}
      top={"20%"}
      left={"20%"}
      backgroundColor={"#FFF"}
      borderRadius={"16px"}
      boxShadow={"0 0 10px rgba(0, 0, 0, 0.5)"}
      zIndex={1000}
    >
      <FinancialTip {...props} />
    </Box>
  );
};

export default FinancialTipModal;
