import { Box, styled } from "@mui/material";

const Frame = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100%",
  justifyContent: "center",
  backgroundColor: "#F2F1EF",
  [theme.breakpoints.down("md")]: {
    gap: 8,
    alignItems: "center",
    padding: "56px 8px 8px 8px",
  },
  [theme.breakpoints.up("md")]: {
    gap: 8,
    alignItems: "flex-start",
    padding: "8px 12px 8px 16px",
  },
}));

export default Frame;
