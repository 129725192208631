import { Box, styled, Typography } from "@mui/material";
import { fontWeights } from "@themes";

const Frame = styled(Box)(() => ({
  display: "inline-flex",
  padding: "8px 12px",
  flexDirection: "column",
  alignItems: "flex-start",
  borderRadius: 32,
  backgroundColor: "#144235",
  height: "100%",
}));

const Content = ({ tag }) => {
  return (
    <Typography
      variant="caption1"
      fontWeight={fontWeights.MEDIUM}
      color={"#B7D9CF"}
    >
      {tag}
    </Typography>
  );
};

const Tag = ({ tag }) => {
  return (
    <Frame>
      <Content tag={tag} />
    </Frame>
  );
};

export default Tag;
