import React, { useState } from 'react';
import ClosePopup from './ClosePopup';
import Insight from '../Insights/Insight';
import insightsSet from '../content/insights';
import ProductIcon from './ProductIcon';

const INSIGHTS = insightsSet;

const FinancialGuideIcon = ({ size = '20', style }) => {
    return (
        <div
            className='financial-guide-icon popup-button-icon'
            style={style}
        >
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width={size}
                viewBox='0 0 20 21'
                fill='#2A935A'
            >
                <path
                    d='M13.75 3.84668C13.75 1.77559 12.0711 0.0966797 10 0.0966797C7.92892 0.0966797 6.25001 1.77559 6.25001 3.84668C6.25001 5.91777 7.92892 7.59668 10 7.59668C12.0711 7.59668 13.75 5.91777 13.75 3.84668ZM9.12463 9.51465C6.80704 8.0959 3.05313 7.70605 1.16406 7.59863C0.529298 7.56269 0 8.04629 0 8.65684V17.36C0 17.9197 0.452735 18.3865 1.03477 18.4166C2.74024 18.5061 6.19064 18.8338 8.57541 20.035C8.94142 20.2193 9.37541 19.9682 9.37541 19.5713V9.9623C9.37502 9.77988 9.28478 9.61269 9.12463 9.51465ZM18.836 7.59863C16.9473 7.70566 13.193 8.0959 10.8758 9.51465C10.7156 9.61269 10.6254 9.78652 10.6254 9.96894V19.5701C10.6254 19.9682 11.0606 20.2197 11.4278 20.035C13.8121 18.835 17.2606 18.5072 18.9657 18.4178C19.5477 18.3873 20.0004 17.9205 20.0004 17.3607V8.65684C20 8.04629 19.4707 7.56269 18.836 7.59863Z'
                />
            </svg>
        </div>
    );
}

const DictionaryHeader = () => {
    return (
        <div className='dictionary-header'>
            <h2>Financial Products Guide</h2>
            <p>Learn More About Different Financial Products</p>
        </div>
    );
}

// Arrow icon shown for each option
const Arrow = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='9'
            height='14'
            viewBox='0 0 9 14'
            fill='none'
        >
            <path
                d='M1 1L7 7L1 13'
                stroke='black'
                strokeOpacity='0.6'
                strokeWidth='2'
                strokeLinecap='round'
            />
        </svg>
    );
};

const DictionaryOption = ({ insight, showInsight }) => {
    const { type, title, colour } = insight;
    return (
        <div
            className='dictionary-option'
            onClick={() => showInsight(insight)}
            style={{ backgroundColor: colour.title }}
        >
            <div className='dictionary-option-header'>
                <ProductIcon icon={type} colour={colour.icon} />
                <p
                    className='text-highlight-dark'
                >{title}</p>
            </div>
            <Arrow />
        </div>
    );
}

// List of the clickable options available
const DictionaryOptions = ({ showInsight }) => {
  return (
    <div className='dictionary-options'>
        {INSIGHTS.map((insight, index) => {
            return (
                <React.Fragment key={index}>
                    <DictionaryOption
                        insight={insight}
                        showInsight={showInsight}
                    />
                </React.Fragment>
            );
        })}
    </div>
  );
}

// Dictionary Popup displayed when FinancialGuide is clicked
const Dictionary = ({ setDictionary }) => {
    // Navigate back to dictionary when insight is closed
    const backtoDict = (arg) => {
        if (!arg) {
            setDictionary(<Dictionary setDictionary={setDictionary} />)
        }
    };

    const showInsight = (props) => {
        setDictionary(<Insight {...props} setInsight={backtoDict} />)
    }

    return (
        <div id='dictionary' className='dictionary popup'>
            <div className='dictionary-content popup-content'>
                <ClosePopup setPopup={setDictionary} />
                <FinancialGuideIcon
                    size='40'
                    style={{ marginTop: '72px', padding: '22px' }}
                />
                <DictionaryHeader />
                <DictionaryOptions showInsight={showInsight} />
            </div>
        </div>
    );
}

const FinancialGuide = () => {
    const [dictionary, setDictionary] = useState(null);

    const showFinancialGuide = () => {
        setDictionary(<Dictionary setDictionary={setDictionary} />);
    }

    return (
        <div>
            <div className='popup-button' onClick={showFinancialGuide}>
                <FinancialGuideIcon />
                <p>Financial Guide</p>
            </div>
            {dictionary}
        </div>
    );
}

export default FinancialGuide;
export { Dictionary };
