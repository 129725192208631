import React, { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import FinancialTipModal from "./FinancialTipModal";
import MarketingIcons from "@ui-library/MarketingIcons";

const ExpandIcon = (props) => {
  const styles = {
    "& path": {
      fill: props.color,
      stroke: props.backgroundColor,
      strokeWidth: "0.5px",
    },
    width: "32px",
    height: "32px",
  };

  return props.expanded ? (
    <RemoveCircleOutlineIcon sx={styles} />
  ) : (
    <AddCircleOutlineIcon sx={styles} />
  );
};

const FinancialAccordionSummary = (props) => {
  const handleClick = (event) => {
    event.stopPropagation();
    props.setShowTip(true);
  };

  return (
    <AccordionSummary
      expandIcon={
        <ExpandIcon
          expanded={props.expanded}
          color={props.color}
          backgroundColor={props.backgroundColor}
        />
      }
      sx={{ padding: 0, ".MuiAccordionSummary-content": { margin: 0 } }}
    >
      <Box display={"flex"} gap={"8px"} alignItems={"center"}>
        <Typography
          fontSize={"17px"}
          fontWeight={500}
          lineHeight={"26px"}
          letterSpacing={"-0.5px"}
        >
          {props.title}
        </Typography>
        <MarketingIcons colors={props.tipButtonColors} onClick={handleClick} />
      </Box>
    </AccordionSummary>
  );
};

const FinancialAccordion = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={{
        width: "100%",
        padding: "16px",
        borderRadius: "16px",
        backgroundColor: props.backgroundColor,
        color: props.color,
        "&:before": {
          display: "none",
        },
      }}
      expanded={expanded}
      onChange={() => {
        setExpanded((prev) => !prev);
      }}
    >
      <FinancialAccordionSummary
        title={props.title}
        expanded={expanded}
        setShowTip={props.setShowTip}
        color={props.color}
        backgroundColor={props.backgroundColor}
        tipButtonColors={props.tipButtonColors}
      />
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
          gap: "16px",
        }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};

const FinancialCard = (props) => {
  const [showTip, setShowTip] = useState(false);

  return (
    <>
      <FinancialAccordion setShowTip={setShowTip} {...props} />
      {showTip && (
        <FinancialTipModal
          content={props.tipModalContent}
          onClose={() => setShowTip(false)}
          {...props}
        />
      )}
    </>
  );
};

export default FinancialCard;
