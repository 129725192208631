import React from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import RetirementPlotBar from "@features/projection/components/projectionscreen/RetirementPlotBar";
import Banner from "@features/projection/components/banner";
import NetWorthProjection from "@features/projection/components/projectionscreen/networthchart";

const ProjectionScreen = () => {
  return (
    <Grid
      item
      container
      sx={{
        display: "flex",
        alignItems: "stretch",
        flexShrink: 0,
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Grid item xs={12} md={9}>
        <NetWorthProjection />
      </Grid>
      <Grid item md>
        <RetirementPlotBar />
      </Grid>
    </Grid>
  );
};

const BannerContainer = () => {
  return (
    <Grid item>
      <Box sx={{ minHeight: "130px", mt: 2, display: "inline" }}>
        <Banner />
      </Box>
    </Grid>
  );
};

const Main = () => {
  return (
    <Grid item xs={12} md={8} xl={9} width={"100%"}>
      <Card
        elevation={0}
        sx={{
          backgroundColor: "#FFF",
          borderRadius: "16px",
          padding: "24px 24px 40px 24px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 !important",
          }}
        >
          <Grid container direction={"column"}>
            <ProjectionScreen />
            <BannerContainer />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Main;
