const page1 = [
    {
        type: "title",
        text: "To negotiate higher salary, know when to negotiate.",
    },
    {
        type: "body",
        text: "There are typically two good opportunities to negotiate your salary: when you've been in the same role for at least 6 months or when you're taking on a new role.",
    },
];

const page2 = [
    {
        type: "title",
        text: "Know When Not to Negotiate",
    },
    {
        type: "body",
        text: "Salary negotiation may not be possible in roles with clear salary bands, graduate schemes, and many charity/government positions.",
    },
];

const page3 = [
    {
        type: "title",
        text: "Determine Your Worth",
    },
    {
        type: "body",
        text: "Understand your market value and decide on three important numbers: The highest salary you're aiming for. The salary you would be satisfied with. Your absolute minimum, or \"walk away\" number.",
    },
];

const page4 = [
    {
        type: "title",
        text: "Timing is Key",
    },
    {
        type: "body",
        text: "In a new job, it's often better to wait until you receive an offer before discussing salary. Allow your skills and abilities to impress your employer first. In an existing job, provide examples of your successes and their positive impact on the company, quantifying your contributions.",
    },
    {
        type: "body",
        text: "Remember that salary negotiation can be a delicate process, so approach it with abundant mindset and preparation.",
    },
];

const content = [page1, page2, page3, page4];

const negotiateSalary = {
    topic: "How can I negotiate a higher salary?",
    image: "/learn/financialTips/higher_salary.png",
    color: {
        cardColor: "#5E2A14",
        textColor: "#FFF2AF",
        contentColor: "#975F2A",
    },
    imageStyles: { objectPosition: "top" },
    content: content,
};

export default negotiateSalary;
