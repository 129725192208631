import React from 'react';
import { changeProperty, getIsMarried, setProperty } from '../../promptCalculations';
import PromptChoiceExtended from '../promptTypes/PromptChoiceExtended';
import { getNetWorth } from '../../playerState';

const COST = 526;
const COST_PART_TIME = 262;

const page1 = {
    title: "You are having a kid.",
    caption: `The full time childcare is £${COST} per week. After 52 weeks of maternity leave`,
    description: "Balancing work and family is one of the toughest job in the world. I trust myself that I can make the best situation for me and my family.",
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-4.jpeg",
    },
};

const page2 = {
    title: "You are having a kid.",
    caption: "",
    description: "What would you choose? There's no right or wrong decision here.",
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-4.jpeg",
    },
    choices: [
        {
            choice: `Go back to work, paying £${COST / 2} per week for childcare`,
            outcome: 0,
        },
        {
            choice: `Work part time, paying £${COST_PART_TIME / 2} per week for childcare`,
            outcome: 1,
        },
        {
            choice: "Stop working, don't pay any childcare",
            outcome: 2,
        },
    ],
};

const change1 = () => {
    changeProperty({
        change: "increase", property: "spending", by: getIsMarried() ? COST / 2 : COST,
    });
}

const change2 = () => {
    const NET_WORTH = getNetWorth();
    setProperty('salary', NET_WORTH.income.salary / 2);
    changeProperty({
        change: "increase", property: "spending", by: getIsMarried() ? (COST_PART_TIME) / 2 : COST_PART_TIME,
    });
}

const change3 = () => {
    setProperty('salary', 0);
}

const changes = [
    change1, change2, change3,
];

const tipScreen = {
    content: [
        { type: "title", text: "Balancing Work and Family" },
        { type: "subtitle", text: "Go back to work, paying £263 per week for childcare:" },
        {
            type: "paragraph",
            text: "Pros: Financial stability, career continuity, social interaction."
        },
        {
            type: "paragraph",
            text: "Cons: High childcare costs, limited time with child, work-life balance challenges."
        },
        { type: "subtitle", text: "Work part-time, paying £131 per week for childcare:" },
        {
            type: "paragraph",
            text: "Pros: Reduced childcare costs, more time with child, improved work-life balance."
        },
        {
            type: "paragraph",
            text: "Cons: Lower income(result in lower pension pot in retirement), career advancement challenges, potential for isolation."
        },
        { type: "subtitle", text: "Stop working, don\'t pay any childcare:" },
        {
            type: "paragraph",
            text: "Pros: Full-time parenting, cost savings, flexibility."
        },
        {
            type: "paragraph",
            text: "Cons: Financial dependence, career interruption(result in much lower pension pot in retirement), social and professional isolation."
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/woman-3.jpeg",
    },
};
  
const tips = [
    tipScreen,
];

const colour = {
    primary: "#FFDCDC",
    secondary: "#E04B77",
};

const Prompt12 = (props) => {
    const { setPrompt } = props;

    const outcomes = [
        {
            title: "Woohoo!",
            caption: `You now are paying half of the childcare from your account, your husband paying half. You have £${COST} as extra going out of your account`,
            change: 0,
        },
        {
            title: "Woohoo!",
            caption: `Work part time, paying £${COST_PART_TIME / 2} per week for childcare`,
            change: 1,
        },
        {
            title: "Woohoo!",
            caption: "Stop working, don\'t pay any childcare",
            change: 2,
        },
    ];

    const prompt12 = {
        type: "choice-extended",
        page1: page1,
        page2: page2,
        outcomes: outcomes,
        changes: changes,
        tips: tips,
        colour: colour,
    };

    return (
        <PromptChoiceExtended {...prompt12} setPrompt={setPrompt} />
    );
}

export default Prompt12;
