import React from "react";
import PromptChoice from "../promptTypes/PromptChoice";
import { addAnnualChange, changeProperty } from "../../promptCalculations";
import { formatMoney } from "src/game/playerState";

const options = [
  {
    by: 50,
    frequency: "monthly",
  },
  {
    by: 200,
    frequency: "once",
  },
];

const choiceText = (amount, frequency) => {
  const text = `Invest ${formatMoney(amount)} ${(() => {
    switch (frequency) {
      case "monthly":
        return "per month";
      case "yearly":
        return "per year";
      case "once":
        return "once";
      default: // assume once
        return "once";
    }
  })()}`;
  return text;
};

const page = {
  title:
    "There is a blue chip stock with dividend of 4.5% with potential capital gain.",
  description: "Would you:",
  choices: [
    {
      choice: choiceText(options[0].by, options[0].frequency),
      outcome: 0,
    },
    {
      choice: choiceText(options[1].by, options[1].frequency),
      outcome: 1,
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/pie-chart-2.png",
    style: {
      backgroundColor: "#77B6D1",
    },
  },
};

// const outcomes = [
//   {
//     title: "Well done!",
//     caption:
//       "Keep up the great work! You invested £600 total this year, receiving approximately £23 as a dividend!",
//     change: 0,
//   },
//   {
//     title: "Woohoo!",
//       caption:
//         "You are one of the 42% of the UK population that holds an investment!",
//     change: 1,
//   },
// ];

// const changes = [
//   {
//     change: "increase",
//     property: "blueChipStocks",
//     by: "50",
//     frequency: "monthly",
//   },
//   {
//     change: "increase",
//     property: "blueChipStocks",
//     by: "200",
//     frequency: "once",
//   },
// ];

const change1 = () => {
  addAnnualChange({
    change: "increase",
    property: "blueChipStocks",
    by: "50",
    frequency: "monthly",
  });
};

const change2 = () => {
  changeProperty({
    change: "increase",
    property: "blueChipStocks",
    by: "200",
    frequency: "once",
  });
};

const changes = [change1, change2];

const tipScreen = {
  content: [
    {
      type: "title",
      text: "Investing regularly means putting money into investments on a regular basis, a bit at a time.",
    },
    {
      type: "paragraph",
      text: "This helps even out the ups and downs of prices because you're buying at different times. It's good if you want to play it safe and not get too emotional about your investments.",
    },
    {
      type: "paragraph",
      text: "On the other hand, investing a lump sum means putting all your money into investments at once. It's simple and can be good if you think it's the perfect time to invest.",
    },
  ],
  image: {
    src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/saving-pig-2.jpeg",
  },
};

const tips = [tipScreen];

const colour = {
  primary: "#B9EAFF",
  secondary: "#5F32B6",
};

// const prompt2 = {
//   type: "choice",
//   page: page,
//   outcomes: outcomes,
//   changes: changes,
//   tips: tips,
//   colour: colour,
// };

const Prompt2 = (props) => {
  const { setPrompt } = props;

  const NUM_MONTHS = 12;
  const MONTHLY_RATE = 0.045 / NUM_MONTHS;
  const fv =
    options[0].by *
    (((1 + MONTHLY_RATE) ** NUM_MONTHS - 1) / MONTHLY_RATE) *
    (1 + MONTHLY_RATE);
  const dividend = fv * 0.03;

  const outcomes = [
    {
      title: "Well done!",
      caption: `Keep up the great work! You invested £${
        options[0].by * 12
      } total this year, receiving approximately £${Math.round(
        dividend
      )} as a dividend!`,
      change: 0,
    },
    {
      title: "Woohoo!",
      caption:
        "You are one of the 42% of the UK population that holds an investment!",
      change: 1,
    },
  ];

  const prompt2 = {
    type: "choice",
    page: page,
    outcomes: outcomes,
    changes: changes,
    tips: tips,
    colour: colour,
  };

  return <PromptChoice {...prompt2} setPrompt={setPrompt} />;
};

export default Prompt2;
