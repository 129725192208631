import { YEARS } from "./constants";
import {
  calculateFutureValueUntilRetirement,
  calculateRealEstateFutureValue,
  END_OF_PERIOD,
} from "@utils/financialCalculations";

const calculateSeriesData = (years, data, currentAge, retirementAge, type) => {
  return years.map((year) =>
    calculateFutureValueUntilRetirement(
      currentAge,
      retirementAge,
      year,
      -data.initialAmount || -data.totalPension || 0,
      -data.monthlyInvestment * 12 ||
        -(data.totalContribution * 0.01 * data.annualSalary) ||
        0,
      data.interestRate / 100,
      END_OF_PERIOD
    )
  );
};

const calculateInvestmentData = (investment, currentAge, retirementAge) =>
  calculateSeriesData(
    YEARS,
    investment,
    currentAge,
    retirementAge,
    "Investment"
  );

const calculateSavingsData = (savings, currentAge, retirementAge) =>
  calculateSeriesData(YEARS, savings, currentAge, retirementAge, "Savings");

const calculatePensionData = (pension, currentAge, retirementAge) =>
  calculateSeriesData(YEARS, pension, currentAge, retirementAge, "Pension");

const calculateRealEstateData = (
  realEstate,
  currentAge,
  retirementAge,
  RE_APPRECIATION_RATE
) =>
  YEARS.map((year) => {
    let res = calculateRealEstateFutureValue(
      currentAge,
      retirementAge,
      year,

      realEstate.currentPropertyValue,
      RE_APPRECIATION_RATE,
      realEstate.monthlyPayment,
      realEstate.monthlyOverpayment,

      realEstate.remainingMortgage,
      realEstate.interestRate / 100,
      realEstate.mortgageType
    );

    // if (year === 1) {
    //   console.log({
    //     // car: realEstate.remainingMortgage,
    //     res,
    //   });
    // }

    return res;
  });

export {
  calculateInvestmentData,
  calculatePensionData,
  calculateRealEstateData,
  calculateSavingsData,
};
