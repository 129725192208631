import { SvgIcon } from "@mui/material";

const GameIcon = ({ color = "#065D44", selected = false }) => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={selected ? color : "none"}
        >
            <path
                d="M21 7.5V17.5C21 20.5 19.5 22.5 16 22.5H8C4.5 22.5 3 20.5 3 17.5V7.5C3 4.5 4.5 2.5 8 2.5H16C19.5 2.5 21 4.5 21 7.5Z"
                stroke={selected ? "white" : color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.25 11.5H7.75C6.79 11.5 6 10.71 6 9.75V7.25C6 6.29 6.79 5.5 7.75 5.5H16.25C17.21 5.5 18 6.29 18 7.25V9.75C18 10.71 17.21 11.5 16.25 11.5Z"
                stroke={selected ? "white" : color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.3 15.7812L8 18.0812"
                stroke={selected ? "white" : color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.0293 15.8086L10.3293 18.1086"
                stroke={selected ? "white" : color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.4902 15.8281H16.5103"
                stroke={selected ? "white" : color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.4902 18.0005V17.9805"
                stroke={selected ? "white" : color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

export default GameIcon;
