import React from "react";
import { ButtonBase, styled } from "@mui/material";

const Frame = styled(ButtonBase)(() => ({
  width: 48,
  height: 48,
  borderRadius: 48,
}));

const Image = styled("img")(() => ({
  width: "100%",
  height: "auto",
}));

const AvatarButton = () => {
  return (
    <Frame>
      <Image src="assets/images/temporary_avatar.png" />
    </Frame>
  );
};

export default AvatarButton;
