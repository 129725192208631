import financialProducts from "../financialProducts/financialProducts";

const page1 = {
    content: [
        {
            type: 'title',
            text: 'There are two ways of return in stocks & shares:'
        },
        {
            type: 'list',
            listType: 'ordered',
            listItems: [
                {
                    text: 'Through capital gains. Prices go up, you sell your stocks.'
                },
                {
                    text: 'Through dividends. Usually company who gives out dividends are well-established, stable companies with a history of reliable performance.'
                }
            ]
        }
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/capital-gain-tree.png",
        style: {
            objectFit: "contain",
        },
    },
};

const page2 = {
    content: [
        {
            type: 'title',
            text: 'What\'s Blue Chip Stock?'
        },
        {
            type: 'definition',
            keyword: 'Blue Chip Stocks:',
            text: 'Blue Chip stocks are established, stable companies with a history of reliable performance and often pay dividends.'
        },
        {
            type: 'paragraph',
            text: 'Here are some examples of Blue Chip Stocks: P&G, AT&T and Coca-Cola.'
        },
        {
            type: 'image',
            src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/company-icons.svg",
            alt: "Coca-Cola, Apple, P&G",
            style: {
                height: "28px",
                width: "auto"
            },
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/companies.png",
    },
};

const page3 = {
    content: [
        {
            type: 'title',
            text: 'Blue Chip Stock Example: P&G'
        },
        {
            type: 'paragraph',
            text: 'The Procter & Gamble Company, founded in 1837, is an American multinational consumer goods corporation with long history and reliable performance. Include many famous consumer brands like Assuie, Olay, Pampers and more.'
        },
        {
            type: 'paragraph',
            text: 'Performance over the last 5 years:'
        },
        {
            type: 'list',
            listType: 'unordered',
            listItems: [
                {
                    text: 'Dividend Yield: 2.5% per year',
                },
                {
                    text: 'Capital Gain: Over 91% in total',
                },
            ],
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/graph-1.png",
        style: {
            objectFit: "contain",
        },
    },
};

const blueChipStocks = {
    ...financialProducts['BlueChipStocks'],
    content: {
        page1: page1,
        page2: page2,
        page3: page3
    },
};

export default blueChipStocks;
