import React from "react";
import { Tabs, Box, styled } from "@mui/material";
import Tab from "@ui-library/Tab";

const labels = [
  { label: "Investment", icon: "assets/icons/icon_investment.svg" },
  { label: "Savings", icon: "assets/icons/icon_savings.svg" },
  { label: "Pension", icon: "assets/icons/icon_pension.svg" },
  { label: "Real Estate", icon: "assets/icons/icon_real-estate.svg" },
  { label: "Total", icon: "assets/icons/icon_total.svg" },
];

const StyledTabBar = styled(Tabs)(({ theme }) => ({
  minHeight: "unset",
  flex: "1 1 auto",
  "& .MuiTabs-flexContainer": {
    paddingLeft: "80px",
    alignItems: "flex-start",
    flexWrap: "wrap",
    alignSelf: "stretch",
    gap: 4,
  },
  [theme.breakpoints.down("md")]: {
    alignSelf: "center",
    "& .MuiTabs-flexContainer": {
      paddingLeft: 0,
    },
  },
}));

const NavBar = ({ activeTab, setActiveTab, ...props }) => {
  const handleChange = (event, newValue) => {
    // console.log(activeTab);
    setActiveTab(newValue);
  };

  return (
    // <Box display={"flex"} flexWrap={"wrap"}>
    <StyledTabBar
      value={activeTab}
      onChange={handleChange}
      centered
      aria-label="Net Worth Projection Tabs"
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
      {...props}
    />
    // </Box>
  );
};

const ChartNavigation = (props) => {
  return (
    <NavBar {...props}>
      {labels.map(({ label, icon }, index) => (
        <Tab key={index} label={label} iconurl={icon} />
      ))}
    </NavBar>
  );
};

export default ChartNavigation;
