const title = {
    type: "title",
    text: "Think of this as your go-to handbag. You can dip into it anytime without any fuss, and it earns a bit of interest.",
};

const description = {
    type: "body",
    text: "This account is perfect for keeping your savings accessible while earning a bit of interest. There's no minimum deposit, so you can start saving right away.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Perfect for your emergency fund or short-term savings goals where you need quick access to your cash.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "body",
            text: "Marcus by Goldman Sachs, HSBC, Nationwide, Santander, Monzo, Revolut.",
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "If you need funds quickly for unexpected expenses, this account is your best friend.",
        },
    ],
};

const examples = {
    type: "section",
    heading: "Example",
    body: [
        {
            type: "section",
            subheading: "HSBC Flexible Saver",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Interest Rate: 2% AER",
                        },
                        {
                            type: "body",
                            text: "Minimum Deposit: None",
                        },
                    ],
                },
            ],
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "You can withdraw your money anytime without penalty.",
        },
    ],
};

const easyAccessSavingsAccount = [
    title,
    description,
    benefits,
    providersExamples,
    greatFor,
    examples,
    accessibility,
];

export default easyAccessSavingsAccount;
