import React from "react";
import {
  Tab as MuiTab,
  Box,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { base, baseMobile, hover, selected } from "./styles";

const StyledTab = styled(MuiTab)(({ theme }) => ({
  ...base(),
  "&:hover": hover(),
  "&.Mui-selected": selected(),
  [theme.breakpoints.down("md")]: baseMobile(),
}));

const Icon = ({ iconurl }) => {
  return (
    <Box width={"24px"} height={"24px"}>
      <Box
        component={"img"}
        src={iconurl}
        alt=""
        width={"100%"}
        height={"100%"}
      />
    </Box>
  );
};

const Tab = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledTab
      icon={isMobile ? <Icon iconurl={props.iconurl} /> : null}
      label={props.label}
      {...props}
    />
  );
};

export default Tab;
