import { Box, styled } from "@mui/material";
import IntroCard from "../components/introduction";
import { ChatDisplay } from "../components/display";
import { useChat } from "../hooks";
import { getMessages } from "@services/chatService";

const Container = styled(Box)(({ theme, history }) => ({
  flexGrow: 1,
  overflow: "auto",
  padding: "16px",
}));

const Body = () => {
  const { history, loading, loadingResponse, errorOccurred } = useChat();

  const messages = getMessages(history);

  if ((loading || messages.length === 0) && !errorOccurred) {
    return (
      <Container history={[]}>
        <IntroCard />
      </Container>
    );
  }

  const [first, ...remainder] = messages;

  if (messages.length === 1 && !errorOccurred) {
    return (
      <Container history={messages}>
        <IntroCard message={first.message.intro} />
      </Container>
    );
  }

  return (
    <Container history={messages}>
      {
        (remainder.length > 0 || loadingResponse || errorOccurred) &&
        <ChatDisplay history={remainder} />
      }
    </Container>
  );
};

export default Body;
