import { useTheme } from "@mui/material";
import FinancialCard from "../../FinancialCard";
import marketingTips from "../marketingTips";
import Heading from "./Heading";
import Inputs from "./Inputs";

const RealEstateAccordion = () => {
  const theme = useTheme();
  const colorSet = theme.vars.palette.properties;

  const tipButtonColors = {
    circleColor: colorSet.secondary,
    pathColor: colorSet.tertiary,
    hoverCircleColor: colorSet.tertiary,
    hoverPathColor: colorSet.primary,
  };

  return (
    <FinancialCard
      title={"Real Estate"}
      backgroundColor={colorSet.primary}
      color={colorSet.tertiary}
      tipModalContent={marketingTips.properties}
      tipButtonColors={tipButtonColors}
    >
      <Heading />
      <Inputs />
    </FinancialCard>
  );
};

export default RealEstateAccordion;
