import React, { useState } from "react";
import { Box, styled } from "@mui/material";
import { PageContainer } from "@features/learn";
import TextContent from "@components/textContent";
import financialTips from "@content/financialTips";
import { Step } from "@components";

const Container = styled(Box)(() => ({
    alignItems: "center",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
}));

const Header = styled(Box)(() => ({
    alignSelf: "stretch",
    borderRadius: "32px 32px 0 0",
    display: "flex",
    height: 233,
}));

const Image = styled("img")(() => ({
    borderRadius: "inherit",
    objectFit: "cover",
    objectPosition: "0 30%",
    width: "100%",
}));

const Body = styled(Box)(() => ({
    alignSelf: "stretch",
    borderRadius: "0 0 32px 32px",
    display: "flex",
    flexDirection: "column",
    gap: 48,
    height: "100%",
    justifyContent: "space-between",
    padding: "32px 24px 24px",
}));

const Frame = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
}))

const FinancialTipPage = (props) => {
    const { id } = props;
    const { image, color, content } = financialTips[id];
    const [page, setPage] = useState(1);

    const pages = content.map((page, pageIndex) => (
        <TextContent
            key={pageIndex}
            content={page}
            color="white"
            variants={{ title: pageIndex === 0 ? "h3" : "h4" }}
            sx={{ gap: "24px" }}
        />
    ));

    // Handles behaviour when the page is clicked
    const onClick = () => page < pages.length
        ? setPage(page + 1)
        : setPage(1);

    return (
        // height: "100%", padding: "16px" + overflowY: "auto" on Body or TextContent
        // Or, overflowY: "auto", padding: "16px"
        <PageContainer sx={{ overflowY: "auto", padding: "16px" }}>
            <Container>
                <Header backgroundColor={color.cardColor}>
                    <Image src={image} />
                </Header>
                <Body
                    backgroundColor={color.contentColor}
                    onClick={onClick}
                >
                    {pages[page - 1]}
                    <Frame>
                        {/* Share button placeholder */}
                        <div style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "32px"
                        }} />
                        <Step step={page} of={pages.length} />
                    </Frame>
                </Body>
            </Container>
        </PageContainer>
    );
};

export default FinancialTipPage;
