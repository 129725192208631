import React from "react";
import { Box, styled, Typography } from "@mui/material";
import useProjectionContext from "@features/projection/hooks/useProjectionContext";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip from "@ui-library/Tooltip";
import { INTEREST_ONLY, REPAYMENT } from "@utils/financialCalculations";

const Frame = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
  padding: "8px 0px 12px 0px",
  gap: "12px",
});

const CaptionTooltip = () => (
  <Tooltip
    title={
      <React.Fragment>
        Interest Only: Only pay the interest on the loan without reducing the
        principal amount.
        <br />
        <br />
        Repayment: Pay both the interest and the principal amount of the loan.
      </React.Fragment>
    }
  >
    <QuestionMarkIcon
      style={{
        color: "white",
        background: "#89969c",
        borderRadius: "11px",
        height: "15px",
        width: "15px",
        marginLeft: "10px",
      }}
    />
  </Tooltip>
);

const Caption = () => (
  <Box>
    <Typography variant="bodyS">Mortgage Type</Typography>
    <CaptionTooltip />
  </Box>
);

const OptionsContainer = styled(ToggleButtonGroup)(({}) => ({
  display: "flex",
  alignItems: "flex-start",
  alignSelf: "stretch",
  gap: "8px",

  ".MuiToggleButtonGroup-grouped": {
    display: "flex",
    minHeight: "36px",
    paddingLeft: "24px",
    paddingRight: "24px",
    borderRadius: "44px",
    border: "1px solid var(--Gray-1, #333)",
    // minWidth: "80px",
    "&.Mui-selected": {
      backgroundColor: "#333", // Selected background color
      color: "white", // Selected text color
      "&:hover": {
        backgroundColor: "#333", // Lighter grey on hover for selected
      },
    },
    "&:not(.Mui-selected)": {
      backgroundColor: "transparent", // Non-selected background color
      color: "black", // Non-selected text color
      textTransform: "none", // Keep text as is
      "&:hover": {
        backgroundColor: "#333", // Darker grey on hover for non-selected
      },
    },
  },
}));

const OptionButton = styled(ToggleButton)(({ selected }) => ({
  textTransform: "none",
}));

const Options = ({ value, onChange }) => {
  return (
    <OptionsContainer
      exclusive
      value={value}
      onChange={onChange}
      aria-label="mortgage type"
    >
      <OptionButton value={INTEREST_ONLY}>Interest Only</OptionButton>
      <OptionButton value={REPAYMENT}>Repayment</OptionButton>
    </OptionsContainer>
  );
};

const MortgageTypeToggle = (props) => {
  return (
    <Frame>
      <Caption />
      <Options {...props} />
    </Frame>
  );
};

export default MortgageTypeToggle;
