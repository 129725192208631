import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  styled,
  useMediaQuery,
} from "@mui/material";
import { ChatIcon, GameIcon, HomeIcon, LearnIcon, TrackerIcon } from "../icons";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded"; // TEMPORARY
import { useNavigation } from "@hooks";

const Container = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isLessThan390px",
})(({ isLessThan390px }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  borderRadius: 0,
  padding: isLessThan390px ? "0 12px 8px 12px" : "0 24px 8px 24px",
  zIndex: 9999,
}));

const NavBar = styled(BottomNavigation)(() => ({
  height: "auto",
}));

const NavIcon = styled(BottomNavigationAction)(() => ({
  color: "#065D44", // UI-500
  gap: 2,
  height: "70px",
  minWidth: 0,
  padding: 0,
}));

const CenterIcon = styled(NavIcon)(() => ({
  marginTop: "-12px",
  minWidth: 88,
}));

const BottomNavBar = () => {
  const { isSelected, navigate } = useNavigation();
  const isLessThan390px = useMediaQuery("(max-width: 390px)");

  return (
    <Container isLessThan390px={isLessThan390px}>
      <NavBar showLabels>
        {/* <NavIcon
                    label="Home"
                    icon={<HomeIcon selected={isSelected.home} />}
                    onClick={() => navigate("home")}
                /> */}
        {/* Temporarily points to projection instead of tracker */}
        <NavIcon
          label="Projection"
          icon={
            <TrackerIcon
              /* selected={isSelected.projection} */ selected={true}
            />
          }
          onClick={() => navigate("projection")}
        />
        {/* <CenterIcon
                    icon={<ChatIcon />}
                    onClick={() => navigate("ai")}
                /> */}
        {/* Temporary until home is implemented */}
        <NavIcon
          label="Money Bestie"
          icon={<SmsRoundedIcon color="#065D44" />}
          onClick={() => navigate("ai")}
        />
        <NavIcon
          label="Learn"
          icon={<LearnIcon /* selected={isSelected.learn} */ selected={true} />}
          onClick={() => navigate("learn")}
        />
        <NavIcon
          label="Game"
          icon={<GameIcon /* selected={isSelected.game} */ selected={true} />}
          onClick={() => navigate("game")}
        />
      </NavBar>
    </Container>
  );
};

export default BottomNavBar;
