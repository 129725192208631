const page2template = {
  title: "How do you want your money to work for you?",
  caption:
    "You can choose more than one option and assign ratios to each after selection.",
};

const page3template = {
  title: "How would you like to split your investments?",
  caption: "Choose any percent ratio for each",
};

export { page2template, page3template };
