const page1 = [
    {
        type: "title",
        text: "How much should I save each month?",
    },
    {
        type: "body",
        text: "Aim to save at least 20% of your monthly income. If that's not feasible, start with 10% and gradually increase the percentage as your financial situation improves.",
    },
];

const page2 = [
    {
        type: "title",
        text: "Save smartly: Allocate, Automate, Grow!",
    },
    {
        type: "body",
        text: "Break it down into specific goals: 10% towards retirement, 5% towards an emergency fund, and 5% towards other savings goals, like a vacation or home purchase. Use automated transfers to ensure you consistently save each month. Every little bit counts — keep saving and watch your wealth grow!",
    },
];

const content = [page1, page2];

const saveEachMonth = {
    topic: "How much should I save each month?",
    image: "/learn/financialTips/monthly_saving.jpeg",
    color: {
        cardColor: "#2694B5",
        textColor: "#FFFFFF",
        contentColor: "#00607E",
    },
    content: content,
};

export default saveEachMonth;
