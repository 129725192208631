import React from 'react';
import Avatar from '../../components/Avatar';
import Outcome from './Outcome';
import ChoiceButton, { ContinueButton, SkipButton } from './ChoiceButton';
import { applyChanges } from '../../playerState';
// import '../Prompts.css';

const ContinueSkipCard = (props) => {
    const { description, colour, onNext, setPrompt } = props;

    return (
        <div className='prompt-choice-card'>
            <div className='choice-avatar'>
                <Avatar size='88px' />
            </div>
            <div className='choice-content' style={{ backgroundColor: colour }}>
                <p>{description}</p>
                <div className='choice-buttons'>
                    <ContinueButton onNext={onNext} />
                    <SkipButton setPrompt={setPrompt} />
                </div>
            </div>
        </div>
    );
}

const MultipleChoiceCard = (props) => {
    const { description, choices, includeSkip = true, colour, onChoice, setPrompt } = props;

    return (
        <div className='prompt-choice-card'>
            <div className='choice-avatar'>
                <Avatar size='88px' />
            </div>
            <div className='choice-content' style={{ backgroundColor: colour }}>
                <p>{description}</p>
                <div className='choice-buttons multiple-choice-buttons'>
                    {choices.map((choice, index) => (
                        <React.Fragment key={index}>
                            <ChoiceButton
                                choice={choice.choice}
                                onChoice={() => onChoice(index)}
                            />
                        </React.Fragment>
                    ))}
                </div>
                {includeSkip && <SkipButton setPrompt={setPrompt} />}
            </div>
        </div>
    );
}

export { ContinueSkipCard, MultipleChoiceCard };
