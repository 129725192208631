import { Box, Card, CardContent, styled } from "@mui/material";
import Text, { Body } from "./Text";
import Tag from "./Tag";

const Frame = styled(Card)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "16px 16px 32px 24px",
  alignItems: "flex-start",
  borderRadius: 12,
  backgroundColor: "#065D44", // UI-500
  gap: 8,
  // minHeight: 200,
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  alignSelf: "stretch",
  padding: 0,
  minHeight: 25,
});

const Container = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  padding: 0,
  ":last-child": { paddingBottom: 0 },
});

const Grid = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "12px",
});

const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
  gap: "24px",
});

const PromotionCard = ({ children, ...props}) => {
  const { left, right, title, body, button, tag, ...rest } = props;
  const textProps = { title, body, sx: { width: "85%" } };

  return (
    <Frame {...rest}>
      <Header>{tag && <Tag tag={tag} />}</Header>
      <Container>
        <Grid>
          {left && <Grid sx={{ width: "80px" }}>{left}</Grid>}
          <Content>
            <Text {...textProps} />
            {button}
            <Body body={children} />
          </Content>
          {right && <Grid>{right}</Grid>}
        </Grid>
      </Container>
    </Frame>
  );
};

export default PromotionCard;
