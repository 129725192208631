import React from "react";
import { styled } from "@mui/material";
import {
    FinancialProductCardDetails,
    FinancialProductCardHeader,
} from "@components/financialproducts";

const Image = styled("img")(() => ({
    height: 160,
    objectFit: "contain",
    width: "100%",
}));

const ContentHeader = (props) => {
    const { title, growth, risk, image, color, icon } = props;

    return (
        <React.Fragment>
            <FinancialProductCardHeader
                title={title}
                icon={icon}
                {...color}
                sx={{
                    wrapper: { padding: "6px 8px 6px 0" },
                    avatar: { height: 48, width: 48 }
                }}
            />
            <Image src={image} />
            <FinancialProductCardDetails
                growth={growth}
                risk={risk}
                sx={{ minHeight: 0 }}
            />
        </React.Fragment>
    );
};

export default ContentHeader;
