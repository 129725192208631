import React, { useState } from "react";
import { Box } from "@mui/material";
import { useQuestionnaireContext } from "@hooks";
import Section from "../Section";
import {
  Question,
  Validation,
} from "@features/questionnaire/components/textStyles";
import ChipGroup, { Chip } from "@ui-library/ChipGroup";
import { OthersField } from "../FormContentBox";

const ChipQuestion = ({
  selectType,
  id,
  question,
  validation,
  options,
  other,
  ...props
}) => {
  const { formData, updateFormData } = useQuestionnaireContext();

  const [chipOptions, setChipOptions] = useState(options);

  const handleArrayClick = (option) => {
    let updated;
    if (formData[id].includes(option)) {
      updated = formData[id].filter((item) => item !== option);
    } else {
      updated = [...formData[id], option];
    }
    updateFormData(id, updated);
  };

  const handleSingleClick = (option) => {
    updateFormData(id, option);
  };

  const handleNewOption = (newOption) => {
    if (newOption && !chipOptions.includes(newOption)) {
      setChipOptions((prev) => [...prev, newOption]);
      if (selectType === "single") {
        handleSingleClick(newOption);
      } else if (selectType === "array") {
        handleArrayClick(newOption);
      }
    }
  };

  const fields = chipOptions.map((option) => {
    let field;
    if (selectType === "array") {
      field = (
        <Chip
          key={option}
          text={option}
          type={"default"}
          isChecked={formData[id].includes(option)}
          onChange={() => handleArrayClick(option)}
        />
      );
    } else if (selectType === "single") {
      field = (
        <Chip
          key={option}
          text={option}
          isChecked={formData[id] === option}
          onChange={() => handleSingleClick(option)}
        />
      );
    }
    return field;
  });

  return (
    <Section gap={"32px"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"24px"}
      >
        <Question>{question}</Question>
        {validation && <Validation>{validation}</Validation>}
        <Box width={{ xs: "100%", md: "45.76vw" }}>
          <ChipGroup gap={"8px"}>{fields}</ChipGroup>
        </Box>
      </Box>
      {other && (
        <Box>
          <OthersField id={id} onAddOption={handleNewOption} />
        </Box>
      )}
    </Section>
  );
};

export { ChipQuestion };
