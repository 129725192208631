import financialProducts from "../financialProducts/financialProducts";

const page1 = {
    content: [
        {
            type: 'title',
            text: 'What\'s Junior ISA ?'
        },
        {
            type: 'paragraph',
            text: 'A Junior ISA (Individual Savings Account) is a tax-efficient savings account specifically designed for children under the age of 18 in the UK.'
        },
        {
            type: 'paragraph',
            text: 'There are two types: Cash Junior ISAs and Stocks and Share Junior ISAs.'
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/children-2.jpeg",
    },
};

const page2 = {
    content: [
        {
            type: 'title',
            text: 'What\'s Junior ISA ?'
        },
        {
            type: 'paragraph',
            text: 'The maximum amount you can put into a Junior ISA in the 2024/2025 tax year is £9,000.  The tax year goes on for 12 months, starting from April 6 and ending on April 5 of the next year.'
        },
        {
            type: 'paragraph',
            text: 'If both of you and your partner has capped the £20k allowance, contributing to Junior ISA is good way to save up your kids college fund, study abroad expense.'
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/children-2.jpeg",
    },
};

const page3 = {
    content: [
        {
            type: 'title',
            text: 'How much should I put in Junior ISA account?'
        },
        {
            type: 'paragraph',
            text: 'Let\'s say you contribute £2000 per year into your kids Junior Stocks and Share ISA account since they are 5. Assuming an approximate annual growth of 8%, by the time they turn 18, that will turn into £47,481 for your kid!'
        },
    ],
    image: {
        src: "https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/graph-2.png",
        style: {
            objectFit: "contain",
        },
    },
};

const juniorIsaAccount = {
    ...financialProducts['JuniorIsaAccount'],
    content: {
        page1: page1,
        page2: page2,
        page3: page3
    },
};

export default juniorIsaAccount;
