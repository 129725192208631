import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const QuestionnaireContext = createContext({});

export const QuestionnaireProvider = ({ children }) => {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    // email: "", // sign in details
    birthYear: "",
    ethnicity: [],
    gender: "",
    priorities: [],
    attributes: [],
    motivation: "",
    financialGoals: [],
    goals: [],
  });

  const navigate = useNavigate();

  const updateFormData = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const sendFormData = () => {
    const url =
      "https://wvru9a5el6.execute-api.eu-west-1.amazonaws.com/default/onboardNewUser";


    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...formData,
        email: sessionStorage.getItem("userEmail"),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // navigate("/projection");
        navigate("/ai")
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const toggleCheckbox = (key, value) => {
    setFormData((prev) => {
      const current = prev[key];
      const updated = current.includes(value)
        ? current.filter((item) => item !== value)
        : [...current, value];
      return {
        ...prev,
        [key]: updated,
      };
    });
  };

  const contextValue = {
    page,
    setPage,
    formData,
    updateFormData,
    toggleCheckbox,
    sendFormData,
  };

  return (
    <QuestionnaireContext.Provider value={contextValue}>
      {children}
    </QuestionnaireContext.Provider>
  );
};

export default QuestionnaireContext;

// eslint-disable-next-line
const testData = {
  name: "John Doe",
  email: "john.doe@example.com",
  birthYear: "1990",
  ethnicity: ["Asian"],
  gender: "Male",
  priorities: ["Health", "Career"],
  attributes: ["Hardworking", "Creative"],
  motivation: "Self-improvement",
  financialGoals: ["Save for retirement", "Buy a house"],
  goals: ["Run a marathon", "Learn a new language"],
};
