import React from "react";
import ReactDOM from "react-dom/client";
import { Experimental_CssVarsProvider as CSSVarsProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import theme from "./themes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CSSVarsProvider theme={theme}>
    <CssBaseline />
    <App />
  </CSSVarsProvider>
);
