import React from "react";
import {
  Card,
  CardContent,
  styled,
} from "@mui/material";
import Header from "./Header";
import Image from "./Image";
import Details from "./Details";

const Container = styled(Card)(({ theme }) => ({
  backgroundColor: theme.vars.palette.common.white,
  borderRadius: 32,
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  minWidth: 256,
  padding: "8px 8px 12px 8px",
  transition: "none",
  width: 256,
}));

const ContentWrapper = styled(CardContent)(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  padding: 0,
  ":last-child": { paddingBottom: 0 },
}));

const FinancialProductCard = (props) => {
  const {
    title, image, growth, risk, color, icon, onClick = () => {},
  } = props;

  return (
    <Container onClick={onClick}>
      <Header
        {...color}
        icon={icon}
        title={title}
      />
      <ContentWrapper>
        <Image src={image} />
        <Details growth={growth} risk={risk} />
      </ContentWrapper>
    </Container>
  );
};

export default FinancialProductCard;
