import React, { useState } from "react";
import PromptPage from "../components/PromptPage";

const Page1 = (props) => {
  const { page, tips, colour, setPrompt, onNext } = props;

  return (
    <PromptPage
      pageType={'description'}
      {...page}
      tips={tips}
      colour={colour.secondary}
      setPrompt={setPrompt}
      onNext={onNext}
    />
  );
};

const Page2 = (props) => {
  const { page, options, tips, setSelectedOptions, setPrompt, onNext } = props;
  
  return (
    <PromptPage
      pageType={'product-selection'}
      {...page}
      options={options}
      tips={tips}
      setSelectedOptions={setSelectedOptions}
      setPrompt={setPrompt}
      onNext={onNext}
    />
  );
};

const Page3 = (props) => {
  const { page, options, outcome, tips, selectedOptions, setPrompt, inputs, setInputs } = props;

  return (
    <PromptPage
      pageType={'product-distribution'}
      {...page}
      options={options}
      outcome={outcome}
      tips={tips}
      selectedOptions={selectedOptions}
      setPrompt={setPrompt}
      inputs={inputs}
      setInputs={setInputs}
    />
  );
};

const PromptDistribution = (props) => {
  const { page1, page2, page3, options, outcome, tips, colour, setPrompt, /* selectedOptions, setSelectedOptions, inputs, setInputs */ } = props;
  const [page, setPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputs, setInputs] = useState([0, 0, 0]);

  // Extract amount to distribute and frequency from page1
  const { amount, frequency } = page1;

  // Used to navigate to the next page
  const onNext = () => {
    setPage((prev) => prev + 1);
  };

  const pages = [
    <Page1
      page={page1}
      tips={tips}
      colour={colour}
      setPrompt={setPrompt}
      onNext={onNext}
    />,
    <Page2
      page={page2}
      options={options}
      tips={tips}
      setSelectedOptions={setSelectedOptions}
      setPrompt={setPrompt}
      onNext={onNext}
    />,
    <Page3
      page={{...page3, amount, frequency}}
      options={options}
      outcome={outcome}
      tips={tips}
      selectedOptions={selectedOptions}
      setPrompt={setPrompt}
      inputs={inputs}
      setInputs={setInputs}
    />,
  ];

  return pages[page - 1];
};

export default PromptDistribution;
