import React from "react";
import { Box, styled } from "@mui/material";
import displayContent from "./Content";

const Container = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    gap: 32,
}));

const TextContent = ({
    content = [], color = "black", variants = {}, sx = {},
}) => (
    <Container color={color} sx={sx}>
        {displayContent(content, variants)}
    </Container>
);

export default TextContent;
