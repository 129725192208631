import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";

//eslint-disable-next-line
const Login = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={"8px"}
    >
      <Typography fontSize={"14px"} lineHeight={"22px"}>
        Already have an account?
      </Typography>
      <Button sx={{ paddingX: "24px", paddingY: "15px", height: "44px" }}>
        <Typography
          fontSize={"14px"}
          lineHeight={"12.512px"}
          fontWeight={500}
          color={"#000"}
          sx={{ textDecoration: "none" }}
        >
          Log In
        </Typography>
      </Button>
    </Box>
  );
};

const LoginPage = () => (
  <Container
    sx={{
      backgroundColor: "#0C3520",
      height: "100vh",
      width: "100vw",
    }}
  ></Container>
);

export default LoginPage;
