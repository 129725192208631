const marketingTips = {
  investment: {
    image: "assets/images/investmentstock.png",
    title: "Intelligent Investment Strategies",
    description: [
      "Dispelling Investing Myths: Wealth-building through investing isn't reserved for the wealthy. It's about patience and long-term gains, not initial wealth.",
      "Learn the essentials in our investing module: affordable assets, risk assessment, and step-by-step investment guidance.",
    ],
  },
  saving: {
    image: "assets/images/savingstock.png",
    title: "Boosting Your Savings: Effective Methods",
    description: [
      "Savings are great for short-term financial goals. Whether it's a vacation, a big purchase, insurance, or an emergency fund, a rule of thumb is to have instant access savings accounts with high interest.",
      "Explore more in our financial course to master savings strategies.",
    ],
  },
  pension: {
    image: "assets/images/pensionstock.png",
    title: "Pension: Understanding Retirement Income",
    description: [
      "Workplace pensions are employer-offered retirement plans. Employees contribute part of their salary, often matched by the employer. These offer tax benefits and compound interest for retirement savings.",
      "Explore our financial course for detailed insights on pension types and maximizing benefits.",
    ],
  },
  properties: {
    image: "assets/images/propertiesstock.png",
    title: "What are the Benefits of Investing in Properties?",
    description: [
      "Real estate presents unique chances to grow wealth and secure your financial future, offering long-term appreciation, portfolio diversification, and a steady income stream through rental earnings.",
    ],
  },
};

export default marketingTips;
