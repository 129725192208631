const title = {
    type: "title",
    text: "Pays out if you're diagnosed with a serious illness - a financial cushion during tough times.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Critical illness insurance provides a lump sum to cover expenses during serious health conditions.",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "section",
            heading: "Legal & General Critical Illness Cover",
            subheading: "Covers 41 critical illnesses with a lump sum payout.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £8 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available directly from Legal & General's website or through financial advisors.",
                        },
                    ],
                },
            ],
        },
        {
            type: "section",
            heading: "Vitality Critical Illness Cover",
            subheading: "Comprehensive cover for critical illnesses with added health and wellness benefits.",
            body: [
                {
                    type: "list",
                    items: [
                        {
                            type: "body",
                            text: "Rate: Starts from £12 per month.",
                        },
                        {
                            type: "body",
                            text: "Accessibility: Available on the Vitality website or through their network of brokers.",
                        },
                    ],
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Want peace of mind during health crises? Critical illness insurance offers financial support when you need it most.",
        },
    ],
};

const criticalIllnessInsurance = [
    title,
    benefits,
    providersExamples,
    greatFor,
];

export default criticalIllnessInsurance;
