const conditional = (type, isChecked) =>
  type === "single" ? singleType(isChecked) : defaultType(isChecked);

const defaultType = (isChecked) => ({
  backgroundColor: isChecked ? "#333" : "transparent",
  border: "1px solid #333",
  paddingLeft: "24px",
  paddingRight: "24px", 
  height: "36px",
  borderRadius: "44px",
  textTransform: "none",
  alignItems: "center",
  "&.MuiChip-clickable": {
    "&:hover": {
      backgroundColor: isChecked ? "#333" : "transparent",
    },
  },
  "&:active": {
    backgroundColor: "#333",
  },
  ".MuiChip-label": {
    paddingLeft: 0,
    paddingRight: 0,
    color: isChecked ? "#FFF" : "#333",
  },
});

const singleType = (isChecked) => ({
  backgroundColor: isChecked ? "#C7B99C" : "#F2F1EF",
  // color: "#333333",
  paddingX: "24px",
  paddingY: "8px",
  height: "44px",
  borderRadius: "40px",
  textTransform: "none",
  alignItems: "center",
  "&.MuiChip-clickable": {
    "&:hover": {
      backgroundColor: "#C7B99C",
    },
  },
  "&:active": {
    backgroundColor: "#C7B99C",
  },
  ".MuiChip-label": {
    paddingLeft: 0,
    paddingRight: 0,
    color: isChecked ? "#FFF" : "#333",
  },
});

export { conditional };
