import { PROMPT_INTERVAL, rollbackAnnualChanges } from "./promptCalculations";

// Inputs from GameOnboarding
let avatar = null;      // avatar paths have the form 'avatar-X.svg', avatar stores integer X
let playerName = null;
let playerInitialAge = 0;
let initialTotalSavings = 0;
let initialYearlySalary = 0;
let initialMonthlySpending = 0;  // Here, more positive value => more spending

// If player-chosen avatar exists, return it, else return default 1.
export const getAvatar = () => {
    return avatar ? avatar : 1;
}

// If avatarPath is given, use it, else use the avatar returned by getAvatar()
export const getAvatarURL = (avatarNum = getAvatar()) => {
    return (
        `https://s3.eu-west-1.amazonaws.com/winvestcollective.com/game/avatars/avatar-${avatarNum}.svg`
    );
}

export const getPlayerName = () => {
    return playerName ? playerName : 'Jane Doe';
}

export const getInitialPlayerAge = () => {
    return playerInitialAge;
}

export const getPlayerInputs = () => {
    return {
        playerName: playerName,
        playerInitialAge: playerInitialAge,
        initialTotalSavings: initialTotalSavings,
        initialYearlySalary: initialYearlySalary,
        initialMonthlySpending: initialMonthlySpending
    };
}

export const setAvatar = (imageURL) => {
    avatar = imageURL;
}

export const setPlayerDetails = (name, age) => {
    playerName = name;
    playerInitialAge = age;
}

export const setInitialValues = (savings, salary, spending) => {
    initialTotalSavings = savings;
    initialYearlySalary = salary;
    initialMonthlySpending = spending;
}

// Used for net worth chart as player progresses through game
let playerCurrentAge = 0;

export const getCurrentPlayerAge = () => {
    return playerCurrentAge;
}

export const setCurrentPlayerAge = (age) => {
    playerCurrentAge = age;
}

// Used in leaderboard database
let playerDatetime = null;

export const getPlayerDatetime = () => {
    return playerDatetime;
}

// Sets and returns playerDatetime
export const setPlayerDatetime = () => {
    playerDatetime = new Date().toISOString().slice(0, 19) + 'Z';
    return playerDatetime;
}

// Net Worth

const INCOME = {
    total: 0, salary: 0, mortgage: 0, spending: 0,
};

const SAVINGS = {
    total: 0, savingsAccount: 0, emergencyFund: 0, cash: 0,
};

const INVESTMENTS = {
    total: 0, indexFund: 0, blueChipStocks: 0, isaAccount: 0, juniorISA: 0,
};

const PENSION = {
    total: 0, annualGrowth: 0, contribution: 0.04,
};

// Breakdown of the player's net worth
const NET_WORTH = {
    total: 0,
    income: INCOME,
    savings: SAVINGS,
    investments: INVESTMENTS,
    pension: PENSION,
};

// Used to calculate percentage change at the end
let startingNetWorth = 0;
let finishingNetWorth = 0;

// Records all instances of NET_WORTH
// Used to allow rollback to previous prompts/states
const NET_WORTH_STATES = [];

export const getNetWorthStates = () => {
    return NET_WORTH_STATES;
}

export const saveNetWorth = () => {
    const { total, income, savings, investments, pension } = getNetWorth();
    NET_WORTH_STATES.push({
        // total: total,
        income: {
            // total: income.total,
            salary: income.salary,
            mortgage: income.mortgage,
            spending: income.spending,
        },
        savings: {
            // total: savings.total,
            savingsAccount: savings.savingsAccount,
            emergencyFund: savings.emergencyFund,
            cash: savings.cash,
        },
        investments: {
            // total: investments.total,
            indexFund: investments.indexFund,
            blueChipStocks: investments.blueChipStocks,
            isaAccount: investments.isaAccount,
            juniorISA: investments.juniorISA,
        },
        pension: {
            // total: pension.total,
            annualGrowth: pension.annualGrowth,
            contribution: pension.contribution,
        },
    });
}

export const rollbackNetWorth = () => {
    NET_WORTH_STATES.pop();
    const current = NET_WORTH_STATES.at(-1);
    // Modify INCOME, SAVINGS, INVESTMENTS, PENSION
    // Set and recalculate net worth
    Object.entries(current.income).map(([key, value]) => {
        INCOME[key] = value;
    });
    Object.entries(current.savings).map(([key, value]) => {
        SAVINGS[key] = value;
    });
    Object.entries(current.investments).map(([key, value]) => {
        INVESTMENTS[key] = value;
    });
    Object.entries(current.pension).map(([key, value]) => {
        PENSION[key] = value;
    });
    calculateNetWorth();
    // Remove annual changes for the prompt
    rollbackAnnualChanges();
    // Decrease current player age, ensure it cannot be less than initial age
    const prevAge = getCurrentPlayerAge() - PROMPT_INTERVAL;
    setCurrentPlayerAge(
        prevAge < getInitialPlayerAge() ? getInitialPlayerAge() : prevAge
    );
}

// Initialises the player's net worth and records startingNetWorth
// For mortgage and spending, more negative value => higher value
export const initNetWorth = () => {
    INCOME.salary = initialYearlySalary / 12;
    INCOME.mortgage = 0;
    INCOME.spending = -initialMonthlySpending;    // spending is negative
    SAVINGS.savingsAccount = 0;
    SAVINGS.emergencyFund = 0;
    SAVINGS.cash = initialTotalSavings;
    INVESTMENTS.indexFund = 0;
    INVESTMENTS.blueChipStocks = 0;
    INVESTMENTS.isaAccount = 0;
    INVESTMENTS.juniorISA = 0;
    PENSION.annualGrowth = 0;
    PENSION.contribution = 0.04;
    startingNetWorth = getTotalNetWorth();
}

// Updates and returns net worth
export const getNetWorth = () => {
    calculateNetWorth();
    return NET_WORTH;
}

// Updates net worth, and returns the total
const getTotalNetWorth = () => {
    calculateNetWorth();
    return NET_WORTH.total;
}

// Returns starting net worth in '£ X,XXX' format
export const getStartingNetWorth = () => {
    return formatMoneyWithSpace(startingNetWorth);
}

// Sets and returns finishing net worth in '£ X,XXX' format
export const setFinishingNetWorth = () => {
    finishingNetWorth = getTotalNetWorth();
    return formatMoneyWithSpace(finishingNetWorth);
}

// Calculates percentage change from starting to finishing net worth
export const getPercentageChange = () => {
    let percentageChange;

    if (startingNetWorth === 0) {
        // Divides by a starting net worth of 0.01
        // Subtract 1 to return a value of the form: 999999%
        // Assume never used <= very unlikely to have zero startingNetWorth
        percentageChange = ( finishingNetWorth * 100 / 0.01 ) - 1;
    } else {
        percentageChange = ( finishingNetWorth - startingNetWorth ) * 100 / startingNetWorth;
    }

    return percentageChange ? percentageChange : 0;
}

// Converts numeric value to string format '£ X,XXX.XX'
export const formatMoneyWithSpace = (value) => {
    return `${value < 0 ? '- ' : ''}£ ${formatDecimal(value)}`;
}

// Converts numeric value to string format '£X,XXX.XX'
export const formatMoney = (value) => {
    return `${value < 0 ? '-' : ''}£${formatDecimal(value)}`;
}

export const formatDecimal = (value) => {
    return Math.abs(value).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
}

// calculateX functions update the 'total' property of X
// Used to update the player's total net worth

const calculateIncome = () => {
    INCOME.total = INCOME.salary + INCOME.mortgage + INCOME.spending;
}

const calculateSavings = () => {
    SAVINGS.total = (
        SAVINGS.savingsAccount + SAVINGS.emergencyFund + SAVINGS.cash
    );
}

const calculateInvestments = () => {
    INVESTMENTS.total = (
        INVESTMENTS.indexFund
        + INVESTMENTS.blueChipStocks
        + INVESTMENTS.isaAccount
        + INVESTMENTS.juniorISA
    );
}

const calculatePension = () => {
    PENSION.total = PENSION.annualGrowth;
}

const calculateNetWorth = () => {
    calculateIncome();
    calculateSavings();
    calculateInvestments();
    calculatePension();
    // net worth = total assets - total liabilities
    NET_WORTH.total = (
        (SAVINGS.total + INVESTMENTS.total + PENSION.total)
            - Math.abs(NET_WORTH.income.mortgage)
    );
}

// GameMap

// An integer indicating the current block/prompt number
// 1 to 15 for prompts, 0 for start, -1 for finish
let markerPosition = 0;

export const getMarkerPosition = () => {
    return markerPosition;
}

export const setMarkerPosition = (pos) => {
    markerPosition = pos;
}

// Initialises the game
export const initGame = () => {
    markerPosition = 0;
    setCurrentPlayerAge(getInitialPlayerAge());
    initNetWorth();
    saveNetWorth();
}
