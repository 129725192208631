const title = {
    type: "title",
    text: "This is your guaranteed chart-topper - a regular payment from the government that you can claim when you reach State Pension age, based on your National Insurance contributions.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "The State Pension provides a basic level of income in retirement, serving as a foundation for your retirement savings.",
        },
    ],
};

const growth = {
    type: "section",
    heading: "Growth",
    body: [
        {
            type: "body",
            text: "Indexed (increases in line with inflation, earnings, or 2.5%, whichever is highest). Historically, the State Pension has been triple-locked, ensuring it grows with the highest of these three measures.",
        },
    ],
};

const risk = {
    type: "section",
    heading: "Risk",
    body: [
        {
            type: "body",
            text: "Low (government-backed)",
        },
    ],
};

const providersExamples = {
    type: "section",
    heading: "Providers Examples",
    body: [
        {
            type: "body",
            text: "Government (UK): Basic State Pension",
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "The State Pension provides a foundation for your retirement income, ensuring a basic level of financial security.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Automatically enrolled based on National Insurance contributions, managed through the UK government's pension service.",
        },
    ],
};

const statePension = [
    title,
    benefits,
    growth,
    risk,
    providersExamples,
    greatFor,
    accessibility,
];

export default statePension;
