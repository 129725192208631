import React, { useEffect, useState } from 'react';
import PromptPage from '../components/PromptPage';
import Leaderboard from '../../Leaderboard/Leaderboard';
import { BankStatement } from '../../components/MyNetWorth';
import { useNavigate } from 'react-router-dom';
import { PROMPTS } from '../../GameMap/mapBlocks';
import { getAvatar, setFinishingNetWorth, getPercentageChange, getPlayerName, getStartingNetWorth, setPlayerDatetime } from '../../playerState';

const PromptFinish = (/* props */) => {
    const props = PROMPTS.promptFinish;
    const { page, colour, setPrompt } = props;
    const [popup, setPopup] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const postPlayer = async () => {
            const lambaUrl =
                "https://v7pv4kidb8.execute-api.eu-west-1.amazonaws.com/default/storePlayerScore";
            const playerData = {
                player_name: getPlayerName(),
                player_icon: getAvatar(),
                datetime: setPlayerDatetime(),
                starting_net_worth: getStartingNetWorth(),
                finishing_net_worth: setFinishingNetWorth(),
                percentage_change: getPercentageChange(),
            };
            
            try {
                const response = await fetch(lambaUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(playerData),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                // No need to process response body
                console.log(`Request successful with status: ${response.status}`);
            } catch (error) {
                // handle error
                console.log(error);
            }
        };

        postPlayer();
    }, []);

    const handleFinish = (choiceIndex) => {
        switch (choiceIndex) {
            case 0:
                console.log('restart game');
                navigate('../wealth-quest', { replace: true });
                break;
            case 1:
                console.log('show net worth');
                setPopup(<BankStatement setBankStatement={setPopup} />);
                break;
            default:
                console.log('show leaderboard');
                setPopup(<Leaderboard setLeaderboard={setPopup} />);
                break;
        };
    }

    return (
        <div className='prompt' style={{ backgroundColor: colour.primary }}>
            <div className='prompt-content'>
                <PromptPage
                    pageType={'finish'}
                    {...page}
                    onChoice={handleFinish}
                    includeSkip={false}
                    includeFooter={false}
                    colour={colour.secondary}
                    // setPrompt={setPrompt}
                />
                {popup}
            </div>
        </div>
    );
}

export default PromptFinish;
