import React from "react";
import { Stack } from "@mui/material";
import { Body, Form, ChatProvider } from "@features/aichat";

const AIChatPage = () => {
  return (
    <ChatProvider>
      <Stack sx={{
        height: "100%",
        background: "radial-gradient(55.48% 72.79% at -3.33% 70.62%, rgba(237, 60, 119, 0.14) 0%, rgba(255, 255, 255, 0.20) 100%), radial-gradient(62.97% 57% at 100% 82.23%, #F9E6D1 0%, rgba(255, 255, 255, 0.00) 100%), #FCEFF0",
        flex: "1 1 auto"
      }}>
        <Body />
        <Form />
      </Stack>
    </ChatProvider>
  );
};

export default AIChatPage;
