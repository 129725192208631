const title = {
    type: "title",
    text: "These funds are like a make-up palette, a mix of various assets packaged into one investment.",
};

const description = {
    type: "body",
    text: "ETFs provide diversification and can be tailored to specific investment goals or sectors. There's no minimum investment, making them accessible for all investors.",
};

const benefits = {
    type: "section",
    heading: "Benefits",
    body: [
        {
            type: "body",
            text: "Provides diversification and can be tailored to specific investment goals or sectors.",
        },
    ],
};

const eftsExamples = {
    type: "section",
    heading: "EFTs Examples",
    body: [
        {
            type: "list",
            items: [
                {
                    type: "section",
                    heading: "iShares Core S&P 500 ETF (IVV)",
                    subheading: "Average annual return of 13.5% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "Vanguard Total Stock Market ETF (VTI)",
                    subheading: "Average annual return of 13.1% over the past 10 years.",
                },
                {
                    type: "section",
                    heading: "SPDR S&P 500 ETF (SPY)",
                    subheading: "Average annual return of 13.4% over the past 10 years.",
                },
            ],
        },
    ],
};

const greatFor = {
    type: "section",
    heading: "Great For",
    body: [
        {
            type: "body",
            text: "Want a diversified portfolio without picking individual stocks? ETFs spread your risk across many assets, making it easier to manage.",
        },
    ],
};

const accessibility = {
    type: "section",
    heading: "Accessibility",
    body: [
        {
            type: "body",
            text: "Easily bought and sold on the stock market.",
        },
    ],
};

const indexFunds = [
    title,
    description,
    benefits,
    eftsExamples,
    greatFor,
    accessibility,
];

export default indexFunds;
