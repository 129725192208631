const easyAccessSavingsAccount = {
  category: "savingsAccounts",
  title: "Easy Access Savings Accounts",
  growth: { low: 0.5, high: 2 },
  risk: "low",
};

const regularSavingsAccount = {
  category: "savingsAccounts",
  title: "Regular Savings Accounts",
  growth: { low: 1, high: 3 },
  risk: "low",
};

const fixedRateBonds = {
  category: "savingsAccounts",
  title: "Fixed-Rate Bonds",
  growth: { low: 2, high: 5 },
  risk: "low",
};

const cashISA = {
  category: "ISAs",
  title: "Cash ISAs",
  growth: { low: 0.5, high: 2 },
  risk: "low",
};

const stocksAndSharesISA = {
  category: "ISAs",
  title: "Stocks and Shares ISAs",
  growth: { low: 5, high: 8 },
  risk: "moderateToHigh",
};

const lifetimeISA = {
  category: "ISAs",
  title: "Lifetime ISAs (LISA)",
  growth: { other: "25% + interest per year" },
  risk: "lowToModerate",
};

const juniorISA = {
  category: "ISAs",
  title: "Junior ISAs",
  growth: { low: 0.5, high: 8 },
  risk: "lowToHigh",
};

const personalPension = {
  category: "pensionProducts",
  title: "Personal Pensions",
  growth: { low: 4, high: 7 },
  risk: "moderateToHigh",
};

const workplacePension = {
  category: "pensionProducts",
  title: "Workplace Pensions",
  growth: { low: 4, high: 8 },
  risk: "moderateToHigh",
};

const sipp = {
  category: "pensionProducts",
  title: "Self-Invested Personal Pensions (SIPPs)",
  growth: { low: 5, high: 9 },
  risk: "moderateToHigh",
};

const stakeholderPension = {
  category: "pensionProducts",
  title: "Stakeholder Pensions",
  growth: { low: 3, high: 6 },
  risk: "moderateToHigh",
};

const statePension = {
  category: "pensionProducts",
  title: "State Pension",
  growth: { other: "increase in line with inflation, earnings, or 2.5%" },
  risk: "low",
};

const blueChipStocks = {
  category: "investmentProducts",
  title: "Blue Chip Stocks",
  growth: { low: 4, high: 7 },
  risk: "moderate",
};

const growthStocks = {
  category: "investmentProducts",
  title: "Growth Stocks",
  growth: { other: "Varies (could go up to 1000%)" },
  risk: "high",
};

const indexFunds = {
  category: "investmentProducts",
  title: "Index Funds and ETFs",
  growth: { low: 5, high: 8 },
  risk: "moderateToHigh",
};

const mutualFunds = {
  category: "investmentProducts",
  title: "Mutual Funds and Unit Trusts",
  growth: { low: 4, high: 8 },
  risk: "moderate",
};

const reit = {
  category: "investmentProducts",
  title: "Real Estate Investment Trusts (REITs)",
  growth: { low: 4, high: 7 },
  risk: "moderate",
};

const bonds = {
  category: "investmentProducts",
  title: "Bonds",
  growth: { low: 1, high: 5 },
  risk: "lowToModerate",
};

const diyInvestmentPlatform = {
  category: "financialPlatforms",
  title: "DIY Investment Platforms",
  growth: { other: "N/A" },
  risk: "varies",
};

const humanFinancialAdvisor = {
  category: "financialPlatforms",
  title: "Human Financial Advisors",
  growth: { other: "N/A" },
  risk: "varies",
};

const roboAdvisor = {
  category: "financialPlatforms",
  title: "Robo-Advisors",
  growth: { other: "N/A" },
  risk: "varies",
};

const healthInsurance = {
  category: "insuranceProducts",
  title: "Health Insurance",
  growth: { other: "N/A" },
  risk: "low",
};

const lifeInsurance = {
  category: "insuranceProducts",
  title: "Life Insurance",
  growth: { other: "N/A" },
  risk: "low",
};

const carInsurance = {
  category: "insuranceProducts",
  title: "Car Insurance",
  growth: { other: "N/A" },
  risk: "low",
};

const homeInsurance = {
  category: "insuranceProducts",
  title: "Home Insurance",
  growth: { other: "N/A" },
  risk: "low",
};

const travelInsurance = {
  category: "insuranceProducts",
  title: "Travel Insurance",
  growth: { other: "N/A" },
  risk: "low",
};

const incomeProtectionInsurance = {
  category: "insuranceProducts",
  title: "Income Protection Insurance",
  growth: { other: "N/A" },
  risk: "low",
};

const criticalIllnessInsurance = {
  category: "insuranceProducts",
  title: "Critical Illness Insurance",
  growth: { other: "N/A" },
  risk: "low",
};

const generalInvestmentAccount = {
  category: "investmentAccounts",
  title: "General Investment Accounts",
  growth: { low: 5, high: 8 },
  risk: "moderateToHigh",
};

const stocksAndSharesAccount = {
  category: "investmentAccounts",
  title: "Stocks and Shares ISAs",
  growth: { low: 5, high: 8 },
  risk: "moderateToHigh",
};

// Contains all financial products
const financialProducts = {
  blueChipStocks,
  bonds,
  carInsurance,
  cashISA,
  criticalIllnessInsurance,
  diyInvestmentPlatform,
  easyAccessSavingsAccount,
  fixedRateBonds,
  generalInvestmentAccount,
  growthStocks,
  healthInsurance,
  homeInsurance,
  humanFinancialAdvisor,
  incomeProtectionInsurance,
  indexFunds,
  juniorISA,
  lifeInsurance,
  lifetimeISA,
  mutualFunds,
  personalPension,
  regularSavingsAccount,
  reit,
  roboAdvisor,
  sipp,
  stakeholderPension,
  statePension,
  stocksAndSharesAccount,
  stocksAndSharesISA,
  travelInsurance,
  workplacePension,
};

export default financialProducts;
