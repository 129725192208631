import React from "react";
import { Avatar, CardHeader, styled } from "@mui/material";
import icons from "./icons";

const IconAvatar = styled(Avatar)(({ color }) => ({
  width: 40,
  height: 40,
  backgroundColor: color,
}));

const Wrapper = styled(CardHeader, {
  shouldForwardProp: (prop) => (
    prop !== "backgroundColor" &&
    prop !== "textColor"
  ),
})(({ theme, backgroundColor, color, textColor = color }) => ({
  backgroundColor: backgroundColor,
  padding: "6px 8px",
  borderRadius: 48,
  gap: 8,
  height: 48,
  ".MuiCardHeader-title": {
    ...theme.typography.bodyEmphasis,
    color: textColor,
  },
  ".MuiCardHeader-avatar": { margin: 0 },
}));

const Header = (props) => {
  const { icon, color, sx = {} } = props;

  return (
    <Wrapper
      avatar={<IconAvatar color={color} sx={sx.avatar}>
        {icons[icon]}
      </IconAvatar>}
      {...props}
      sx={sx.wrapper}
    />
  );
};

export default Header;
