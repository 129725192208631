import React from "react";
import {
  Box,
  Divider,
  Grid,
  keyframes,
  styled,
  Typography,
} from "@mui/material";
import GoogleAuth from "./GoogleAuth";
import GridContainer from "@features/signup/layouts/GridContainer";
import { useAuthContext } from "@hooks";
import SubmitEmailButton from "./SubmitEmailButton";
import AuthInputField from "./AuthInputField";
import Separator from "./Separator";

// Used for SigningIn
const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const Logo = () => {
  return (
    <img
      src="assets/logos/logo-light-green.svg"
      color="#FFF"
      alt=""
      width={"183px"}
    />
  );
};

const Caption = styled(({ children, ...props }) => (
  <Typography variant="caption1" {...props}>
    {children}
  </Typography>
))(({ theme }) => ({
  color: "#B7D9CF",
  textAlign: "center",
  padding: "0 40px",
  [theme.breakpoints.down("md")]: {
    padding: "0px",
  },
}));

const ButtonsSection = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

// Displayed while client waits for sign-in API
const SigningIn = styled((props) => (
  <Typography variant="bodyL" {...props}>
    Signing in...
  </Typography>
))(() => ({
  alignSelf: "center",
  animation: `${fadeIn} 0.5s ease-in-out`,
  animationFillMode: "forwards",
  color: "#5C736C",
  opacity: 0,
}));

const AuthGridContainer = styled(GridContainer)(({ theme }) => ({
  backgroundColor: "#144235",
  padding: "40px",
  borderRadius: "32px 0px 0px 32px",
  gap: "10px",
  justifyContent: "normal",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: "64px 24px 40px 24px",
    borderRadius: 0,
    gap: "40px",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "48px",
  padding: "24px 40px 0px 40px",
  [theme.breakpoints.down("md")]: {
    padding: "0px",
  },
}));

const EmailFrame = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: 16,
});

const Frame = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: 32,
  width: 320,
});

const Authentication = () => {
  const { isSignedIn, setUserEmail, email, setName, name } = useAuthContext();

  return (
    <AuthGridContainer>
      <Logo />
      <InnerContainer>
        <Frame>
          <EmailFrame>
            <Typography variant="h2" color={"#FFF"}>
              Sign up
            </Typography>
            <AuthInputField
              id="name"
              label={"Name"}
              value={name}
              setValue={setName}
            />
            <AuthInputField
              id="email"
              label={"E-mail"}
              value={email}
              setValue={setUserEmail}
            />
            <SubmitEmailButton />
            <Caption>
              Your privacy is our top priority and all your data is securely
              stored
            </Caption>
          </EmailFrame>
          <Separator />
          <ButtonsSection>
            <GoogleAuth />
            <Caption>
              Please sign in using Google through the Chrome browser, and click
              the sign-in button only once.
            </Caption>
          </ButtonsSection>
        </Frame>
      </InnerContainer>
      {isSignedIn && <SigningIn />}
    </AuthGridContainer>
  );
};

export default Authentication;
