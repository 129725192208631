import React from "react";
import { Button, Typography } from "@mui/material";
import { useQuestionnaireContext } from "@hooks";
import { totalPages } from "@content/questionnaire";

const handleClick = (page, setPage, formData, sendFormData) => {
  setPage(page + 1);
  if (page === totalPages) {
    sendFormData();
  }
};

const ProgressButton = (props) => {
  const { page, setPage, formData, sendFormData } = useQuestionnaireContext();

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#000",
        color: "#FFF",
        paddingX: "24px",
        paddingY: "15px",
        borderRadius: "44px",
        textTransform: "none",
      }}
      onClick={() => handleClick(page, setPage, formData, sendFormData)}
    >
      <Typography
        flex={"1 0 0"}
        fontWeight={500}
        fontSize={"15px"}
        lineHeight={"12.512px"}
      >
        {props.text}
      </Typography>
    </Button>
  );
};

export default ProgressButton;
